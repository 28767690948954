import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSync, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import StepEditLink from './StepEditLink';
import { BackendService, UsefulLinkDto, UsefulLinkGetDto } from '../../api/services.generated';
import { useAuthorizedBackendApi } from '../../api/api';

export default function StepLink(props: {stepId: string | null}) {
    const api:BackendService | null = useAuthorizedBackendApi();
    const [stepId, setStepId] = useState<string | null>(props.stepId);
    const [linkId, setLinkId] = useState<string | null | undefined>("");
    const [title, setTitle] = useState<string | null | undefined>("");
    const [description, setDescription] = useState<string | null | undefined>("");
    const [url, setUrl] = useState<string | null | undefined>("");
    const [links, setLinks] = useState<UsefulLinkDto[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [loadingLink, setLoadingLink] = useState<boolean>(false);
    
    useEffect(()=>{
        refreshLinkTable();
    },[]);
    
    async function refreshLinkTable() {
        if(!api) {
            return;
        }

        try {
            const response:UsefulLinkDto[] | null = await api.step_GetUsefulLinks(props.stepId);

            if (!response) {
                return;
            }

            setLinks(response);
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
    }

    async function newStepLink() {
        setLoadingLink(true);
        
        if(!api) {
            return;
        }

        try {
            const response:UsefulLinkDto[] | null = await api.step_GetUsefulLinks(props.stepId);

            if (!response) {
                return;
            }

            setLinks(response);
            setLinkId("");
            setTitle("");
            setDescription("");
            setUrl("");
            setLoadingLink(false);
            
            toast.success("Mise à jour effectuée !", {
                position: "top-center",
                hideProgressBar: false
            });
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
            setLinks([]);
            setLoadingLink(false);
        }
    }

    async function getEditLink(linkId: string | null | undefined) {
        setLoadingLink(true);
        if(!api) {
            return;
        }

        try {
            const response:UsefulLinkDto | null = await api.step_GetUsefulLink(linkId, props.stepId);

            if (!response) {
                return;
            }

            setLinkId(linkId);
            setTitle(response.title);
            setUrl(response.url);
            setDescription(response.description);
            setLoadingLink(false);

            toast.success("Mise à jour effectuée !", {
                position: "top-center",
                hideProgressBar: false
            });
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
        finally {
            setLoadingLink(false);
        }
    }

    async function deleteUsefulLink(id: string | null | undefined) {
        fetch(new Request("api/Step/DeleteUsefulLink"), {
            method: "DELETE",
            body: JSON.stringify({
                StepId: props.stepId,
                LinkId: id
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {
            if (ok) {
                toast.success("Le lien a été supprimé!", {
                    position: "top-left",
                    hideProgressBar: false
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }
    
    return (
        <div className="row card-body">
            <div className="col-md-8">
                <div>
                    <div>
                        <div className="alert alert-secondary">
                            <Button variant="contained" size="small" color="primary" className="mr-2 text-capitalize" onClick={newStepLink}>
                                <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm" /> Nouveau
                            </Button>
                            <Button variant="contained" size="small" className="text-capitalize btn-info-custom" onClick={refreshLinkTable}>
                                <FontAwesomeIcon icon={faSync} className="mr-1" size="sm" /> Rafraîchir
                            </Button>
                        </div>
                        <div className="table-responsive">
                            <Table className="w-100" size="small">
                                <TableHead style={{ backgroundColor: "#000" }}>
                                    <TableRow>
                                        <TableCell style={{ width: "20px" }} />
                                        <TableCell style={{ color: "#fff" }}>Titre</TableCell>
                                        <TableCell style={{ color: "#fff" }}>Url</TableCell>
                                        <TableCell style={{ color: "#fff" }}>Description</TableCell>
                                        <TableCell style={{ width: "20px" }} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        links !== null ? 
                                        links.map((row: UsefulLinkDto, i: string | number| undefined) => (
                                            <TableRow key={i}>
                                                <TableCell align="center">
                                                    <FontAwesomeIcon icon={faPen} className="pointer"
                                                        onClick={() => getEditLink(row.linkId)} />
                                                </TableCell>
                                                <TableCell>{row.title}</TableCell>
                                                <TableCell>
                                                    {
                                                        row.url !== null ?
                                                        <a href={row.url} target="_blank">{row.url}</a>
                                                        : <>Adresse non définie</>
                                                    }
                                                </TableCell>
                                                <TableCell>{row.description}</TableCell>
                                                <TableCell className="float-right">
                                                    <FontAwesomeIcon icon={faTrash} className="text-danger pointer" onClick={() => deleteUsefulLink(row.linkId)} />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        : null
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                {
                    !loadingLink ?
                        <StepEditLink refresh={refreshLinkTable} stepId={props.stepId} linkId={linkId} title={title} url={url} description={description}  /> :
                        <FontAwesomeIcon icon={faSync} className="fa-5x fa-spin" style={{ marginLeft: "40%" }} />
                }
            </div>
        </div>
    );
}
