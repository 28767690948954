import React, { Component, Context } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ActivitiesDto, BackendService } from '../../../api/services.generated';
import { AuthorizedBackendApiContext } from '../../../api/api';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faPlusCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import EditActivity from './EditActivity';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

interface MyState {
    activities:ActivitiesDto[],
    api: BackendService | null
}

export default class ActivitiesList extends Component<{}, MyState> {

    static contextType:Context<BackendService | null> = AuthorizedBackendApiContext

    state: MyState = {
        activities:[],
        api:null
    }
    EditActivityRef: React.RefObject<EditActivity>

    constructor({}){
        super({});

        this.EditActivityRef = React.createRef();

        this.handleGetActivities = this.handleGetActivities.bind(this);
        this.handleNewActivity = this.handleNewActivity.bind(this);
    }

    componentDidMount(){
        this.setState({
            api:this.context as BackendService
        },()=>this.handleGetActivities());
    }

    async handleGetActivities(){
        if(!this.state.api){
            return;
        }
        
        try
        {
            const response:ActivitiesDto[] | null = await this.state.api.activity_GetActivities();
            this.setState({
                activities: response ? response : []
            })
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        }
    }

    handleNewActivity(){
        this.EditActivityRef.current?.handleClearField();
    }

    handleEditActivity(activityId:string | null | undefined){
        if(activityId && activityId !== undefined)
            this.EditActivityRef.current?.editActivity(activityId);
    }

    render() {
        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Activités - Success Team</title>
                        <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                    </MetaTags>
                </div>
                <nav className="siteStyle top-nav">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to='/dashboard'>Dashboard</Link></li>
                        <li className="breadcrumb-item active"><h1 className="siteTitle">Activités</h1></li>
                    </ol>
                </nav>
                <div className="row py-3 px-0 mx-3">
                    <div className="col-md-8">
                        <div className="alert alert-secondary">
                            <Button variant="contained" className="text-initial mr-2" color="primary" size="small" onClick={this.handleNewActivity}>
                                <FontAwesomeIcon icon={faPlusCircle}  className="mr-2" /> Nouvelle
                            </Button>
                            <Button variant="contained" className="btn-info-custom text-initial" size="small" onClick={this.handleGetActivities}>
                                <FontAwesomeIcon icon={faSync} className="mr-2" /> Rafraîchir
                            </Button>
                        </div>
                        <Table className="w-100" size="small">
                            <TableHead style={{ backgroundColor: "#000" }}>
                                <TableRow>
                                    <TableCell style={{ color: "#fff" }}>Activité</TableCell>
                                    <TableCell style={{ color: "#fff" }}>Description</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.activities.map((activity:ActivitiesDto)=>(
                                        <TableRow key={activity.activityId?.toString()}>
                                            <TableCell>{activity.activityName}</TableCell>
                                            <TableCell>{activity.activityDescription}</TableCell>
                                            <TableCell align="center">
                                                <IconButton style={{fontSize:"1em"}} onClick={()=>this.handleEditActivity(activity.activityId)}>
                                                    <FontAwesomeIcon icon={faPen} />
                                                </IconButton>
                                                <IconButton style={{fontSize:"1em"}}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </div>
                    <div className="col-md-4">
                        { this.state.api && <EditActivity api={this.state.api} ref={this.EditActivityRef} 
                        refreshActivities={this.handleGetActivities} />}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
