import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import { VerifiedInput } from '../global/VerifiedInput';
import { TextField } from '@material-ui/core';
import { getErrorMessage } from "../../api/utils";
import { AuthorizedBackendApiContext } from "../../api/api";


export default class CreateMember extends Component {
    static contextType = AuthorizedBackendApiContext;

    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            city: "",
            country: "",
            address: "",
            teams: [{value:"dqs123123dqsdqs", label:"Invité"}],
            teamId: "",
            teamName: "",
            team: "",
            openModal: true,
            btnSaveLoading: false,
            dataLoaded: this.props.memberId !== "" ? false : true,
            birthDate: null,
            facebook: "",
            linkedIn: "",
            instagram: "",
            socialNetworkOther: "",
            genre:"Male"
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleCreateMember = this.handleCreateMember.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        this.getTeams();

        if (this.props.memberId !== "")
            this.getMember(this.props.memberId);
    }

    handleClose() {
        this.props.onCloseModal();
        this.setState({
            openModal: false
        });
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSelect(e) {
        this.setState({
            teamId: e.value,
            teamName: e.label
        });
    }

    async handleCreateMember() {
        this.setState({
            btnSaveLoading: true
        });

        try {
            const result = await this.context.member_SetMember({
                Id: this.props.memberId,
                FirstName: this.state.firstName,
                LastName: this.state.lastName,
                Email: this.state.email,
                PhoneNumber: this.state.phone,
                Genre: this.state.genre,
                City: this.state.city,
                Country: this.state.country,
                Address: this.state.address,
                Team: {
                    TeamId: this.state.team,
                    TeamName: this.state.team && this.state.teams.find(s => s.value === this.state.team).label
                }
            });
            toast.success(result.message + " !", {
                position: "top-center",
                hideProgressBar: false
            });
            this.handleClose();
            this.props.refreshTable();
        } catch (error){
            toast.warn(getErrorMessage(error) + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        } finally {
            this.setState({
                btnSaveLoading: true
            });
        }
    }

    getMember(memberId) {
        fetch(new Request("api/Member/GetMember?memberId=" + memberId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        firstName: response.firstName,
                        lastName: response.lastName,
                        email: response.email,
                        phone: response.phoneNumber,
                        genre: response.genre,
                        city: response.city,
                        country: response.country,
                        address: response.address,
                        teamId: response.teamId,
                        birthDate: response.birthDate,
                        facebook: response.facebook,
                        linkedIn: response.linkedIn,
                        instagram: response.instagram,
                        socialNetworkOther: response.socialNetworkOther
                    });
                    this.getTeams();
                    this.setState({ team: response.teamId, dataLoaded: true }); // To optimize later
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
            });
    }

    getTeams() {
        fetch(new Request("api/Team/GetTeamsRegister", {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    response.map(row => (
                        this.setState({
                            teams: [...this.state.teams, {
                                value: row.teamId,
                                label: row.teamName
                            }]
                        }, () => { // To optimize after, just a quick fix
                            var filtered = this.state.teams.reduce((filtered, item) => {
                                if (!filtered.some(filteredItem => JSON.stringify(filteredItem) === JSON.stringify(item)))
                                    filtered.push(item)
                                return filtered
                            }, []);
                            this.setState({ teams: filtered });
                        })
                    ));
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
                this.setState({
                    teams: []
                });
            });
    }

    render() {
        const disabled = this.props.memberId !== "";
        return (
            <Modal show={this.state.openModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="siteStyle">
                        <span className="h6">{this.props.memberId === "" ? "Créer un nouveau membre" : "Editer un membre"}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        !this.state.dataLoaded ? 
                            <React.Fragment>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <Skeleton />
                                    </div>
                                </div>
                            </React.Fragment> : 
                            <React.Fragment>
                                
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <VerifiedInput
                                            name="firstName" title="Prénom(s)" value={this.state.firstName}
                                            checked={false} onChange={this.handleInput}
                                            type="text" errorMessage="" disabled={disabled}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <VerifiedInput
                                            name="lastName" title="Nom(s)" value={this.state.lastName}
                                            checked={false} onChange={this.handleInput}
                                            type="text" errorMessage="" disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <TextField fullWidth name="email" label="Email" value={this.state.email}
                                            onChange={this.handleInput} type="email" disabled={disabled} variant="filled" />
                                    </div>
                                    <div className="col-md-6">
                                        <VerifiedInput
                                            name="genre" title="Genre" value={this.state.genre}
                                            checked={false} onChange={this.handleInput}
                                            type="select" errorMessage="" disabled={disabled}
                                            options={[
                                                { value: "Male", label: "Masculin" },
                                                { value: "Female", label: "Féminin" }
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <VerifiedInput
                                            name="team" title="Equipe" value={this.state.team} /*value={this.state.teams.find(s => s.value === this.state.teamId)}*/
                                            checked={false} onChange={this.handleInput} disabled={disabled}
                                            type="select" errorMessage="" options={this.state.teams}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <VerifiedInput
                                            name="phone" title="Téléphone" value={this.state.phone}
                                            checked={false} onChange={this.handleInput}
                                            type="text" errorMessage="" disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <VerifiedInput
                                            name="city" title="Ville" value={this.state.city}
                                            checked={false} onChange={this.handleInput}
                                            type="text" errorMessage="" disabled={disabled}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <VerifiedInput
                                            name="country" title="Pays" value={this.state.country}
                                            checked={false} onChange={this.handleInput}
                                            type="text" errorMessage="" disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <VerifiedInput
                                            name="address" title="Adresse" value={this.state.address}
                                            checked={false} onChange={this.handleInput}
                                            type="textarea" errorMessage="" disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.props.memberId === "" &&
                        <Button className="mr-1 text-capitalize" variant="contained" color="primary" size="small"
                            onClick={this.handleCreateMember} disabled={this.state.btnSaveLoading}>
                            <FontAwesomeIcon icon={faSave} className="mr-1" size="sm" /> Enregister
                        </Button>
                    }
                    
                    <Button variant="contained" size="small" className="text-capitalize bg-white" onClick={this.handleClose}>
                        <FontAwesomeIcon icon={faTimesCircle} className="mr-1" size="sm" /> Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
