import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSync, faPen } from '@fortawesome/free-solid-svg-icons'
import { BackendService, ModulesDto } from '../../../api/services.generated'
import { useAuthorizedBackendApi } from '../../../api/api'
import { toast } from 'react-toastify'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Chip from '@material-ui/core/Chip'
import CreateModule from './CreateModule'
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags'

export default function ModulesList() {

    const editModuleRef = useRef<CreateModule | null>(null)
    const api: BackendService | null = useAuthorizedBackendApi()

    const [modules, setModules] = useState<ModulesDto[]>([])
    const [modulesLoading, setModulesLoading] = useState<boolean>(false)

    useEffect(() => {
        refreshTable()
    }, [])

    function newModule(){
        editModuleRef.current!.initFields()
    }

    async function refreshTable(){
        if(!api) {
            return;
        }

        setModulesLoading(true)
        try{
            const response: ModulesDto[] | null = await api.module_GetModules()
            setModules(response ? response : [])
        }
        catch(error){
            toast.warn(error + " !", {
                hideProgressBar: false,
                position: "top-left"
            });
            setModules([])
        }
        finally{
            setModulesLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>Modules - Success Team</title>
                    <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </MetaTags>
            </div>
            <nav className="siteStyle top-nav">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to='/dashboard'>Dashboard</Link></li>
                    <li className="breadcrumb-item active"><h1 className="siteTitle">Modules</h1></li>
                </ol>
            </nav>
            <div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            {
                                modulesLoading ? <FontAwesomeIcon icon={faSync} className="fa-spin fa-5x" style={{marginLeft:"40%",marginTop:"20%"}} /> : 
                                <React.Fragment>
                                    <div className="alert alert-secondary">
                                        <Button variant="contained" size="small" color="primary" className="mr-2 text-capitalize" onClick={newModule}>
                                            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm" /> Nouveau
                                        </Button>
                                        <Button variant="contained" size="small" className="text-capitalize btn-info-custom" onClick={refreshTable}>
                                            <FontAwesomeIcon icon={faSync} className="mr-1" size="sm" /> Rafraîchir
                                        </Button>
                                    </div>
                                    <div className="table-responsive">
                                        <Table className="w-100" size="small">
                                            <TableHead style={{ backgroundColor: "#000" }}>
                                                <TableRow>
                                                    <TableCell style={{ color: "#fff" }}></TableCell>
                                                    <TableCell style={{ color: "#fff" }}>Nom de module</TableCell>
                                                    <TableCell style={{ color: "#fff" }}>Etape</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    modules.map((row:ModulesDto) => (
                                                        <TableRow key={row.moduleId?.toString()}>
                                                            <TableCell>
                                                                <FontAwesomeIcon icon={faPen} className="pointer"
                                                                    onClick={() => editModuleRef.current!.getModule(row.moduleId)} />
                                                            </TableCell>
                                                            <TableCell>{row.moduleName}</TableCell>
                                                            <TableCell>
                                                                <Chip label={row.stepsCount} color="primary" size="small" />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                        <div className="col-md-6">
                            <CreateModule refresh={refreshTable} ref={editModuleRef} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
