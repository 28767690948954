import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faHome } from '@fortawesome/free-solid-svg-icons';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';

export default class JamSessionParticipation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            severitySnackbar: "",
            messageSnackbar:""
        }
    }

    componentDidMount() {
        fetch(new Request("api/JamSession/ConfirmJamSession"), {
            method: "POST",
            body: JSON.stringify({
                JamSessionId: this.props.match.params.jamsession,
                GuestId: this.props.match.params.member
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    var severity, message;

                    if (response.register) {
                        severity = "success";
                        message = "Votre participation à la JamSession: " + response.jamSessionNumber + " a été confirmé.\n Merci de vous connecter et choisir un rôle pour participer à la JAMSESSION.";
                    } else {
                        severity = "info";
                        message = "Vous êtes déjà enregistrés à la JamSession: " + response.jamSessionNumber;
                    }
                    this.setState({
                        loading: false,
                        severitySnackbar: severity,
                        messageSnackbar: message
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    severitySnackbar: "warning",
                    messageSnackbar: error + " !"
                });
            });
    }

    render() {

        return (
            this.state.loading ?
                <Skeleton /> :
                <div className="row justify-content-md-center align-items-start">
                    <div className="col-md-4">
                        <Paper>
                            <MuiAlert elevation={6} variant="filled" severity={this.state.severitySnackbar}>
                                {this.state.messageSnackbar}
                                <div className="mt-3"> 
                                    <Link to="/">
                                        <Button variant="contained" color="primary">
                                            <FontAwesomeIcon icon={faHome} className="mr-1" /> Retour sur AKILI
                                    </Button>
                                    </Link>
                                </div>
                                
                                
                            </MuiAlert>
                        </Paper>
                    </div>
                </div>
                
        );
    }
}