import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

export function Dashboard({isAdmin, isAuth} : {isAdmin: boolean, isAuth: boolean}) {
    const [openModalComingSoon, setOpenModalComingSoon] = React.useState(false);
    
    const handleOpenModalComingSoon = () => {
        setOpenModalComingSoon(!openModalComingSoon);
    }

    return (
        <div className="px-0">
            <div className="wrapper">
                <MetaTags>
                    <title>Tableau de bord - Success Team</title>
                    <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </MetaTags>
            </div>
        	<nav className="siteStyle top-nav">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item active"><h1 className="siteTitle">Dashboard</h1></li>
                </ol>
            </nav>
            <div className="row card-body">
                {
                    isAdmin &&
                        <React.Fragment>
                            
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <Link className="pointer" to="/role">
                                    <div className="card-counter default">
                                        <i className="fa fa-sitemap" />
                                        <span className="count-numbers">42</span>
                                        <span className="count-name">Rôles</span>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <Link className="pointer" to="/route">
                                    <div className="card-counter default">
                                        <i className="fa fa-bookmark" />
                                        <span className="count-numbers">42</span>
                                        <span className="count-name">Parcours</span>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <Link className="pointer" to="/module">
                                    <div className="card-counter default">
                                        <i className="fa fa-boxes" />
                                        <span className="count-numbers">42</span>
                                        <span className="count-name">Modules</span>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <Link className="pointer" to="/step">
                                    <div className="card-counter default">
                                        <i className="fa fa-layer-group" />
                                        <span className="count-numbers">42</span>
                                        <span className="count-name">Etapes</span>
                                    </div>
                                </Link>
                            </div>
                        </React.Fragment>
                }
                {
                    isAuth && 
                        <React.Fragment>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <Link className="pointer" to="/team">
                                    <div className="card-counter default">
                                        <FontAwesomeIcon icon={faPeopleArrows} className="fa-3x" />
                                        <span className="count-numbers">42</span>
                                        <span className="count-name">Equipes</span>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <Link className="pointer" to="/members">
                                    <div className="card-counter default">
                                        <i className="fa fa-users" />
                                        <span className="count-numbers">42</span>
                                        <span className="count-name">Membres</span>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <Link className="pointer" to="/jamSessions">
                                    <div className="card-counter default">
                                        <i className="fa fa-brain" />
                                        <span className="count-numbers">42</span>
                                        <span className="count-name">Jam Sessions</span>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <Link className="pointer" to="/projects">
                                    <div className="card-counter default">
                                        <i className="fa fa-folder-open" />
                                        <span className="count-numbers">42</span>
                                        <span className="count-name">Projets</span>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <Link className="pointer" to="/activity">
                                    <div className="card-counter default">
                                        <i className="fa fa-tasks" />
                                        <span className="count-numbers">42</span>
                                        <span className="count-name">Activités</span>
                                    </div>
                                </Link>
                            </div>
                        </React.Fragment>
                }
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <Link className="pointer" to="/posts">
                        <div className="card-counter default">
                            <i className="fa fa-ad" />
                            <span className="count-numbers">42</span>
                            <span className="count-name">Annonces</span>
                        </div>
                    </Link>
                </div>
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <Link className="pointer" to="/shops">
                        <div className="card-counter default">
                            <i className="fa fa-shopping-bag" />
                            <span className="count-numbers">42</span>
                            <span className="count-name">Boutiques</span>
                        </div>
                    </Link>
                </div>
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <Link className="pointer" to="/events">
                        <div className="card-counter default">
                            <i className="fa fa-calendar-check" />
                            <span className="count-numbers">42</span>
                            <span className="count-name">Evènements</span>
                        </div>
                    </Link>
                </div>
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <Link className="pointer" to="/home">
                        <div className="card-counter default">
                            <i className="fa fa-home" />
                            <span className="count-numbers">42</span>
                            <span className="count-name">Retour à l'accueil</span>
                        </div>
                    </Link>
                </div>
            </div>

            <Modal show={openModalComingSoon} onHide={handleOpenModalComingSoon}>
                <Modal.Header closeButton>
                    <Modal.Title className="siteStyle">
                        <strong>Page en cours de création</strong>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src="./img/blog-en-chantier.jpg" className="img-fluid" alt="coming soon" />
                </Modal.Body>
            </Modal>
        </div>
    );
}
