import React, { Component, Context } from 'react';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSync, faUser, faEdit, faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons';
import { BackendService, TeamInfoDto,MemberTeamDto, AvatarDto } from '../../../api/services.generated';
import { AuthorizedBackendApiContext } from '../../../api/api';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import EditTeam from './EditTeam';
import Chip from '@material-ui/core/Chip';
import Skeleton from '@material-ui/lab/Skeleton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// @ts-ignore
import { ReactTitle, MetaTags } from "react-meta-tags";

interface MyState {
    teams?: TeamInfoDto[], 
    teamName?: string | null, 
    teamId?: string | null, 
    loadingTeam: boolean, 
    membersCount: number,
    api:BackendService | null,
    members:IMemberInfoDto[],
    editTeam:boolean,
    anchorElProfilSetting?:Element, 
    profilSelected?:string
}

interface MyProps {
    isAdmin:boolean
}

interface IMemberInfoDto{
    member: MemberTeamDto,
    imgBase64?: string
}

export default class TeamsManagement extends Component<MyProps,MyState> {
    static contextType:Context<BackendService | null> = AuthorizedBackendApiContext
    constructor(props:MyProps){
        super(props);
        this.newTeam = this.newTeam.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
        this.handleEditTeam = this.handleEditTeam.bind(this);
        this.handleCloseEditTeam = this.handleCloseEditTeam.bind(this);
        this.handleCloseProfilMenu = this.handleCloseProfilMenu.bind(this);
        this.handleOpenProfilMenu = this.handleOpenProfilMenu.bind(this);
    }

    state: MyState = {
        teamName:"",
        teamId:"",
        loadingTeam:false,
        members:[],
        membersCount:0,
        api:null,
        editTeam:false
    };

    componentDidMount(){
        this.setState({
            api:(this.context as BackendService | null)
        },()=>{
            this.refreshTable();
        });
    }

    newTeam() {
        this.setState({
            editTeam:true,
            teamName:"",
            teamId:""
        });
    }

    refreshTable() {
        if(!this.state.api) {
            this.setState({
                teams:[],
                members:[],
                editTeam:false
            });
        }else{
            this.setState({
                teams:undefined,
                members:[]
            },()=>{
                if(this.state.api !== null) {
                    this.state.api.team_GetTeams()?.then<void,never>((response:TeamInfoDto[] | null)=>{
                        this.setState({
                            teams: response || [],
                            editTeam:false
                        });
                    });
                    this.getMembers(null,true,null);
                }
                
            });
            
        }
    }

    getMembers(ev: any, exp:boolean,teamId?: string | null) {
        if(exp){
            if(!this.state.api){
                this.setState({
                    members:[]
                });
            }else if(teamId !== undefined){
                if(this.state.members.find(m=>m.member.teamId === teamId) === undefined){
                    this.state.api.team_GetTeamMembers(teamId)?.then<void,never>((response:MemberTeamDto[] | null)=>{
                        if(response){
                            var array = this.state.members;
                            response.forEach(item=>{
                                array.push({
                                    member:item
                                });
                            });
                            this.setState({
                                members:array
                            },()=>{
                                response.forEach(item=>{
                                    this.downloadAvatar(item.memberId,item.avatarId);
                                })
                            });
                        }
                    });
                }
            }
        }
        
    }

    downloadAvatar(memberId?: string | null, avatarId?: string | null) {
        if(!this.state.api){
            this.setState({
                members:[]
            });
        }else{
            if(memberId !== null && memberId !== undefined && avatarId !== null && avatarId !== undefined){
                this.state.api.member_DownloadMemberAvatar(memberId,avatarId)?.then((response:AvatarDto | null) => {
                    if(response){
                        var array = this.state.members;
                        var index = array.findIndex(m=>m.member.memberId === memberId);
                        array[index].imgBase64 = "data:"+response.contentType+";base64," + response.image;
                        this.setState({
                            members:array
                        });
                    }
                })
            }
        }
    }

    handleEditTeam(teamName?: string | null, teamId?:string | null) {
        this.setState({
            editTeam:false
        },()=>{
            this.setState({
                teamId: teamId,
                teamName: teamName,
                editTeam:true
            })
        })
    }

    handleCloseEditTeam(){
        this.setState({
            editTeam:false
        })
    }

    handleCloseProfilMenu(){
        this.setState({
            anchorElProfilSetting:undefined
        })
    }

    handleOpenProfilMenu(e:any,memberId?:string | null) {
        this.setState({
            anchorElProfilSetting:e.currentTarget as Element,
            profilSelected:memberId !== null ? memberId : undefined
        })
    }

    async handleRemoveUserTeam(memberId?:string | null) {
        if(!this.state.api || !memberId){
            return;
        }
        
        try
        {
            this.setState({
                anchorElProfilSetting:undefined
            });

            await this.state.api.team_DeleteUserFromTeam(memberId)
            var array = [...this.state.members];
            var teams = [...this.state.teams];
            const index: number = array.findIndex(f=>f.member.memberId == memberId);
            const indexTeam: number = teams.findIndex(f=>f.teamId === array[index].member.teamId);
            array[index].member.teamId = null;
            teams[indexTeam].membersCount -= 1;

            this.setState({
                members:[...array],
                teams:[...teams]
            });
        }
        catch(error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        }
    }

    render(){
        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Equipes - Success Team</title>
                        <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                    </MetaTags>
                </div>
                <nav className="siteStyle top-nav">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item active"><h1 className="siteTitle">Equipes</h1></li>
                    </ol>
                </nav>

                <div className="container-fluid pt-4">
                    <div className="row">
                        <div className={this.state.editTeam ? "col-md-8" : "col-md-12"}>
                            <div className="alert alert-secondary">
                                {
                                    this.props.isAdmin && 
                                    <Button variant="contained" size="small" color="primary" className="mr-2 text-capitalize" onClick={this.newTeam}>
                                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm" /> Nouveau
                                    </Button>
                                }
                                
                                <Button variant="contained" size="small" onClick={this.refreshTable} className="text-capitalize btn-info-custom">
                                    <FontAwesomeIcon icon={faSync} className="mr-1" size="sm" /> Rafraîchir
                                </Button>
                            </div>
                            {
                                this.state.teams === undefined ?
                                <React.Fragment>
                                    <div className="form-group">
                                        <Skeleton variant="rect" height={60} />
                                    </div>
                                    <div className="form-group">
                                    <Skeleton variant="rect" height={60} />
                                    </div>
                                    <div className="form-group">
                                        <Skeleton variant="rect" height={60} />
                                    </div>
                                </React.Fragment> :
                                this.state.teams.map((row: TeamInfoDto) =>(
                                    <Accordion key={row.teamId || undefined} onChange={(ev,exp:boolean)=>this.getMembers(ev,exp,row.teamId)}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <p style={{flexBasis:"125px", fontSize: "1rem", fontWeight: "bold"}}>
                                                {row.teamName}
                                                <FontAwesomeIcon icon={faEdit} onClick={()=>this.handleEditTeam(row.teamName,row.teamId)} className="pl-2 fa-lg" />
                                                
                                            </p>
                                            <Chip size="small" label={row.membersCount+" Membres"} color="primary" />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <div className="container row px-3 w-100" style={{maxWidth: "5000px"}}>
                                                {
                                                    this.state.members.filter(m=>m.member.teamId === null).length > 0 && 
                                                    <Grid container spacing={3}>
                                                        {
                                                            (this.state.members.filter(m=>m.member.teamId === row.teamId) || []).map((item: IMemberInfoDto,index)=>(
                                                            <Grid className="col-4 col-xl-3" key={index} item>
                                                                <Card className="mx-auto fixedCard">
                                                                    <div className="fixedImage">
                                                                        <img src={item.member.avatarId !==  null && item.imgBase64 !== null ? item.imgBase64 : "./img/empty_img.jpg"} className="fixedImage" />
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <div className="my-3">
                                                                            <strong className="memberName siteStyle">{item.member.memberName}</strong>
                                                                        </div>
                                                                        
                                                                        <Button 
                                                                            variant="contained" 
                                                                            className="text-initial text-white btn-warning-custom mr-2 mb-2" 
                                                                            size="small" component={Link} 
                                                                            to={"/profile/" + item.member.memberId}
                                                                        >
                                                                            Profil
                                                                        </Button>
                                                                        {
                                                                            this.props.isAdmin && 
                                                                            <Button 
                                                                                variant="contained" 
                                                                                className="text-initial bg-white mr-2 mb-2" 
                                                                                size="small"
                                                                                onClick={()=>this.handleRemoveUserTeam(item.member.memberId)}
                                                                            >
                                                                                Supprimer de l'équipe
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                </Card>
                                                            </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    ))
                                }
                                
                                <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <p style={{flexBasis:"125px", fontSize: "1rem", fontWeight: "bold"}}>Aucune équipe </p>
                                            {
                                                <Chip size="small" label={this.state.members.filter(m=>m.member.teamId === null).length+" Membres"} />
                                            }
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="container row px-3 w-100" style={{maxWidth: "5000px"}}>
                                                {
                                                    this.state.members.filter(m=>m.member.teamId === null).length > 0 && 
                                                    <Grid container spacing={3}>
                                                        {
                                                            (this.state.members.filter(m=>m.member.teamId === null) || []).map((item: IMemberInfoDto,index)=>(
                                                            <Grid className="col-4 col-xl-3" key={index} item>
                                                                <Card className="mx-auto fixedCard">
                                                                    <div className="fixedImage">
                                                                        <img src="./img/empty_img.jpg" className="fixedImage" />
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <div className="my-3">
                                                                            <strong className="memberName siteStyle">{item.member.memberName}</strong>
                                                                        </div>
                                                                        
                                                                        <Button 
                                                                            variant="contained" 
                                                                            className="text-initial text-white btn-warning-custom mr-2 mb-2" 
                                                                            size="small" component={Link} 
                                                                            to={"/profile/" + item.member.memberId}
                                                                        >
                                                                            Voir le profil
                                                                        </Button>
                                                                    </div>
                                                                </Card>
                                                            </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                        </div>
                        {
                            this.state.editTeam && 
                            <div className="col-md-4">
                                <EditTeam refresh={this.refreshTable} teamId={this.state.teamId} 
                                onClose={this.handleCloseEditTeam} isAdmin={this.props.isAdmin} />
                            </div>
                        }
                        
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
