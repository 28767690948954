import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPlusCircle, faSync, faPen, faSave } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import MUIRichTextEditor from 'mui-rte';
import { createMuiTheme, Theme, MuiThemeProvider } from '@material-ui/core/styles';
import { convertToRaw } from 'draft-js';
import JamSessionFiles from './JamSessionFiles';

const defaultTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#000000"
        }
    }
});

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
                minHeight: "250px"
            },
            editor: {
                fontSize: "0.875rem",
                lineHeight: "24px",
                fontFamily: "Nunito"
            },
            placeHolder: {
                fontSize: "14px",
                fontFamily: "Nunito"
            },
            anchorLink: {
                color: "#333333",
                textDecoration: "underline"
            }
        }
    }
});


class EditJamSessionReport extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            jamSessionClosed: this.props.jamSessionClosed,
            responseCount: this.props.responses !== undefined ? this.props.responses.length : 0,
            confirmRegistered: false,
            //membersInvitation: [],
            invitationSelected: "1",
            tabValue: 0,
            evaluations: [],
            open: false,
            open2: false,
            evaluationId: "",
            author: localStorage.getItem("firstName"),
            content: "",
            loadingData: false,
            strongPoints: "",
            learnPoints: "",
            excellentPoints: "",
            comment: "",
            subject: null,
            subjectText: "",
            title: "",
            reports: [],
            reportId: "",
            object: this.props.theme,
            message: "",
            editorState: '{"blocks":[{"key":"7po5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
            canEdit: this.props.isAdmin,
            //editorState: EditorState.createEmpty('')
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClickOpen2 = this.handleClickOpen2.bind(this);
        this.saveEvaluation = this.saveEvaluation.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
        this.getEvaluation = this.getEvaluation.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.saveReport = this.saveReport.bind(this);
        this.refreshTable2 = this.refreshTable2.bind(this);
        this.getReport = this.getReport.bind(this);
        this.onSave = this.onSave.bind(this);
        this.change = this.change.bind(this);
    }

    componentDidMount() {
        this.refreshTable();
        this.refreshTable2();
    }

    change(state) {
        this.setState({editorState: JSON.stringify(convertToRaw(state.getCurrentContent()))});
    }

    onSave(data) {
        console.log(data);
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleClickOpen() {
        this.setState({
            open: !this.state.open,
            message: "",
            object: "",
            loadingData: false
        });
    }

    handleClickOpen2() {
        this.setState({
            open2: !this.state.open2,
            strongPoints: "",
            learnPoints: "",
            excellentPoints: "",
            title: "",
            loadingData: false
        });
    }

    randomDate(start, end) {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    }

    saveReport() {
        this.setState({
            loadingData: true
        });

        fetch(new Request("api/JamSession/SetReport", {
            method: "POST",
            body: JSON.stringify({
                JamSessionId: this.props.jamSessionId,
                ReportId: this.state.reportId,
                Object: this.state.object,
                //Message: this.state.message
                Message: this.state.editorState
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);

        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    message: "",
                    editorState: '{"blocks":[{"key":"7po5","text":"","type":"header-two","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    object: "",
                    open: false,
                    loadingData: false
                });

                toast.success("Mise à jour effectuée !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.refreshTable2();
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
            this.setState({
                loadingData: false
            });
        });
    }

    refreshTable2() {
        fetch(new Request("api/JamSession/GetReportJamSession?jamSessionId=" + this.props.jamSessionId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    reports: response
                });
                if (response !== null && response.length > 0) {
                    this.getReport(response[0].reportId);
                }
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                hideProgressBar: false,
                position: "top-left"
            });
            this.setState({
                reports: []
            });
        });
    }

    getReport(id) {
        fetch(new Request("api/JamSession/GetReportJamSession?JamSessionId=" + this.props.jamSessionId + "&ReportId=" + id), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {

            if (ok) {
                this.setState({
                    reportId: id,
                    object: response.object,
                    author: response.createByName,
                    message: response.message,
                    editorState: !response.message.startsWith('{') ? '{"blocks":[{"key":"7po5","text":"'+ response.message +'","type":"header-two","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' : response.message,
                    canEdit: response.canEdit
                    //editorState: response.message,
                    //open: true
                })
            } else {
                this.setState({
                    //open: false
                });
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    saveEvaluation() {
        this.setState({
            loadingData: true
        });

        fetch(new Request("api/JamSession/SetEvaluationJamSession", {
            method: "POST",
            body: JSON.stringify({
                JamSessionId: this.props.jamSessionId,
                ProjectEvaluationId: this.state.evaluationId,
                Title: this.state.title,
                StrongPoints: this.state.strongPoints,
                PointsImprove: this.state.learnPoints,
                ExcellencePoints: this.state.excellentPoints
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);

        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    strongPoints: "",
                    learnPoints: "",
                    excellentPoints: "",
                    title: "",
                    open2: false,
                    loadingData: false
                });

                toast.success("Mise à jour effectuée !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.refreshTable();
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
            this.setState({
                loadingData: false
            });
        });
    }

    refreshTable() {
        fetch(new Request("api/JamSession/GetEvaluationJamSessions?jamSessionId=" + this.props.jamSessionId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    evaluations: response
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                hideProgressBar: false,
                position: "top-left"
            });
            this.setState({
                evaluations: []
            });
        });
    }

    getEvaluation(id) {
        this.setState({
            loadingTeam: true
        });

        fetch(new Request("api/JamSession/GetEvaluationJamSession?JamSessionId=" + this.props.jamSessionId + "&ProjectEvaluationId=" + id), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {

            if (ok) {
                this.setState({
                    evaluationId: id,
                    title: response.title,
                    author: response.createByName,
                    strongPoints: response.strongPoints,
                    learnPoints: response.pointsImprove,
                    excellentPoints: response.excellencePoints,
                    open2: true
                })
            } else {
                this.setState({
                    open2: false
                });
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    isNotNull(word) {
        if (word !== null && word !== undefined && word !== "") {
            return true;
        }
        return false;
    }

    handleTabChange(e, value) {
        this.setState({
            tabValue: value
        });
    }

    render() {
        return (
            <React.Fragment>
                <Tabs className="mb-3 border-bottom" indicatorColor="primary" textColor="primary"
                    value={this.state.tabValue} onChange={this.handleTabChange}
                    style={{ backgroundColor: "#FFF" }} TabIndicatorProps={{ style: { background: '#303f9f' } }}
                >
                    <Tab label="Evaluation" className="text-initial" />
                    <Tab label="Rapport" className="text-initial" />
                    <Tab label="Documentation" className="text-initial" />
                </Tabs>

                <div role="tabpanel" hidden={this.state.tabValue !== 0}>
                    <div className="row mb-3">
                        <div className="col-12">
                            <Button variant="contained" className="text-initial mr-2" color="primary" size="small" onClick={() => { this.setState({evaluationId: ""}); this.handleClickOpen2(); }}>
                                <FontAwesomeIcon className="mr-1" icon={faPlusCircle} size="sm" /> Nouveau
                            </Button>
                            <Button className="text-initial btn-info-custom" variant="contained" size="small" onClick={this.refreshTable}>
                                <FontAwesomeIcon icon={faSync} className="mr-1" size="sm" /> Rafraîchir
                            </Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <Table className="w-100" size="small">
                                    <TableHead style={{ backgroundColor: "#000" }}>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell style={{ color: "#FFF" }}>Titre</TableCell>
                                            <TableCell style={{ color: "#FFF" }}>Auteur</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.evaluations !== null ? 
                                                this.state.evaluations.map((row, i) => (
                                                <TableRow key={"feedbackNumber-"+i} >
                                                    <TableCell align="center">
                                                        <FontAwesomeIcon icon={faPen} className="pointer" onClick={() => this.getEvaluation(row.projectEvaluationId)} />
                                                    </TableCell>
                                                    <TableCell>{row.title}</TableCell>
                                                    <TableCell>{row.createByName}</TableCell>
                                                </TableRow>
                                            )) : null
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.open2} onHide={this.handleClickOpen2}>
                        <Modal.Header closeButton>
                            <Modal.Title className="siteStyle">
                                <strong>Ajouter une évaluation</strong>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <TextField className="mb-3" fullWidth label="Auteur" disabled={true}
                                name="author" value={this.state.author} variant="filled" />
                            <TextField className="mb-3" fullWidth label="Titre" onChange={this.handleInput}
                                name="title" value={this.state.title} variant="filled" />
                            <TextField className="mb-3" fullWidth variant="filled" multiline onChange={this.handleInput} rows="3"
                                name="strongPoints" label="Points forts" value={this.state.strongPoints} />
                            <TextField className="mb-3" fullWidth variant="filled" multiline onChange={this.handleInput} rows="3"
                                name="learnPoints" label="Points à améliorer" value={this.state.learnPoints} />
                            <TextField className="mb-3" fullWidth variant="filled" multiline onChange={this.handleInput} rows="3"
                                name="excellentPoints" label="Points d'excellence" value={this.state.excellentPoints} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="contained" className="text-initial mr-2" onClick={this.saveEvaluation} color="primary" size="small">
                                <FontAwesomeIcon className="mr-1" icon={faSave} size="sm" /> Enregistrer
                            </Button>
                            <Button variant="contained" className="text-initial bg-white" onClick={this.handleClickOpen2} color="default" size="small">
                                <FontAwesomeIcon className="mr-1" icon={faTimesCircle} size="sm" /> Fermer
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

                <div role="tabpanel" hidden={this.state.tabValue !== 1}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-6">
                                    <h3 className="siteStyle font-weight-bolder" style={{fontSize: "1.4em"}}>Rapport de la jamSession</h3>
                                </div>
                                <div className="col-6 text-right">
                                    <Button variant="contained" className="text-initial mr-2" onClick={this.saveReport} 
                                    color="primary" size="small" disabled={!this.state.canEdit}>
                                        <FontAwesomeIcon className="mr-1" icon={faSave} size="sm" /> Enregistrer
                                    </Button>
                                </div>
                            </div>

                            <TextField className="mb-3" disabled fullWidth label="Auteur" onChange={this.handleInput}
                                name="author" value={this.state.author} variant="filled" />
                            <TextField className="mb-3" fullWidth label="Objet" onChange={this.handleInput}
                                name="object" value={this.state.object} variant="filled" disabled={!this.state.canEdit} />
                            <div style={{fontSize: "14px !important", fontfamily: "Nunito !important", border: "1px #e5e5e5 solid", paddingLeft: "10px", paddingRight: "10px", minHeight: "150px", borderBottom: "1px #767676 solid"}}>
                                <MuiThemeProvider theme={defaultTheme}>
                                    <MUIRichTextEditor 
                                        label="Entrez votre message..."
                                        controls={["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "numberList", "bulletList", "quote", "clear"]}
                                        toolbarButtonSize="small"
                                        inheritFontSize={true}
                                        defaultValue={this.state.editorState}
                                        onSave={this.onSave}
                                        onChange={this.change}
                                        readOnly={!this.state.canEdit}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div role="tabpanel" hidden={this.state.tabValue !== 2}>
                    <JamSessionFiles jamSessionId={this.props.jamSessionId} editFile={this.props.isAdmin || this.props.hasModerator} />
                </div>
            </React.Fragment>
        );
    }
}

const MapStateToProps = state => {
    return {
        jamSessionIsReading: state.jamSessionIsReading,
        isAdmin: state.isAdmin
    };
}

export default connect(MapStateToProps)(EditJamSessionReport);
