import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";
import { toast } from "react-toastify";
import EditJamSessionChart from "./EditJamSessionChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faPaperPlane, faReply, faTimesCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import EditJamSessionInvitation from "./EditJamSessionInvitation";

class EditJamSessionResponses extends Component {

    constructor(props) {
        super(props);

        this.state = {
            jamSessionClosed: this.props.jamSessionClosed,
            responseCount: this.props.responses !== undefined ? this.props.responses.length : 0,
            confirmRegistered: false,
            //membersInvitation: [],
            invitationSelected: "1",
            open: false,
            open2: false,
            checkedA: false,
            checkedB: false,
            value: "all",
            loadingData: false,
            invitationList: this.props.invitatedList.concat(this.props.responses || []).sort(this.compare)
        };

        this.handleSwitchToggle = this.handleSwitchToggle.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleClickOpen2 = this.handleClickOpen2.bind(this);
        this.handleCloseDialog2 = this.handleCloseDialog2.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.setInvitationReminder = this.setInvitationReminder.bind(this);
    }

    componentDidMount() {
        console.log(this.props.invitatedList);
        console.log(this.props.responses);
    }

    handleClickOpen() {
        this.setState({ open: true });
    }

    handleCloseDialog() {
        this.setState({ open: false });
    }

    handleClickOpen2() {
        this.setState({ open2: true, value: "all" });
    }

    handleCloseDialog2() {
        this.setState({ open2: false });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeRadio(event) {
        this.setState({ value: event.target.value });
    }

    handleSwitchToggle() {
        var status = !this.state.jamSessionClosed;
        this.setState({
            jamSessionClosed: status
        });

        fetch(new Request("api/JamSession/SetJamSessionClosedToggle?jamSessionId=" + this.props.jamSessionId + "&closed=" + status), {
            method: "POST",
            body: JSON.stringify({
                JamSessionId: this.props.jamSessionId,
                Closed: status
            }),
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (!ok) {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    jamSessionClosed: !this.state.jamSessionClosed
                });
            });
    }

    setInvitationReminder() {
        this.setState({
            loadingData: true
        });

        fetch(new Request("api/JamSession/SetInvitationReminder", {
            method: "POST",
            body: JSON.stringify({
                JamSessionId: this.props.jamSessionId,
                ReminderMembersNotReply: this.state.value !== "all"
            }),
            headers: new Headers({
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);

            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        value: "all",
                        open2: false,
                        loadingData: false
                    });

                    toast.success("Rappel envoyé !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    loadingData: false
                });
            });
    }

    compare(a, b) {
        if (a.fullName < b.fullName) {
            return -1;
        }
        if (a.fullName > b.fullName) {
            return 1;
        }
        return 0;
    }
      

    render() {
        return (
            <React.Fragment>
                {
                    !this.props.jamSessionIsReading && <div className="row mb-3">
                        <div className="col-12">
                            {
                                this.props.isAdmin &&
                                <Button variant="contained" className="text-initial float-right" size="small" color="primary" onClick={this.handleClickOpen2}>
                                    <FontAwesomeIcon className="mr-1" icon={faBell}/> Envoyer un rappel
                                </Button>
                            }
                        </div>
                    </div>
                }

                <div className="row">
                    <div className="col-12">
                    <React.Fragment>
                        <div className="form-group row">
                            <div className="col-md-6 col-6">
                                <Chip className="text-light btn-warning-custom" style={{ fontSize: "100%" }} label={this.state.responseCount + (this.state.responseCount > 1 ? " réponses" : " réponse")} />
                            </div>
                            {
                                this.props.isAdmin && 
                                <React.Fragment>
                                    <div className="col-md-6 col-6 text-right">
                                        <label className="pr-0">Clôturer</label>
                                        <Switch checked={this.state.jamSessionClosed} onChange={this.handleSwitchToggle} color="primary" />
                                    </div>
                                </React.Fragment>
                            }
                        </div>

                        <div style={{ overflowX: "auto" }}>
                            <Table className="mb-4 w-100" size="small">
                                <TableHead style={{ backgroundColor: "#000" }}>
                                    <TableRow>
                                        <TableCell style={{ color: "#fff" }}>Nom</TableCell>
                                        <TableCell style={{ color: "#fff" }}>Email</TableCell>
                                        <TableCell style={{ color: "#fff" }}>Statut</TableCell>
                                        <TableCell style={{ color: "#fff" }}>Rôle</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        (this.state.invitationList || []).map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {
                                                        row.userId !== undefined && 
                                                        <FontAwesomeIcon 
                                                            icon={faCheckCircle} 
                                                            color="#54c147" 
                                                            style={{
                                                                width: "16px", 
                                                                height: "16px", 
                                                                position: "relative", 
                                                                right: "8px", 
                                                                bottom: "-2px"
                                                            }} 
                                                        />
                                                    }
                                                    {row.fullName}
                                                </TableCell>
                                                <TableCell>{row.email || "Adresse indéfinie"}</TableCell>
                                                <TableCell>{row.isMember ? "Membre" : "Invité"}</TableCell>
                                                <TableCell>
                                                    {
                                                        (row.roles || []).map((role, indexRole) => (
                                                            <Chip key={indexRole} className="mr-2" label={role.jamSessionRoleName} color="primary" size="small" />
                                                        ))
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </div>

                        {/* I am not sure it is really useful */}
                        {/* <div className="row">
                            <div className="col-5 offset-3">
                                <EditJamSessionChart jamSessionId={this.props.jamSessionId}/>
                            </div>
                        </div> */}
                        </React.Fragment>
                    </div>
                </div>

                <Dialog
                    maxWidth="sm" fullWidth={true} open={this.state.open}
                    onClose={this.handleCloseDialog} aria-labelledby="max-width-dialog-title">
                    <DialogTitle id="max-width-dialog-title h6 font-weight-bolder siteStyle">Invitez vos contacts à la
                        JamSession</DialogTitle>
                    <DialogContent>
                        <EditJamSessionInvitation jamSessionId={this.props.jamSessionId} members={this.props.members}/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" className="text-initial btn-warning-custom text-white" onClick={this.handleCloseDialog} size="small">
                            <FontAwesomeIcon className="mr-1" icon={faTimesCircle} size="sm"/> Fermer
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth="sm" fullWidth={true} open={this.state.open2}
                    onClose={this.handleCloseDialog2} aria-labelledby="max-width-dialog-title">
                    <DialogTitle id="max-width-dialog-title h6 font-weight-bolder siteStyle">Envoyer un
                        rappel</DialogTitle>
                    <DialogContent>
                        <RadioGroup aria-label="radio" name="radio" value={this.state.value} onChange={this.handleChangeRadio}>
                            <FormControlLabel value="all" control={<Radio color="primary"/>}
                                label="Tous les participants"/>
                            <FormControlLabel value="responses" control={<Radio color="primary"/>}
                                label="Ceux qui n'ont pas répondu"/>
                        </RadioGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" disabled={this.state.loadingData} className="text-initial"
                            color="primary" size="small" onClick={this.setInvitationReminder}>
                            <FontAwesomeIcon className="mr-1" icon={faPaperPlane} size="sm"/> Envoyer
                        </Button>
                        <Button variant="contained" className="text-initial" onClick={this.handleCloseDialog2}
                            size="small">
                            <FontAwesomeIcon className="mr-1" icon={faTimesCircle} size="sm"/> Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

const MapStateToProps = state => {
    return {
        invitatedList: [...state.invitatedList],
        jamSessionIsReading: state.jamSessionIsReading
    };
};

export default connect(MapStateToProps)(EditJamSessionResponses);
