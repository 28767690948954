import React, { ReactNode, useState } from "react";
import { Button, PropTypes } from "@material-ui/core";
import { toast } from "react-toastify";
import { getErrorMessage } from "../api/utils";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

type ButtonProps = {
    color?: PropTypes.Color;
    disableElevation?: boolean;
    disableFocusRipple?: boolean;
    endIcon?: React.ReactNode;
    fullWidth?: boolean;
    href?: string;
    size?: "small" | "medium" | "large";
    startIcon?: React.ReactNode;
    variant?: "text" | "outlined" | "contained";
};

const StyledSpinnerContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 200ms;

    // workaround for MUI button margins to center the spinner
    margin-top: -2px;
`;

const StyledContentContainer = styled.div`
    transition: opacity 200ms;
`;

export function AsyncButton({
    onClickAsync,
    children,
    ...props
}: Omit<ButtonProps, "disabled"> &
    React.ButtonHTMLAttributes<HTMLButtonElement> & { onClickAsync: () => Promise<void>; children: ReactNode }) {
    const [loading, setLoading] = useState(false);

    return (
        <Button
            {...props}
            disabled={loading}
            onClick={async () => {
                setLoading(true);
                try {
                    await onClickAsync();
                } catch (error) {
                    toast.warn(getErrorMessage(error) + " !", {
                        position: "top-left",
                        hideProgressBar: false,
                    });
                } finally {
                    setLoading(false);
                }
            }}
        >
            <div style={{ position: "relative" }}>
                <StyledContentContainer style={{ opacity: loading ? 0.3 : 1 }}>{children}</StyledContentContainer>
                {
                    <StyledSpinnerContainer style={{ opacity: loading ? 1 : 0 }}>
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-2x" />
                    </StyledSpinnerContainer>
                }
            </div>
        </Button>
    );
}
