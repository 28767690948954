import React, { useState } from "react";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import { TextField } from "@material-ui/core";
import { BackendService, FileResponse, UsefulLinkDto } from "../../api/services.generated";
import { useAuthorizedBackendApi } from "../../api/api";

interface LinkProps {
    stepId: string | null,
    linkId: string | null | undefined,
    title: string | null | undefined,
    description: string | null | undefined,
    url: string | null | undefined,
    refresh: () => void
}

export default function StepEditLink(props: LinkProps) {
    const api: BackendService | null = useAuthorizedBackendApi();
    const [stepId, setStepId] = useState<string | null>(props.stepId);
    const [linkId, setLinkId] = useState<string | null | undefined>(props.linkId);
    const [title, setTitle] = useState<string | null | undefined>(props.title);
    const [description, setDescription] = useState<string | null | undefined>(props.description);
    const [url, setUrl] = useState<string | null | undefined>(props.url);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    function initFields() {
        setTitle("");
        setDescription("");
        setUrl("");
        setLoadingData(false);
    }

    async function saveLink() {
        setLoadingData(true);

        if(!api) {
            return;
        }

        try {
            const response:FileResponse | null = await api.step_SetUsefulLink({
                stepId: stepId,
                linkId: linkId || "",
                title: title,
                url: url || false,
                description: description
            } as UsefulLinkDto);

            initFields();

            toast.success("Mise à jour effectuée !", {
                position: "top-center",
                hideProgressBar: false
            });
            props.refresh();
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
        finally {
            setLoadingData(false);
        }
        
        /*
        await fetch(new Request("api/Step/SetUsefulLink", {
            method: "POST",
            body: JSON.stringify({
                StepId: stepId,
                LinkId: linkId || "",
                Title: title,
                Url: url || false,
                Description: description
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                initFields();

                toast.success("Mise à jour effectuée !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                props.refresh();
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
            setLoadingData(false);
        });
        */
    }

    return (
        <React.Fragment>
            <div className="alert alert-secondary">
                <Button variant="contained" size="small" className="mr-1 text-capitalize btn-success-custom"
                    onClick={saveLink} disabled={loadingData}>
                    <FontAwesomeIcon icon={faSave} className="mr-1" size="sm" /> Enregistrer
                </Button>
            </div>

            <div className="form-group">
                <TextField
                    fullWidth name="title" value={title}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setTitle(e.target.value)} label="Titre" variant="filled"
                />
            </div>
            <div className="form-group">
                <TextField
                    fullWidth name="url" value={url}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setUrl(e.target.value)} label="URL" variant="filled"
                />
            </div>
            <div className="form-group">
                <TextField
                    fullWidth name="description" value={description}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setDescription(e.target.value)} label="Description" variant="filled" 
                    multiline rows="3"
                />
            </div>
        </React.Fragment>
    );
}