import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAlignLeft,
    faCalendar,
    faEdit,
    faExclamationTriangle,
    faLock,
    faMapMarker,
    faSync,
    faUserEdit,
    faUserTie,
    faVideo,
} from "@fortawesome/free-solid-svg-icons";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import EditJamSessionResponses from "./EditJamSessionResponses";
import EditJamSessionAgendaMember from "./EditJamSessionAgendaMember";
import Skeleton from "@material-ui/lab/Skeleton";
import EditJamSessionReport from "./EditJamSessionReport";
import EditJamSessionUnsubscribe from "./EditJamSessionUnsubscribe";
import { useAsyncEffect } from "../../../utils/react";
import { useAuthorizedBackendApi } from "../../../api/api";
import { JamSessionDto } from "../../../api/services.generated";
import { useCurrentUser } from "../../../utils/login";
import { JamSessionInscription2 } from "./JamSessionInscription2";
import { format } from "date-fns";
import { JamSessionInvitation2 } from "./JamSessionInvitation2";
import { JamSessionContext, makeJamSessionContext, useJamSession } from "./JamSessionContext";

export function ViewJamSession2({ sessionId }: { sessionId: string }) {
    const api = useAuthorizedBackendApi();
    const [session, setSession] = useState<JamSessionDto | "loading" | "error">("loading");
    const jamSessionProvider = useMemo(() => makeJamSessionContext(), []);

    const { trigger: loadSession } = useAsyncEffect(
        async ({ wrap }) => {
            if (!api) {
                return;
            }
            try {
                const result = await wrap(api.jamSession_GetJamSession(sessionId));
                if (result) {
                    setSession(result);
                }
            } catch (e) {
                console.log(e);
                setSession("error");
            }
        },
        [api, sessionId]
    );

    useEffect(() => {
        if (session === "loading" || session === "error") {
            jamSessionProvider.setSession(null);
        } else {
            jamSessionProvider.setSession({ ...session, sessionId });
        }
    }, [session]);

    let content = (
        <div className="mx-auto text-center mt-5">
            <Skeleton />
        </div>
    );

    if (session !== "loading" && session !== "error") {
        content = (
            <div className="card-body container-fluid mt-0">
                <TopPanel refresh={loadSession} />
                <div className="row">
                    <div className={"col-lg-8"}>
                        <InfoPanel />
                        <SessionTabs />
                    </div>
                    <div className="col-lg-4">
                        <JamSessionInvitation2 />
                    </div>
                </div>
            </div>
        );
    }

    const s = session === "loading" || session === "error" ? null : session;

    return (
        <JamSessionContext.Provider value={jamSessionProvider}>
            <nav className="siteStyle top-nav">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/jamSessions">Jamsessions</Link>
                    </li>
                    <li className="breadcrumb-item active">{s?.theme}</li>
                </ol>
            </nav>
            <div className="border-bottom-0 shadow-none">{content}</div>
        </JamSessionContext.Provider>
    );
}

function TopPanel({ refresh }: { refresh: () => void }) {
    const user = useCurrentUser();
    const [unsubscribeOpenDialog, setUnsubscribeOpenDialog] = useState(false);
    const { session } = useJamSession();

    return (
        <>
            <div className="alert alert-secondary">
                <div>
                    <div className="row">
                        <div className="col-sm-4 col-12">
                            <span className="ml-1">
                                <strong className="siteStyle">JAM SESSION N° {session?.jamSessionNumber}</strong>
                            </span>
                        </div>
                        {session && !session.closed ? (
                            <div className="col-sm-8 col-12 my-2 text-right">
                                <>
                                    <Button
                                        variant="contained"
                                        className="text-capitalize mr-2 btn-info-custom"
                                        size="small"
                                        onClick={refresh}
                                    >
                                        <FontAwesomeIcon icon={faSync} className="mr-0 mr-sm-1" size="sm" />{" "}
                                        <span className="d-none d-sm-block">Rafraîchir</span>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className="text-capitalize mr-2"
                                        size="small"
                                        onClick={() => setUnsubscribeOpenDialog(true)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faExclamationTriangle}
                                            className="mr-0 mr-sm-1"
                                            size="sm"
                                        />{" "}
                                        <span className="d-none d-sm-block">Désinscrire</span>
                                    </Button>
                                </>

                                {user.isAdmin && (
                                    <Link to={"/jamsession/" + session?.sessionId} className="pointer">
                                        <Button
                                            variant="contained"
                                            className="text-capitalize mr-2 bg-white group-button"
                                            size="small"
                                        >
                                            <FontAwesomeIcon icon={faEdit} className="mr-0 mr-sm-1" size="sm" />
                                            <span className="d-none d-sm-block">Editer</span>
                                        </Button>
                                    </Link>
                                )}
                            </div>
                        ) : (
                            <div className="col-sm-8 col-12 my-2 text-right">
                                <Chip label="Cloturé" icon={<FontAwesomeIcon icon={faLock} className="ml-2" />} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {unsubscribeOpenDialog && (
                <EditJamSessionUnsubscribe
                    jamSessionId={session?.sessionId}
                    closeDialogUnsubscrition={() => setUnsubscribeOpenDialog(false)}
                />
            )}
        </>
    );
}

function InfoPanel() {
    const { session } = useJamSession();

    if (!session) {
        return <Skeleton />;
    }

    return (
        <div className="border mb-3" style={{ borderRadius: "5px" }}>
            <List dense={true}>
                <div className="row">
                    <div className="col-lg-3">
                        <ListItem>
                            <ListItemIcon className="text-dark">
                                <FontAwesomeIcon icon={faCalendar} />
                            </ListItemIcon>
                            <ListItemText
                                className="text-dark text-capitalize"
                                primary="Date"
                                secondary={
                                    format(session.jamSessionDate, "eeee, d MMMM y") +
                                    ", " +
                                    session.jamSessionHourFrom.substring(0, 5) +
                                    " - " +
                                    session.jamSessionHourTo.substring(0, 5)
                                }
                            />
                        </ListItem>
                    </div>
                    <div className="col-lg-3">
                        <ListItem>
                            <ListItemIcon className="text-dark">
                                <FontAwesomeIcon icon={faEdit} />
                            </ListItemIcon>
                            <ListItemText className="text-dark" primary="Thème" secondary={session.theme} />
                        </ListItem>
                    </div>
                    <div className="col-lg-3">
                        <ListItem>
                            <ListItemIcon className="text-dark">
                                <FontAwesomeIcon icon={faUserEdit} />
                            </ListItemIcon>
                            <ListItemText
                                className="text-dark"
                                primary="Modérateur"
                                secondary={session.moderator?.fullName}
                            />
                        </ListItem>
                    </div>
                    <div className="col-lg-3">
                        <ListItem>
                            <ListItemIcon className="text-dark">
                                <FontAwesomeIcon icon={faUserTie} />
                            </ListItemIcon>
                            <ListItemText
                                className="text-dark"
                                primary="Evaluateur"
                                secondary={session.generalEvaluator?.fullName}
                            />
                        </ListItem>
                    </div>
                    <div className="col-lg-3">
                        <ListItem>
                            <ListItemIcon className="text-dark">
                                <FontAwesomeIcon icon={faMapMarker} />
                            </ListItemIcon>
                            <ListItemText className="text-dark" primary="Adresse" secondary={session.address} />
                        </ListItem>
                    </div>
                    <div className="col-lg-6">
                        <ListItem className="pr-3">
                            <ListItemIcon className="text-dark">
                                <FontAwesomeIcon icon={faAlignLeft} />
                            </ListItemIcon>
                            <ListItemText className="text-dark" primary="Description" secondary={session.description} />
                        </ListItem>
                    </div>
                    <div className="col-lg-3">
                        {session.conferenceLink && (
                            <Button
                                variant="contained"
                                className="text-initial mr-2 mb-3 btn-warning-custom"
                                size="medium"
                                target="_blank"
                                href={session.conferenceLink}
                            >
                                <FontAwesomeIcon icon={faVideo} className="mr-1" size="sm" />{" "}
                                <span className="d-none d-sm-block">Salle virtuelle</span>
                            </Button>
                        )}
                    </div>
                </div>
            </List>
        </div>
    );
}

function SessionTabs() {
    const [tabValue, setTabValue] = useState(0);
    const {session} = useJamSession();
    const user = useCurrentUser();


    if (!session){
        return <Skeleton/>;
    }

    const date = session.jamSessionDate;
    return (
        <>
            <Tabs
                className="mb-3 border-bottom"
                indicatorColor="secondary"
                textColor="primary"
                variant="scrollable"
                value={tabValue}
                onChange={(_, v) => setTabValue(v)}
                scrollButtons="on"
                style={{ backgroundColor: "#FFF" }}
                TabIndicatorProps={{ style: { background: "#fd7419" } }}
            >
                <Tab label="Inscription" className="text-initial" />
                <Tab label="Participants" className="text-initial" />
                <Tab label="Agenda" className="text-initial" />
                <Tab label="Compte rendu" className="text-initial" />
            </Tabs>
            <div role="tabpanel" hidden={tabValue !== 0}>
                {<JamSessionInscription2 isReadonly={false} />}
            </div>
            <div role="tabpanel" hidden={tabValue !== 1}>
                <EditJamSessionResponses
                    responses={session.registers}
                    jamSessionId={session.sessionId}
                    isAdmin={user.isAdmin}
                />
            </div>
            <div role="tabpanel" hidden={tabValue !== 2}>
                <EditJamSessionAgendaMember
                    agendas={session.agenda}
                    beginHour={session.jamSessionHourFrom}
                    canGeneratePDF={true}
                    jamSessionNumber={session.jamSessionNumber}
                    jamSessionTitle={session.theme}
                    jamSessionDate={
                        date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getDate()
                    }
                />
            </div>
            <div role="tabpanel" hidden={tabValue !== 3}>
                <EditJamSessionReport
                    responses={session.registers}
                    hasModerator={session.hasModerator}
                    theme={session.theme}
                    jamSessionId={session.sessionId}
                />
            </div>
        </>
    );
}
