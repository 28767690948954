import React, { Component } from "react";
//import myConfig from '../../config/Config';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSync, faTimesCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

const subjectDefault = { stepId: null, stepName:"Feedback Général" };

export default class ProjectFeedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            steps: [subjectDefault, ...this.props.steps],
            feedbacks: [],
            author: "Vous (" + localStorage.getItem("firstName") + ")",
            strongPoints: "",
            learnPoints: "",
            excellentPoints: "",
            comment: "",
            subject: subjectDefault,
            subjectText: "",
            title: "",
            evaluationId: ""
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleAutocomplete = this.handleAutocomplete.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.saveEvaluation = this.saveEvaluation.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
    }

    componentDidMount() {

        fetch(new Request("api/Project/GetProjectEvaluations?projectId=" + this.props.projectId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        feedbacks: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
                this.setState({
                    feedbacks: []
                });
            });
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleAutocomplete(event, value) {
        this.setState({
            subject: value
        });
    }

    handleOpenModal() {
        this.setState({
            modal: !this.state.modal,
            strongPoints: "",
            learnPoints: "",
            excellentPoints: "",
            comment: "",
            subject: subjectDefault,
            subjectText: "",
            title: "",
            loadingData: false
        });
    }

    saveEvaluation() {
        this.setState({
            loadingData: true
        });

        fetch(new Request("api/Project/SetEvaluationProject", {
            method: "POST",
            body: JSON.stringify({
                ProjectId: this.props.projectId,
                ProjectEvaluationId: this.state.evaluationId,
                Title: this.state.title,
                StepId: this.state.subject !== undefined && this.state.subject !== null ? this.state.subject.stepId :  null,
                StrongPoints: this.state.strongPoints,
                PointsImprove: this.state.learnPoints,
                ExcellencePoints: this.state.excellentPoints
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);

            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        projectId: null,
                        strongPoints: "",
                        learnPoints: "",
                        excellentPoints: "",
                        subject: subjectDefault,
                        subjectText: "",
                        title: "",
                        modal: false,
                        loadingData: false
                    });

                    toast.success("Mise à jour effectuée !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                    this.refreshTable();
                    //this.props.refresh();
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    loadingData: false
                });
            });
    }

    refreshTable() {
        fetch(new Request("api/Project/GetProjectEvaluations?projectId=" + this.props.projectId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        feedbacks: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
                this.setState({
                    feedbacks: []
                });
            });
    }

    getEvaluation(id) {
        this.setState({
            loadingTeam: true
        });

        fetch(new Request("api/Project/GetProjectEvaluation?ProjectId=" + this.props.projectId + "&ProjectEvaluationId=" + id), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {

                if (ok) {
                    this.setState({
                        evaluationId: id,
                        title: response.title,
                        author: response.createByName,
                        subject: !response.stepId  ? subjectDefault : this.props.steps.find(x => x.stepId === response.stepId),
                        strongPoints: response.strongPoints,
                        learnPoints: response.pointsImprove,
                        excellentPoints: response.excellencePoints,
                        modal: true
                        //message: response.message
                    })
                } else {
                    this.setState({
                        modal: false
                    });
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    isNotNull(word) {
        if (word !== null && word !== undefined && word !== "") {
            return true;
        }
        return false;
    }

    render() {
        return (
            <React.Fragment>
                {
                    !this.props.isViewer && 
                    <div className="alert alert-secondary">
                        <Button className="text-initial mr-2" variant="contained" color="primary" size="small" onClick={() => { this.setState({evaluationId: ""}); this.handleOpenModal(); }}>
                            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm" /> Ajouter
                        </Button>
                        <Button className="text-initial btn-info-custom" variant="contained" size="small" onClick={this.refreshTable}>
                            <FontAwesomeIcon icon={faSync} className="mr-1" size="sm" /> Rafraîchir
                        </Button>
                    </div>
                }
                
                <Table size="small">
                    <TableHead style={{ backgroundColor: "#000" }}>
                        <TableRow>
                            <TableCell />
                            <TableCell style={{ color: "#FFF" }}>Auteur</TableCell>
                            <TableCell style={{ color: "#FFF" }}>Sujet</TableCell>
                            <TableCell style={{ color: "#FFF" }}>Titre</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.feedbacks !== null ? 
                                this.state.feedbacks.map((row, i) => (
                                    <TableRow key={"feedbackNumber-"+i} >
                                        <TableCell align="center">
                                            <FontAwesomeIcon icon={faPen} className="pointer" onClick={() => this.getEvaluation(row.projectEvaluationId)} />
                                        </TableCell>
                                        <TableCell>{row.createByName}</TableCell>
                                        <TableCell>{this.isNotNull(row.stepName) ? row.stepName : "Feedback Général"}</TableCell>
                                        <TableCell>{row.title}</TableCell>
                                    </TableRow>
                                )) : null
                        }
                    </TableBody>
                </Table>

                <Modal show={this.state.modal} onHide={this.handleOpenModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className="siteStyle">
                            <strong>Ajouter un feedback</strong>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group row">
                            <div className="col-md-5">
                                <div className="siteStyle mt-2">Auteur : {this.state.author}</div>
                            </div>
                            <div className="col-md-7">
                                <Autocomplete className="mb-1" options={this.state.steps}
                                    getOptionLabel={o => o.stepName}
                                    onChange={this.handleAutocomplete} value={ this.state.subject }
                                    renderInput={(params) => <TextField {...params} label="Sujet" fullWidth variant="filled" />}
                                />
                                <TextField className="mb-3" fullWidth label="Titre" onChange={this.handleInput}
                                    name="title" value={this.state.title} variant="filled" />
                            </div>
                        </div>
                        
                        <div className="form-group">
                            <label>Points forts</label>
                            <textarea onChange={this.handleInput} rows="3" className="form-control"
                                name="strongPoints" value={this.state.strongPoints} />
                        </div>
                        <div className="form-group">
                            <label>Points à améliorer</label>
                            <textarea onChange={this.handleInput} rows="3" className="form-control"
                                name="learnPoints" value={this.state.learnPoints} />
                        </div>
                        <div className="form-group">
                            <label>Points d'excellence</label>
                            <textarea onChange={this.handleInput} rows="3" className="form-control"
                                name="excellentPoints" value={this.state.excellentPoints} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="text-initial mr-2" variant="contained" color="primary" size="small" onClick={this.saveEvaluation}>
                            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm" /> Enregistrer
                        </Button>
                        <Button className="text-initial" variant="contained" size="small" onClick={this.handleOpenModal}>
                            <FontAwesomeIcon icon={faTimesCircle} className="mr-1" size="sm" /> Fermer
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}
