import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export default class CreateNewPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newPassword: "",
            confirmPassword: "",
            errorMessage: "",
            success: false,
            disableBtn:false
        }

        this.handleInput = this.handleInput.bind(this);
        this.sendRequest = this.sendRequest.bind(this);

    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    sendRequest() {
        this.setState({
            disableBtn:true
        })

        fetch(new Request("api/Member/CreatedNewPassword"), {
                method: "POST",
                body: JSON.stringify({
                    Token: this.props.match.params.token,
                    Id: this.props.match.params.user,
                    NewPassword: this.state.newPassword,
                    ConfirmPassword: this.state.confirmPassword
                }),
                headers: new Headers({
                    'Content-Type': 'application/json'
                })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        errorMessage: "",
                        success: true,
                        disableBtn: false
                    });
                } else {
                    throw new Error(response);
                }
                
            })
            .catch(error => {
                this.setState({
                    errorMessage: error+" !",
                    success: false,
                    disableBtn: false
                });
            });
    }

    render() {
        return (
            <div className="main-content">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-6">
                            <div className="login-screen">
                                <div className="card-body">
                                    {
                                        this.state.success && <div className="alert alert-info">
                                                    <FontAwesomeIcon icon={faExclamationCircle} className="fa-2x mr-2" />
                                                    Votre nouveau mot de passe a été sauvegardé. Cliquez sur ce <a href="/">lien (Page d'accueil)</a> pour
                                                    vous connecter sur la plateforme AKILI.
                                            </div>
                                    }
                                    {
                                        this.state.errorMessage !== "" && < div className="alert alert-warning">
                                            <FontAwesomeIcon icon={faExclamationTriangle} className="fa-2x mr-2" />
                                            {this.state.errorMessage}
                                        </div>
                                    }
                                    {
                                        !this.state.success && <React.Fragment>
                                            <div className="alert alert-secondary">
                                                <Button disabled={this.state.disableBtn} variant="contained" onClick={this.sendRequest} color="primary">
                                                    <FontAwesomeIcon icon={faSave} className="mr-2" size="sm" /> Changer
                                                </Button>
                                            </div>
                                            <div className="form-group">
                                                <TextField label="Nouveau mot de passe" onChange={this.handleInput}
                                                    value={this.state.newPassword} fullWidth name="newPassword" type="password" />
                                            </div>
                                            <div className="form-group">
                                                <TextField label="Confirmer votre mot de passe" onChange={this.handleInput}
                                                    value={this.state.confirmPassword} name="confirmPassword" fullWidth type="password" />
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}