import styled from "styled-components";

export const FlexFiller = styled.div`
  flex: 1 1 0;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const FlexCenterRow = styled.div`
  display: flex;
  align-items: center;
`;
