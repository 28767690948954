import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faEnvelope,
    faEye,
    faKey,
    faLock,
    faSign,
    faSignInAlt,
    faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";
import { Chip } from "@material-ui/core";
import EditProfile from "./members/EditProfile";
import ChangePassword from "./identification/ChangePassword";
import Modal from "react-bootstrap/Modal";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { toast } from "react-toastify";
import { useAuthorizedBackendApi } from "../api/api";
import { StepsEvaluationDto } from "../api/services.generated";
import { useTranslation } from "react-i18next";
import { ToggleButton } from "@material-ui/lab";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useTranslations } from "../translations";
import { connect } from "react-redux";

function Avatar({disconnectApp, avatar}: {disconnectApp: any, avatar: any}) {
    const [profileDialogOpen, setProfileDialogOpen] = useState(false);
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const [tasks, setTasks] = useState<StepsEvaluationDto[] | undefined>(undefined);
    const [anchorEl, setAnchorEl] = useState<Element>();
    const [open, setOpen] = useState(false);
    const t = useTranslations();

    function openDialogProfile() {
        setProfileDialogOpen(true);
        handleCloseMenu();
    }

    function closeDialogProfil() {
        setProfileDialogOpen(false);
    }

    function handleOpenChangePassword() {
        setChangePasswordOpen(true);
        handleCloseMenu();
    }

    function logOut() {
        disconnectApp();
        handleCloseMenu();
    }

    function handleCloseMenu() {
        setAnchorEl(undefined);
    }

    function handleCloseChangePassword() {
        setChangePasswordOpen(false);
    }

    function handleClickOpen() {
        setOpen(!open);
        setAnchorEl(undefined);
    }

    const api = useAuthorizedBackendApi();

    async function getEvaluationProjectSteps() {
        if (!api) {
            return;
        }
        try {
            const tasks = await api.project_GetStepsEvaluation();
            if (tasks) {
                setTasks(tasks);
            }
        } catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
    }

    return localStorage.getItem("token") === undefined || localStorage.getItem("token") === null ? (
        <React.Fragment>
            <div className="d-none d-sm-block">
                <Link to="/signup" className="pointer">
                    <Button className="mr-2 text-initial btn-warning-custom" variant="contained" size="medium">
                        <FontAwesomeIcon icon={faSign} className="mr-1" /> {t.header.register()}
                    </Button>
                </Link>
                <Link to="/login" className="pointer">
                    <Button className="text-initial bg-white" variant="contained" color="default" size="medium">
                        <FontAwesomeIcon icon={faSignInAlt} className="mr-1" /> {t.header.logIn()}
                    </Button>
                </Link>
                <LanguageMenu />
            </div>
            <div className="d-block d-sm-none">
                <div className="pointer" onClick={(e) => setAnchorEl(e.currentTarget)} style={{ display: "flex" }}>
                    <img
                        className="img-fluid rounded-circle ml-2 mr-2 border"
                        alt="user"
                        style={{ width: "32px", height: "32px" }}
                        src={
                            "https://cdn0.iconfinder.com/data/icons/google-material-design-3-0/48/ic_settings_48px-512.png"
                        }
                    />
                    <FontAwesomeIcon icon={faCaretDown} className="ml-2 mt-2" />
                </div>
                <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!anchorEl}
                    onClose={handleCloseMenu}
                    TransitionComponent={Fade}
                    style={{ top: "36px" }}
                >
                    <MenuItem>
                        <Link to="/signup" className="pointer">
                            <Button
                                className="mr-2 text-initial btn-warning-custom"
                                variant="contained"
                                size="small"
                                fullWidth
                            >
                                <FontAwesomeIcon icon={faSign} className="mr-1" /> {t.header.register()}
                            </Button>
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/login" className="pointer">
                            <Button
                                className="text-initial bg-white"
                                variant="contained"
                                color="default"
                                size="small"
                                fullWidth
                            >
                                <FontAwesomeIcon icon={faSignInAlt} className="mr-1" /> {t.header.logIn()}
                            </Button>
                        </Link>
                    </MenuItem>
                </Menu>
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <div className="pointer" onClick={(e) => setAnchorEl(e.currentTarget)} style={{ display: "flex" }}>
                <img
                    className="img-fluid rounded-circle ml-2 mr-2 border"
                    alt="user"
                    style={{ width: "32px", height: "32px" }}
                    src={avatar ? avatar : "/img/user.png"}
                />
                <span className="d-none d-sm-block mt-2 text-initial">{localStorage.getItem("firstName")}</span>
                <FontAwesomeIcon icon={faCaretDown} className="ml-2 mt-2" />
            </div>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleCloseMenu}
                TransitionComponent={Fade}
                style={{ top: "36px" }}
            >
                {tasks !== undefined && tasks.length > 0 && (
                    <MenuItem className="my-1" onClick={handleClickOpen}>
                        <FontAwesomeIcon className="mr-2" icon={faEnvelope} /> A faire
                        <Chip
                            label={tasks.map((x) => x.step?.length || 0).reduce((a, b) => a + b)}
                            className="ml-2"
                            color="secondary"
                            size="small"
                            style={{ position: "absolute", right: "15px" }}
                        />
                    </MenuItem>
                )}
                <MenuItem className="my-1" component={Link} to="/messages">
                    <FontAwesomeIcon className="mr-2" icon={faEnvelope} /> Mes messages
                </MenuItem>
                <MenuItem className="my-1" onClick={openDialogProfile}>
                    <FontAwesomeIcon className="mr-2" icon={faUserEdit} /> Editer mon profil
                </MenuItem>
                <MenuItem className="my-1" onClick={handleOpenChangePassword}>
                    <FontAwesomeIcon className="mr-2" icon={faKey} /> Changer de mot de passe
                </MenuItem>
                <MenuItem className="my-1">
                    <LanguageSwitch />
                </MenuItem>
                <MenuItem className="my-1" onClick={logOut}>
                    <Button className="text-initial mx-auto" variant="contained" color="primary">
                        <FontAwesomeIcon className="mr-2" icon={faLock} /> Se déconnecter
                    </Button>
                </MenuItem>
            </Menu>
            {profileDialogOpen && <EditProfile closeProfil={closeDialogProfil} />}
            {changePasswordOpen && <ChangePassword closeDialog={handleCloseChangePassword} />}

            <Modal show={open} onHide={handleClickOpen}>
                <Modal.Header closeButton>
                    <Modal.Title className="siteStyle">
                        <strong>A faire</strong>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert alert-info mb-3">
                        Vous avez été assigné comme évaluateur sur des étapes des projets suivants :{" "}
                    </div>
                    <Table className="w-100" size="small">
                        <TableHead style={{ backgroundColor: "#000" }}>
                            <TableRow>
                                <TableCell />
                                <TableCell style={{ color: "#FFF" }}>Projet concerné</TableCell>
                                <TableCell style={{ color: "#FFF" }}>Etapes à évaluer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(tasks || []).map((row, i) => (
                                <TableRow key={"taskNumber-" + i}>
                                    <TableCell align="center" title="Voir le projet">
                                        <Link to={"/project/" + row.projectId}>
                                            <FontAwesomeIcon icon={faEye} className="pointer" />
                                        </Link>
                                    </TableCell>
                                    <TableCell>{row.projectName}</TableCell>
                                    <TableCell>
                                        {(row.step || []).map((elm) => (
                                            <Chip label={elm.stepName} color="primary" size="small" />
                                        ))}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

function LanguageSwitch() {
    const { i18n } = useTranslation();
    function changeLanguage(e: React.MouseEvent, lang: string) {
        i18n.changeLanguage(lang);
        localStorage.setItem("language", lang);
        e.preventDefault();
        e.stopPropagation();
    }
    return (
        <ToggleButtonGroup value={i18n.language} aria-label="text formatting">
            <ToggleButton onClick={(e) => changeLanguage(e, "en")} value="en">
                EN
            </ToggleButton>
            <ToggleButton onClick={(e) => changeLanguage(e, "fr")} value="fr">
                FR
            </ToggleButton>
            <ToggleButton onClick={(e) => changeLanguage(e, "nl")} value="nl">
                NL
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

function LanguageMenu() {
    const [anchorEl, setAnchorEl] = useState<Element>();
    const { i18n } = useTranslation();
    function changeLanguage(lang: string) {
        i18n.changeLanguage(lang);
        localStorage.setItem("language", lang);
    }
    return (
        <>
            <Button onClick={(e) => setAnchorEl(e.currentTarget)}>{i18n.language}</Button>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                TransitionComponent={Fade}
                // style={{ top: "36px" }}
            >
                <MenuItem className="my-1" onClick={() => changeLanguage("en")}>
                    EN
                </MenuItem>
                <MenuItem className="my-1" onClick={() => changeLanguage("fr")}>
                    FR
                </MenuItem>
                <MenuItem className="my-1" onClick={() => changeLanguage("nl")}>
                    NL
                </MenuItem>
            </Menu>
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {
        avatar: state.avatar
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        disconnectApp: () => {
            dispatch({ type: "DISCONNECT_APP" });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
