import React from "react";

export class DesignTest extends React.PureComponent {
    static displayName = DesignTest.name;

    constructor(props) {
        super(props);
        this.state = { currentCount: 0, modal: false };
        this.incrementCounter = this.incrementCounter.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        var i = 2;
        var radius = 200;
        var fields = document.getElementsByClassName("itemDot");
        var container = document.getElementById("dotCircle");
        var width = parseFloat(getComputedStyle(container, null).width.replace("px", ""));
        radius = width / 2.5;

        var height = parseFloat(getComputedStyle(container, null).height.replace("px", ""));
        var angle = 3 * Math.PI / 2, step = (2 * Math.PI) / fields.length;

        Array.prototype.forEach.call(fields, function(el) {
            var x = Math.round(width / 2 + radius * Math.cos(angle) - parseFloat(getComputedStyle(el, null).width.replace("px", "")) / 2);
            var y = Math.round(height / 2 + radius * Math.sin(angle) - parseFloat(getComputedStyle(el, null).height.replace("px", "")) / 2);
            if (window.console) {
                console.log(el.textContent, x, y);
            }

            el.style.left = x + "px";
            el.style.top = y + "px";
            angle += step;
        });

        var clickHandler = function() {
            var dataTab = this.dataset.tab;

            var itemDots = document.getElementsByClassName("itemDot");
            for (var k = 0; k < itemDots.length; k++) {
                itemDots[k].classList.remove("active");
            }

            this.classList.add("active");

            var circItems = document.getElementsByClassName("CirItem");
            for (var j = 0; j < circItems.length; j++) {
                circItems[j].classList.remove("active");
            }

            var circItemTab = document.getElementsByClassName("CirItem" + dataTab);
            circItemTab[0].classList.add("active");
            i = dataTab;
        };

        var elements = document.getElementsByClassName("itemDot");
        for (var l = 0; l < elements.length; l++) {
            elements[l].onclick = clickHandler;
        }

    }

    incrementCounter() {
        this.setState({
            currentCount: this.state.currentCount + 1
        });
    }

    toggle() {
        this.setState({ modal: !this.state.modal });
    }

    render() {
        return (
            <div>
                <section className="iq-features">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-12"/>
                            <div className="col-lg-6 col-md-12">
                                <div className="holderCircle">
                                    <div className="round"/>
                                    <div className="dotCircle" id="dotCircle">
                                        {
                                            this.props.steps.map((row, i) => (
                                                <span key={"arr1" + i}
                                                      className={i === 0 ? "itemDot active itemDot" + i + " " + row.style : "itemDot itemDot" + i + " " + row.style}
                                                      data-tab={i}>
                                                    <i className={row.icon}/>
                                                    <span className="forActive"/>
                                                </span>
                                            ))
                                        }
                                    </div>
                                    <div className="contentCircle">
                                        {
                                            this.props.steps.map((row, i) => (
                                                <div key={"arr1" + i}
                                                     className={i === 0 ? "CirItem title-box active CirItem" + i : "CirItem title-box CirItem" + i}>
                                                    <h2 className="title"><span>{row.title}</span></h2>
                                                    <p>{row.description}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12"/>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
