import React, { Component } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import ShareProject from "../ShareProject";
import { AuthorizedBackendApiContext } from "../../../api/api";

export default class SharedMembers extends Component {
    static contextType = AuthorizedBackendApiContext;

    constructor(props) {
        super(props);

        this.handleOpenShareProjectModal = this.handleOpenShareProjectModal.bind(this);
        this.handleCloseShareProjectModal = this.handleCloseShareProjectModal.bind(this);

        this.state = {
            membersShared: [],
            members:[],
            openShareProjectModal:false
        }
    }

    componentDidMount() {

        this.getMembersShare();
        this.getMembers();
    }

    getMembers(){

        fetch(new Request("api/Member/GetMemberShareProject"), {
            method: "GET",
            headers: new Headers({
                "Accept": "application/json",
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    members: response
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error=>{
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    getMembersShare(){
        fetch(new Request("api/Project/GetMembersShare?projectId="+this.props.projectId), {
            method: "GET",
            headers: new Headers({
                "Accept": "application/json",
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        membersShared: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    handleOpenShareProjectModal(){
        this.setState({
            openShareProjectModal:true
        })
    }

    handleCloseShareProjectModal(refreshData){
        this.setState({
            openShareProjectModal:false
        })

        if(refreshData !== undefined && refreshData){
            this.setState({
                membersShared:[]
            },()=>{
                this.getMembersShare()
            })
        }
    }

    removeMemberSharing(memberId) {

        fetch(new Request("api/Project/ProjectShareRemove"), {
            method: "DELETE",
            body: JSON.stringify({
                ProjectId: this.props.projectId,
                MemberId: memberId
            }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    var data = this.state.membersShared;
                    var index = this.state.membersShared.findIndex(f => f.id === memberId);
                    var member = this.state.membersShared.find(f => f.id === memberId);
                    data.splice(index, 1);

                    this.setState({
                        membersShared: data,
                        members: [...this.state.members, member]
                    });
                }
                else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="alert alert-secondary">
                    <Button className="text-initial" variant="contained" color="primary"
                        onClick={this.handleOpenShareProjectModal} size="small">
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" size="sm" /> Partager
                    </Button>
                </div>
                <Table size="small">
                    <TableHead style={{ backgroundColor: "#000" }}>
                        <TableRow>
                            <TableCell style={{ color: "#FFF" }}>Membre</TableCell>
                            <TableCell style={{ color: "#FFF" }}>Permission</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (this.state.membersShared || []).map(item => (
                                <TableRow key={item.memberId}>
                                    <TableCell>{item.memberName}</TableCell>
                                    <TableCell>{item.permissionName}</TableCell>
                                    <TableCell>
                                        <FontAwesomeIcon icon={faTrash} className="pointer"
                                            onClick={() => this.removeMemberSharing(item.memberId)} />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {
                    this.state.openShareProjectModal && 
                    <ShareProject closeModal={this.handleCloseShareProjectModal} projectName={this.props.projectName} refreshData={true}
                     members={this.state.members} api={this.context} projectId={this.props.projectId} ownerName={this.props.fullName} />
                }
            </React.Fragment>
        );
    }
}