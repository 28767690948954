import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlusCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import EditRoute from './EditRoute';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

export default class RoutesList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            routes: []
        }

        this.refreshTable = this.refreshTable.bind(this);
        this.newRoute = this.newRoute.bind(this);

        this.EditRouteRef = React.createRef();
    }

    componentDidMount() {
        this.refreshTable();
    }

    getEditRoute(routeId) {
        this.EditRouteRef.current.getRoute(routeId);
    }

    newRoute() {
        this.EditRouteRef.current.initFields();
    }

    refreshTable() {
        fetch(new Request("api/Route/GetRoutes", {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        routes: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
                this.setState({
                    routes: []
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Parcours - Success Team</title>
                        <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                    </MetaTags>
                </div>
                <nav className="siteStyle top-nav">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to='/dashboard'>Dashboard</Link></li>
                        <li className="breadcrumb-item active"><h1 className="siteTitle">Parcours</h1></li>
                    </ol>
                </nav>
                <div className="row py-3 px-0 mx-3">
                    <div className="col-md-6">
                        <div>
                            <div>
                                <div className="alert alert-secondary">
                                    <Button variant="contained" size="small" color="primary"
                                        className="mr-2 text-capitalize" onClick={this.newRoute}>
                                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm" /> Nouveau
                                    </Button>
                                    <Button variant="contained" size="small" className="text-capitalize btn-info-custom" onClick={this.refreshTable}>
                                        <FontAwesomeIcon icon={faSync} className="mr-1" size="sm" /> Rafraîchir
                                    </Button>
                                </div>
                                <div className="table-responsive">
                                    <Table className="w-100" size="small">
                                        <TableHead style={{ backgroundColor: "#000" }}>
                                            <TableRow>
                                                <TableCell style={{ color: "#fff" }}></TableCell>
                                                <TableCell style={{ color: "#fff" }}>Parcours</TableCell>
                                                <TableCell style={{ color: "#fff" }}>Modules</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                (this.state.routes || []).map((row, i) => (
                                                    <TableRow key={row.routeId}>
                                                        <TableCell>
                                                            <FontAwesomeIcon icon={faPen} className="pointer"
                                                                onClick={() => this.getEditRoute(row.routeId)} />
                                                        </TableCell>
                                                        <TableCell>{row.routeName}</TableCell>
                                                        <TableCell><Chip label={row.modulesCount} color="primary" size="small" /></TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <div>
                                <EditRoute refresh={this.refreshTable} ref={this.EditRouteRef} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
