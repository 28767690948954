import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faHome } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';

export default class GuestResponse extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            severitySnackbar: "",
            messageSnackbar:""
        }
    }

    componentDidMount() {

        fetch(new Request("api/JamSession/RegisterGuestJamSession"), {
            method: "POST",
            body: JSON.stringify({
                JamSessionId: this.props.match.params.jamsession,
                GuestId: this.props.match.params.guest
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(response => {
                this.setState({
                    loading: false,
                    severitySnackbar: response.alreadyRegistered ? "info" : "success",
                    messageSnackbar: response.alreadyRegistered ? "Vous êtes déjà enregistrés à la JamSession: " + response.jamSessionNumber :
                    "Votre enregistrement à la JamSession: " + response.jamSessionNumber + " a été sauvegardé."
                });
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    severitySnackbar: "warning",
                    messageSnackbar: error + " !"
                });
            });
    }

    render() {
        return (
            <div className="main-content" style={{ paddingTop: '10%' }}>
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    {
                                        this.state.loading ? 
                                            <FontAwesomeIcon icon={faSync} className="fa-4x fa-spin" style={{ marginTop: '10%', marginLeft: '40%' }} />
                                            : 
                                            <MuiAlert elevation={6} variant="filled" severity={this.state.severitySnackbar}>
                                                {this.state.messageSnackbar}
                                                <div className="mt-3">
                                                    <Link to="/">
                                                        <Button variant="contained" color="primary">
                                                            <FontAwesomeIcon icon={faHome} className="mr-1" /> Retour sur AKILI
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </MuiAlert> 
                                        
                                    }
                                </div>
                                    </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}