import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useTranslations } from '../translations';

export function VideoBanner() {

    const t = useTranslations()

    return (
        <React.Fragment>
            <div className="header">
                <div className="overlay" />
                <video playsInline autoPlay muted loop>
                    <source src="./img/homevideo.webm" type="video/mp4" />
                </video>
                <div className="container h-100">
                    <div className="d-flex h-100 text-center align-items-center">
                        <div className="w-100 text-white">
                            <h1 className="bannerH1">{t.home.videoBanner.message()}</h1>
                            <div className="lead mb-0 mt-4">
                                <Button component={Link} to="/about" className="text-initial btn-warning-custom" variant="contained" size="large">
                                {t.home.videoBanner.button()}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
