import React,{ Component } from "react";
import Divider from '@material-ui/core/Divider';


export default class ProjectDescription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subProject: "",
            project: "",
            steps: [],
            createdDate: "",
            fullName:""
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleElevatorPitch = this.handleElevatorPitch.bind(this);
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleElevatorPitch(e) {
        if (e.target.value.length <= 150) {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    render() {
        return (
            <div className="row siteStyle">
                <div className="col-md-12">
                    <div className="h6 my-3 alert alert-info">
                        Veuillez remplir le formulaire ci-dessous afin de nous donner plus d'informations concernant votre projet.
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mt-3">
                        <label>1. Pourquoi est ce que je veux réaliser ce projet</label>
                        <small id="whyRealizeHelper" className="form-text text-muted small-description">Quel est le problème à résoudre, l'opportunité à saisir ? Ou mes raisons personnelles ?</small>
                        <textarea onChange={this.props.handleInput} rows="3" className="form-control"
                            name="whyRealize" aria-describedby="whyRealizeHelper" value={this.props.whyRealize} />
                    </div>
                    <Divider className="mb-2" />
                    <div className="form-group">
                        <label>2. Pour qui  résout-on ces problèmes ?</label>
                        <small id="forWhomSolveHelper" className="form-text text-muted small-description">Qui va bénéficier des solutions (public cible, clients)</small>
                        <textarea onChange={this.props.handleInput} rows="3" className="form-control"
                            name="forWhomSolve" aria-describedby="forWhomSolveHelper" value={this.props.forWhomSolve} />
                    </div>
                    <Divider className="mb-2" />
                    <div className="form-group">
                        <label>3. Solutions existantes ?</label>
                        <small id="existingSolutionsHelper" className="form-text text-muted small-description">Comment est-ce que le public cible résout ce problème aujourd'hui ?</small>
                        <textarea onChange={this.props.handleInput} rows="3" className="form-control"
                            name="existingSolutions" aria-describedby="existingSolutionsHelper" value={this.props.existingSolutions} />
                    </div>
                    <Divider className="mb-2" />
                    <div className="form-group">
                        <label>4. Quoi ?</label>
                        <small id="whichSolutionHelper" className="form-text text-muted small-description">Quelle est la solution que je propose (Les grandes lignes) ?</small>
                        <textarea onChange={this.props.handleInput} rows="3" className="form-control"
                            name="whichSolution" aria-describedby="whichSolutionHelper" value={this.props.whichSolution} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mt-3">
                        <label>5. Quelle est ma particularité ?</label>
                        <small id="whatPeculiarityHelper" className="form-text text-muted small-description">En quoi suis-je unique, différente ou plus intéressante? Pourquoi les clients me choisissent moi à la place des concurrents</small>
                        <textarea onChange={this.props.handleInput} rows="3" className="form-control"
                            name="whatPeculiarity" aria-describedby="whatPeculiarityHelper" value={this.props.whatPeculiarity} />
                    </div>
                    <Divider className="mb-2" />
                    <div className="form-group">
                        <label>6. Comment ?</label>
                        <small id="howBuildHelper" className="form-text text-muted small-description">Comment est ce que je compte construire la solution concrètement? (Détails pratiques)</small>
                        <textarea onChange={this.props.handleInput} rows="3" className="form-control"
                            name="howBuild" aria-describedby="howBuildHelper" value={this.props.howBuild} />
                    </div>
                    <Divider className="mb-2" />
                    <div className="form-group">
                        <label>7. Le succès</label>
                        <small id="howMeasureSuccessHelper" className="form-text text-muted small-description">Comment est ce que je vais mesurer le succès (Profit financier, un meilleur monde, satisfaction personnelle, nombre de clients ou tout autre ...)</small>
                        <textarea onChange={this.props.handleInput} rows="3" className="form-control"
                            name="howMeasureSuccess" aria-describedby="howMeasureSuccessHelper" value={this.props.howMeasureSuccess} />
                    </div>
                    <Divider className="mb-2" />
                    <div className="w-100 mt-3">
                        <label className="d-block">Elevator Pitch</label>
                        <small id="elevatorPitchHelper" className="form-text text-muted small-description">Imaginez que vous êtes face à un investisseur dans un ascenseur, comment lui présenteriez vous votre projet en 3 secondes (150 mots)?</small>
                        <textarea onChange={this.props.handleElevatorPitch} max="150" rows="3" name="elevatorPitch" value={this.props.elevatorPitch} 
                            className="form-control" aria-describedby="elevatorPitchHelper" />
                        <small className="form-text text-muted small-description">Caractères restants : {150 - parseInt(this.props.elevatorPitch.length, 10)}</small>
                    </div>
                </div>
            </div>
        );
    }
}