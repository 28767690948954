import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

export default class EditJamSessionUnsubscribe extends Component{

    constructor(props) {
        super(props);

        this.state = {
            reason:""
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.saveUnsubscribe = this.saveUnsubscribe.bind(this);
    }

    handleInput(e) {
        this.setState({
            reason: e.target.value
        })
    }

    handleClose() {
        this.props.closeDialogUnsubscrition();
    }

    saveUnsubscribe() {
        
        fetch(new Request("api/JamSession/UnSubscribeJamSession"), {
            method: "POST",
            body: JSON.stringify({
                JamSessionId: this.props.jamSessionId,
                ReasonUnsubscribe: this.state.reason
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);

            })
            .then(([ok, response]) => {
                if (ok) {
                    toast.success("Vous avez desinscrit de la Jam Session !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                    this.props.closeDialogUnsubscrition();
                } else {
                    throw new Error(response);
                }
                
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    render() {
        return (
            <Dialog open={true} onClose={this.handleClose} fullWidth={true} maxWidth="sm">
                <DialogTitle>DESINSCRIPTION</DialogTitle>
                <DialogContent>
                    <div className="form-group">
                        <TextField label="Raison désinscription" rows="5" multiline fullWidth
                            value={this.state.reason} onChange={this.handleInput} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.saveUnsubscribe} variant="contained" color="primary">
                        <FontAwesomeIcon icon={faSave} className="mr-2" /> Désinscrire
                    </Button>
                    <Button onClick={this.handleClose} variant="contained">
                        <FontAwesomeIcon icon={faTimes} className="mr-2" /> Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}