import React, { createContext, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { AuthorizedBackendApiContext, useAuthorizedApi } from "../../api/api";
import {
    BackendService,
    IMessageDto,
    IRecentSenderDto,
    RecentSenderDto,
    UnreadMessageEntryDto,
    UserAvatarDto,
} from "../../api/services.generated";
import {
    Avatar,
    Badge,
    CircularProgress,
    createStyles,
    FilledInput,
    FormControl,
    IconButton,
    InputAdornment,
    Link as LinkMui,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useCurrentUser } from "../../utils/login";
import { formatRelative } from "date-fns";

import logoImage from "../../images/logo-successteam.png";
import { SignalRContextType, useSignalr } from "../../api/Signalr";
import { useAsyncEffect, useForceUpdate } from "../../utils/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faSmile, faSync } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { EmojiPicker } from "./EmojiPicker";
import { useAvatarDownloader } from "../members/AvatarDownloadContext";
import { makeSubscribable, Subscribable } from "../../utils/subscriptions";
import { faComments } from "@fortawesome/free-solid-svg-icons/faComments";
import { classNames } from "../../utils/classNames";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { FlexCenterRow } from "../../utils/StyleHelpers";
// @ts-ignore
import { ReactTitle, MetaTags } from "react-meta-tags";
import { AccountCircle } from "@material-ui/icons";

export type MessagingContextType = { unread: Subscribable<UnreadMessageEntryDto[]>; error: boolean } | null;
export const MessagingContext = createContext<MessagingContextType>(null);

export function useMessagingContextProvider(
    signalr: SignalRContextType | null,
    api: BackendService | null
): MessagingContextType | null {
    const unreadSubscribable = useMemo(() => {
        return makeSubscribable<UnreadMessageEntryDto[]>(true, []);
    }, []);

    const [error, setError] = useState(false);

    useAsyncEffect(
        async ({ wrap }) => {
            if (!api) {
                return;
            }
            try {
                const counts = await wrap(api.chat_GetUnreadMessages());
                unreadSubscribable.fire(counts || []);
            } catch (e) {
                setError(true);
            }
        },
        [api]
    );

    useEffect(() => {
        if (!signalr) {
            return;
        }

        const s = signalr.onMessage((data) => {
            const current = unreadSubscribable.current() || [];
            const entry = current.find((x) => x.userId === data.fromId);
            if (entry) {
                entry.count++;
                unreadSubscribable.fire([...current]);
            } else {
                unreadSubscribable.fire([new UnreadMessageEntryDto({ count: 1, userId: data.fromId }), ...current]);
            }
        });

        return () => s.unsubscribe();
    }, [signalr]);

    useEffect(() => {
        if (!signalr) {
            return;
        }

        const s = signalr.onChatRead((data) => {
            const current = unreadSubscribable.current() || [];
            unreadSubscribable.fire(current.filter((x) => x.userId !== data.fromId));
        });

        return () => s.unsubscribe();
    }, [signalr]);

    const result = useMemo(() => {
        return {
            unread: unreadSubscribable.subscribable,
        };
    }, []);
    return { ...result, error };
}

export function useMessagingContext() {
    return useContext(MessagingContext);
}

export function MessagesHeaderIcon() {
    const context: BackendService | null = useContext(AuthorizedBackendApiContext)
    const [unread, setUnread] = useState<UnreadMessageEntryDto[]>([]);
    const messaging = useMessagingContext();
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const [sendersUnread, setSendersUnread] = useState<UserAvatarDto[] | null>(null)

    useEffect(() => {
        if (!messaging) {
            return;
        }
        const s = messaging.unread((data) => {
            setUnread(data);
            setSendersUnread(null)
        });
        return () => s.unsubscribe();
    }, [messaging]);

    const totalUnread = unread.map((x) => x.count).reduce((a, b) => a + b, 0);

    async function getSendersUnread(){
        if(!context){
            return;
        }

        try
        {
            if(!sendersUnread)
            {
                var usersId: string[] = [];
                unread.map((item:UnreadMessageEntryDto)=>{
                    if(item.userId){
                        usersId.push(item.userId)
                    }
                })
                const response: UserAvatarDto[] | null = await context.member_GetUsersAvatar(usersId)
                setSendersUnread(response ? response : [])
            }
        }
        catch
        {
            setSendersUnread([])
        }
    }

    function loadSenderUnread(e:React.MouseEvent<SVGSVGElement,MouseEvent>){
        setAnchorEl(e.currentTarget)
        getSendersUnread()
    }

    return (
        <React.Fragment>
            <Badge badgeContent={messaging?.error ? "!" : totalUnread} color="error">
                <FontAwesomeIcon
                    onClick={loadSenderUnread}
                    size="2x"
                    style={{ cursor: "pointer", color: "#3f51b5" }}
                    icon={faComments}
                />
            </Badge>
            <Menu keepMounted open={Boolean(anchorEl)} onClose={()=>setAnchorEl(null)} 
                anchorEl={anchorEl} style={{ top: "36px" }}>
                {
                    sendersUnread === null ?
                    <MenuItem>
                        <FontAwesomeIcon icon={faSync} className="fa-spin fa-lg" />
                    </MenuItem> :
                    <List component="nav">
                        {
                            sendersUnread.map((sender:UserAvatarDto)=>(
                                <Link to="/messages" key={sender.userId?.toString()} className="text-decoration-none text-dark">
                                    <ListItem button>
                                        <ListItemIcon>
                                            {
                                                !sender.avatar ? <AccountCircle /> :
                                                <Avatar alt={"avatar "+sender.fullName} src={"data:" + sender.avatar.contentType+";base64,"+ sender.avatar.image} />
                                            }
                                        </ListItemIcon>
                                        <ListItemText primary={sender.fullName} />
                                    </ListItem>
                                </Link>
                            ))
                        }
                    </List>
                }
            </Menu>
        </React.Fragment>
        
    );
}

export function Messages({ userId }: { userId: string | null }) {
    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>Messages - Success Team</title>
                    <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </MetaTags>
            </div>
            <nav className="siteStyle" style={{ borderBottom: "1px solid #ccc", zIndex: 22 }}>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active"><h1 className="siteTitle">Messages</h1></li>
                </ol>
            </nav>
            <div className="d-flex custom-chat-div" style={{ flex: "1 1 0" }}>
                <Senders userId={userId} />
                {userId && <Chat userId={userId} />}
            </div>
        </React.Fragment>
    );
}

export function Senders({ userId }: { userId: string | null }) {
    const authorizedApi = useAuthorizedApi();
    const [senders, setSenders] = useState<IRecentSenderDto[] | "loading" | "error">("loading");

    const { trigger: loadSenders } = useAsyncEffect(
        async ({ wrap }) => {
            if (!authorizedApi) {
                return;
            }
            const last = await wrap(authorizedApi.chat_GetLastSenders());
            if (!last) {
                setSenders("error");
                return;
            }
            const existing = last.find((x) => x.userId === userId);
            if (!existing && userId) {
                const selected = await wrap(authorizedApi.member_GetMember(userId));
                if (!selected) {
                    setSenders("error");
                    return;
                }
                last.splice(0, 0, new RecentSenderDto({ userId, ...selected }));
            }
            setSenders(last);
        },
        [authorizedApi]
    );

    if (senders === "loading" || !authorizedApi) {
        return <CircularProgress />;
    }
    if (senders === "error") {
        return <LoadError onLoad={() => loadSenders()} />;
    }

    return (
        <div
            className="d-flex flex-column custom-chat-senders"
            style={{ backgroundColor: "#e9ecef", borderRight: "1px solid #ccc" }}
        >
            {senders.length === 0 && <div>Vous n'avez pas reçu de messages</div>}
            {senders.map((x) => (
                <Sender key={x.userId!} sender={x} selectedUserId={userId} />
            ))}
        </div>
    );
}

export function Sender({ selectedUserId, sender }: { selectedUserId: string | null; sender: IRecentSenderDto }) {
    const signalr = useSignalr();
    const fu = useForceUpdate();
    const messaging = useMessagingContext();
    const [unread, setUnread] = useState(0);

    useEffect(() => {
        if (!messaging) {
            return;
        }
        const s = messaging.unread((data) => {
            const thisSender = data.find((x) => x.userId === sender.userId);
            setUnread(thisSender?.count || 0);
        });
        return () => s.unsubscribe();
    }, [messaging, sender.userId]);

    useEffect(() => {
        if (signalr) {
            const s = signalr.onOnline((data) => {
                if (data.userId === sender.userId) {
                    sender.isOnline = data.online;
                    fu();
                }
            });
            return () => s.unsubscribe();
        }
    }, [signalr]);

    return (
        <Link
            className="siteStyle custom-chat-sender"
            key={sender.userId!}
            to={"/messages/" + sender.userId}
            style={{
                backgroundColor: sender.userId === selectedUserId ? "#c7edfc" : undefined,
            }}
        >
            <div style={{ position: "relative" }}>
                <UserAvatar userId={sender.userId!} />
                {sender.isOnline && (
                    <span
                        style={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            background: "rgb(66, 183, 42)",
                            borderRadius: "50%",
                            display: "inline-block",
                            height: "10px",
                            width: "10px",
                        }}
                    />
                )}
            </div>

            <div
                className={classNames("flex-fill", [unread > 0, "font-weight-bolder"])}
                style={{ marginLeft: "10px", display: "flex" }}
            >
                {unread > 0 && <div style={{ marginRight: "10px" }}>({unread})</div>}
                <div>
                    {sender.firstName} {sender.lastName}
                </div>
            </div>
        </Link>
    );
}

export function Message({
    sender,
    message,
    isLast,
}: {
    sender: IRecentSenderDto;
    message: IMessageDto;
    isLast: boolean;
}) {
    const currentUser = useCurrentUser();
    const ref = useRef<HTMLDivElement | null>();
    useLayoutEffect(() => {
        if (isLast) {
            ref.current!.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    const isOwn = currentUser.userId === sender.userId;
    const body = isOwn ? (
        <MessageBody user={sender} message={message} isOwn={isOwn} />
    ) : (
        <>
            <UserAvatar userId={sender.userId!} />
            <div style={{ width: "10px" }} />
            <MessageBody user={sender} message={message} isOwn={isOwn} />
        </>
    );
    return (
        <div
            className="d-flex"
            style={{ margin: "10px 0", alignSelf: isOwn ? "flex-end" : "flex-start" }}
            ref={(r) => (ref.current = r)}
        >
            {body}
        </div>
    );
}

export function MessageBody({
    user,
    message,
    isOwn,
}: {
    user: IRecentSenderDto;
    message: IMessageDto;
    isOwn: boolean;
}) {
    return (
        <div>
            <div className="d-flex pb-1">
                <span className="siteStyle" style={{ fontSize: "12px" }}>
                    {user.firstName} {user.lastName}
                </span>
                <div className="flex-fill" style={{ minWidth: "10px" }} />
                <span className="siteStyle" style={{ fontSize: "12px" }}>
                    {formatRelative(message.sentAt, new Date())}
                </span>
            </div>
            <FlexCenterRow style={{ justifyContent: isOwn ? "flex-end" : undefined }}>
                {isOwn &&
                    (message.isRead ? (
                        <FontAwesomeIcon icon={faCheck} size="sm" style={{ marginRight: "5px" }} />
                    ) : (
                        <FontAwesomeIcon icon={faClock} size="sm" style={{ marginRight: "5px" }} />
                    ))}
                <div
                    className="border py-1 px-2"
                    style={{
                        borderRadius: !isOwn ? "0 10px 10px" : "10px 10px 0px 10px",
                        fontSize: "13px",
                        backgroundColor: !isOwn ? "#ebebeb" : "#c7edfc",
                        color: "#252423",
                        maxWidth: !isOwn ? "75%" : "auto",
                    }}
                >
                    <Typography variant="body2" component="p">
                        {message.messageText}
                    </Typography>
                </div>
            </FlexCenterRow>
        </div>
    );
}

export function UserAvatar({ userId }: { userId: string }) {
    const [image, setImage] = useState<string>();
    const authorizedApi = useAuthorizedApi();
    const downloader = useAvatarDownloader();

    useAsyncEffect(
        async function ({ wrap }) {
            if (!authorizedApi) {
                return;
            }
            setImage("loading");
            const avatar = await wrap(downloader.getAvatar(userId));
            if (!avatar) {
                setImage(undefined);
                return;
            }
            setImage(avatar);
        },
        [userId, authorizedApi]
    );

    return !image ? (
        <img src={logoImage} className="mt-1 rounded-circle bg-white img-fluid mx-auto custom-chat-avatar" alt="pho" />
    ) : image === "loading" ? (
        <Skeleton variant="circle" style={{ width: "40px", height: "40px" }} />
    ) : (
        <img
            src={"data:image/jpeg;base64," + image}
            className="mt-1 rounded-circle bg-white img-fluid mx-auto custom-chat-avatar"
            alt="pho"
        />
    );
}

let messageId = 0;

export function Chat({ userId }: { userId: string }) {
    const authorizedApi = useAuthorizedApi();
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [messages, setMessages] = useState<IMessageDto[]>([]);
    const [otherUser, setOtherUser] = useState<IRecentSenderDto | "loading">("loading");
    const [thisUser, setThisUser] = useState<IRecentSenderDto | "loading">("loading");
    const currentUser = useCurrentUser();
    const signalr = useSignalr();
    const fu = useForceUpdate();

    useEffect(() => {
        if (!signalr || !authorizedApi) {
            return;
        }
        const s = signalr.onMessage((data) => {
            if (data.toId === userId) {
                setMessages([...messages, data]);
                if (!document.hidden) {
                    authorizedApi.chat_MarkChatRead(userId);
                }
            }
        });
        return () => s.unsubscribe();
    }, [signalr, messages, authorizedApi, userId]);

    useEffect(() => {
        if (!authorizedApi) {
            return;
        }
        const cb = () => {
            if (document.visibilityState == "visible") {
                authorizedApi.chat_MarkChatRead(userId);
            }
        };
        document.addEventListener("visibilitychange", cb);
        return () => document.removeEventListener("visibilitychange", cb);
    }, [authorizedApi, userId]);

    useEffect(() => {
        if (!signalr) {
            return;
        }
        const s = signalr.onChatRead((data) => {
            if (data.toId === userId) {
                messages.forEach((x) => (x.isRead = true));
                fu();
            }
        });
        return () => s.unsubscribe();
    }, [signalr, userId, messages]);

    useAsyncEffect(
        async ({ wrap }) => {
            if (!authorizedApi) {
                return;
            }
            await wrap(authorizedApi.chat_MarkChatRead(userId));
        },
        [userId, authorizedApi]
    );

    useAsyncEffect(
        async ({ wrap }) => {
            if (!authorizedApi) {
                return;
            }
            setLoading(true);
            setLoadingError(false);
            try {
                const newMessages = await wrap(authorizedApi.chat_GetLastMessages(userId, null));
                if (!newMessages) {
                    setLoadingError(true);
                    return;
                }
                setMessages(newMessages);
                const memberInfo = await wrap(authorizedApi.member_GetMember(userId));
                if (memberInfo) {
                    setOtherUser({ userId, ...memberInfo });
                }
                const memberInfo2 = await wrap(authorizedApi.member_GetMember(currentUser.userId));
                if (memberInfo2) {
                    setThisUser({ userId: currentUser.userId, ...memberInfo2 });
                }
            } finally {
                setLoading(false);
            }
        },
        [userId, authorizedApi]
    );

    async function loadPreviousMessages(api: BackendService) {
        setLoading(true);
        setLoadingError(false);
        try {
            const lastId = messages.length === 0 ? null : messages[0].messageId;
            const newMessages = await api.chat_GetLastMessages(userId, lastId);
            if (!newMessages) {
                setLoadingError(true);
                return;
            }
            setMessages([...newMessages, ...messages]);
        } finally {
            setLoading(false);
        }
    }

    if (!authorizedApi) {
        return <></>;
    }

    const messagesArea =
        otherUser === "loading" || thisUser === "loading" ? (
            <CircularProgress />
        ) : (
            <>
                {messages.map((x, i) => (
                    <Message
                        key={x.messageId!}
                        sender={x.fromId === thisUser.userId ? thisUser : otherUser}
                        message={x}
                        isLast={i === messages.length - 1}
                    />
                ))}
            </>
        );

    return (
        <div className="d-flex flex-column flex-fill custom-chat" style={{ padding: "0px" }}>
            <div
                className="d-flex flex-column px-2 px-md-5"
                style={{ overflow: "auto", flex: "1 1 0", padding: "10px" }}
            >
                {loading ? (
                    <CircularProgress />
                ) : (
                    <LinkMui
                        className="text-white"
                        onClick={() => loadPreviousMessages(authorizedApi)}
                        style={{
                            alignSelf: "center",
                            padding: "5px 10px",
                            backgroundColor: "#fd7419",
                            cursor: "pointer",
                            borderRadius: "10px",
                        }}
                    >
                        Charger
                    </LinkMui>
                )}
                {loadingError && <div>Les messages n'ont pas été chargés</div>} {messagesArea}
            </div>
            <div className="d-flex">
                <SendMessage
                    userId={userId}
                    onSend={(text) => {
                        setMessages([
                            ...messages,
                            {
                                fromId: currentUser.userId,
                                messageId: (messageId++).toString(),
                                sentAt: new Date(),
                                messageText: text,
                                toId: userId,
                                isRead: false,
                            },
                        ]);
                    }}
                />
            </div>
        </div>
    );
}

export function LoadError({ onLoad }: { onLoad: () => void }) {
    return (
        <div className="d-flex">
            <div>Erreur lors du chargement des données.</div>
            <div onClick={onLoad}>Recharger</div>
        </div>
    );
}

export function SendMessage({ userId, onSend }: { userId: string; onSend: (text: string) => void }) {
    const api = useContext(AuthorizedBackendApiContext);
    const [text, setText] = useState("");
    const [show, setShow] = useState(false);

    function sendMessage(api: BackendService) {
        if (/\S/.test(text)) {
            // string is not empty and not just whitespace
            api.chat_SendMessage(userId, text);
            setText("");
            onSend(text);
        }
    }

    if (api === null) {
        return <div>Le chat n'est pas disponible</div>;
    }

    return (
        <div className="d-flex w-100" style={{ borderTop: "1px #E5E5E5 solid" }}>
            <FormControl variant="filled" style={{ width: "calc(100% - 80px)" }}>
                <FilledInput
                    id="filled-adornment-password"
                    value={text}
                    onChange={(x) => setText(x.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            sendMessage(api);
                        }
                    }}
                    className="border-0"
                    placeholder="Tapez un message"
                    startAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="show emoji"
                                onClick={() => setShow(!show)}
                                edge="end"
                                style={{ marginRight: "10px", marginBottom: "10px" }}
                                //size="small"
                            >
                                <FontAwesomeIcon icon={faSmile} size="sm" />
                            </IconButton>
                        </InputAdornment>
                    }
                    multiline
                    style={{
                        padding: "18px 12px 10px",
                        borderBottom: "none !important",
                        borderRight: "none !important",
                        borderLeft: "none !important",
                    }}
                />
            </FormControl>
            <div style={{ width: "80px" }}>
                <IconButton
                    onClick={() => sendMessage(api)}
                    style={{
                        marginTop: "11px",
                        marginRight: "10px",
                        marginLeft: "10.5px",
                        height: "36px",
                        width: "36px",
                        fontSize: "15px",
                        backgroundColor: "rgb(63, 81, 181)",
                        color: "#FFF",
                    }}
                    color="primary"
                >
                    <FontAwesomeIcon icon={faPaperPlane} size="sm" />
                </IconButton>
            </div>
            <EmojiPicker show={show} setShow={setShow} text={text} setText={setText} />
        </div>
    );
}
