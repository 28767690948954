import React, { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faList, faPlusCircle, faSync, faTh } from "@fortawesome/free-solid-svg-icons";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MaterialTable, { Column } from "material-table";
import CreateMember from "./CreateMember";
import { useAuthorizedApi } from "../../api/api";
import { ListedMemberDto } from "../../api/services.generated";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../api/utils";
import { useAsyncEffect } from "../../utils/react";
import { AsyncButton } from "../../utils/AsyncButton";
import { CardMember } from "./CardMember";
import { ButtonExcel } from "./ButtonExcel";
import { ImageMember } from "./ImageMember";
// @ts-ignore
import { ReactTitle, MetaTags } from "react-meta-tags";
import Skeleton from "@material-ui/lab/Skeleton";

export function MembersList({ isAdmin }: { isAdmin: boolean }) {
    const api = useAuthorizedApi();
    const [cardStyle, setCardStyle] = useState(true);
    const [members, setMembers] = useState<Array<ListedMemberDto> | "loading">("loading");
    const [openModalCreateMember, setOpenModalCreateMember] = useState(false);

    const { trigger: refreshMembersList } = useAsyncEffect(
        async function({ wrap }) {
            if (!api) {
                return;
            }
            try {
                const members = await wrap(api.member_GetMembers());
                if (members) {
                    setMembers(members);
                }
            } catch (error) {
                toast.warn(getErrorMessage(error) + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            }
        },
        [api]
    );

    function toggleStyle() {
        setCardStyle((x) => !x);
    }

    function handleOpenModalCreateMember() {
        setOpenModalCreateMember(true);
    }

    function handleCloseModalCreateMember() {
        setOpenModalCreateMember(false);
    }

    function handleLockMember(memberId: string | null | undefined, e: ChangeEvent<HTMLInputElement>) {
        const checked = e.target.checked;

        fetch(new Request("api/Member/LockMember"), {
            method: "POST",
            body: JSON.stringify({
                Id: memberId,
                Locked: checked
            }),
            headers: new Headers({
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token")
            })
        })
            .then((response) => {
                if (response.status !== 204) return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    var value = checked ? "bloqué" : "débloqué";
                    toast.success("Le compte de l'utilisateur a été " + value + " !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch((error) => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    const columns: Column<ListedMemberDto>[] = [
        {
            render: (rowData) => (
                <Link to={"/profile/" + rowData.id} style={{ color: "#6c757d" }}>
                    <FontAwesomeIcon icon={faEye}/>
                </Link>
            )
        },
        {
            render: (rowData) => (
                <ImageMember member={rowData} />
            )
        },
        { title: "Noms et prénoms", field: "fullName" },
        { title: "Equipe", field: "teamName" },
        { title: "Genre", field: "genre" },
        { title: "Téléphone", field: "phoneNumber" },
        { title: "Email", field: "email" },
        { title: "Ville", field: "city" },
        { title: "Pays", field: "country" }
    ];

    if (isAdmin) {
        columns.push({
            title: "Bloquer",
            render: (rowData) => (
                <Switch color="primary" onChange={(e) => {handleLockMember(rowData.id, e); refreshMembersList();}} defaultChecked={rowData.locked}/>
            )
        });
    }

    if (!api || members === "loading") {
        return <Skeleton />;
    }

    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>Membres - Success Team</title>
                    <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </MetaTags>
            </div>
            <nav className="siteStyle top-nav">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active"><h1 className="siteTitle">Membres</h1></li>
                </ol>
            </nav>
            <div className="card-body">
                <div>
                    {isAdmin ? (
                        <div
                            className="alert alert-secondary"
                            style={{ display: "flow-root", paddingLeft: "0.9rem", paddingRight: "0.9rem" }}
                        >
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                className="mr-2 text-initial"
                                onClick={() => {
                                    handleOpenModalCreateMember();
                                }}
                            >
                                <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm"/> Nouveau
                            </Button>
                            <AsyncButton
                                variant="contained"
                                size="small"
                                className="mr-2 text-initial btn-info-custom"
                                onClickAsync={refreshMembersList}
                            >
                                <FontAwesomeIcon icon={faSync} className="mr-1" size="sm"/> Rafraîchir
                            </AsyncButton>
                            { isAdmin && <ButtonExcel data={members} /> }

                            <ButtonGroup
                                variant="contained"
                                color="primary"
                                aria-label="contained primary button group"
                                className="float-right group-button"
                            >
                                <Button
                                    className={cardStyle ? "text-initial" : "text-initial bg-white"}
                                    color={cardStyle ? "primary" : "default"}
                                    onClick={() => toggleStyle()}
                                >
                                    <FontAwesomeIcon icon={faTh}/>
                                </Button>
                                <Button
                                    className={!cardStyle ? "text-initial" : "text-initial bg-white"}
                                    color={!cardStyle ? "primary" : "default"}
                                    onClick={() => toggleStyle()}
                                >
                                    <FontAwesomeIcon icon={faList}/>
                                </Button>
                            </ButtonGroup>
                        </div>
                    ) : (
                        <div className="alert alert-secondary" style={{ display: "flow-root" }}>
                            <ButtonGroup
                                variant="contained"
                                color="primary"
                                aria-label="contained primary button group"
                                className="float-right"
                            >
                                <Button
                                    className={cardStyle ? "text-initial" : "text-initial bg-white"}
                                    color={cardStyle ? "primary" : "default"}
                                    onClick={() => toggleStyle()}
                                >
                                    <FontAwesomeIcon icon={faTh}/>
                                </Button>
                                <Button
                                    className={!cardStyle ? "text-initial" : "text-initial bg-white"}
                                    color={!cardStyle ? "primary" : "default"}
                                    onClick={() => toggleStyle()}
                                >
                                    <FontAwesomeIcon icon={faList}/>
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}

                    {!cardStyle ? (
                        <div className="row">
                            <div className="col-md-12">
                                <div>
                                    <div>
                                        <MaterialTable
                                            //options={{
                                            //    searchFieldStyle:
                                            //}}
                                            columns={columns}
                                            data={members}
                                            title="Liste des membres"
                                            options={{
                                                pageSize: 10,
                                                pageSizeOptions: [10, 20, 50]
                                            }}
                                            //components={{
                                            //    Toolbar: props => (
                                            //        isAdmin ? <React.Fragment>
                                            //            <MTableToolbar {...props} />
                                            //            <div className="px-4 pb-3">
                                            //                <Button variant="contained" size="small" color="primary" className="mr-2 text-capitalize" onClick={() => { this.handleOpenModalCreateMember(""); }}>
                                            //                    <FontAwesomeIcon icon={faPlusCircle} className="mr-1" /> Nouveau
                                            //                </Button>
                                            //                <Button variant="contained" size="small" className="text-capitalize btn-info-custom" onClick={this.refreshMembersList}>
                                            //                    <FontAwesomeIcon icon={faSync} className="mr-1" /> Rafraîchir
                                            //                </Button>
                                            //            </div>

                                            //        </React.Fragment> : <MTableToolbar {...props} />

                                            //    )
                                            //}}
                                            localization={{
                                                pagination: {
                                                    labelRowsSelect: "lignes",
                                                    labelRowsPerPage: "Lignes par page",
                                                    firstAriaLabel: "Première page",
                                                    firstTooltip: "Première page",
                                                    previousAriaLabel: "Précédent",
                                                    previousTooltip: "Précedent",
                                                    nextAriaLabel: "Suivant",
                                                    nextTooltip: "Suivant",
                                                    lastAriaLabel: "Dernière page",
                                                    lastTooltip: "Dernière page",
                                                    labelDisplayedRows: "{from}-{to} sur {count}"
                                                },
                                                toolbar: {
                                                    searchTooltip: "Rechercher",
                                                    searchPlaceholder: "Rechercher"
                                                },
                                                header: {
                                                    actions: "Actions"
                                                },
                                                body: {
                                                    emptyDataSourceMessage: "Pas d'enregistrements à afficher",
                                                    filterRow: {
                                                        filterTooltip: "Filtrer"
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-12">
                                <div>
                                    <div>
                                        <div className="row">
                                            {members.map((row) => (
                                                <div
                                                    key={row.id || ""}
                                                    className="col-lg-3 col-md-4 col-sm-6 col-12 siteStyle"
                                                    style={{ display: "block" }}
                                                >
                                                    <CardMember member={row}/>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {openModalCreateMember && (
                        <CreateMember
                            memberId={""}
                            refreshTable={() => refreshMembersList}
                            onCloseModal={() => handleCloseModalCreateMember()}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
