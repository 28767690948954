import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Skeleton from "@material-ui/lab/Skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFile, faFileImage, faFilePdf, faFileWord } from "@fortawesome/free-solid-svg-icons";

export class ProjectStepDocuments extends React.Component {
    static displayName = ProjectStepDocuments.name;

    constructor(props) {
        super(props);
        this.state = {
            currentCount: 0,
            modal: false,
            loadSupportFiles: false
        };
    }

    render() {
        return (
            <div className="siteStyle mt-3">
                <div className="card" style={{ backgroundColor: "#F5F5F5" }}>
                    <div className="card-body">
                        <div className="custom-subtitle border-bottom font-weight-bold">Outils et liens utiles</div>
                        {
                            this.props.loadSupportFiles ?
                                <React.Fragment>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Skeleton variant="circle" width={30} height={30}/>

                                            </ListItemIcon>
                                            <ListItemText primary={<Skeleton variant="text"/>}/>
                                            <ListItemSecondaryAction>
                                                <Skeleton variant="text" width={20} height={20}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Skeleton variant="circle" width={30} height={30}/>

                                            </ListItemIcon>
                                            <ListItemText primary={<Skeleton variant="text"/>}/>
                                            <ListItemSecondaryAction>
                                                <Skeleton variant="text" width={20} height={20}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Skeleton variant="circle" width={30} height={30}/>

                                            </ListItemIcon>
                                            <ListItemText primary={<Skeleton variant="text"/>}/>
                                            <ListItemSecondaryAction>
                                                <Skeleton variant="text" width={20} height={20}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Skeleton variant="circle" width={30} height={30}/>

                                            </ListItemIcon>
                                            <ListItemText primary={<Skeleton variant="text"/>}/>
                                            <ListItemSecondaryAction>
                                                <Skeleton variant="text" width={20} height={20}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </React.Fragment> :
                                <List component="nav">
                                    {
                                        (this.props.supportFiles || []).map((item, index) => (
                                            <ListItem key={index}>
                                                <ListItemIcon>
                                                    {
                                                        item.contentType === "application/pdf" ?
                                                            <FontAwesomeIcon icon={faFilePdf}
                                                                             className="text-danger"/> :
                                                            (item.contentType === "application/msword" || item.contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ?
                                                                <FontAwesomeIcon icon={faFileWord}
                                                                                 className="text-primary"/> :
                                                                (item.contentType === "image/gif" || item.contentType === "image/jpeg" || item.contentType === "image/png") ?
                                                                    <FontAwesomeIcon icon={faFileImage}/> :
                                                                    <FontAwesomeIcon icon={faFile}/>
                                                    }
                                                </ListItemIcon>
                                                <ListItemText primary={item.fileName}/>
                                                <ListItemSecondaryAction>
                                                    <FontAwesomeIcon icon={faDownload}
                                                                     onClick={() => this.props.downloadSupportFile(item.fileName, this.props.stepId)}
                                                                     className="pointer"/>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
