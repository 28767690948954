import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faSync, faEye, faList } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { BackendService, JamSessionSetDto, MainJamSessionUserDto, JamSessionRoleDto, NotifyJamSession, MemberSimpleDataDto, JamSessionRegistration, SessionAgendaDto, JamSessionDto } from '../../../api/services.generated';
import { useAuthorizedBackendApi } from '../../../api/api';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import EditJamSessionResponses from './EditJamSessionResponses';
import EditJamSessionAgenda from './EditJamSessionAgenda';
import EditJamSessionAgendaMember from './EditJamSessionAgendaMember';
import JamSessionInscription from './JamSessionInscription';
import PermissionJamSession from './PermissionJamSession';
import { SystemState, Action } from '../../../reducers/types';
import { connect } from 'react-redux';


interface JamSessionRoleChecked {
    role:JamSessionRoleDto,
    checked?: boolean
}

function UpdateJamSession(props: any) {

    const api: BackendService | null = useAuthorizedBackendApi()

    const [jamSessionId, setJamSessionId] = useState<string | undefined>(props.match?.params?.id)
    const [jamSessionNumber, setJamSessionNumber] = useState<string | null>()
    const [btnSaveLoading, setBtnSaveLoading] = useState<boolean>(false)
    const [generalEvaluatorId, setGeneralEvaluatorId] = useState<string>()
    const [moderatorId, setModeratorId] = useState<string>()
    const [theme, setTheme] = useState<string | null>()
    const [jamSessionDate, setJamSessionDate] = useState<Date | string>((new Date()).getFullYear() + "-" + ("0" + ((new Date()).getMonth() + 1)).slice(-2) + "-" + ("0" + (new Date()).getDate()).slice(-2))
    const [jamSessionHourFrom, setJamSessionHourFrom] = useState<string>()
    const [jamSessionHourTo, setJamSessionHourTo] = useState<string>()
    const [address, setAddress] = useState<string | null>()
    const [description, setDescription] = useState<string | null>()
    const [homeMessage, setHomeMessage] = useState<string | null>("Insérer le message d'accueil ici...")
    const [moderator, setModerator] = useState<MainJamSessionUserDto | null>(null)
    const [generalEvaluator, setGeneralEvaluator] = useState<MainJamSessionUserDto | null>(null)
    const [protractor, setProtractor] = useState<MainJamSessionUserDto | null>(null)
    const [protractors, setProtractors] = useState<MainJamSessionUserDto[]>([])
    const [conferenceLink, setConferenceLink] = useState<string>("")
    const [jamSessionRoles, setJamSessionRoles] = useState<JamSessionRoleDto[] | null>()
    const [roles, setRoles] = useState<JamSessionRoleChecked[]>([])
    const [rolesInit, setRolesInit] = useState<JamSessionRoleChecked[]>([])
    const [members, setMembers] = useState<MemberSimpleDataDto[]>([])
    const [loadedForm, setLoadedForm] = useState<boolean>(false)
    const [tabValue, setTabValue] = useState<number>(0)
    const [jamSessionClosed, setJamSessionClosed] = useState<boolean>(false)
    const [membersResponse, setMembersResponse] = useState<JamSessionRegistration[]>([])
    const [agendas, setAgendas] = useState<SessionAgendaDto[]>([])
    const [isRegistered, setIsRegistered] = useState<boolean>(false)

    useEffect(()=>{
        if(jamSessionId !== undefined){
            getJamSession()
            getAllRoles()
        }
        getMembers()
    },[])

    useEffect(()=>{
        var rolesdata: JamSessionRoleChecked[] = [];
        (rolesInit || []).map((item:JamSessionRoleChecked)=>{
            item.checked = (jamSessionRoles || []).some(j=>j.jamSessionRoleId === item.role.jamSessionRoleId)
            rolesdata.push(item)
        })

        setRoles(rolesdata);
    },[jamSessionRoles, rolesInit])

    useEffect(()=>{
        if(members.length > 0){
            const mod = members.find(f=>f.id === moderatorId)
            mod && setModerator(mod)
            const genEval = members.find(f=>f.id === generalEvaluatorId)
            genEval && setGeneralEvaluator(genEval)
        }
    },[moderatorId,generalEvaluatorId])
 
    async function saveJamSession(){
        if (generalEvaluator && moderator && generalEvaluator.id === moderator.id) {
            toast.warn("Un membre ne peut pas être modérateur et évaluateur général", {
                position: "top-left",
                hideProgressBar: false
            });
        }
        else {
            setBtnSaveLoading(true)
            if(!api) {
                return;
            }

            try {
                const response:NotifyJamSession | null = await api.jamSession_SetJamSession({
                    jamSessionId: jamSessionId,
                    theme: theme,
                    jamSessionDate: jamSessionDate,
                    jamSessionHourFrom: jamSessionHourFrom,
                    jamSessionHourTo: jamSessionHourTo,
                    address: address,
                    description: description,
                    //homeMessage: homeMessage,
                    moderator: moderator,
                    generalEvaluator: generalEvaluator,
                    conferenceLink: conferenceLink,
                    protractor: protractor,
                    jamSessionRoles: roles.filter(r=>r.checked).map(item=>item.role)
                } as JamSessionSetDto);

                if (!response) {
                    return;
                }

                if (response.isNew) {
                    response.jamSessionId !== null && setJamSessionId(response.jamSessionId);
                    response.jamSessionNumber !== null && setJamSessionNumber(response.jamSessionNumber);

                    getAllRoles();
                }

                toast.success("Jam Session sauvegardée !", {
                    position: "top-center",
                    hideProgressBar: false
                });
            }
            catch (error) {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false,
                });
            }
            finally {
                setBtnSaveLoading(false);
            }
        }
    }

    async function getAllRoles(){

        if(!api){
            return;
        }

        try{
            const response: JamSessionRoleDto[] | null = await api.jamSessionRole_GetJamSessionRolesSimpleData()
            
            var rolesdata: JamSessionRoleChecked[] = [];
            (response || []).map((item:JamSessionRoleDto)=>(
                rolesdata.push({
                    role:item,
                    checked: false
                })
            ))

            setRolesInit([...rolesdata]);
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
        
    }

    async function getMembers(){

        if(!api) {
            return;
        }

        try {
            const response: MemberSimpleDataDto[] | null = await api.member_GetMembersName()
            if(!response){
                return;
            }

            setMembers(response);
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
    }

    function refreshGetJamSession(){
        getJamSession()
    }

    function handleToggleCheckbox(e:React.ChangeEvent<HTMLInputElement>,checked:boolean) {
        var array = [...roles];
        var index = roles.findIndex(r => r.role.jamSessionRoleId === e.target.value);
        array[index].checked = checked;
        setRoles([...array]);
    }

    async function getJamSession(){
        setLoadedForm(true)

        try{
            if(!api){

                return;
            }

            const response: JamSessionDto | null = await api.jamSession_GetJamSession(jamSessionId ? jamSessionId : null)
            if(!response){
                return;
            }
            
            var jamDate = new Date(response.jamSessionDate)
            setTheme(response.theme)
            setConferenceLink(response.conferenceLink ? response.conferenceLink : "")
            setJamSessionNumber(response.jamSessionNumber)
            setJamSessionHourFrom(response.jamSessionHourFrom)
            setJamSessionHourTo(response.jamSessionHourTo)
            setJamSessionDate(jamDate.getFullYear()+"-"+ ("0" + (jamDate.getMonth() + 1)).slice(-2) + "-" + ("0" + jamDate.getDate()).slice(-2))
            setAddress(response.address)
            setModeratorId(response.moderator && response.moderator.id ? response.moderator.id : undefined)
            setModerator(response.moderator ? response.moderator : null)
            setGeneralEvaluatorId(response.generalEvaluator && response.generalEvaluator.id ? response.generalEvaluator.id : undefined)
            setGeneralEvaluator(response.generalEvaluator ? response.generalEvaluator : null)
            setDescription(response.description)
            //setHomeMessage(response.homeMessage)
            setJamSessionRoles(response.jamSessionRoles)
            setJamSessionClosed(response.closed)
            setMembersResponse(response.registers ? response.registers : [])
            setIsRegistered(response.isRegistered)
            setAgendas(response.agenda ? response.agenda : [])
            setProtractor(response.protractor ? response.protractor : null)
            setProtractors(response.protractors ? response.protractors : [])
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
        finally
        {
            setLoadedForm(false)
        }
    }

    return (
        <React.Fragment>
            <nav className="siteStyle top-nav">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to="/jamSessions">Jamsessions</Link></li>
                    <li className="breadcrumb-item"><Link to={"/viewjamsession/" + jamSessionId}>{theme}</Link></li>
                    <li className="breadcrumb-item active"><h1 className="siteTitle">Editer</h1></li>
                </ol>
            </nav>
            <div className="card">
                <div className="card-body">
                    <div className="alert alert-secondary">
                        <div>
                            <div className="row">
                                <div className="col-sm-4 col-12">
                                    <span className="ml-1">
                                        <strong className="siteStyle">
                                            {
                                                jamSessionId ?
                                                    <span>JAM SESSION N° {jamSessionNumber}</span> :
                                                    <span>Nouvelle Jam Session</span>
                                            }    
                                        </strong>
                                    </span>
                                </div>
                                <div className="col-sm-8 col-12 my-2 text-right">
                                    <Button variant="contained" color="primary" disabled={btnSaveLoading}
                                        onClick={saveJamSession} className="text-initial mr-2 group-button" size="small">
                                        <FontAwesomeIcon icon={faSave} className="mr-0 mr-sm-1" size="sm" />
                                        <span className="d-none d-sm-block">
                                        {
                                            jamSessionId ? "Enregistrer" : "Mettre à jour"
                                        }
                                        </span>
                                    </Button>
                                    
                                    {
                                        jamSessionId &&
                                        <React.Fragment>
                                            <Button variant="contained" className="text-initial mr-2 btn-info-custom group-button" 
                                                onClick={refreshGetJamSession} size="small">
                                                <FontAwesomeIcon icon={faSync} className="mr-0 mr-sm-1" size="sm" /> <span className="d-none d-sm-block">Rafraîchir</span>
                                            </Button>
                                            <Button component={Link} to={"/viewjamsession/" + jamSessionId} size="small"
                                                variant="contained" className="text-initial bg-white mr-2 group-button">
                                                <FontAwesomeIcon icon={faEye} className="mr-0 mr-sm-1" size="sm" /> <span className="d-none d-sm-block">Voir la session</span>
                                            </Button>
                                            <Button component={Link} to={"/jamSessions"} variant="contained"
                                                className="text-initial bg-white group-button" size="small">
                                                <FontAwesomeIcon icon={faList} className="mr-0 mr-sm-1" size="sm" /> <span className="d-none d-sm-block">Jam Sessions</span>
                                            </Button>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        loadedForm ? <div className="mx-auto text-center mt-5" style={{ minHeight: '200px' }}>
                            <FontAwesomeIcon icon={faSync} className="fa-5x fa-spin" />
                        </div> : 
                        <div className="row">
                            <div className="col-12">
                                <Tabs className="mb-3 border-bottom" indicatorColor="secondary" textColor="primary" variant="scrollable"
                                    value={tabValue} onChange={(e: any,value:number)=>setTabValue(value)} scrollButtons="on"
                                    style={{ backgroundColor: "#FFF" }} TabIndicatorProps={{ style: { background: '#fd7419' } }}>
                                    <Tab label="Détails" className="text-capitalize" />
                                    <Tab label="Participants" className="text-capitalize" disabled={jamSessionId === undefined} />
                                    <Tab label="Agenda" className="text-capitalize" disabled={jamSessionId === undefined} />
                                    <Tab label="Inscription & Permissions" className="text-capitalize" disabled={jamSessionId === undefined} />
                                </Tabs>
                                <div role="tabpanel" hidden={tabValue !== 0}>
                                    <div className="form-group row align-items-center">
                                        <div className="col-12 col-sm-6 col-md-2">
                                            <TextField type="date" variant="filled" fullWidth label="Date Jam Session"
                                                onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setJamSessionDate(e.target.value)}
                                                    value={jamSessionDate} disabled={props.jamSessionIsReading} />
                                        </div>
                                        <div className="col-6 col-sm-3 col-md-2 mt-3 mt-sm-0">
                                            <TextField fullWidth variant="filled" type="time" className="mr-0"
                                                label="Heure début" value={jamSessionHourFrom} disabled={props.jamSessionIsReading}
                                                onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setJamSessionHourFrom(e.target.value)} />

                                        </div>
                                        <div className="col-6 col-sm-3 col-md-2 mt-3 mt-sm-0">
                                            <TextField fullWidth variant="filled" type="time" label="Heure fin"
                                                value={jamSessionHourTo} disabled={props.jamSessionIsReading}
                                                onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setJamSessionHourTo(e.target.value)} />
                                        </div>
                                        <div className="col-12 col-md-6 mb-sm-3 mb-md-0">
                                            <TextField variant="filled" fullWidth label="Lien vers la salle virtuelle"
                                                value={conferenceLink ? conferenceLink : ""} disabled={props.jamSessionIsReading} 
                                                onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setConferenceLink(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group row align-items-center">
                                        <div className="col-12 col-md-6 mb-sm-3 mb-md-0">
                                            <TextField variant="filled" fullWidth label="Lieu évènement" value={address} disabled={props.jamSessionIsReading}
                                                    onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setAddress(e.target.value)} />
                                        </div>
                                        <div className="col-12 col-md-6 mb-sm-3 mb-md-0">
                                            <Autocomplete onChange={(e: any,value:MainJamSessionUserDto | null)=> setProtractor(value)}
                                                options={protractors} getOptionLabel={(o:MainJamSessionUserDto) => o.fullName ? o.fullName : ""}
                                                value={protractor} disabled={props.jamSessionIsReading}
                                                renderInput={(params: any) => (
                                                    <TextField {...params} fullWidth variant="filled" label="Rapporteur" />
                                                )} />
                                        </div>
                                    </div>
                                    <div className="form-group row align-items-center">
                                        <div className="col-12 col-md-6 mb-sm-3 mb-md-0">
                                            <TextField variant="filled" value={theme} 
                                                fullWidth label="Thème Jam Session" disabled={props.jamSessionIsReading}
                                                onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setTheme(e.target.value)} />
                                        </div>
                                        <div className="col-12 col-md-3 mt-3 mt-sm-0">
                                            <Autocomplete onChange={(e: any,value:MainJamSessionUserDto | null)=> setModerator(value)}
                                                options={members} getOptionLabel={(o:MemberSimpleDataDto) => o.fullName ? o.fullName : ""}
                                                value={moderator} disabled={props.jamSessionIsReading}
                                                renderInput={params => (
                                                    <TextField {...params} fullWidth variant="filled" label="Modérateur" />
                                                )} />
                                        </div>
                                        <div className="col-12 col-md-3 mt-3 mt-sm-0">
                                            <Autocomplete onChange={(e: any,value:MainJamSessionUserDto | null)=> setGeneralEvaluator(value)} 
                                            options={members} value={generalEvaluator}
                                            getOptionLabel={(o:MemberSimpleDataDto) => o.fullName ? o.fullName : ""} 
                                                disabled={props.jamSessionIsReading === "true"} renderInput={params => (
                                                    <TextField {...params} fullWidth variant="filled" label="Evaluateur Général" />)} />
                                        </div>
                                    </div>
                                    {
                                        jamSessionId && 
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-body pb-0 pl-3">
                                                        <h5 className="card-title">Autres rôles nécessaires</h5>
                                                        <div className="row">
                                                            {
                                                                (roles || []).map((row:JamSessionRoleChecked) => (
                                                                    <div key={row.role.jamSessionRoleId?.toString()} className="col-md-3 col-12">
                                                                        <FormControlLabel label={row.role.jamSessionRoleName} control={
                                                                            <Checkbox checked={row.checked} value={row.role.jamSessionRoleId} color="primary"
                                                                                disabled={props.jamSessionIsReading} onChange={handleToggleCheckbox}  />} />
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="form-group row align-items-center">
                                        <div className="col-md-12 col-12">
                                            <TextField value={description} variant="filled" rows="4" fullWidth
                                                disabled={props.jamSessionIsReading} multiline label="Description"
                                                onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setDescription(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group row align-items-center">
                                        <div className="col-md-12 col-12">
                                            <TextField value={homeMessage} variant="filled" rows="4" fullWidth
                                                disabled={props.jamSessionIsReading} multiline label="Message d'accueil"
                                                onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setHomeMessage(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" hidden={tabValue !== 1}>
                                {
                                    jamSessionId && <EditJamSessionResponses responses={membersResponse} members={members}
                                        jamSessionId={jamSessionId} isAdmin={true} jamSessionClosed={jamSessionClosed} />
                                }
                                </div>
                                <div role="tabpanel" hidden={tabValue !== 2}>
                                    {
                                        jamSessionId && !props.jamSessionIsReading &&
                                        <EditJamSessionAgenda jamSessionId={jamSessionId} agendas={agendas} />
                                    }
                                    {
                                        jamSessionId && props.jamSessionIsReading &&
                                        <EditJamSessionAgendaMember agendas={agendas} beginHour={jamSessionHourFrom} />
                                    }
                                </div>
                                <div role="tabpanel" hidden={tabValue !== 3}>
                                    {
                                        jamSessionId &&
                                        <div className="row">
                                            <div className="col-md-7">
                                                <JamSessionInscription jamSessionRoles={jamSessionRoles}
                                                    jamSessionId={jamSessionId} isRegistered={isRegistered} />
                                            </div>
                                            <div className="col-md-5">
                                                <PermissionJamSession jamSessionId={jamSessionId} />
                                            </div>
                                        </div>
                                        
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

const MapDispatchToProps = (dispatch: any) => {
    return {
        setInvitations: (data: any) => {
            dispatch({
                type: "FILL_INVITATION",
                invitatedList: data
            } as Action);
        },
        ToggleIsReading: (isReading: any) => {
            dispatch({
                type: "TOGGLE_IS_READING",
                isReading: isReading
            })
        }
    };
}

const mapStateToProps = (state: SystemState) => {
    return {
        jamSessionIsReading: state.jamSessionIsReading
    }
}

export default connect(mapStateToProps, MapDispatchToProps)(UpdateJamSession);
