import decode from 'jwt-decode';

export const IsTokenExpired = () => {

    const token = localStorage.getItem("token");
    if (token === null)
        return true;

    

    try {
        const decoded = decode(token);
        
        if (decoded.exp < Date.now() / 1000) {
            //    // Checking if token is expired.
            localStorage.clear();
            return true;
        } else return false;
    } catch (err) {
        return false;
    }
};

export const IsAdmin = () => {
    const token = localStorage.getItem("token");
    if (token === null)
        return false;

    try {
        const decoded = decode(token);
        const roles = decoded.role.split(",");
        return roles.some(role=>role === "Admin") || roles.some(role=>role === "SuperAdmin");
    } catch (err) {
        return false;
    }
}

export const IsSuperAdmin = () => {
    const token = localStorage.getItem("token");
    
    if (token === null)
        return false;

    try {
        const decoded = decode(token);
        const roles = decoded.role.split(",");
        return roles.some(role=>role === "SuperAdmin");
    } catch (err) {
        return false;
    }
}

export const UniqueName = () => {
    const token = localStorage.getItem("token");
    if (token === null)
        return null;

    try {
        const decoded = decode(token);
        return decoded.unique_name;
    } catch (err) {
        return null;
    }
}

