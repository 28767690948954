import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCopy,
    faEnvelope,
    faLink,
    faPaperPlane,
    faTimes,
    faUsers,
    faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import JamSessionInvitationMemberRow from "./JamSessionInvitationMemberRow";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { AsyncButton } from "../../../utils/AsyncButton";
import { useAuthorizedBackendApi } from "../../../api/api";
import {
    IMemberSimpleDataDto,
    JamSessionExternalInvitationDto,
    JamSessionInvitationsDto,
    SessionInvitedMemberDto,
} from "../../../api/services.generated";
import { useCurrentUser } from "../../../utils/login";
import { useAsyncEffect } from "../../../utils/react";
import { ifNotNullAndDefined } from "../../../utils/typescript";
import Skeleton from "@material-ui/lab/Skeleton";
import { useAvatarDownloader } from "../../members/AvatarDownloadContext";
import { useJamSession } from "./JamSessionContext";

export function JamSessionInvitation2() {
    const [selectedTab, setSelectedTab] = useState(0);
    return (
        <React.Fragment>
            <Tabs
                className="mb-3 border-bottom"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                value={0}
                scrollButtons="on"
                style={{ backgroundColor: "#FFF" }}
                TabIndicatorProps={{ style: { background: "#007bff" } }}
            >
                <Tab label="Invités à la JamSession" className="text-capitalize font-weight-bolder" />
            </Tabs>
            <div role="tabpanel" hidden={false}>
                <Tabs
                    className="mb-3 border-bottom"
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    value={selectedTab}
                    onChange={(_, value) => setSelectedTab(value)}
                    style={{ backgroundColor: "#FFF" }}
                    TabIndicatorProps={{ style: { background: "#303f9f" } }}
                >
                    <Tab
                        style={{ minWidth: 50 }}
                        icon={<FontAwesomeIcon icon={faUsers} />}
                        className="text-capitalize"
                    />
                    <Tab
                        style={{ minWidth: 50 }}
                        icon={<FontAwesomeIcon icon={faEnvelope} />}
                        className="text-capitalize"
                    />
                    <Tab
                        style={{ minWidth: 50 }}
                        icon={<FontAwesomeIcon icon={faLink} />}
                        className="text-capitalize"
                    />
                </Tabs>
                <div role="tabpanel" hidden={selectedTab !== 0}>
                    <Invite />
                </div>

                <div role="tabpanel" hidden={selectedTab !== 1}>
                    <ExternalInvite />
                </div>

                <div role="tabpanel" hidden={selectedTab !== 2}>
                    <ShareLink />
                </div>
            </div>
        </React.Fragment>
    );
}

function Invite() {
    const user = useCurrentUser();
    const api = useAuthorizedBackendApi();

    const [allMembers, setAllMembers] = useState<IMemberSimpleDataDto[] | "loading">("loading");
    const [selectedMembers, setSelectedMembers] = useState<IMemberSimpleDataDto[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const { session, notifySessionUpdated } = useJamSession();

    useAsyncEffect(
        async ({ wrap }) => {
            if (!api) return;
            const members = await wrap(api.member_GetMembersName());
            if (members) {
                setAllMembers(members);
            }
        },
        [api]
    );

    if (!session) {
        return <Skeleton />;
    }

    return (
        <>
            {user.isAdmin && <Chip className="mb-3" label="Inviter un membre" color="primary" />}
            {user.isAdmin && allMembers !== "loading" && (
                <div>
                    <Button onClick={() => setSelectedMembers(allMembers)}>
                        {`Inviter tous les membres (${allMembers.length})`}
                    </Button>
                    <div className="form-group">
                        <Autocomplete
                            multiple
                            options={allMembers}
                            getOptionLabel={(o) => o.fullName || ""}
                            onChange={(_, v) => setSelectedMembers([...v])}
                            value={selectedMembers}
                            limitTags={2}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Sélectionner membres"
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                />
                            )}
                        />
                    </div>
                </div>
            )}
            <List dense={true} className="px-0">
                <ListItem className="px-0">
                    <ListItemText
                        primary={ifNotNullAndDefined(session.registers, []).length + " confirmés"}
                        secondary={ifNotNullAndDefined(session.invitation, []).length + " en attente"}
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            title="Envoyer un mail aux invités"
                            edge="end"
                            aria-label="delete"
                            size="small"
                            onClick={() => setModalOpen(true)}
                        >
                            <FontAwesomeIcon icon={faEnvelope} size="sm" />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem className="px-0">
                    <ListItemAvatar>
                        <Avatar>
                            <JamSessionInvitationMemberRow id={session.owner?.id} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={session.owner?.fullName} secondary="Organisateur" />
                </ListItem>

                {(session.invitation || []).map((row) => (
                    <ListItem key={"memberAlreadySelected-" + row.userId} className="px-0">
                        <ListItemAvatar>
                            <Avatar>
                                <MemberInvitationRow userId={row.userId!} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={row.fullName} />
                    </ListItem>
                ))}
                {selectedMembers.map((row) => (
                    <ListItem key={"memberSelected-" + row.id} className="px-0">
                        <ListItemAvatar>
                            <Avatar>
                                <JamSessionInvitationMemberRow id={row.id} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={row.fullName} />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                size="small"
                                onClick={() => setSelectedMembers(selectedMembers.filter((x) => x.id !== row.id))}
                            >
                                <FontAwesomeIcon icon={faWindowClose} size="sm" />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>

            {user.isAdmin && (
                <div className="form-group mt-3">
                    <AsyncButton
                        variant="contained"
                        color="primary"
                        className="text-capitalize"
                        onClickAsync={async () => {
                            await api?.jamSession_SendInvitationMembers(
                                JamSessionInvitationsDto.fromJS({
                                    jamSessionId: session.sessionId,
                                    invitedMembers: selectedMembers.map((x) =>
                                        JamSessionExternalInvitationDto.fromJS( {
                                            userId: x.id,
                                        })
                                    ),
                                })
                            );
                            if (!session.invitation) session.invitation = [];
                            for (const member of selectedMembers) {
                                session.invitation.push(
                                    SessionInvitedMemberDto.fromJS({
                                        userId: member.id,
                                        fullName: member.fullName,
                                        email: member.email,
                                    })
                                );
                            }
                            notifySessionUpdated();
                            setSelectedMembers([]);
                        }}
                        size="small"
                    >
                        <FontAwesomeIcon icon={faPaperPlane} className="mr-2" size="sm" /> Inviter
                    </AsyncButton>
                </div>
            )}

            <SendEmailDialog show={modalOpen} onHide={() => setModalOpen(false)} />
        </>
    );
}

function SendEmailDialog({ show, onHide }: { show: boolean; onHide: () => void }) {
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title className="siteStyle">
                    <strong>Envoyer un e-mail aux invités</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextField
                    className="mb-3"
                    fullWidth
                    label="Objet"
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                    variant="filled"
                />
                <TextField
                    className="mb-3"
                    fullWidth
                    label="Message"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    variant="filled"
                    multiline
                    rows="4"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="contained"
                    color="primary"
                    className="text-capitalize"
                    size="small"
                    onClick={() => {
                        alert("Cette fonctionnalité n'est pas disponible pour le moment.");
                    }}
                >
                    <FontAwesomeIcon icon={faPaperPlane} className="mr-2" size="sm" /> Inviter
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function MemberInvitationRow({ userId }: { userId?: string }) {
    const downloader = useAvatarDownloader();
    const [imageLoading, setImageLoading] = useState(false);
    const [image, setImage] = useState<string | null>(null);

    useAsyncEffect(
        async ({ wrap }) => {
            setImageLoading(true);
            try {
                if (!userId) {
                    return;
                }
                const image = await wrap(downloader.getAvatar(userId));
                if (image) {
                    setImage(image);
                }
            } finally {
                setImageLoading(false);
            }
        },
        [userId, downloader]
    );

    if (imageLoading) {
        return <Skeleton />;
    }

    if (!image) {
        return <img src="./img/user.png" className="img-fluid" alt="pho" />;
    }

    return <img src={"data:image/jpeg;base64," + image} className="img-fluid" alt="pho" />;
}

function ExternalInvite() {
    const [input, setInput] = useState({ email: "", firstName: "", lastName: "", subject: "", comment: "" });
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const { session, notifySessionUpdated } = useJamSession();

    const api = useAuthorizedBackendApi();

    async function sendInvitationMember() {
        if (!api || !session) return;

        if (session.registers?.some((x) => x.email?.toLowerCase() === input.email.toLowerCase())) {
            toast.warn("Cette adresse mail fait déjà partie des membres!", {
                position: "top-left",
                hideProgressBar: false,
            });
            return;
        }

        try {
            await api.jamSession_SendInvitationMembers(
                JamSessionInvitationsDto.fromJS({
                    jamSessionId: session.sessionId,
                    invitedMembers: [JamSessionExternalInvitationDto.fromJS(input)],
                })
            );
            if (!session.invitation) session.invitation = [];
            session.invitation.push(
                SessionInvitedMemberDto.fromJS({ fullName: `${input.firstName} ${input.lastName}` })
            );
            notifySessionUpdated();
            setSnackbarMessage("Invitation envoyée");
            setShowSnackbar(true);
        } catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
    }

    return (
        <>
            <Chip className="mb-3" label="Inviter une personne externe" color="primary" />
            <TextField
                className="mb-3"
                fullWidth
                label="A"
                onChange={(e) => setInput({ ...input, email: e.target.value })}
                value={input.email}
                variant="filled"
            />
            <TextField
                className="mb-3"
                fullWidth
                label="Prénom"
                onChange={(e) => setInput({ ...input, firstName: e.target.value })}
                value={input.firstName}
                variant="filled"
            />
            <TextField
                className="mb-3"
                fullWidth
                label="Nom"
                onChange={(e) => setInput({ ...input, lastName: e.target.value })}
                value={input.lastName}
                variant="filled"
            />
            <TextField
                className="mb-3"
                fullWidth
                label="Sujet"
                onChange={(e) => setInput({ ...input, subject: e.target.value })}
                value={input.subject}
                variant="filled"
            />
            <TextField
                className="mb-3"
                fullWidth
                label="Message"
                onChange={(e) => setInput({ ...input, comment: e.target.value })}
                value={input.comment}
                variant="filled"
                multiline
                rows="3"
            />
            <div className="float-right">
                <AsyncButton
                    variant="contained"
                    color="primary"
                    className="text-capitalize"
                    onClickAsync={async () => {
                        await sendInvitationMember();
                    }}
                    size="small"
                >
                    <FontAwesomeIcon icon={faPaperPlane} className="mr-2" size="sm" /> Inviter
                </AsyncButton>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={showSnackbar}
                onClose={() => setShowSnackbar(false)}
                autoHideDuration={5000}
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        className="p-1"
                        onClick={() => setShowSnackbar(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} size="sm" />
                    </IconButton>,
                ]}
            >
                <MuiAlert onClose={() => setShowSnackbar(false)} severity="success">
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

function ShareLink() {
    const [link, setLink] = useState("");
    const { session } = useJamSession();

    useEffect(() => {
        if (session) {
            setLink(window.location.origin + "/inscriptionGuest/" + btoa(session.sessionId));
        } else {
            setLink("");
        }
    }, [session?.sessionId]);

    return (
        <>
            <Chip className="mb-3" label="Envoyer le lien d'inscription" color="primary" />
            <div className="alert alert-info siteStyle px-2 text-truncate">
                <span className="font-weight-bolder">Lien généré : </span>
                <br />
                {<span className=""> {link}</span>}
            </div>
            <Button
                variant="contained"
                className="bg-white text-capitalize"
                onClick={() => {
                    navigator.clipboard.writeText(link);
                    toast.info("Lien copié!");
                }}
                size="small"
            >
                <FontAwesomeIcon icon={faCopy} className="mr-2" size="sm" /> Copier le lien
            </Button>
        </>
    );
}
