import React from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

export function Shops() {
    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>Boutiques - Success Team</title>
                    <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </MetaTags>
            </div>
            <nav className="siteStyle top-nav">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to='/dashboard'>Dashboard</Link></li>
                    <li className="breadcrumb-item active">Boutiques</li>
                </ol>
            </nav>
            <div className="jumbotron d-flex align-items-center bg-dark min-vh-25 mb-0" style={{ backgroundImage: "url('./img/Orange-Background-Free.png')" }}>
                <div className="container text-center">
                    <h1 className="text-white siteStyle font-weight-bolder custom-title">BOUTIQUES</h1>
                </div>
            </div>

            <div className="bg-white custom-margin">
                <div className="container row mx-auto">
                    <div className="col-md-12">
                        <div className="siteStyle text-center custom-margin-bottom">
                            <h2 className="custom-title2">Besoin de quelque chose?</h2>
                            <p className="custom-subtitle mt-3">Commandez directement l'article ou le service de votre choix dans notre boutique en ligne</p>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <img src="./img/meeting-image.jpg" className="rounded img-fluid" alt="team" />
                    </div>
                    <div className="col-md-6">
                        <p className="siteStyle custom-text">
                            Notre boutique en ligne rassemble les créations de nos membres. 
                            Nous avons à cœur de les soutenir, mais aussi de vous faire découvrir un 
                            travail de qualité.
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
