import { faShare, faEnvelope, faCalendar, faMapMarker, faEdit, faAlignLeft, faUserEdit, faUserTie } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import EditJamSessionAgendaMember from './EditJamSessionAgendaMember'
import JamSessionInscription from './JamSessionInscription'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TextField from '@material-ui/core/TextField'
import { UnauthorizedBackendApiContext } from '../../../api/api'
import { getErrorMessage } from '../../../api/utils'
import { toast } from 'react-toastify'
import { JamSessionDto, JamSessionRoleDto, RegisterGuestJamSessionDto, ResponseRegisterGuestJamSessionDto, SessionAgendaDto } from '../../../api/services.generated'
import { connect } from 'react-redux'

function InscriptionForm({auth,match,setInvitations}:{auth:boolean,match:any,setInvitations:(data:any)=>void}) {

    const context = useContext(UnauthorizedBackendApiContext)

    const [jamSessionDate, setJamSessionDate] = useState<string | null>(null)
    const [hourFrom, setHourFrom] = useState("00:00")
    const [hourTo, setHourTo] = useState("00:00")
    const [address, setAddress] = useState<string | null>()
    const [theme, setTheme] = useState<string | null>()
    const [description, setDescription] = useState<string | null>()
    const [moderator, setModerator] = useState<string | null>()
    const [generalEvaluator, setGeneralEvaluator] = useState<string | null>()
    const [agendas, setAgendas] = useState<SessionAgendaDto[]>([])
    const [jamSessionNumber, setJamSessionNumber] = useState<string | null>()
    const [date, setDate] = useState((new Date()).getFullYear() + "-" + ("0" + ((new Date()).getMonth() + 1)).slice(-2) + "-" + (new Date()).getDate())
    const [firstName, setFirstName] = useState<string | null>()
    const [lastName, setLastName] = useState<string | null>()
    const [email, setEmail] = useState("")
    const [comment, setComment] = useState<string>("")
    const [showJamSessionRoles, setShowJamSessionRoles] = useState(false)
    const [jamSessionRoles, setJamSessionRoles] = useState<JamSessionRoleDto[]>([])
    const [isRegistered, setIsRegistered] = useState(false)
    const [jamSessionId, setJamSessionId] = useState<string | null>(null)

    useEffect(() => {
        if(jamSessionId !== null){
            getJamSession()
        }
        
    }, [jamSessionId])

    useEffect(() => {
        getDecodeValue(match.params.id)
        
    }, [])

    async function getDecodeValue(value:string){
        if(!context){
            return;
        }

        try
        {
            const response:string | null = await context.jamSession_GetDecodeValue(value);
            setJamSessionId(response)
        }
        catch(error){
            toast.warn(getErrorMessage(error) + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
    }

    async function getJamSession(){
        try
        {
            if(!context){
                return;
            }
            const response: JamSessionDto | null = await context.jamSession_GetJamSession(jamSessionId)
            if(response){
                const date = new Date(response.jamSessionDate)
                const event = new Date(date.getUTCFullYear() + "-" + ("0" + (date.getUTCMonth() + 1)).slice(-2) + "-" + ("0" + date.getUTCDate()).slice(-2))
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
                const jamDate = event.toLocaleDateString('fr-FR', options)

                setTheme(response.theme)
                setJamSessionNumber(response.jamSessionNumber)
                setHourFrom(response.jamSessionHourFrom)
                setHourTo(response.jamSessionHourTo)
                setDate(date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getDate())
                setJamSessionDate(jamDate)
                setAddress(response.address)
                setDescription(response.description)
                setJamSessionRoles(response.jamSessionRoles ? response.jamSessionRoles : [])
                setIsRegistered(response.isRegistered)
                setShowJamSessionRoles(true)
                setModerator(response.moderator ? response.moderator.fullName : "")
                setGeneralEvaluator(response.generalEvaluator ? response.generalEvaluator.fullName : "")
                setAgendas(response.agenda ? response.agenda : [])

                setInvitations(response.invitation ? response.invitation : [])
            }
        }
        catch(error){
            toast.warn(getErrorMessage(error) + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
    }

    async function RegisterJamSession(){
        try{
            if(!context){
                return;
            }

            if(!auth){
                const response: ResponseRegisterGuestJamSessionDto | null = await context.jamSession_RegisterGuestJamSession({
                    jamSessionId: match.params.id,
                    email: email,
                    lastName:lastName,
                    firstName:firstName,
                    comment:comment
    
                } as RegisterGuestJamSessionDto)
    
                if(response){
                    if(response.alreadyRegistered){
                        toast.warn("You are already register at this JamSession !")
                    }
                }
            }
            else{
                
            }
            
        }
        catch(error){
            toast.warn(getErrorMessage(error) + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
    } 

    return (
        <div className="card">
            
            <div className="card-body mx-auto" style={{ maxWidth: "1000px" }}>
                <div>
                    <div className="row siteStyle py-2">
                        <div className="col-md-3">
                            <Link className="pointer" to="/home"><img className="img-fluid" src="./img/logo-successteam.png" alt="st" style={{ maxHeight: "100px" }} /></Link>
                        </div>
                        <div className="col-md-9">
                            <h3 className="text-primary font-weight-bolder" style={{ fontSize: "2em" }}>Formulaire d'inscription à la JamSession</h3>
                            {
                                !auth ? 
                                <h6 className="text-success" style={{ fontSize: "2em", marginTop: "6px" }}>Invité</h6>
                                : <h6 className="text-success" style={{ fontSize: "1.75em", marginTop: "6px" }}>Membres</h6>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 my-2">
                        <div>
                        <div className="card">
                            <div className="card-header">JAMSESSION (#{jamSessionNumber})</div>
                            {/*
                                !auth ? 
                                    <List dense={true}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ListItem>
                                                    <ListItemIcon className="text-dark">
                                                        <FontAwesomeIcon icon={faCalendar} />
                                                    </ListItemIcon>
                                                    <ListItemText className="text-dark text-capitalize" primary="Date" secondary={jamSessionDate + ", " + hourFrom.substring(0, 5) + " - " + hourTo.substring(0, 5)} />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon className="text-dark">
                                                        <FontAwesomeIcon icon={faMapMarker} />
                                                    </ListItemIcon>
                                                    <ListItemText className="text-dark" primary="Adresse" secondary={address} />
                                                </ListItem>
                                            </div>
                                            <div className="col-md-6">
                                                <ListItem>
                                                    <ListItemIcon className="text-dark">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </ListItemIcon>
                                                    <ListItemText className="text-dark" primary="Thème" secondary={theme} />
                                                </ListItem>
                                                <div className="alert alert-info mx-3 my-1" style={{fontSize: '0.875rem'}}>
                                                    Insérer le message d'accueil ici...
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <ListItem>
                                                    <ListItemIcon className="text-dark">
                                                        <FontAwesomeIcon icon={faAlignLeft} />
                                                    </ListItemIcon>
                                                    <ListItemText className="text-dark" primary="Description" secondary={description} />
                                                </ListItem>
                                            </div>
                                        </div>
                                    </List>
                                    : */}
                                    <List dense={true}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <ListItem>
                                                    <ListItemIcon className="text-dark">
                                                        <FontAwesomeIcon icon={faCalendar} />
                                                    </ListItemIcon>
                                                    <ListItemText className="text-dark text-capitalize" primary="Date" secondary={jamSessionDate + ", " + hourFrom.substring(0, 5) + " - " + hourTo.substring(0, 5)} />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon className="text-dark">
                                                        <FontAwesomeIcon icon={faMapMarker} />
                                                    </ListItemIcon>
                                                    <ListItemText className="text-dark" primary="Adresse" secondary={address} />
                                                </ListItem>
                                            </div>
                                            <div className="col-md-4">
                                                <ListItem>
                                                    <ListItemIcon className="text-dark">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </ListItemIcon>
                                                    <ListItemText className="text-dark" primary="Thème" secondary={theme} />
                                                </ListItem>
                                            </div>
                                            <div className="col-md-4">
                                                <ListItem>
                                                    <ListItemIcon className="text-dark">
                                                        <FontAwesomeIcon icon={faUserEdit} />
                                                    </ListItemIcon>
                                                    <ListItemText className="text-dark" primary="Modérateur" secondary={moderator} />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon className="text-dark">
                                                        <FontAwesomeIcon icon={faUserTie} />
                                                    </ListItemIcon>
                                                    <ListItemText className="text-dark" primary="Evaluateur" secondary={generalEvaluator} />
                                                </ListItem>
                                            </div>
                                            <div className="col-md-12">
                                                <ListItem>
                                                    <ListItemIcon className="text-dark">
                                                        <FontAwesomeIcon icon={faAlignLeft} />
                                                    </ListItemIcon>
                                                    <ListItemText className="text-dark" primary="Description" secondary={description} />
                                                </ListItem>
                                            </div>
                                        </div>
                                    </List>
                            {/*}*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 my-2">
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <span className="siteStyle font-weight-bolder" style={{fontSize: '1.05em'}}>Programme de la jamSession</span>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="w-100 mb-2">
                                    {
                                        agendas !== null ? 
                                        <EditJamSessionAgendaMember 
                                            agendas={agendas} beginHour={hourFrom} canGeneratePDF={false}
                                            jamSessionNumber={jamSessionNumber} jamSessionTitle={theme} jamSessionDate={date}
                                        /> : <Skeleton />
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-md-12 my-2">
                    {
                        !auth ? 
                            <div className="card mt-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <TextField className="mb-3" fullWidth label="Nom" value={lastName} variant="filled"
                                                onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setLastName(e.target.value)} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField className="mb-3" fullWidth label="Prénom" value={firstName} variant="filled"
                                                 onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setLastName(e.target.value)} />
                                        </div>
                                        <div className="col-md-12">
                                            <TextField className="mb-3" fullWidth label="Email" value={email} variant="filled"
                                                onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setEmail(e.target.value)} />
                                        </div>
                                        <div className="col-md-12">
                                            <TextField className="mb-1" fullWidth label="Commentaire" value={comment} variant="filled" multiline rows="3"
                                                onChange={(e:React.ChangeEvent<HTMLTextAreaElement>)=>setComment(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                        </div> :
                        showJamSessionRoles &&
                        <JamSessionInscription jamSessionRoles={jamSessionRoles} isAdmin={false} jamSessionId={match.params.id}
                            isRegistered={isRegistered} handleRegistredState={()=>setIsRegistered(true)}  />
                    }
                    </div>
                    <div className="col-md-12 my-2">
                        <Button variant="contained" className="text-initial mr-2 btn-info-custom" size="large" onClick={RegisterJamSession}>
                            <FontAwesomeIcon icon={faEnvelope} className="mr-1" size="sm" /> Envoyer
                        </Button>
                        <Button variant="contained" className="text-initial mr-2 float-right" color="primary" size="large">
                            <FontAwesomeIcon icon={faShare} className="mr-1" size="sm" /> Partager
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const MapDispatchToProps = (dispatch:any) => {
    return {
        setInvitations: (data:any) => {
            dispatch({
                type: "FILL_INVITATION",
                invitatedList: data
            });
        }
    };
}


export default connect(null, MapDispatchToProps)(InscriptionForm);