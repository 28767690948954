import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import React, { useContext, useEffect, useState } from 'react'
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags'
import { Link, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { UnauthorizedBackendApiContext } from '../../api/api'
import { LoggedInUserDto, LoginUserDto } from '../../api/services.generated'
import { connect } from 'react-redux'
import { toast } from "react-toastify"
import ForgotPassword from './ForgotPassword'
import { getErrorMessage } from '../../api/utils'

function Login(props:any) {

    const api = useContext(UnauthorizedBackendApiContext)
    const [userName, setUserName] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [loading, setLoading] = useState(false)
    const [openModalPassword, setOpenModalPassword] = useState(false)

    useEffect(() => {
        
        if (localStorage.getItem("participation") !== undefined) {
            props.participationJamSession(
                localStorage.getItem("participation"),
                localStorage.getItem("jamSessionId")
            );

            localStorage.removeItem("participation");
            localStorage.removeItem("jamSessionId");
        }
    }, [])

    async function handleLogin() {
        if(!api){
            return;
        }
        if(userName === ""){
            toast.warn("Votre Email ne peut pas être vide")
            return;
        }
        if(password === ""){
            toast.warn("Votre mot de passe ne peut pas être vide")
            return;
        }
        if(password.length < 8){
            toast.warn("Votre mot de passe n'est pas correct")
            return;
        }
        setLoading(true)

        try {
            const response: LoggedInUserDto | null = await api.member_Login({
                userName: userName,
                password: password,
            } as LoginUserDto);
            if(response){
                props.connectApp(response)
            }
        } catch (error) {
            setLoading(false)

            toast.warn(getErrorMessage(error) + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
    }

    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>Se connecter - Success Team</title>
                    <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </MetaTags>
            </div>
            <nav className="siteStyle top-nav">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item active"><h1 className="siteTitle">Se connecter</h1></li>
                </ol>
            </nav>

            <div className="main-content">
                <div className="container">
                    <AppBar color="transparent">
                        <Toolbar>
                            <Typography className="d-flex">
                                <Link to="/">
                                    <img
                                        src="./img/logo-successteam.png"
                                        alt="logo"
                                        width="60"
                                    />
                                </Link>
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <form className="pt-5">
                        <div className="row justify-content-md-center">
                            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                                <div className="login-screen">
                                    <div className="login-box">
                                        <Link
                                            to="/"
                                            className="pointer text-center"
                                        >
                                            <div className="login-logo">
                                                <span
                                                    style={{
                                                        color: "#5061a2",
                                                        fontSize: "1.5rem",
                                                    }}
                                                    className="font-weight-bold mx-2"
                                                >
                                                    AKILI
                                                </span>
                                                <span
                                                    style={{
                                                        color: "#fd751a",
                                                        fontSize: "1.5rem",
                                                    }}
                                                    className="font-weight-bold"
                                                >
                                                    PORTAL
                                                </span>
                                            </div>
                                        </Link>
                                        <div className="form-group">
                                            <TextField label="Email" value={userName} type="email" fullWidth variant="filled"
                                                onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setUserName(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <TextField label="Mot de passe" value={password} type="password" fullWidth variant="filled"
                                                onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setPassword(e.target.value)} />
                                        </div>
                                        <div className="actions">
                                            <Button className="btn-block btn-warning-custom" variant="contained"
                                                disabled={loading} onClick={handleLogin}>
                                                <FontAwesomeIcon
                                                    icon={faLockOpen}
                                                    className="mr-2"
                                                />{" "}
                                                Se connecter
                                            </Button>
                                            <span
                                                className="btn btn-link text-left"
                                                style={{
                                                    fontSize: "0.75rem",
                                                }}
                                                onClick={()=>setOpenModalPassword(true)}>
                                                <small className="siteStyle">
                                                    Mot de passe oublié ?
                                                </small>
                                            </span>
                                        </div>
                                        <div className="mt-4">
                                            <Link
                                                to="/signup"
                                                className="additional-link siteStyle"
                                            >
                                                <span>Créer un compte</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {openModalPassword && <ForgotPassword onClose={()=>setOpenModalPassword(false)} />}
        </React.Fragment>
    )
}

const mapDispatchToProps = (dispatch:(e:any)=>void) => {
    return {
        connectApp: (user:LoggedInUserDto) => {
            dispatch({
                type: "CONNEXION_APP",
                token: user.token,
                firstName: user.firstName,
                contentType: user.avatar != null ? user.avatar.contentType : null,
                avatar: user.avatar != null ? user.avatar.image : null,
            });
        },
        participationJamSession: (participation:string, jamSessionId:string) => {
            dispatch({
                type: "PARTICIPATION_STATUS",
                participationJamSession: participation,
                jamSessionId: jamSessionId,
            });
        },
    };
};

export default withRouter(connect(null, mapDispatchToProps)(Login));