import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSync, faPen, faCheckCircle, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'
import Skeleton from '@material-ui/lab/Skeleton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

export default class ProjectStepEvaluationRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stepId: this.props.stepId,
            projectId: this.props.projectId,
            message: "",
            fileDocument: "............",
            projects: [],
            steps: [],
            members: [],
            membersSelected: [],
            sendingType: "team"
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleRadio = this.handleRadio.bind(this);
        this.handleMembersSelected = this.handleMembersSelected.bind(this);
        this.getMembers = this.getMembers.bind(this);
        this.sendEvaluationRequest = this.sendEvaluationRequest.bind(this);
        this.handleAddPrincipalFile = this.handleAddPrincipalFile.bind(this);

        //this.inputPrincipalFile = React.createRef();
    }

    componentDidMount() {
        this.getMembers();
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleRadio(e) {
        console.log(e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleAutocomplete(event, value) {
        console.log(value);
        /*this.setState({
            stepId: value
        });*/
    }

    handleMembersSelected(e, values) {
        this.setState({
            membersSelected: values
        })
    }

    getMembers() {
        fetch(new Request("api/Member/GetMembers"), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    members: response
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    sendEvaluationRequest() {
        console.log('Great!');
        this.props.closeModal();
    }

    handleAddPrincipalFile(e) {
        if (e.target.files !== null && e.target.files !== undefined) {
            console.log(e.target.files[0]);
            this.setState({ fileDocument: e.target.files[0].name });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <div className="form-group">
                        <h5 className="siteStyle">Envoyer à : </h5>
                        <RadioGroup row aria-label="sendingType" name="sendingType" value={this.state.sendingType} onChange={this.handleRadio}>
                            <FormControlLabel value="team" control={<Radio color="primary" size="small" />} label="Mon équipe" labelPlacement="end" />
                            <FormControlLabel value="all" control={<Radio color="primary" size="small" />} label="Tous les membres" labelPlacement="end" />
                            <FormControlLabel value="members" control={<Radio color="primary" size="small" />} label="Choisir les membres" labelPlacement="end" />
                        </RadioGroup>
                    </div>
                    {
                        this.state.sendingType === "members" ? 
                        <React.Fragment>
                            {
                                this.state.members.length !== 0 ? 
                                <div className="form-group">
                                    <Autocomplete multiple={true} options={this.state.members} onChange={this.handleMembersSelected}
                                        getOptionLabel={(o)=>o.fullName !== null && o.fullName !== undefined ? o.fullName : ""}
                                        value={this.state.membersSelected} renderInput={(params)=>(
                                            <TextField {...params} variant="filled" label="Sélectionner membres" />
                                        )} size="small" />
                                </div> : <Skeleton />
                            }
                        </React.Fragment> : null
                    }
                    
                    
                    <div className="form-group">
                        <TextField label="Projet" fullWidth name="projectId" variant="filled"
                            value={this.props.projectName} onChange={this.handleInput} disabled={this.props.projectId !== undefined} />
                    </div>
                    <div className="form-group">
                        <TextField label="Etape" fullWidth name="stepId" variant="filled"
                            value={this.props.steps.filter(elm => elm.stepId === this.props.stepId)[0].stepName} onChange={this.handleInput} disabled={this.props.stepId !== undefined} />
                    </div>
                    <FormControl className="mb-3" variant="filled" fullWidth>
                        <InputLabel htmlFor="filled-adornment-password">Choisir un fichier</InputLabel>
                        <FilledInput
                            id="filled-adornment-password"
                            type="text"
                            value={this.state.fileDocument}
                            disabled={true}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={() => {document.getElementById("principalFile").click();}} edge="end">
                                    <FontAwesomeIcon className="mr-1" icon={faFileDownload} size="sm" />
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                    <input type="file" hidden onChange={(e) => {this.handleAddPrincipalFile(e);}} id="principalFile" />

                    <div className="form-group">
                        <TextField label="Message" fullWidth name="message" variant="filled"
                            value={this.state.message} onChange={this.handleInput} multiline rows="4" />
                    </div>
                        
                    <Divider className="my-3" />
                    
                    <Button className="float-right text-initial" color="primary" variant="contained" size="small" onClick={this.sendEvaluationRequest} >
                        <FontAwesomeIcon className="mr-1" icon={faCheckCircle} size="sm" /> Envoyer la demande
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}
