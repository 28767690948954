import React from "react";
import { Link, NavLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAd,
    faBars,
    faBookmark,
    faBoxes,
    faCalendarCheck,
    faChevronDown,
    faChevronUp,
    faLayerGroup,
    faShoppingBag,
    faSign,
    faSignInAlt,
    faTachometerAlt,
    faUsersCog,
    faIdCard,
    faPeopleArrows,
    faArchive,
    faFolderOpen,
    faSitemap,
    faCalendarDay,
    faTasks
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from "react-bootstrap/Modal";
import { Footer } from "./Footer";
import { Notifications } from "./Notifications";
import { MessagesHeaderIcon } from "./messages/Messages";
import Avatar from "./Avatar";
//import InboxIcon from '@material-ui/icons/MoveToInbox';
//import MailIcon from '@material-ui/icons/Mail';

const drawerWidth = 240;

interface IState {
    top: boolean;
    left: boolean;
    bottom: boolean;
    right: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    list: {
        width: 250
    },
    fullList: {
        width: "auto"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerOpen: {
        width: drawerWidth,
        backgroundColor: "white",
        color: "black",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: 0,
        backgroundColor: "white",
        color: "black",
        [theme.breakpoints.up("sm")]: {
            width: 0
        }
    },
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: "100%"
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar
    },
    icon: {
        //color: "rgb(238, 238, 238)"
        color: "black"
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    fontIcon: {
        fontSize: "10px"
    },
    navIcon: {
        fontSize: "12px",
        //color: "rgb(238, 238, 238)"
        color: "black"
    },
    iconBar: {
        height: "64px",
        paddingTop: "1rem"
    },
    listItem: {
        //color: "rgb(238, 238, 238)",
        color: "black",
        textDecoration: "none"
    }
}));

const MyLink: any = React.forwardRef((props: any, ref: any) => <NavLink innerRef={ref} {...props} />);

export const Sidebar = (props: any) => {
    if (localStorage.getItem("drawerMenuState") === null) {
        localStorage.setItem("drawerMenuState", "true");
    }
    const aux2 = localStorage.getItem("drawerMenuState") === "true";

    const [state, setState] = React.useState<IState>({
        top: false,
        left: false,
        bottom: false,
        right: false
    });
    const [openModalComingSoon, setOpenModalComingSoon] = React.useState<boolean>(false);
    const [openSubMenu, setOpenSubMenu] = React.useState<boolean | string | null>(
        localStorage.getItem("drawerMenuState") === null ? false : aux2
    );
    const [openMembersSubMenu, setOpenMembersSubMenu] = React.useState<boolean>(false);
    const [openProjectsSubMenu, setOpenProjectsSubMenu] = React.useState<boolean>(false);
    const [openJamSessionssSubMenu, setOpenJamSessionsSubMenu] = React.useState<boolean>(false);
    const [openbarAdmin, setOpenBarAdmin] = React.useState<boolean>(false);

    const classes = useStyles();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const handleClick = () => {
        setOpenSubMenu(!openSubMenu);
    };

    const handleCollapseMembersClick = ()=>{
        setOpenMembersSubMenu(!openMembersSubMenu);
    }
    const handleCollapseProjectsClick = ()=>{
        setOpenProjectsSubMenu(!openProjectsSubMenu);
    }
    const handleCollapseJamSessionClick = ()=>{
        setOpenJamSessionsSubMenu(!openJamSessionssSubMenu);
    }

    const handleOpenModalComingSoon = () => {
        setOpenModalComingSoon(!openModalComingSoon);
    };

    //const handleDrawerOpen = () => {
    //    setOpen(true);
    //};

    //const handleDrawerClose = () => {
    //    setOpen(false);
    //};

    const handleDrawerToggle = () => {
        setOpenBarAdmin(!openbarAdmin);
        localStorage.setItem("drawerState", (!openbarAdmin).toString());
    };

    const toggleDrawer = (anchor: any, open: any) => (event: any) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
        localStorage.setItem("drawerState", open);
    };

    const showList = (anchor: any) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom"
            })}
        >
            <div className="text-center" style={{ height: "50px", paddingTop: "5px" }}>
                <Link to="/home" className="py-2 pr-2 pointer">
                    <img style={{ height: "35px" }} src="./img/logo-successteam-low.png" alt="logo"/>
                    <strong
                        className="ml-2 text-initial"
                        style={{ fontSize: "1.35em", position: "relative", top: "5px" }}
                    >
                        <span style={{ color: "#3f51b5" }}>Success</span> <span style={{ color: "#fd7419" }}>Team</span>
                    </strong>
                </Link>
            </div>

            {localStorage.getItem("token") === undefined || localStorage.getItem("token") === null ? (
                <div/>
            ) : (
                <div>
                    <Divider/>
                    <List>
                        <ListItem button component={MyLink} to={"/dashboard"} activeClassName="Mui-selected">
                            <Tooltip title="Dashboard" placement="right">
                                <ListItemIcon className={classes.icon}>
                                    <FontAwesomeIcon icon={faTachometerAlt} className="fa-lg"/>
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Dashboard"/>
                        </ListItem>
                        {
                            props.isSuperAdmin &&
                            <ListItem button component={MyLink} to={"/tenants"} activeClassName="Mui-selected">
                                <Tooltip title="Locataire" placement="right">
                                    <ListItemIcon className={classes.icon}>
                                        <FontAwesomeIcon icon={faTachometerAlt} className="fa-lg"/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Locataire"/>
                            </ListItem>
                        }

                        <ListItem button onClick={handleCollapseMembersClick}>
                            <Tooltip title="Membres" placement="right">
                                <ListItemIcon className={classes.icon}>
                                    <FontAwesomeIcon icon={faUsersCog} className="fa-lg" />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Membres"/>
                            {openMembersSubMenu ? (
                                <FontAwesomeIcon className={classes.fontIcon} icon={faChevronUp}/>
                            ) : (
                                <FontAwesomeIcon className={classes.fontIcon} icon={faChevronDown}/>
                            )}
                        </ListItem>
                        <Collapse in={openMembersSubMenu} timeout="auto" unmountOnExit>
                            <ListItem button component={MyLink} to={"/members"} activeClassName="Mui-selected" className={classes.nested}>
                                <Tooltip title="Liste Membres" placement="right">
                                    <ListItemIcon className={classes.icon}>
                                        <FontAwesomeIcon icon={faIdCard}/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Liste Membres"/>
                            </ListItem>
                            <ListItem button component={MyLink} to="/team" activeClassName="Mui-selected" className={classes.nested}>
                                <Tooltip title="Equipe" placement="right">
                                    <ListItemIcon className={classes.icon}>
                                        <FontAwesomeIcon icon={faPeopleArrows}/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Equipes"/>
                            </ListItem>
                        </Collapse>
                        <ListItem button onClick={handleCollapseProjectsClick}>
                            <Tooltip title="Projets" placement="right">
                                <ListItemIcon className={classes.icon}>
                                    <FontAwesomeIcon icon={faArchive} className="fa-lg" />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Projets"/>
                            {openProjectsSubMenu ? (
                                <FontAwesomeIcon className={classes.fontIcon} icon={faChevronUp}/>
                            ) : (
                                <FontAwesomeIcon className={classes.fontIcon} icon={faChevronDown}/>
                            )}
                        </ListItem>
                        <Collapse in={openProjectsSubMenu} timeout="auto" unmountOnExit>
                            <ListItem button component={MyLink} to="/projects" activeClassName="Mui-selected" className={classes.nested}>
                                <Tooltip title="Liste Projets" placement="right">
                                    <ListItemIcon className={classes.icon}>
                                        <FontAwesomeIcon icon={faFolderOpen}/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Liste Projets"/>
                            </ListItem>
                            {
                                props.isAdmin &&
                                <React.Fragment>
                                    <ListItem button component={MyLink} to="/route" className={classes.nested} activeClassName="Mui-selected">
                                        <Tooltip title="Parcours" placement="right">
                                            <ListItemIcon className={classes.icon}>
                                                <FontAwesomeIcon icon={faBookmark}/>
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText primary="Parcours"/>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={MyLink}
                                        to="/module"
                                        className={classes.nested}
                                        activeClassName="Mui-selected">
                                        <Tooltip title="Modules" placement="right">
                                            <ListItemIcon className={classes.icon}>
                                                <FontAwesomeIcon icon={faBoxes}/>
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText primary="Modules"/>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={MyLink}
                                        to="/step"
                                        className={classes.nested}
                                        activeClassName="Mui-selected">
                                        <ListItemIcon className={classes.icon}>
                                            <FontAwesomeIcon icon={faLayerGroup}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Etapes"/>
                                    </ListItem>
                                </React.Fragment>
                            }
                        </Collapse>
                        <ListItem button onClick={handleCollapseJamSessionClick}>
                            <Tooltip title="JamSessions" placement="right">
                                <ListItemIcon className={classes.icon}>
                                    <FontAwesomeIcon icon={faCalendarCheck} className="fa-lg" />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="JamSessions"/>
                            {openJamSessionssSubMenu ? (
                                <FontAwesomeIcon className={classes.fontIcon} icon={faChevronUp}/>
                            ) : (
                                <FontAwesomeIcon className={classes.fontIcon} icon={faChevronDown}/>
                            )}
                        </ListItem>
                        <Collapse in={openJamSessionssSubMenu} timeout="auto" unmountOnExit>
                            <ListItem button component={MyLink} to="/jamSessions" activeClassName="Mui-selected" className={classes.nested}>
                                <Tooltip title="Liste JamSessions" placement="right">
                                    <ListItemIcon className={classes.icon}>
                                        <FontAwesomeIcon icon={faCalendarDay}/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Liste JamSessions"/>
                            </ListItem>
                            {
                                props.isAdmin &&
                                <React.Fragment>
                                    <ListItem button component={MyLink} to="/role" className={classes.nested} activeClassName="Mui-selected">
                                        <Tooltip title="Rôles" placement="right">
                                            <ListItemIcon className={classes.icon}>
                                                <FontAwesomeIcon icon={faSitemap}/>
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText primary="Rôles"/>
                                    </ListItem>
                                    <ListItem button component={MyLink} to="/activity" className={classes.nested} activeClassName="Mui-selected">
                                        <Tooltip title="Activités" placement="right">
                                            <ListItemIcon className={classes.icon}>
                                                <FontAwesomeIcon icon={faTasks}/>
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText primary="Activités"/>
                                    </ListItem>
                                </React.Fragment>

                            }

                        </Collapse>


                    </List>
                </div>
            )}
            <Divider/>
            {localStorage.getItem("token") === undefined || localStorage.getItem("token") === null ? (
                <div className="mt-3 d-block d-sm-none text-center">
                    <Link to="/signup" className="pointer">
                        <Button className="mr-2 text-initial btn-warning-custom" variant="contained" size="small">
                            <FontAwesomeIcon icon={faSign} className="mr-1"/> S'inscrire
                        </Button>
                    </Link>
                    <Link to="/login" className="pointer">
                        <Button className="text-initial bg-white" variant="contained" size="small">
                            <FontAwesomeIcon icon={faSignInAlt} className="mr-1"/> S'identifier
                        </Button>
                    </Link>
                </div>
            ) : null}
            <List>
                <ListItem button onClick={handleOpenModalComingSoon}>
                    <Tooltip title="Annonces" placement="right">
                        <ListItemIcon className={classes.icon}>
                            <FontAwesomeIcon icon={faAd}/>
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Annonces"/>
                </ListItem>
                <ListItem button onClick={handleOpenModalComingSoon}>
                    <Tooltip title="Boutiques" placement="right">
                        <ListItemIcon className={classes.icon}>
                            <FontAwesomeIcon icon={faShoppingBag}/>
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Boutiques"/>
                </ListItem>
                <ListItem button component={MyLink} to="/events" activeClassName="Mui-selected">
                    <Tooltip title="Evènements" placement="right">
                        <ListItemIcon className={classes.icon}>
                            <FontAwesomeIcon icon={faCalendarCheck}/>
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Evènements"/>
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="fixed" className={classes.appBar} style={{ backgroundColor: "white", color: "#222" }}>
                <Toolbar>
                    <div>
                        {["left"].map((anchor) => (
                            <React.Fragment key={"2-" + anchor}>
                                <IconButton
                                    aria-label="open drawer"
                                    onClick={toggleDrawer(anchor, true)}
                                    edge="start"
                                >
                                    <FontAwesomeIcon icon={faBars}/>
                                </IconButton>
                            </React.Fragment>
                        ))}
                    </div>

                    <Typography variant="h6" noWrap/>
                    <div className="d-none d-sm-block">
                        <Link to="/home" className="py-2 pr-2 pointer">
                            <img style={{ height: "35px" }} src="./img/logo-successteam-low.png" alt="logo"/>
                            <strong className="ml-2 text-initial site-title">
                                <span style={{ color: "#3f51b5" }}>Success</span>{" "}
                                <span style={{ color: "#fd7419" }}>Team</span>
                            </strong>
                        </Link>
                    </div>
                    <div className="d-block d-sm-none">
                        <Link to="/home" className="py-2 pr-2 pointer">
                            <img style={{ height: "35px" }} src="./img/logo-successteam-low.png" alt="logo"/>
                            <strong className="ml-2 text-initial site-title">
                                <span style={{ color: "#3f51b5" }}>S</span>{" "}
                                <span style={{ color: "#fd7419" }}>T</span>
                            </strong>
                        </Link>
                    </div>

                    <div style={{ flex: "1 1 0" }}/>
                    {props.isAuth && (
                        <>
                            {/*<Link to="/messages">
                                <MessagesHeaderIcon />
                            </Link>*/}
                            <MessagesHeaderIcon />
                            
                            <div style={{ flex: "0 0 10px" }} />
                            <Notifications />
                            <div style={{ flex: "0 0 10px" }} />
                        </>
                    )}
                    <Avatar />
                </Toolbar>
            </AppBar>

            <div>
                <React.Fragment>
                    <SwipeableDrawer
                        anchor={"left"}
                        open={state["left"]}
                        onClose={toggleDrawer("left", false)}
                        onOpen={toggleDrawer("left", true)}
                    >
                        {showList("left")}
                    </SwipeableDrawer>
                </React.Fragment>
            </div>

            <div className="w-100">
                <div className={classes.toolbar}/>
                <div className={clsx("custom-min-height", { "d-flex": props.flex, "mx-0": props.flex })} style={{flexDirection: 'column'}}>
                    {props.children}
                </div>
                <Footer isShown={props.isFooterShown} />

                <Modal show={openModalComingSoon} onHide={handleOpenModalComingSoon}>
                    <Modal.Header closeButton>
                        <Modal.Title className="siteStyle">
                            <strong>Page en cours de création</strong>
                        </Modal.Title>;
                    </Modal.Header>
                    <Modal.Body>
                        <img src="./img/blog-en-chantier.jpg" className="img-fluid" alt="coming soon"/>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default Sidebar;
