import { createContext, useContext, useMemo } from "react";
import { BackendService } from "./services.generated";
import { useIsMounted } from "../utils/useIsMounted";
import { useConfig } from "../utils/config";

export const AuthorizedBackendApiContext = createContext<BackendService | null>(undefined!);
export const UnauthorizedBackendApiContext = createContext<BackendService | null>(undefined!);

export function useAuthToken(): string | null {
    return localStorage.getItem("token");
}

export function useUnauthorizedBackendApiProvider() {
    const config = useConfig();
    return useMemo(() => {
        return new BackendService(config.backendApiUrl);
    }, [config.backendApiUrl]);
}

export function useAuthorizedBackendApiProvider() {
    const config = useConfig();
    const token = useAuthToken();
    return useMemo(() => {
        if (!token) {
            return null;
        }
        return makeAuthorizedService(config.backendApiUrl, token);
    }, [config.backendApiUrl, token]);
}

export function useAuthorizedBackendApi() {
    return useContext(AuthorizedBackendApiContext);
}

export function makeAuthorizedService(baseUrl: string, token: string): BackendService {
    function addBearer(r: RequestInfo, i: RequestInit | undefined) {
        if (typeof r === "string") {
            r = new Request(r, i);
        }

        const authorization = "Bearer " + token;
        i = i || { headers: new Headers() };
        if (!i.headers) {
            i.headers = new Headers();
        }
        if (i.headers instanceof Headers) {
            i.headers.append("Authorization", authorization);
            i.headers.append("Content-Type", "application/json");
        } else if (Array.isArray(i.headers)) {
            i.headers.push(["Authorization", authorization]);
        } else {
            i.headers.Authorization = authorization;
        }
        return new Request(r.url, i);
    }

    return new BackendService(baseUrl, {
        async fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
            const r = addBearer(url, init);
            return fetch(r);
        },
    });
}

export function useAuthorizedApi() {
    const api = useContext(AuthorizedBackendApiContext);
    const isMounted = useIsMounted();

    // this is used to abandon promises in case component was unmounted before the query returned
    return useMemo(() => {
        if (!api) {
            return null;
        }
        return abandonReturnedPromisesIf(api, () => (isMounted.current ? "ok" : "abandon"));
    }, [api, isMounted]);
}

function abandonReturnedPromisesIf<T>(service: T, condition: () => "abandon" | "ok"): T {
    const wrapper: any = {};
    for (let key of Object.getOwnPropertyNames((service as any).constructor.prototype)) {
        const value = (service as any)[key];
        if (typeof value === "function") {
            wrapper[key] = function wrapFn() {
                const result = value.apply(service, arguments);
                if (typeof result.then === "function") {
                    return new Promise((resolve, reject) => {
                        result.then((r: any) => {
                            if (condition() === "ok") {
                                resolve(r);
                            }
                        });
                        result.catch((e: any) => {
                            if (condition() === "ok") {
                                reject(e);
                            }
                        });
                    });
                } else {
                    return result;
                }
            };
        }
    }
    return wrapper;
}

export function getBase64(file:Blob, callBackOnload:any, callBackOnerror:any) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        callBackOnload(reader.result);
    };
    reader.onerror = function (error) {
        callBackOnerror();
    };
}
