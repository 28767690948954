import React, { useState, useEffect, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faSync, faTimesCircle, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Skeleton from "@material-ui/lab/Skeleton";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "./EditProfile.css";
import { SystemState, Action } from '../../reducers/types';
import { connect } from 'react-redux';
import { AuthorizedBackendApiContext, useAuthorizedBackendApi } from "../../api/api";
import { BackendService, FileResponse, MemberInfoDto, MemberSimpleDataDto, UserProfileDtoBase as UserProfileDto } from "../../api/services.generated";

function EditProfile(props: any) {
    const context: BackendService | null = useContext(AuthorizedBackendApiContext)

    const [firstName, setFirstName] = useState<string | null | undefined>("");
    const [lastName, setLastName] = useState<string | null | undefined>("");
    const [email, setEmail] = useState<string | null | undefined>("");
    const [city, setCity] = useState<string | null | undefined>("");
    const [country, setCountry] = useState<string | null | undefined>("");
    const [phone, setPhone] = useState<string | null | undefined>("");
    const [address, setAddress] = useState<string | null | undefined>("");
    const [facebook, setFacebook] = useState<string | null | undefined>("");
    const [linkedIn, setLinkedIn] = useState<string | null | undefined>("");
    const [instagram, setInstagram] = useState<string | null | undefined>("");
    const [socialNetworkOther, setSocialNetworkOther] = useState<string | null | undefined>("");
    const [website, setWebsite] = useState<string | null | undefined>("");
    const [maritalStatus, setMaritalStatus] = useState<string | null | undefined>("");
    const [dependentChildren, setDependantChildren] = useState<boolean | null | undefined>(false);
    const [professionalStatus, setProfessionalStatus] = useState<string[] | null | undefined>([]);
    const [professionalStatusOther, setProfessionalStatusOther] = useState<string | null | undefined>("");
    const [dateBirth, setDateBirth] = useState<Date | null | undefined>();
    const [saveDisabled, setSaveDisabled] = useState<boolean | null | undefined>(false);
    const [dataLoaded, setDataLoaded] = useState<boolean | null | undefined>(false);
    const [tabSelected, setTabSelected] = useState<number>(0);
    const [hiddenProfessionalStatusOther, setHiddenProfessionalStatusOther] = useState<boolean | null | undefined>(true);
    const [activityField, setActivityField] = useState<string | null | undefined>("");
    const [training, setTraining] = useState<string | null | undefined>("");
    const [expertiseArea, setExpertiseArea] = useState<string | null | undefined>("");
    const [interestCenter, setInterestCenter] = useState<string[] | null | undefined>([]);
    const [favorites, setFavorites] = useState<string[] | null | undefined>([]);
    const [knowledgeST, setKnowledgeST] = useState<string | null | undefined>("");
    const [isIntroducedByMember, setIsIntroducedByMember] = useState<boolean | null | undefined>(false);
    const [introducedByMember, setIntroducedByMember] = useState<MemberSimpleDataDto | null | undefined>(null);
    const [objectifsST, setObjectifsST] = useState<string[] | null | undefined>([]);
    const [objectives, setObjectives] = useState<string | null | undefined>("");
    const [members, setMembers] = useState<MemberSimpleDataDto[] | null | undefined>([]);
    const [avatarLoading, setAvatarLoading] = useState<boolean | null | undefined>(false);
    const [avatarProfile, setAvatarProfile] = useState<string | null | undefined>(props.avatar !== null ? props.avatar : null);
    const [favoriteQuote, setFavoriteQuote] = useState<string | null | undefined>("");
    const [memberPresentation, setMemberPresentation] = useState<string | null | undefined>("");
    const [memberPresentationLength, setMemberPresentationLength] = useState<number>(300);
    const [receivePrivateMessagesToEmails, setReceivePrivateMessagesToEmails] = useState<boolean>(true);
    const [receiveJamSessionAddressChangedToEmails, setReceiveJamSessionAddressChangedToEmails] = useState<boolean>(true);
    const [receiveJamSessionMainRoleAssignmentsToEmails, setReceiveJamSessionMainRoleAssignmentsToEmails] = useState<boolean>(true);


    useEffect(()=>{
        getProfile();
        console.log("GetProfile Test");
    }, [])
    
    const getProfile = async () => {
        /* */
        setDataLoaded(false);
    
        fetch(new Request("api/Member/GetProfil"), {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            }),
        })
        .then((response) => {
            if (response.status !== 204) return Promise.all([response.ok, response.json()]);
    
            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                setDataLoaded(true);
                setFirstName(response.firstName);
                setLastName(response.lastName);
                setEmail(response.email);
                setDateBirth(response.dateBirth);
                setAddress(response.address);
                setCity(response.city);
                setCountry(response.country);
                setPhone(response.phoneNumber);
                setFacebook(response.facebook);
                setLinkedIn(response.linkedIn);
                setInstagram(response.instagram);
                setSocialNetworkOther(response.socialNetworkOther);
                setDependantChildren(response.dependentChildren);
                setMaritalStatus(response.maritalStatus);
                setProfessionalStatus(response.professionalStatus);
                setProfessionalStatusOther(response.professionalStatusOther);
                setHiddenProfessionalStatusOther(!(response.professionalStatus || []).some((p: string) => p === "OTHER"));
                setActivityField(response.activityField);
                setTraining(response.training.join("\n"));
                setExpertiseArea(response.expertiseArea.join("\n"));
                setInterestCenter(response.interestCenter.join("\n"));
                setFavorites(response.favorites.join("\n"));
                setKnowledgeST(response.knowledgeST);
                setIsIntroducedByMember(response.isIntroducedByMember);
                setIntroducedByMember(response.introducedByMemberId !== null ? response.members.find((f: MemberInfoDto) => f.id === response.introducedByMemberId) : null);
                setObjectives(response.objectifsST !== null ? response.objectifsST.join("\n") : "");
                setMembers(response.members);
                setFavoriteQuote(response.favoriteQuote);
                setMemberPresentation(response.memberPresentation);
                setReceivePrivateMessagesToEmails(response.receivePrivateMessagesToEmails);
                setReceiveJamSessionAddressChangedToEmails(response.receiveJamSessionAddressChangedToEmails);
                setReceiveJamSessionMainRoleAssignmentsToEmails(response.receiveJamSessionMainRoleAssignmentsToEmails);
            } else {
                throw new Error(response);
            }
        })
        .catch((error) => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        });
        /* */
    }

    const saveProfile = async () => {
        if (isIntroducedByMember && introducedByMember === undefined) {
            toast.warn("Sélectionner le membre qui vous a introduit !", {
                position: "top-left",
                hideProgressBar: false,
            });
            return;
        }

        setSaveDisabled(true);
        if (!context) {
            return;
        }

        try {
            //console.log("Country : " + country);
            const response:FileResponse | null = await context.member_SetProfil({
                city: city,
                country: country,
                phoneNumber: phone,
                address: address,
                facebook: facebook,
                linkedIn: linkedIn,
                socialNetworkOther: socialNetworkOther,
                instagram: instagram,
                dateBirth: dateBirth,
                maritalStatus: maritalStatus,
                dependentChildren: dependentChildren,
                professionalStatus: professionalStatus,
                professionalStatusOther: professionalStatusOther,
                training: training !== null && training !== undefined ? training.split('\n') : null,
                expertiseArea: expertiseArea !== null && expertiseArea !== undefined ? expertiseArea.split('\n') : null,
                activityField: activityField,
                interestCenter: interestCenter !== null ? interestCenter : null,
                favorites: favorites !== null ? favorites : null,
                knowledgeST: knowledgeST,
                isIntroducedByMember: isIntroducedByMember,
                introducedByMemberId: introducedByMember !== null && introducedByMember !== undefined ? introducedByMember.id : null,
                objectifsST: objectives !== null ? objectives : null,
                favoriteQuote: favoriteQuote,
                membrePresentation: memberPresentation,
                receivePrivateMessagesToEmails: receivePrivateMessagesToEmails,
                receiveJamSessionAddressChangedToEmails: receiveJamSessionAddressChangedToEmails,
                receiveJamSessionMainRoleAssignmentsToEmails: receiveJamSessionMainRoleAssignmentsToEmails,
            } as UserProfileDto);

            toast.success("Le profil a été mis a jour avec succès !", {
                position: "top-left",
                hideProgressBar: false,
            });
            
            props.closeProfil();
        } 
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        } 
        finally {
            setSaveDisabled(false);
        }
    }

    const handleDateChange = (date: Date) => {
        setDateBirth(date);
    }

    const handleClose = () => {
        props.closeProfil();
    }

    const handleChangeTab = (event: any, value: number) => {
        setTabSelected(value);
    }

    const handleChangeCheckbox = (e: any) => {
        if (e.target.checked) {
            if (e.target.value !== "OTHER") {
                setProfessionalStatus([...professionalStatus, e.target.value]);
                setProfessionalStatusOther("");
            } 
            else {
                setProfessionalStatus([...professionalStatus, e.target.value]);
                setHiddenProfessionalStatusOther(false);
            }
        } 
        else {
            var array = professionalStatus;
            var index = 0;
            if (array !== null && array !== undefined)
                var index = array.findIndex((f) => f === e.target.value);
            
            if (e.target.value === "OTHER") {
                setProfessionalStatusOther("");
                setHiddenProfessionalStatusOther(true);
            }

            if (array !== null && array !== undefined)
                array.splice(index, 1);
            
            setProfessionalStatus(array);
        }
    }

    const handleAddTraining = (item: string) => {
        //setTraining([...training, item]);
    }

    const handleAddExpertiseArea = (item: string) => {
        //setExpertiseArea([...expertiseArea, item]);
    }

    const handleAddInterestCenter = (item: string) => {
        setInterestCenter([...interestCenter, item]);
    }

    const handleAddFavorites = (item: string) => {
        setFavorites([...favorites, item]);
    }

    const handleAddObjectifsMotivation = (item: string) => {
        setObjectifsST([...objectifsST, item]);
    }

    const handleRemoveObjectifsMotivation = (item: string, index: number) => {
        var array = objectifsST;
        if (array !== null && array !== undefined)
            array.splice(index, 1);
        setObjectifsST(array);
    }

    const handleRemoveTraining = (item: string, index: number) => {
        var array = training;
        /*
        if (array !== null && array !== undefined)
            array.splice(index, 1);
        */
        setTraining(array);
    }

    const handleRemoveExpertiseArea = (item: string, index: number) => {
        var array = expertiseArea;
        /*
        if (array !== null && array !== undefined)
            array.splice(index, 1);
        */
        setExpertiseArea(array);
    }

    const handleRemoveInterestCenter = (item: string, index: number) => {
        var array = interestCenter;
        if (array !== null && array !== undefined)
            array.splice(index, 1);
        setInterestCenter(array);
    }

    const handleRemoveFavorites = (item: string, index: number) => {
        var array = favorites;
        if (array !== null && array !== undefined)
            array.splice(index, 1);
        setFavorites(array);
    }

    const handleChangeIntroducedByMember = (event: any, value: any) => {
        setIntroducedByMember(value);
    }

    const handleChangeAvatar = (e: any) => {
        setAvatarLoading(true);
        
        var file = e.target.files[0];
        var formData = new FormData();

        formData.append("file", file);
        axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");

        axios
            .post("api/Member/ChangeAvatar", formData)
            .then(() => {
                getBase64(file, (result: string) => {
                    setAvatarProfile(result);
                    setAvatarLoading(false);
                    
                    props.uploadAvatar(result);
                });
            })
            .catch((error) => {
                setAvatarProfile(null);
                setAvatarLoading(false);
                
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false,
                });
            });
    }

    const getBase64 = (file: any, cb: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            setAvatarProfile(null);
        };
    }

    const handleInputMemberPresentation = (e: any) => {
        var remain = 300 - e.target.value.length;

        if (remain < 0) {
            setMemberPresentation(e.target.value.substr(0, 300));
            setMemberPresentationLength(0);
        } else {
            setMemberPresentation(e.target.value);
            setMemberPresentationLength(remain);
        }
    }


    return (
        <Dialog open={true} onClose={() => { props.closeProfil(); }} fullWidth={true} maxWidth="md">
            <DialogTitle className="text-initial">
                <FontAwesomeIcon icon={faUserEdit} className="mr-2" /> <span className="h6">Editer le profil</span>
            </DialogTitle>
            <DialogContent>
                {!dataLoaded ? (
                    <React.Fragment>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <Skeleton />
                            </div>
                            <div className="col-md-6">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <Skeleton />
                            </div>
                            <div className="col-md-6">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <Skeleton />
                            </div>
                            <div className="col-md-6">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <Skeleton />
                            </div>
                            <div className="col-md-6">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <Skeleton />
                            </div>
                            <div className="col-md-6">
                                <Skeleton />
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Informations personnelles</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="w-100">
                                    <div className="row">
                                        <div className="col-md-4">
                                            {
                                                avatarLoading ? 
                                                <FontAwesomeIcon
                                                    icon={faSync}
                                                    className="fa-4x h-100 fa-spin"
                                                    style={{ position: "absolute", left: "40%" }}
                                                /> : null
                                            }
                                            
                                            <img
                                                src={
                                                    avatarProfile === null
                                                        ? "/img/user.png"
                                                        : avatarProfile
                                                }
                                                alt="avatar"
                                                className={`mx-auto d-block pointer ${
                                                    avatarLoading && "avatarLoading"
                                                }`}
                                                onClick={() => { 
                                                    const el = document.getElementById("avatarFile");
                                                    if (el)
                                                        el.click();
                                                    else
                                                        console.log("Not working");
                                                }}
                                            />
                                            <input
                                                type="file"
                                                hidden
                                                onChange={handleChangeAvatar}
                                                id="avatarFile"
                                            />
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <TextField
                                                        name="lastName"
                                                        label="Nom"
                                                        value={lastName}
                                                        onChange={(e: any) => { setLastName(e.target.value); }}
                                                        fullWidth
                                                        variant="filled"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField
                                                        name="firstName"
                                                        label="Prénom"
                                                        value={firstName}
                                                        onChange={(e: any) => { setFirstName(e.target.value); }}
                                                        fullWidth
                                                        variant="filled"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <TextField
                                                        name="email"
                                                        label="Email"
                                                        value={email}
                                                        onChange={(e: any) => { setEmail(e.target.value); }}
                                                        type="email"
                                                        fullWidth
                                                        variant="filled"
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            //margin="normal"
                                                            autoOk
                                                            variant="inline"
                                                            inputVariant="filled"
                                                            label="Date de naissance"
                                                            format="dd/MM/yyyy"
                                                            value={dateBirth}
                                                            onChange={(e: any) => {
                                                                handleDateChange(e);
                                                            }}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                            }}
                                                            className="w-100"
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <FormControl component="fieldset">
                                                        <FormLabel className="siteStyle" component="legend">
                                                            Enfants à charge
                                                        </FormLabel>
                                                        <RadioGroup
                                                            aria-label="Enfants à charge"
                                                            name="dependentChildren"
                                                            className="d-inline"
                                                            value={dependentChildren}
                                                            onChange={(e: any) => { setDependantChildren(e.target.value === "true"); }}
                                                        >
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio color="primary" />}
                                                                label="Oui"
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio color="primary" />}
                                                                label="Non"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField
                                                        name="phone"
                                                        label="Téléphone"
                                                        value={phone}
                                                        onChange={(e: any) => { setPhone(e.target.value); }}
                                                        fullWidth
                                                        variant="filled"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <TextField
                                                name="city"
                                                label="Ville"
                                                value={city}
                                                onChange={(e: any) => { setCity(e.target.value); }}
                                                fullWidth
                                                variant="filled"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                name="country"
                                                label="Pays"
                                                value={country}
                                                onChange={(e: any) => { setCountry(e.target.value); }}
                                                fullWidth
                                                variant="filled"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <TextField
                                                name="address"
                                                label="Résidence (Rue / Numéro / Boîte)"
                                                value={address}
                                                onChange={(e: any) => { setAddress(e.target.value); }}
                                                fullWidth
                                                variant="filled"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FormControl component="fieldset">
                                                <FormLabel className="siteStyle" component="legend">
                                                    Statut matrimonial
                                                </FormLabel>
                                                <RadioGroup
                                                    aria-label="Statut matrimonial"
                                                    name="maritalStatus"
                                                    className="d-inline"
                                                    value={maritalStatus}
                                                    onChange={(e: any) => { setMaritalStatus(e.target.value); }}
                                                >
                                                    <FormControlLabel
                                                        value="MARRIED"
                                                        control={<Radio color="primary" />}
                                                        label="Marié"
                                                    />
                                                    <FormControlLabel
                                                        value="COUPLE"
                                                        control={<Radio color="primary" />}
                                                        label="En couple"
                                                    />
                                                    <FormControlLabel
                                                        value="SINGLE"
                                                        control={<Radio color="primary" />}
                                                        label="Célibataire"
                                                    />
                                                    <FormControlLabel
                                                        value="OTHER"
                                                        control={<Radio color="primary" />}
                                                        label="Autre"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div
                                            className={
                                                hiddenProfessionalStatusOther ? "col-md-12" : "col-md-6"
                                            }
                                        >
                                            <FormControl component="fieldset">
                                                <FormLabel className="siteStyle" component="legend">
                                                    Statut professionnel
                                                </FormLabel>
                                                <FormGroup row>
                                                    <FormControlLabel
                                                        value="EMPLOYEE"
                                                        onChange={handleChangeCheckbox}
                                                        label="Travailleur salarié"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={(professionalStatus || []).some(
                                                                    (p) => p === "EMPLOYEE"
                                                                )}
                                                            />
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        value="FREELANCER"
                                                        label="Travailleur indépendant"
                                                        onChange={handleChangeCheckbox}
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={(professionalStatus || []).some(
                                                                    (p) => p === "FREELANCER"
                                                                )}
                                                            />
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        value="STUDENT"
                                                        onChange={handleChangeCheckbox}
                                                        label="Etudiant"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={(professionalStatus || []).some(
                                                                    (p) => p === "STUDENT"
                                                                )}
                                                            />
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        value="JOB_SEARCH"
                                                        onChange={handleChangeCheckbox}
                                                        label="A la recherche d'emploi"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={(professionalStatus || []).some(
                                                                    (p) => p === "JOB_SEARCH"
                                                                )}
                                                            />
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        value="OTHER"
                                                        onChange={handleChangeCheckbox}
                                                        label="Autre"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={(professionalStatus || []).some(
                                                                    (p) => p === "OTHER"
                                                                )}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </div>
                                        {!hiddenProfessionalStatusOther && (
                                            <div className="col-md-6">
                                                <TextField
                                                    name="professionalStatusOther"
                                                    label="Autre statut professionnel"
                                                    value={professionalStatusOther}
                                                    onChange={(e: any) => { setProfessionalStatusOther(e.target.value); }}
                                                    fullWidth
                                                    variant="filled"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            value={memberPresentation}
                                            onChange={handleInputMemberPresentation}
                                            rows="4"
                                            fullWidth
                                            name="memberPresentation"
                                            variant="filled"
                                            multiline
                                            label="Présentation membre"
                                            helperText={
                                                memberPresentationLength + " caractères restants"
                                            }
                                        />
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Informations professionnelles</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="w-100">
                                    <div className="form-group">
                                        <TextField
                                            name="activityField"
                                            label="Si travailleur ou travailleur indépendant, votre profession / domaine d'activité"
                                            value={activityField}
                                            onChange={(e: any) => { setActivityField(e.target.value); }}
                                            fullWidth
                                            variant="filled"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            name="training"
                                            label="Vos background (Vos formations)"
                                            value={training}
                                            onChange={(e: any) => { setTraining(e.target.value); }}
                                            fullWidth
                                            variant="filled"
                                            multiline
                                            rows="3"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            name="expertiseArea"
                                            label="Vos domaines de compétences / Votre savoir faire"
                                            value={expertiseArea}
                                            onChange={(e: any) => { setExpertiseArea(e.target.value); }}
                                            fullWidth
                                            variant="filled"
                                            multiline
                                            rows="3"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            name="website"
                                            label="Site internet"
                                            value={website}
                                            onChange={(e: any) => { setWebsite(e.target.value); }}
                                            fullWidth
                                            variant="filled"
                                        />
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Réseaux sociaux</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="w-100">
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <TextField
                                                name="facebook"
                                                label="Facebook"
                                                fullWidth
                                                value={facebook}
                                                onChange={(e: any) => { setFacebook(e.target.value); }}
                                                variant="filled"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                name="linkedIn"
                                                label="LinkedIn"
                                                fullWidth
                                                value={linkedIn}
                                                onChange={(e: any) => { setLinkedIn(e.target.value); }}
                                                variant="filled"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <TextField
                                                name="instagram"
                                                label="Instagram"
                                                fullWidth
                                                value={instagram}
                                                onChange={(e: any) => { setInstagram(e.target.value); }}
                                                variant="filled"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                name="socialNetworkOther"
                                                label="Autre réseau social"
                                                fullWidth
                                                value={socialNetworkOther}
                                                onChange={(e: any) => { setSocialNetworkOther(e.target.value); }}
                                                variant="filled"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Motivations personnelles</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="w-100">
                                    <div className="form-group">
                                        <TextField
                                            name="interestCenter"
                                            label="Centres d'intérêts"
                                            value={interestCenter}
                                            onChange={(e: any) => { setInterestCenter(e.target.value); }}
                                            fullWidth
                                            variant="filled"
                                            multiline
                                            rows="3"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            name="favorites"
                                            label="Passe-temps favoris"
                                            value={favorites}
                                            onChange={(e: any) => { setFavorites(e.target.value); }}
                                            fullWidth
                                            variant="filled"
                                            multiline
                                            rows="3"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            name="knowledgeST"
                                            label="Comment avez-vous connu la SuccessTeam"
                                            value={knowledgeST}
                                            onChange={(e: any) => { setKnowledgeST(e.target.value); }}
                                            fullWidth
                                            variant="filled"
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <FormControl component="fieldset">
                                                <FormLabel className="siteStyle pt-3" component="legend">
                                                    Introduit par un membre ?
                                                </FormLabel>
                                                <RadioGroup
                                                    aria-label="Introduit par un membre ?"
                                                    name="isIntroducedByMember"
                                                    className="d-inline"
                                                    value={isIntroducedByMember}
                                                    onChange={(e: any) => { setIsIntroducedByMember(e.target.value === "true") }}
                                                >
                                                    <FormControlLabel
                                                        value={true}
                                                        control={<Radio color="primary" />}
                                                        label="Oui"
                                                    />
                                                    <FormControlLabel
                                                        value={false}
                                                        control={<Radio color="primary" />}
                                                        label="Non"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-6">
                                            {
                                                <Autocomplete
                                                    options={members !== null && members !== undefined ? members : []}
                                                    getOptionLabel={(o: any) => {
                                                        /*
                                                        if (o === null)
                                                            return null;
                                                        if (o === undefined)
                                                            return undefined;
                                                        */
                                                        return o.fullName;
                                                    }}
                                                    onChange={handleChangeIntroducedByMember}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Si oui lequel ?"
                                                            margin="normal"
                                                            variant="filled"
                                                        />
                                                    )}
                                                    disabled={!isIntroducedByMember}
                                                    value={introducedByMember}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            name="objectives"
                                            label="Objectifs et Motivations"
                                            value={objectives}
                                            onChange={(e: any) => { setObjectives(e.target.value); }}
                                            fullWidth
                                            variant="filled"
                                            multiline
                                            rows="3"
                                            helperText="Vos objectifs poursuivis et les motivations personnelles pour rejoindre la SuccessTeam"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            value={favoriteQuote}
                                            onChange={(e: any) => { setFavoriteQuote(e.target.value); }}
                                            name="favoriteQuote"
                                            variant="filled"
                                            label="Citation favorite"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <FormControlLabel
                            label="Recevoir une notification de message privé par e-mail"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={receivePrivateMessagesToEmails}
                                />
                            }
                            onChange={(e: any) => {
                                setReceivePrivateMessagesToEmails(e.target.checked);
                            }}
                        />
                        <FormControlLabel
                            label="Recevoir une notification sur mes rôles dans les jam sessions"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={receiveJamSessionMainRoleAssignmentsToEmails}
                                />
                            }
                            onChange={(e: any) => {
                                setReceiveJamSessionMainRoleAssignmentsToEmails(e.target.checked);
                            }}
                        />
                    </React.Fragment>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    className="text-initial"
                    variant="contained"
                    color="primary"
                    onClick={saveProfile}
                    disabled={saveDisabled !== null ? saveDisabled : true}
                    size="small"
                >
                    <FontAwesomeIcon icon={faSave} className="mr-2" size="sm" /> Enregistrer
                </Button>
                <Button
                    className="text-initial"
                    variant="contained"
                    onClick={handleClose}
                    size="small"
                >
                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2" size="sm" /> Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
}



const mapStateToProps = (state: SystemState) => {
    return {
        avatar: state.avatar,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadAvatar: (imagebase64: string) => {
            dispatch({
                type: "UPLOAD_AVATAR",
                avatar: imagebase64,
            } as Action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
