import React, { useEffect, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { faPen, faPlusCircle, faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditTenant from './EditTenant'
import { BackendService, GetTenantDto } from '../../api/services.generated'
import { useAuthorizedBackendApi } from '../../api/api'
import { toast } from "react-toastify"

export default function Tenants() {

    const api: BackendService | null = useAuthorizedBackendApi()
    const [tenants, setTenants] = useState<GetTenantDto[]>([])
    const [btnRefreshDisabled, setBtnRefreshDisabled] = useState(false)
    const [tenantId, setTenantId] = useState<string | null>(null)

    useEffect(() => {
        getTenants()
    }, [])
    
    async function getTenants(){
        if(!api){
            return;
        }

        setBtnRefreshDisabled(true)
        try
        {
            const response:GetTenantDto[] | null = await api.tenant_GetTenants()
            setTenants(response ? response : [])
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        }
        finally{
            setBtnRefreshDisabled(false)
        }
    }


    return (
        <div className="container-fluid pt-4">
            <div className="row">
                <div className="col-md-8">
                    <Card>
                        <CardContent>
                            <div className="alert alert-secondary">
                                <Button size="small" variant="contained" color="primary" className="mr-3" onClick={()=>setTenantId(null)}>
                                    <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Nouveau
                                </Button>
                                <Button size="small" variant="contained" className="bg-info text-white" 
                                disabled={btnRefreshDisabled} onClick={getTenants}>
                                    <FontAwesomeIcon icon={faSync} className="mr-2" /> Rafraîchir
                                </Button>
                            </div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Client</TableCell>
                                        <TableCell>Hôte</TableCell>
                                        <TableCell>Port</TableCell>
                                        <TableCell>Base de données</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        tenants.map((item:GetTenantDto)=>(
                                            <TableRow key={item.tenantId?.toString()}>
                                                <TableCell className="font-weight-bold">{item.customerName}</TableCell>
                                                <TableCell>{item.host}</TableCell>
                                                <TableCell>{item.port}</TableCell>
                                                <TableCell>{item.dataBase}</TableCell>
                                                <TableCell>
                                                    <FontAwesomeIcon icon={faPen} className="pointer"
                                                    onClick={()=>setTenantId(item.tenantId !== undefined ? item.tenantId : null)} />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </div>
                <div className="col-md-4">
                    <EditTenant refreshTenants={getTenants} _tenantId={tenantId} />
                </div>
            </div>
        </div>
    )
}
