import { faCheckCircle, faExclamationCircle, faExclamationTriangle, faSave, faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import React, { useState, useContext,useEffect } from 'react'
import { Link } from 'react-router-dom'
import { UnauthorizedBackendApiContext } from '../../api/api'
import { getErrorMessage } from '../../api/utils'
import { toast } from 'react-toastify'
import { ConfirmAccountDto } from '../../api/services.generated'

export default function ConfirmAccount({match}:{match:any}) {

    const context = useContext(UnauthorizedBackendApiContext)

    const [checkStatusLoaded, setCheckStatusLoaded] = useState(true)
    const [alreadyRegistered, setAlreadyRegistered] = useState(false)
    const [accountConfirmed, setAccountConfirmed] = useState(false)
    const [btnSaveDisable, setBtnSaveDisable] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    useEffect(() => {
        async function init(){
            if(!context){
                return;
            }
            try
            {
                const response:boolean = await context.member_CheckStatusToken(match.params.userId)
                setAlreadyRegistered(!response)
                setCheckStatusLoaded(false)
            }
            catch(error)
            {
                toast.warn(getErrorMessage(error) + " !", {
                    position: "top-left",
                    hideProgressBar: false,
                });
            }
        }
        init()
    }, [])

    async function handleSave(){
        if(!context){
            return;
        }
        try
        {
            await context.member_ConfirmAccount({
                id:match.params.userId,
                token:match.params.token,
                password:match.params.pwd,
                newPassword:password,
                confirmPassword:confirmPassword
            } as ConfirmAccountDto)
            setAccountConfirmed(true)
        }
        catch(error)
        {
            toast.warn(getErrorMessage(error) + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
    }

    return (
        <div className="main-content">
            <div className="container">
                {
                    checkStatusLoaded ?
                        <div className="row justify-content-md-center pt-5">
                            <div className="col-2">
                                <FontAwesomeIcon icon={faSync} className="fa-5x fa-spin" />
                            </div>
                        </div> : alreadyRegistered ?
                            <div className="alert alert-warning mt-5">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="fa-lg mr-2" /> Votre compte a déjà été confirmé !
                            </div> : 
                        <div className="row justify-content-md-center">
                            <div className="col-md-6">
                                {
                                    !accountConfirmed ?
                                        <Paper elevation={3} className="p-5 mt-5">
                                            <div className="row justify-content-md-center mb-2">
                                                <div className="col-8">
                                                    <img src="img/successTeam.jpg" alt="logo" style={{ width: "100px" }} />
                                                    <span style={{ color: "#5061a2", fontSize: "1.5rem" }} className="font-weight-bold mx-2">AKILI</span>
                                                    <span style={{ color: "#fd751a", fontSize: "1.5rem" }} className="font-weight-bold">PORTAL</span>
                                                </div>

                                            </div>

                                            <div className="alert alert-info">
                                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-3 fa-lg" />
                                                Merci de saisir votre mot de passe pour confirmer l'enregistrement de votre compte à la plateforme
                                                AKILI.
                                            </div>
                                            <div className="alert alert-secondary">
                                                <Button variant="contained" color="primary" disabled={btnSaveDisable} onClick={handleSave}>
                                                    <FontAwesomeIcon icon={faSave} className="mr-2" /> Confirmer
                                                </Button>
                                            </div>
                                            <div className="form-group">
                                                    <TextField label="Votre nouveau mot de passe" value={password} type="password" fullWidth 
                                                    onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setPassword(e.target.value)}
                                                         />
                                            </div>
                                            <div className="form-group">
                                                <TextField label="Confirmer votre mot de passe" value={confirmPassword} type="password" fullWidth 
                                                onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setConfirmPassword(e.target.value)} />
                                            </div>
                                        </Paper> :
                                            <div className="alert alert-success mt-5">
                                                <FontAwesomeIcon icon={faCheckCircle} className="fa-lg mr-2" /> Votre mot de passe a été enregistré
                                                et votre compte a été confirmé sur la plateforme AKILI
    
                                            <div className="row justify-content-md-center my-3">
                                                <div className="col-5">
                                                    <Button>
                                                        <Link to="/">Connectez-vous</Link>
                                                    </Button>
                                                </div>

                                            </div>
                                            <div className="row justify-content-md-center">
                                                <div className="col-6">
                                                    <img src="img/successTeam.jpg" alt="logo" style={{ width: "50px" }} />
                                                    <span style={{ color: "#5061a2", fontSize: "1.5rem" }} className="font-weight-bold mx-2">AKILI</span>
                                                    <span style={{ color: "#fd751a", fontSize: "1.5rem" }} className="font-weight-bold">PORTAL</span>
                                                </div>

                                            </div>
                                        </div>
                                }

                            </div>
                        </div>
                        
                }
            </div>
        </div>
    )
}
