export function ifDefined<T>(item: T | undefined, defaultValue: T): T {
    if (item === undefined) {
        return defaultValue;
    }
    return item;
}

export function ifNotNullAndDefined<T>(x: T | null | undefined, defaultValue: T): T {
    if (x === null || x === undefined) {
        return defaultValue;
    }
    return x;
}

export function ifNotNull<T>(item: T | null, defaultValue: T) {
    if (item === null) {
        return defaultValue;
    }
    return item;
}

export function assertNever<T>(_: never): T {
    throw new Error('should be unreachable.');
}

export function undefinedToNull<T>(t: T | undefined): T | null {
    if (t === undefined) {
        return null;
    }
    return t;
}

export function nullToUndefined<T>(t: T | null): T | undefined {
    if (t === null) {
        return undefined;
    }
    return t;
}
