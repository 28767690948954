import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';
import { VerifiedInput } from './global/VerifiedInput';
import { useAuthorizedBackendApi } from "../api/api";
import { BackendService, FileResponse, ContactUsDto } from '../api/services.generated';

export function Contact() {
    const api: BackendService | null = useAuthorizedBackendApi();

    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [check_name, setCheckName] = React.useState(false);
    const [check_email, setCheckEmail] = React.useState(false);
    const [check_message, setCheckMessage] = React.useState(false);

    function isEmail(email: string) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const sendMessage = async () => {
        var result = validateForm();
        if (result === "OK") {
            setLoading(true)
            if (!api) {
                return;
            }

            try {
                const response:FileResponse | null = await api.contact_SendContactUs({
                    name: name,
                    email: email,
                    message: message
                } as ContactUsDto);

                toast.success("Votre message a été envoyé !", {
                    position: "top-center",
                    hideProgressBar: false
                });
                
                setName("");
                setEmail("");
                setMessage("");
            }
            catch (error) {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false,
                });
            }
            finally{
                setLoading(false);
            }

            /*
            setLoading(true);

            fetch(new Request("api/Contact/SendContactUs"), {
                method: "POST",
                body: JSON.stringify({
                    Name: name,
                    Email: email,
                    Message: message
                }),
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                })
            })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    toast.success("Votre message a été envoyé !", {
                        position: "top-left",
                        hideProgressBar: false
                    });

                    setLoading(false);
                } else {
                    setLoading(false);
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                setLoading(false);
            });
            */
        }
        else {
            toast.warn(result);
        }
    }

    const validateForm = () => {
        var errorsArray = [];
        if (name === "") {
            errorsArray.push("Le nom n'est pas valide");
            setCheckName(true);
        }
        if (!isEmail(email)) {
            errorsArray.push("L'adresse mail n'est pas valide");
            setCheckEmail(true);
        }

        if (errorsArray.length > 0) {
            //return errorsArray.join(" - ");
            return ("Erreurs dans le formulaire : " + errorsArray.join(" - "));
        }
        return "OK";
    }
    
    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>Contact - Success Team</title>
                    <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </MetaTags>
            </div>
            <nav className="siteStyle top-nav">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to='/home'>Accueil</Link></li>
                    <li className="breadcrumb-item active">Contact</li>
                </ol>
            </nav>
            <div className="jumbotron d-flex align-items-center bg-dark min-vh-25 mb-0" style={{ backgroundImage: "url('./img/Orange-Background-Free.png')" }}>
                <div className="container text-center">
                    <h1 className="text-white siteStyle font-weight-bolder custom-title">CONTACT</h1>
                </div>
            </div>

            <div className="bg-white custom-margin">
                <div className="container row mx-auto">
                    <div className="col-md-12">
                        <div className="siteStyle text-center custom-margin-bottom">
                            <h2 className="custom-title2">Formulaire de contact</h2>
                            <p className="custom-subtitle mt-3">Vous avez une question? Nous serons ravis de vous répondre.</p>
                        </div>
                    </div>
                    {
                        loading ?
                        <div className="col-md-12 mt-3">
                            <div className="alert alert-info mb-0">Envoi du message en cours...</div>
                        </div> : null
                    }
                    <div className="col-md-6 mt-3">
                        <VerifiedInput
                            name="name"
                            title="Nom"
                            value={name}
                            checked={check_name}
                            onChange={(e:any) => { setName(e.target.value); }}
                            type="text"
                            errorMessage="Le nom n'est pas valide"
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <VerifiedInput
                            name="email"
                            title="Email"
                            value={email}
                            checked={check_email}
                            onChange={(e:any) => { setEmail(e.target.value); }}
                            type="email"
                            errorMessage="L'adresse mail n'est pas valide"
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <TextField
                            fullWidth name="message" value={message} multiline
                            onChange={(e) => { setMessage(e.target.value) }} label="Message" rows="5" variant="filled"
                        />
                    </div>
                    <div className="col-md-12 mt-3 text-center">
                        <Button className="text-initial btn-warning-custom" size="large" onClick={sendMessage}>
                            <FontAwesomeIcon className="mr-1" icon={faPaperPlane} /> Envoyer le message
                        </Button>
                    </div>
                </div>
            </div>
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.1721542882956!2d4.378706315280524!3d50.8279750679718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c4bfd9fffb09%3A0xa7dccb92a9c8afc2!2sAvenue%20de%20la%20Couronne%20127%2C%201050%20Ixelles%2C%20Belgique!5e0!3m2!1sfr!2scm!4v1587357833904!5m2!1sfr!2scm" width={"100%"} height={450} frameBorder={0} style={{ border: 0 }} allowFullScreen aria-hidden="false" tabIndex={0} title="card" />
            </div>
        </React.Fragment>
    );
}
