import React, {Component} from 'react';
import EditJamSession from './EditJamSession/EditJamSession';
import EditJamSessionMember from './EditJamSession/EditJamSessionMember';
import HomeJamSessionCard from '../HomeJamSessionCard';

export default class JamSessionsCardView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openModalReadJamSession: false,
            openModalEditJamSession: false,
            jamSessionId: "",
            isRegistered: false
        };

        this.handleCloseModalJamSession = this.handleCloseModalJamSession.bind(this);
        this.updateJamSession = this.updateJamSession.bind(this);
        this.handleCloseModalEditJamSession = this.handleCloseModalEditJamSession.bind(this);
    }

    handleCloseModalJamSession(openEditJamSession) {
        if (openEditJamSession) {
            this.setState({
                openModalReadJamSession: false,
                openModalEditJamSession: openEditJamSession
            });
        } else {
            this.setState({
                openModalReadJamSession: false,
                jamSessionId: null
            });
        }
    }

    handleCloseModalEditJamSession() {
        this.setState({
            openModalEditJamSession: false
        });
    }

    updateJamSession(id) {
        this.setState({
            openModalReadJamSession: true,
            jamSessionId: id
        });
    }

    render() {
        return (
            <div className="row">
                {
                    this.props.jamSessions.map(row => (
                        <React.Fragment key={row.jamSessionId}>
                            <HomeJamSessionCard jamSessionNumber={row.jamSessionNumber} closed={row.closed}
                                theme={row.theme} date={row.date} address={row.address}
                                membersRegistered={row.members !== null ? row.members.length : 0}
                                jamSessionId={row.jamSessionId} isAdmin={this.props.isAdmin}
                                updateJamSession={this.updateJamSession}
                            />
                        </React.Fragment>
                    ))
                }
                {
                    this.state.openModalEditJamSession && this.props.isAdmin &&
                    <EditJamSession jamSessionId={this.state.jamSessionId}
                        onCloseModal={this.handleCloseModalEditJamSession}
                    />
                }
                {
                    this.state.openModalReadJamSession && 
                    <EditJamSessionMember jamSessionId={this.state.jamSessionId}
                        onCloseModal={this.handleCloseModalJamSession}
                    />
                }
            </div>
        );
    }
}
