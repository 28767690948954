import React, { useEffect, useState } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { IJamSessionRoleDto, IRouteDetailedInfoDto, JamSessionSubscribeDto } from "../../../api/services.generated";
import { useAsyncEffect, useForceUpdate } from "../../../utils/react";
import { useAuthorizedBackendApi } from "../../../api/api";
import { useCurrentUser } from "../../../utils/login";
import { toast } from "react-toastify";
import { AsyncButton } from "../../../utils/AsyncButton";
import { useJamSession } from "./JamSessionContext";

export function JamSessionInscription2({ isReadonly }: { isReadonly: boolean }) {
    const [routes, setRoutes] = useState<IRouteDetailedInfoDto[] | "loading" | "error">("loading");
    const [isParticipating, setIsParticipating] = useState(false);
    const [roles, setRoles] = useState<(IJamSessionRoleDto & { checked: boolean })[]>([]);
    const [specialTableDescription, setSpecialTableDescription] = useState<string>();
    const [route, setRoute] = useState<string>();
    const [module, setModule] = useState<string>();
    const [project, setProject] = useState<string>();
    const [comment, setComment] = useState("");
    const { session } = useJamSession();
    const api = useAuthorizedBackendApi();
    const user = useCurrentUser();
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        if (!session) {
            setRoles([]);
        } else {
            const registration = session.registers?.find((x) => x.userId === user.userId);
            if (registration && session.jamSessionRoles) {
                setRoles(
                    (session?.jamSessionRoles || []).map((x) => ({
                        ...x,
                        checked: !!registration.roles?.some((r) => r.roleId === x.jamSessionRoleId),
                    }))
                );
            }
        }
    }, [session?.jamSessionRoles, session?.registers]);

    useEffect(() => {
        if (!session) {
            setIsParticipating(false);
            setComment("");
            setSpecialTableDescription("");
            setRoute("");
            setModule("");
            setProject("");
        } else {
            const registration = session.registers?.find((x) => x.userId === user.userId);
            if (registration) {
                setIsParticipating(true);
                setComment(registration.comment || "");
                setSpecialTableDescription(registration.specialTableDescription || "");
                setRoute(registration.routeId || "");
                setModule(registration.moduleId || "");
                setProject(registration.projectId || "");
            }
        }
    }, [session?.registers, user.userId]);

    useAsyncEffect(
        async ({ wrap }) => {
            if (!api) return;
            const r = await wrap(api.route_GetRoutesModules());
            if (!r) return;
            setRoutes(r);
        },
        [api],
        () => setRoutes("error")
    );

    function handleRolesToggle(clickedRoleId: string, checked: boolean) {
        if (!checked) {
            const index = roles.findIndex((o) => o.jamSessionRoleId === clickedRoleId);
            const item = roles[index];
            roles[index] = {
                jamSessionRoleId: item.jamSessionRoleId,
                jamSessionRoleName: item.jamSessionRoleName,
                jamSessionRoleSpecialTable: item.jamSessionRoleSpecialTable,
                checked: false,
            };
            forceUpdate();
            return;
        }

        if (roles.filter((f) => f.checked).length >= 2) {
            toast.warn("Vous ne pouvez pas sélectionner plus de 2 rôles!", {
                position: "top-center",
                hideProgressBar: false,
            });
            return;
        }

        if (
            roles.some((r) => r.checked && (r.jamSessionRoleSpecialTable || r.jamSessionRoleSpecialTable === false)) &&
            roles.some(
                (r) =>
                    r.jamSessionRoleId === clickedRoleId &&
                    (r.jamSessionRoleSpecialTable || r.jamSessionRoleSpecialTable === false)
            )
        ) {
            toast.warn("Vous ne pouvez pas sélectionner une table spéciale et ordinaire!", {
                position: "top-center",
                hideProgressBar: false,
            });
            return;
        }

        const registeredUser = session?.registers?.find(
            (x) => x.userId !== user.userId && x.roles?.some((r) => r.roleId === clickedRoleId)
        );
        if (registeredUser) {
            toast.info("Les membres suivants ont déjà sélectionné ce rôle : " + registeredUser.fullName + "!", {
                position: "top-left",
                hideProgressBar: false,
            });
            return;
        }

        const index = roles.findIndex((i) => i.jamSessionRoleId === clickedRoleId);
        const item = roles[index];

        roles[index] = {
            jamSessionRoleId: item.jamSessionRoleId,
            jamSessionRoleName: item.jamSessionRoleName,
            jamSessionRoleSpecialTable: item.jamSessionRoleSpecialTable,
            checked: true,
        };
        forceUpdate();
    }

    async function save() {
        if (!api || !session) return;
        await api.jamSession_RegisterJamSession(
            JamSessionSubscribeDto.fromJS({
                jamSessionId: session.sessionId,
                comment,
                moduleId: module,
                projectId: project,
                routeId: route,
                userId: user.userId,
                specialTableDescription,
                roles: roles.filter((x) => x.checked).map((x) => x.jamSessionRoleId!),
            })
        );
    }

    return (
        <div className="card pt-3 px-3" style={{ borderColor: "#3f51b5 !important" }}>
            {
                <>
                    {!session?.isRegistered && (
                        <div className="form-group row">
                            <label
                                className="col-md-8 pt-2 siteStyle text-primary font-weight-bolder"
                                style={{ fontSize: "1.15rem" }}
                            >
                                Souhaitez-vous participer à cette Jam Session ?
                            </label>
                            <div className="col-md-4">
                                <RadioGroup
                                    className="float-right"
                                    name="participation"
                                    row
                                    value={isParticipating}
                                    onChange={(_, value) => setIsParticipating(value === "true")}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio color="primary" disabled={isReadonly} />}
                                        label="Oui"
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio color="primary" disabled={isReadonly} />}
                                        label="Non"
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                    )}

                    {isParticipating && (
                        <React.Fragment>
                            <div className="card-title">
                                Quel rôle souhaitez vous avoir ? (Max 2)
                                <OverlayTrigger
                                    trigger="click"
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Les membres participants à la session peuvent choisir de s'inscrire pour
                                            jouer un role dans le déroulement de la session les rôles sont décris dans
                                            le document suivant :
                                            <a
                                                className="text-light"
                                                target="blank"
                                                href="https://docs.google.com/document/d/1GIyRO6Sp3gF6CcDC43nEMf8PpA4XXeyDO3ndB2A-xSg/edit?usp=sharing"
                                            >
                                                Cliquez sur le lien
                                            </a>
                                        </Tooltip>
                                    }
                                >
                                    <FontAwesomeIcon icon={faExclamationCircle} className="ml-2 pointer" />
                                </OverlayTrigger>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    {(roles || []).map((row, index) => (
                                        <div key={index} className="col-md-6 col-12">
                                            <div className="form-check form-check-inline">
                                                <Checkbox
                                                    color="primary"
                                                    onChange={(_, checked) =>
                                                        handleRolesToggle(row.jamSessionRoleId!, checked)
                                                    }
                                                    name="AttendingNextSession"
                                                    id={`customCheck${index}`}
                                                    value={row.jamSessionRoleId}
                                                    checked={row.checked}
                                                />
                                                <label className="form-check-label" htmlFor={`customCheck${index}`}>
                                                    {row.jamSessionRoleName}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {roles.some((r) => r.checked && r.jamSessionRoleSpecialTable === false) &&
                                routes !== "loading" &&
                                routes != "error" && (
                                    <React.Fragment>
                                        <div className="form-group row">
                                            <label className="col-md-3 font-weight-bold siteStyle">Parcours</label>
                                            <div className="col-md-9 mb-sm-0">
                                                <Select
                                                    value={route}
                                                    className="w-100"
                                                    onChange={(e) => setRoute(e.target.value as any)}
                                                    variant="filled"
                                                >
                                                    {(routes || []).map((row) => (
                                                        <MenuItem value={row.routeId!} key={row.routeId!}>
                                                            {row.routeName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-3 font-weight-bold siteStyle">Module</label>
                                            <div className="col-md-9">
                                                <Select
                                                    value={module}
                                                    className="w-100"
                                                    onChange={(e) => setModule(e.target.value as any)}
                                                    disabled={isReadonly}
                                                    variant="filled"
                                                >
                                                    {(routes.find((p) => p.routeId === route)?.modules || []).map(
                                                        (row) => (
                                                            <MenuItem value={row.moduleId!} key={row.moduleId!}>
                                                                {row.moduleName}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-3 font-weight-bold siteStyle">Projet</label>
                                            <div className="col-md-9">
                                                <Select
                                                    value={project}
                                                    className="w-100"
                                                    onChange={(e) => setProject(e.target.value as any)}
                                                    disabled={isReadonly}
                                                    variant="filled"
                                                >
                                                    {(routes.find((x) => x.routeId)?.projects || []).map((item) => (
                                                        <MenuItem value={item.projectId!} key={item.projectId!}>
                                                            {item.projectName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            {roles.some((r) => r.checked && r.jamSessionRoleSpecialTable) && (
                                <div className="form-group row mb-4">
                                    <label className="col-md-4 pt-1 font-weight-bold siteStyle">
                                        Quel défis voudrais-tu soumettre à la SuccessTeam ?
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="">
                                                    (La table spéciale te donne droit à 20-30 min de
                                                    Mastermind/Intelligence Collective sur ton projet ou un sujet
                                                    spécifique où tu souhaites la contribution des Akilis)
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon icon={faExclamationCircle} className="ml-2" />
                                        </OverlayTrigger>
                                    </label>
                                    <div className="col-md-8">
                                        <TextField
                                            value={specialTableDescription}
                                            name="specialTableDescription"
                                            variant="filled"
                                            disabled={isReadonly}
                                            multiline
                                            fullWidth
                                            label=""
                                            rows="4"
                                            onChange={(e) => setSpecialTableDescription(e.target.value)}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="form-group row">
                                <label className="col-md-3 font-weight-bold siteStyle">
                                    Commentaire
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="">
                                                (Attentes particulières par rapport à la session? Suggestion
                                                d'amélioration?)
                                            </Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon icon={faExclamationCircle} className="ml-2" />
                                    </OverlayTrigger>
                                </label>
                                <div className="col-md-9">
                                    <TextField
                                        value={comment}
                                        name="comment"
                                        variant="filled"
                                        multiline
                                        fullWidth
                                        rows="4"
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                    {!isReadonly && (
                                        <AsyncButton
                                            variant="contained"
                                            color="primary"
                                            className="text-capitalize btn-success-custom float-right mt-4"
                                            onClickAsync={save}
                                        >
                                            <FontAwesomeIcon icon={faSave} className="mr-2" /> Enregistrer
                                        </AsyncButton>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </>
            }
        </div>
    );
}
