import React, { Component } from 'react';
import { toast } from 'react-toastify';
import ReactDragListView from 'react-drag-listview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { VerifiedInput } from '../../global/VerifiedInput';
import Skeleton from '@material-ui/lab/Skeleton';

export default class CreateModule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingData: false,
            moduleId: "",
            moduleName: "",
            stepsSelected: [],
            steps:[],
            //dbSteps: [], 
            //checkboxArray: false,
            //checkboxes: []
        };

        //this.basicFunction = this.basicFunction.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.saveModule = this.saveModule.bind(this);
    }

    componentDidMount() {
        this.getSteps();
    }

    initFields() {
        this.setState({
            moduleId: "",
            moduleName: "",
            stepsSelected: [],
            loadingData:false
        })
    }

    getModule(moduleId) {
        this.setState({loadingData: true});

        fetch(new Request("api/Module/GetModule?moduleId=" + moduleId, {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        moduleId: moduleId,
                        moduleName: response.moduleName,
                        stepsSelected: response.steps || [],
                        steps: response.steps.concat(this.state.steps.filter(item => !response.steps.some(item2 => item2.stepId === item.stepId))),
                        loadingData: false
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    stepsSelected: [],
                    loadingData: false
                });
            });
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleCheckbox(step) {
        const index = (this.state.stepsSelected || []).findIndex(s => s.stepId === step.stepId);
        if (index >= 0) {
            var array = this.state.stepsSelected;
            array.splice(index, 1);
            this.setState({
                stepsSelected: array
            });
        } else {
            this.setState({
                stepsSelected: [...this.state.stepsSelected, step]
            });
        }
    }

    getSteps() {
        fetch(new Request("api/Step/GetSteps", {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        steps: response.filter(item => {return {"stepId": item.stepId, "stepName": item.stepName}})
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
                this.setState({
                    steps: []
                });
            });
    }

    saveModule() {
        if (!this.state.moduleName) {
            toast.warn("Le nom du module ne peut pas être vide !", {
                position: "top-center",
                hideProgressBar: false
            });

            return;
        }
        
        fetch(new Request("api/Module/SetModule", {
            method: "POST",
            body: JSON.stringify({
                ModuleId: this.state.moduleId,
                ModuleName: this.state.moduleName,
                Steps: this.state.steps.filter(item => this.state.stepsSelected.some(item2 => item2.stepId === item.stepId))
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                this.initFields();

                toast.success("Mise à jour effectuée !", {
                    position: "top-left",
                    hideProgressBar: false
                });

                this.props.refresh();
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    render() {
        const that = this;
        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...that.state.steps];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({ steps: data });
            },
            nodeSelector: 'li',
            handleSelector: 'a'
        };

        return (
            <React.Fragment>
                <div className="alert alert-secondary">
                    <Button variant="contained" size="small" className="mr-1 text-capitalize btn-success-custom" onClick={this.saveModule} disabled={this.state.loadingData}>
                        <FontAwesomeIcon icon={faSave} className="mr-1" size="sm" /> Enregistrer
                    </Button>
                </div>
                {
                    !this.state.loadingData ? 
                    <React.Fragment>
                        <div className="form-group">
                            <VerifiedInput
                                name="moduleName" title="Nom du module" value={this.state.moduleName}
                                checked={false} onChange={this.handleInput}
                                type="text" errorMessage=""
                            />
                        </div>

                        <div className="siteStyle text-white font-weight-bolder py-2" style={{backgroundColor: '#000', paddingLeft: 'calc(1.5rem + 9px)'}}>
                            <span>Etape</span>
                        </div>
                        <div className="table-fixing w-100 border">
                            {
                                this.state.steps.length > 0 ? 
                                    <ReactDragListView {...dragProps}>
                                        <ul className="siteStyle mb-0">
                                            {this.state.steps.map((row, index) => (
                                                <li className="py-0 px-3 border-bottom" key={index}>
                                                    <Checkbox className="mx-2" checked={(this.state.stepsSelected || []).some(s => s.stepId === row.stepId)}
                                                        color="primary" onChange={() => this.handleCheckbox(row)} size="small" />
                                                    {row.stepName}
                                                    <a className="float-right mt-2" href="#">Ordonner</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </ReactDragListView>
                                : null
                            }
                        </div>
                    </React.Fragment>
                    : <Skeleton />
                }
            </React.Fragment>
        );
    }
}
