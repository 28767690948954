import React, { Component, Context } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { JamSessionMemberNotStatusDto, BackendService } from '../../../api/services.generated'
import { AuthorizedBackendApiContext } from '../../../api/api'
import { toast } from 'react-toastify'

interface MyState {
    userPermissions:JamSessionMemberNotStatusDto[],
    membersSelected:JamSessionMemberNotStatusDto[],
    openDialog:boolean,
    members:JamSessionMemberNotStatusDto[],
    permissionId?:number,
    api:BackendService | null
}

interface MyProps {
    jamSessionId:string
}

export default class PermissionJamSession extends Component<MyProps,MyState> {

    static contextType:Context<BackendService | null> = AuthorizedBackendApiContext

    constructor(props:MyProps){
        super(props);

        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleMembersSelected = this.handleMembersSelected.bind(this);
        this.handlePermissionChanged = this.handlePermissionChanged.bind(this);
        this.handleSaveReporter = this.handleSaveReporter.bind(this);
    }

    state: MyState = {
        userPermissions:[],
        openDialog:false,
        membersSelected:[],
        members:[],
        api:null
    }

    componentDidMount(){
        this.setState({
            api: this.context as BackendService
        },()=>{
            this.getReporters();
            this.getMembersNotStatus();
        })
    }

    getReporters(){
        this.state.api?.jamSession_GetJamSessionReporters(this.props.jamSessionId)
        .then((response:JamSessionMemberNotStatusDto[] | null)=>{
            this.setState({
                userPermissions: response ? response : []
            })
        })
        .catch(error=>{
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        })
    }

    getMembersNotStatus(){
        this.state.api?.jamSession_GetJamSessionMembersNotStatus(this.props.jamSessionId).then((response:JamSessionMemberNotStatusDto[] | null)=>{
            this.setState({
                members:response !== null ? response : []
            })
        })
        .catch(error=>{
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        })
    }

    handleCloseDialog(){
        this.setState({
            openDialog:false
        })
    }

    handleOpenDialog(){
        this.setState({
            openDialog:true
        })
    }

    handleMembersSelected(e:React.ChangeEvent<{}>, values: any[]){
        this.setState({
            membersSelected:values
        })
    }

    handlePermissionChanged(e:any){
        this.setState({
            permissionId: e.target.value as number
        })
    }

    handleSaveReporter(){
        this.state.api?.jamSession_SetJamSessionPermissionReporter(this.props.jamSessionId,
            this.state.membersSelected.map((value:JamSessionMemberNotStatusDto)=>{
                return value.memberId && value.memberId !== undefined ? value.memberId?.toString() : ""
            }).filter(f=>f !== ""))
            .then(()=>{
                var data = this.state.userPermissions;
                var members =  this.state.members;
                var index:number;

                this.state.membersSelected.forEach((item:JamSessionMemberNotStatusDto)=>{
                    data.push(item);
                    index = members.findIndex(f=>f.memberId == item.memberId);
                    members.splice(index,1);
                });

                this.setState({
                    userPermissions:data,
                    membersSelected:[],
                    members:members
                })
            })
            .catch(error=>{
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            })
    }

    handleRemoveJamSessionReporter(memberId?:string | null){
        if(memberId && memberId !== undefined) {
            this.state.api?.jamSession_RemoveJamSessionReporter(this.props.jamSessionId,[memberId]).then(()=>{
                var index = this.state.userPermissions.findIndex(f=>f.memberId === memberId);
                var data = this.state.userPermissions;
                var value =  data[index];
                data.splice(index,1);
                this.setState({
                    members:[...this.state.members, value],
                    userPermissions: data
                });
            })
            .catch(error=>{
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-body">
                        <div className="card-title">Reporteurs</div>
                        <div className="alert alert-secondary">
                            <Button variant="contained" className="text-initial bg-white" onClick={this.handleOpenDialog} size="small">
                                <FontAwesomeIcon icon={faUserLock} className="mr-2" /> Ajouter
                            </Button>
                        </div>
                        <List>
                            {
                                this.state.userPermissions.map((member:JamSessionMemberNotStatusDto)=>(
                                    <ListItem key={member.memberId?.toString()}>
                                        <ListItemText primary={member.fullName} />
                                        <ListItemSecondaryAction>
                                            <IconButton onClick={()=>this.handleRemoveJamSessionReporter(member.memberId)} 
                                            style={{fontSize:"0.6em"}}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </div>
                </div>
                <Dialog open={this.state.openDialog} onClose={this.handleCloseDialog} fullWidth={true} maxWidth="md">
                    <DialogTitle>Reporteur</DialogTitle>
                    <DialogContent>
                        <div className="form-group">
                            <Autocomplete multiple={true} options={this.state.members} onChange={this.handleMembersSelected}
                                getOptionLabel={(o:JamSessionMemberNotStatusDto)=>o.fullName !== null && o.fullName !== undefined ? o.fullName : ""}
                                value={this.state.membersSelected} renderInput={(params)=>(
                                    <TextField {...params} variant="filled" label="Sélectionner membres" size="small" />
                                )} size="small" />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained" className="text-initial" onClick={this.handleSaveReporter} size="small">
                            <FontAwesomeIcon icon={faUserLock} className="mr-2" /> Sauvegarder
                        </Button>
                        <Button variant="contained" className="text-initial" onClick={this.handleCloseDialog} size="small">
                            <FontAwesomeIcon icon={faTimes} className="mr-2" /> Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}
