import React, { Component } from "react";
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCalendarAlt, faUser, faMapMarkerAlt, faAlignLeft, faEdit, faExclamationTriangle, faSync, faBars, faSortDown, faSave, faLock } from "@fortawesome/free-solid-svg-icons";
import JamSessionInscription from "./JamSessionInscription";
import EditJamSessionResponses from "./EditJamSessionResponses";
import { toast } from 'react-toastify';
import EditJamSessionAgendaMember from "./EditJamSessionAgendaMember";
import EditJamSessionUnsubscribe from "./EditJamSessionUnsubscribe";
import EditJamSessionInvitation from "./EditJamSessionInvitation";
import './jamSession.css';
import { connect } from "react-redux";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from "@material-ui/core/Chip";
import Avatar from "../../Avatar";


class EditJamSessionMember extends Component {

    constructor(props) {
        super(props);

        this.state = {
            jamSessionNumber: "",
            tabValue: 0,
            jamSessionRoles: [],
            theme:"",
            address: "",
            moderator: "",
            generalEvaluator: "",
            description: "",
            date: (new Date()).getFullYear() + "-" + ("0" + ((new Date()).getMonth() + 1)).slice(-2) + "-" + (new Date()).getDate(),
            hourFrom: "00:00",
            hourTo: "00:00",
            closed:null,
            //jamSessionInvitation: [],
            membersResponse: [],
            isRegistered: false, 
            showJamSessionRoles: false,
            agendas: [],
            unsubscribeOpenDialog: false,
            anchorEl:null
        }

        this.handleClose = this.handleClose.bind(this); 
        this.handleTabChange = this.handleTabChange.bind(this);
        this.toggleRegisteredState = this.toggleRegisteredState.bind(this);
        this.openUnsubscribeDialog = this.openUnsubscribeDialog.bind(this);
        this.closeUnsubscribeDialog = this.closeUnsubscribeDialog.bind(this);
        this.refreshJamSession = this.refreshJamSession.bind(this);
        this.handleCloseMenu = this.handleCloseMenu.bind(this);
        this.handleOpenMenu = this.handleOpenMenu.bind(this);
        this.handleEditJamSession = this.handleEditJamSession.bind(this);
        this.handleSaveJamSession = this.handleSaveJamSession.bind(this);
    }

    componentDidMount() {
        this.getJamSession();
    }

    handleClose() {
        this.props.onCloseModal(false);
    }

    handleTabChange(e, value) {
        this.setState({
            tabValue: value
        })
    }

    refreshJamSession() {
        this.getJamSession();
    }

    getJamSession() {
        fetch(new Request("api/JamSession/GetJamSession?jamSessionId=" + this.props.jamSessionId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    var date = new Date(response.jamSessionDate);
                    this.setState({
                        theme: response.theme,
                        jamSessionNumber: response.jamSessionNumber,
                        hourFrom: response.jamSessionHourFrom,
                        hourTo: response.jamSessionHourTo,
                        date: date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getDate(),
                        address: response.address,
                        description: response.description,
                        jamSessionRoles: response.jamSessionRoles !== null ? response.jamSessionRoles : [],
                        //jamSessionInvitation: response.invitation !== null ? response.invitation : [],
                        membersResponse: response.registers !== null ? response.registers : [],
                        isRegistered: response.isRegistered,
                        showJamSessionRoles: true,
                        moderator: response.moderator !== null ? response.moderator.fullName : "",
                        generalEvaluator: response.generalEvaluator !== null ? response.generalEvaluator.fullName : "",
                        agendas: response.agenda,
                        closed: response.closed
                    });

                    this.props.setInvitations(response.invitation !== null ? response.invitation : []);
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    toggleRegisteredState() {
        this.setState({
            isRegistered: true
        });
    }

    openUnsubscribeDialog() {
        this.setState({
            unsubscribeOpenDialog:true
        })
    }

    closeUnsubscribeDialog() {
        this.setState({
            unsubscribeOpenDialog: false
        });
    }

    handleCloseMenu() {
        this.setState({
            anchorEl: null
        });
    }

    handleOpenMenu(e) {
        this.setState({
            anchorEl: e.currentTarget
        });
    }

    handleEditJamSession() {
        this.props.onCloseModal(true);
    }

    handleSaveJamSession() {
        this.setState({
            anchorEl: null
        });
        this.child.saveRegister();
    }

    render() {

        return (
            <React.Fragment>
                <Dialog fullScreen open={true}>
                    <AppBar color="default" position="relative">
                        <Toolbar>
                            <div className="row" style={{ flex: 1 }}>
                                <div className="col-sm-6 col-12">
                                    <IconButton edge="start" color="inherit" aria-label="close" onClick={this.handleClose}>
                                        <FontAwesomeIcon icon={faTimesCircle} />
                                    </IconButton>
                                    <img style={{ height: "35px" }} src="./img/logo-successteam.png" alt="logo" />
                                    <span className="ml-1"><strong>Jam-Session N° {this.state.jamSessionNumber}</strong></span>
                                </div>
                                {
                                    this.state.closed !== null &&
                                    <React.Fragment>
                                        {
                                            !this.state.closed ?
                                                <div className="col-sm-3 offset-sm-2 col-12 my-2 text-right">
                                                    {
                                                        this.state.isRegistered ?
                                                            <React.Fragment>
                                                                <Button variant="contained" className="text-capitalize bg-white mr-2" aria-controls="optionMenu"
                                                                    onClick={this.handleOpenMenu} aria-haspopup="true">
                                                                    <FontAwesomeIcon icon={faBars} className="mr-2" /> Option
                                                                    <FontAwesomeIcon icon={faSortDown} className="ml-2" />
                                                                </Button>
                                                                <Menu id="optionMenu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)}
                                                                    keepMounted onClose={this.handleCloseMenu}>

                                                                    <MenuItem onClick={this.handleSaveJamSession}>
                                                                        <FontAwesomeIcon icon={faSave} className="mr-2" /> Mettre à jour
                                                                    </MenuItem>
                                                                    {
                                                                        this.props.isAdmin && <MenuItem onClick={this.handleEditJamSession}>
                                                                            <FontAwesomeIcon icon={faEdit} className="mr-2" /> Editer
                                                                    </MenuItem>
                                                                    }
                                                                    <MenuItem onClick={this.handleCloseMenu}>
                                                                        <Button variant="contained" className="text-capitalize mr-2 btn-warning-custom text-white" onClick={this.openUnsubscribeDialog}>
                                                                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" /> Désinscrire
                                                                         </Button>
                                                                    </MenuItem>
                                                                </Menu>
                                                                <Button variant="contained" className="text-capitalize btn-info-custom" onClick={this.refreshJamSession}>
                                                                    <FontAwesomeIcon icon={faSync} className="mr-2" /> Rafraîchir
                                                                </Button>
                                                            </React.Fragment> :
                                                            <Button variant="contained" className="btn-success-custom text-capitalize" onClick={this.handleSaveJamSession}>
                                                                <FontAwesomeIcon icon={faSave} className="mr-2" /> Enregistrer
                                                            </Button>
                                                    }
                                                </div> :
                                                <div className="col-sm-3 offset-sm-2 col-12 my-2 text-right">

                                                    <Chip label="Cloturé"
                                                        icon={<FontAwesomeIcon icon={faLock} className="ml-2" />} />
                                                </div>
                                        }

                                        <div className="col-sm-1 text-right mt-2" style={{ position: 'absolute', right: '15px' }}>
                                            <Avatar />
                                        </div>
                                    </React.Fragment>
                                }
                                
                            </div>
                        </Toolbar>
                    </AppBar>
                    <div className="container-fluid mt-0">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img src="https://www.seneplus.com/sites/default/files/raw_photos/240_f_128451805_lbotgcyubnohhykeyjmgowyytaecyst9.jpg" className="img-fluid" alt="ads bar" />
                            </div>
                            <div className={this.state.tabValue !== 1 ? "col-md-12" : "col-md-8"}>
                                <Tabs className="mb-3" indicatorColor="primary" textColor="primary" variant="scrollable"
                                    value={this.state.tabValue} onChange={this.handleTabChange} scrollButtons="on"
                                    style={{ backgroundColor: "#F5F5F5"}}
                                >
                                    <Tab label="Inscription" className="text-capitalize" />
                                    <Tab label="Participants" className="text-capitalize" />
                                    <Tab label="Agenda" className="text-capitalize" />
                                </Tabs>
                                <div role="tabpanel" hidden={this.state.tabValue !== 0}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mx-3">
                                                <div>
                                                    <div className="form-group row">
                                                        <div className="col-md-1 col-1">
                                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                                        </div>
                                                        <label className="col-md-11 col-10">
                                                            {this.state.date} de {this.state.hourFrom.substring(0, 5)} à {this.state.hourTo.substring(0, 5)}
                                                        </label>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-md-1 col-1">
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </div>
                                                        <label className="col-md-11 col-10"><span className="font-weight-bold">Thème:</span> {this.state.theme}</label>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-md-1 col-1">
                                                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                        </div>
                                                        <label className="col-md-11 col-10">{this.state.address}</label>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-md-1 col-1">
                                                            <FontAwesomeIcon icon={faUser} />
                                                        </div>
                                                        <label className="col-md-11 col-10"><span className="font-weight-bold">Modérateur: </span>{this.state.moderator}</label>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-md-1 col-1">
                                                            <FontAwesomeIcon icon={faUser} />
                                                        </div>
                                                        <label className="col-md-11 col-10"><span className="font-weight-bold">Evaluateur Général: </span>{this.state.generalEvaluator}</label>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-md-1 col-1">
                                                            <FontAwesomeIcon icon={faAlignLeft} />
                                                        </div>
                                                        <div className="col-md-11 col-10">
                                                            <div className="form-group">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <p>{this.state.description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-8 mb-2">
                                            {
                                                this.state.showJamSessionRoles && < JamSessionInscription jamSessionRoles={this.state.jamSessionRoles} isAdmin={false} onRef={ref=>(this.child = ref)}
                                                    jamSessionId={this.props.jamSessionId} isRegistered={this.state.isRegistered} handleRegistredState={this.toggleRegisteredState} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" hidden={this.state.tabValue !== 1}>

                                    {
                                        this.state.tabValue === 1 && <EditJamSessionResponses responses={this.state.membersResponse}
                                            jamSessionId={this.props.jamSessionId} isAdmin={false} />
                                    }
                                </div>
                                <div role="tabpanel" hidden={this.state.tabValue !== 2}>
                                    <EditJamSessionAgendaMember agendas={this.state.agendas} beginHour={this.state.hourFrom} />
                                </div>
                            </div>

                            {
                                this.state.tabValue === 1 && this.state.jamSessionId !== "" && <div className="col-md-4 border-left">
                                    <div className="h6 font-weight-bolder siteStyle mb-2">Invitez vos contacts à la JamSession</div>
                                    <EditJamSessionInvitation jamSessionId={this.props.jamSessionId} isAdmin={false} />
                                </div>
                            }
                        </div>
                    </div>
                </Dialog>

                {
                    this.state.unsubscribeOpenDialog && <EditJamSessionUnsubscribe
                        jamSessionId={this.props.jamSessionId} closeDialogUnsubscrition={this.closeUnsubscribeDialog} />
                }
            </React.Fragment>
        );
    }
}

const MapDispatchToProps = dispatch => {
    return {
        setInvitations: data => {
            dispatch({
                type: "FILL_INVITATION",
                invitatedList: data
            });
        }
    };
}

const MapStateToProps = state => {
    return {
        isAdmin: state.isAdmin
    }
}

export default connect(MapStateToProps, MapDispatchToProps)(EditJamSessionMember);
