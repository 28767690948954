import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { useAuthorizedBackendApi } from '../../../api/api';
import { BackendService, MemberSimpleDataDto, ActivitySimpleDto, MainJamSessionUserDto, SessionAgendaDto, FileResponse } from '../../../api/services.generated';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';

interface MyProps {
    jamSessionId:string,
    agendas:SessionAgendaDto[]
}

export default function EditJamSessionAgenda(props:MyProps) {

    const api: BackendService | null = useAuthorizedBackendApi();

    const [columns, setColumns] = useState<any>([]);
    const [data, setData] = useState<SessionAgendaDto[]>(props.agendas);
    //const [members, setMembers] = useState<MemberSimpleDataDto[]>([]);
    const [activities, setActivities] = useState<ActivitySimpleDto[]>([]);
    const [membersSent, setMembersSent] = useState<MemberSimpleDataDto[]>([]);

    

    useEffect(()=>{
        
        loadContent();  

    },[]);//,[activities,members,columns]); 

    const  loadContent = async () => {
        const _activities: ActivitySimpleDto[] | null = await getActivitiesRole();
        getMembersRegister(_activities ? _activities : []);
        setActivities(_activities ? _activities : []);
    }

    async function getMembersRegister(_activities:ActivitySimpleDto[]){
        
        try
        {
            if(!api)
                return;

            //return api.member_GetMembersRegisterJamSession(props.jamSessionId);
            const response: MemberSimpleDataDto[] | null = await api.member_GetMembersRegisterJamSession(props.jamSessionId);
            //setMembers(response ? response : []);
            setColumns([{
                title:'Activité', field:'activityId',
                render:(rowData:any)=>rowData.activityName,
                editComponent:(props:any)=>{
                    return <TextField select label="Activité" value={props.value === undefined ? '' : props.value}
                    onChange={e => props.onChange(e.target.value)} margin="normal" fullWidth>
                        {
                            _activities.map((item:ActivitySimpleDto)=>(
                                <MenuItem key={item.activityId?.toString()} value={item.activityId ? item.activityId : ''}>{item.activityName}</MenuItem>
                            ))
                        }
                    </TextField>
                }
            },
            {
                title:'Membres',field:'members',
                render:(rowData:SessionAgendaDto)=>(rowData.members || []).map((member:MainJamSessionUserDto)=>(
                    <Chip key={member.id?.toString()} label={member.fullName} size="small" />
                )),
                editComponent:(props:any)=>(
                    <Autocomplete multiple={true} getOptionLabel={(o:MemberSimpleDataDto)=>o.fullName ? o.fullName : ""} 
                    options={response ? response : []} style={{ fontSize: "10px" }}
                    onChange={(e: React.ChangeEvent<{}>, values: MemberSimpleDataDto[]) => { onTagsChange(e, values); props.onChange(values); }}
                    value={ props.value !== undefined ? props.value.map((item:MainJamSessionUserDto)=>{
                        return response?.find(f=>f.id === item.id) 
                    }) : [] } 
                    renderInput={(params:AutocompleteRenderInputParams)=>(
                        <TextField {...params} variant="standard" label="Membres" placeholder="Choisir..." fullWidth />
                    )} />
                )},
                {
                    title: 'Durée', field: 'timing',
                    editComponent: (props:any) => (
                        <TextField
                            label="Timing"
                            type="time"
                            //InputLabelProps={{ shrink: true, }}
                            inputProps={{ step: 60 }}
                            value={props.value === undefined ? "00:00" : props.value}
                            onChange={e => props.onChange(e.target.value)}
                        />
                    )
                }])
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
    }

    function getActivitiesRole():Promise<ActivitySimpleDto[] | null>{
        if(!api){
            return Promise.resolve([]);
        }

        try
        {
            return api.activity_GetActivitiesData();
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
            return Promise.resolve([]);
        }
    }

    async function addJamSessionAgenda(activityId:string | null, membersSent:MemberSimpleDataDto[], timing:string, newData:SessionAgendaDto){

        try
        {
            const members = membersSent.map((item:MemberSimpleDataDto)=>{
                return {
                    id:item.id
                } as MainJamSessionUserDto
            });
            const agendaId: string | null | undefined = await jamSessionAddJamSessionAgenda(null,props.jamSessionId,members, 
                activityId, timing);

            if(!agendaId || agendaId === undefined){
                return;
            }
            toast.success("Modification effectuée !", {
                hideProgressBar: false,
                position: "top-left"
            });
            const activityName: string | null | undefined = activities.find(a=>a.activityId == newData.activityId)?.activityName;
            const _data = {
                jamSessionAgendaId:agendaId,
                members:membersSent.map((item:MemberSimpleDataDto)=>{
                    return {
                        id:item.id,
                        fullName:item.fullName
                    } as MainJamSessionUserDto
                }),
                activityName: activityName,
                activityId:newData.activityId,
                timing:newData.timing,
                jamSessionId:undefined
            } as SessionAgendaDto;
            setData([...data,_data]);
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
        
    }

    async function updateJamSessionAgenda(activityId:string | null, membersSent:MemberSimpleDataDto[],timing:string, agendaId?:string | null){
        try
        {
            const members = membersSent.map((item:MemberSimpleDataDto)=>{
                return {
                    id:item.id
                } as MainJamSessionUserDto
            });
            const responseAgendaId: string | null | undefined = await jamSessionAddJamSessionAgenda(agendaId !== undefined ? agendaId : null,
                props.jamSessionId, members, activityId ? activityId : null, timing);

            if(!responseAgendaId || responseAgendaId === undefined){
                return;
            }

            toast.success("Modification effectuée !", {
                hideProgressBar: false,
                position: "top-left"
            });

            /*const index : number = data.findIndex(d=>d.jamSessionAgendaId === responseAgendaId);
            var _data : Agenda[] = data;
            _data[index].activityName = activityName;
            _data[index].activityId = activityId;
            _data[index].timing = timing;
            _data[index].members = membersSent.map((item:MemberSimpleDataDto)=>item.fullName ? item.fullName : "");
            setData(_data);*/
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
    }

    function jamSessionAddJamSessionAgenda(agendaId:string | null,jamSessionId:string,members: MainJamSessionUserDto[],
        activityId:string | null, timing:string):Promise<string | null> | undefined{
        return api?.jamSession_AddJamSessionAgenda({
            jamSessionAgendaId:agendaId,
            jamSessionId: jamSessionId,
            members: members,
            activityId:activityId,
            timing:timing
        } as SessionAgendaDto);
    }

    async function deleteJamSessionAgenda(agendaId?:string | null){
        if(!api || !agendaId){
            return;
        }

        try
        {
            await api.jamSession_RemoveJamSessionAgenda(props.jamSessionId, agendaId);

            toast.success("Suppression effectuée !", {
                hideProgressBar: false,
                position: "top-left"
            });

            const index:number = data.findIndex(f=>f.jamSessionAgendaId === agendaId);
            const dataDelete = [...data];
            dataDelete.splice(index,1);
            setData([...dataDelete]);
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
    }

    function onTagsChange(event:any, values:MemberSimpleDataDto[]) {
        setMembersSent(values);
    }

    return (
        <MaterialTable title="Agenda" columns={columns} data={data ? data : []} style={{padding:"10px"}}
            editable={{
                onRowAdd:(newData:SessionAgendaDto)=>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            addJamSessionAgenda(newData.activityId ? newData.activityId : null, membersSent, newData.timing, newData);
                        }, 1500);
                    }),
                onRowUpdate: (newData:SessionAgendaDto, oldData:SessionAgendaDto | undefined) =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            updateJamSessionAgenda(newData.activityId ? newData.activityId : null, membersSent, newData.timing, oldData?.jamSessionAgendaId);
                        }, 1500);
                    }),
                onRowDelete: (oldData:SessionAgendaDto) =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            
                            deleteJamSessionAgenda(oldData.jamSessionAgendaId);
                            resolve();
                        }, 600);
                    })
            }} 
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} sur {count}',
                    firstTooltip: "Première page",
                    previousTooltip: "Précédent",
                    nextTooltip: "Suivant",
                    lastTooltip: "Dernière page",
                    labelRowsSelect: "lignes"
                },
                toolbar: {
                    nRowsSelected: '{0} lignes(s) selectionnées',
                    searchTooltip: "Rechercher",
                    searchPlaceholder: "Rechercher"
                },
                header: {
                    actions: 'Actions'
                },
                body: {
                    addTooltip: "Ajouter",
                    deleteTooltip: "Supprimer",
                    editTooltip: "Modifier",
                    emptyDataSourceMessage: 'Aucun enregistrement à afficher',
                    filterRow: {
                        filterTooltip: 'Trier'
                    },
                    editRow: {
                        saveTooltip: "Enregistrer",
                        deleteText: "Voulez-vous vraiment supprimer cet enregistrement?",
                        cancelTooltip: "Annuler"
                    }
                }
            }} />        
    )
}


