import { createContext, useContext } from "react";

export type AvatarDownloader = {
    getAvatar: (userId: string) => Promise<string | undefined>;
};
export const AvatarDownloadContext = createContext<AvatarDownloader>(undefined!);

async function downloadAvatar(userId: string) {
    try {
        const response = await fetch(new Request("api/Member/DownloadAvatar?memberId=" + userId), {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            }),
        });
        const [ok, response2] = [response.ok, await response.json()];
        if (ok) {
            return response2.image;
        } else {
            throw new Error(response2);
        }
    } catch (e) {
        console.log(e);
        return undefined;
    }
}

export function makeAvatarDownloader() {
    const downloaded = new Map<string, Promise<string | undefined>>(); // userId => image
    return {
        getAvatar: async function (userId: string): Promise<string | undefined> {
            const existing = downloaded.get(userId);
            if (existing) {
                return existing;
            }

            const downloadPromise = downloadAvatar(userId);
            downloaded.set(userId, downloadPromise);
            return downloadPromise;
        },
    };
}

export function useAvatarDownloader() {
    return useContext(AvatarDownloadContext);
}
