import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faEnvelope, faCheck } from '@fortawesome/free-solid-svg-icons';
import MailSubscribe from './MailSubscribe';

export function Footer({isShown} : {isShown: boolean}) {
    return (
        <div>
            {
                isShown ? 
                <footer className="pt-5 pb-4 px-4" id="contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6 mt-2 mb-4">
                                <h5 className="mb-4 font-weight-bold">A PROPOS</h5>
                                <Link to="/home" className="py-2 pr-2 pointer">
                                    <img style={{ height: "35px" }} src="./img/logo-successteam.png" alt="logo" />
                                    <strong className="ml-2 text-initial" style={{ fontSize: "1.35em", position: "relative", top: "5px" }}>
                                        <span style={{ color: "#3f51b5" }}>Success</span> <span style={{ color: "#fd7419" }}>Team</span>
                                    </strong>
                                </Link>
                                <p className="text-white mt-2">
                                    Nous sommes une communauté de porteurs de projets qui se développent
                                    et développent leurs projets dans un esprit de collaboration la régularité
                                    et l’excellence.
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 mt-2 mb-4">
                                <h5 className="mb-4 font-weight-bold">ADRESSE</h5>
                                <ul className="f-address">
                                    <li>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <FontAwesomeIcon icon={faMapMarker} className="mr-2" />
                                                <span className="text-white">
                                                    Avenue de la couronne 127, Batiment i 2e étage, Bruxelles, Belgique.
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <div className="mt-0">
                                                    <Button component={Link} to="/contact" className="text-initial btn-warning-custom" style={{ border: "2px solid white" }} variant="contained" size="small">
                                                        Contactez-nous
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 mt-2 mb-4">
                                <h5 className="mb-4 font-weight-bold">AVANCEZ AVEC NOUS</h5>
                                <p className="text-white">
                                    Cliquez sur l'un des liens ci-dessous afin de suivre notre actualité sur les réseaux sociaux.
                                </p>
                                <ul className="social-pet mt-2">
                                    <li><a href="https://www.facebook.com/TheSuccessTeams/" target="_blank" rel="noopener noreferrer" title="facebook"><i className="fab fa-facebook-f" /></a></li>
                                    <li><a href="https://www.instagram.com/thesuccessteaminternational/?hl=fr" target="_blank" rel="noopener noreferrer" title="instagram"><i className="fab fa-instagram" /></a></li>
                                    <li><a className="bg-white text-dark" href="https://www.linkedin.com/company/thesuccessteams/" target="_blank" rel="noopener noreferrer" title="linkedin"><i className="fab fa-linkedin" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer> : null
            }
            
            {/* Copyright */}
            <section className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="text-center text-white">
                                © 2020 Success Team ASSOCIATION. Tous droits reservés.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
