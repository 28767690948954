import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faSave, faSync } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';

class JamSessionInscription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roles: [],
            routes: [],
            modules:[],
            projects:[],
            routeValue: "",
            moduleValue: "",
            projectValue: "",
            dataOrdinaryTableLoaded: false,
            btnSaveLoading: false,
            specialTableDescription: "",
            comment: "",
            participation: this.props.isRegistered !== null ? this.props.isRegistered : true,
            //openDialogNotParticipation: false,
            loadParticipation: false,
            showChoiceParticipation: this.props.isRegistered !== null ? !this.props.isRegistered : true
        }

        this.handleRolesToggle = this.handleRolesToggle.bind(this);
        this.getRouteProjets = this.getRouteProjets.bind(this);
        this.getModules = this.getModules.bind(this);
        this.handleProjectSelected = this.handleProjectSelected.bind(this);
        this.saveRegister = this.saveRegister.bind(this); 
        this.handleInput = this.handleInput.bind(this);
        this.handleRadio = this.handleRadio.bind(this);
        this.handleParticipationSelect = this.handleParticipationSelect.bind(this);
    }

    async componentDidMount() {
        this.setState({ loadParticipation: true });
        if (this.props.onRef !== undefined) {
            this.props.onRef(this);
        }
        
        var array = [];
        (this.props.jamSessionRoles || []).map(item =>
            array.push({
                jamSessionRoleId: item.jamSessionRoleId,
                jamSessionRoleName: item.jamSessionRoleName,
                jamSessionRoleSpecialTable: item.jamSessionRoleSpecialTable,
                checked: false
            })
        );
        this.setState({ roles: array });

        if (this.props.isRegistered) {
            await this.fillRoutes();
            await this.getMemberInscription();

            if (this.state.routeValue !== null && this.state.routeValue !== "") {
                this.setState({
                    modules: this.state.routes.find(p => p.routeId === this.state.routeValue).modules,
                    projects: this.state.routes.find(p => p.routeId === this.state.routeValue).projects
                });
            }
        }
        this.setState({ loadParticipation: false });
    }

    componentWillUnmount() {
        if (this.props.onRef !== undefined) {
            this.props.onRef(undefined);
        }
        
    }

    handleRolesToggle(e) {
        var index,item,array;
        if (this.state.roles.filter(f => f.checked).length < 2 && e.target.checked) {
            if (this.state.roles.some(r => r.checked && (r.jamSessionRoleSpecialTable || r.jamSessionRoleSpecialTable === false)) &&
                this.state.roles.some(r => r.jamSessionRoleId === e.target.value && (r.jamSessionRoleSpecialTable || r.jamSessionRoleSpecialTable === false))) {
                e.target.checked = false;
                toast.warn("Vous ne pouvez pas sélectionner une table spéciale et ordinaire!", {
                    position: "top-center",
                    hideProgressBar: false
                });
            } else {
                index = this.state.roles.findIndex(i => i.jamSessionRoleId === e.target.value);
                array = this.state.roles;
                item = array[index];

                array[index] = {
                    jamSessionRoleId: item.jamSessionRoleId,
                    jamSessionRoleName: item.jamSessionRoleName,
                    jamSessionRoleSpecialTable: item.jamSessionRoleSpecialTable,
                    checked: true
                };
                this.setState({
                    roles: array
                });

                this.checkRoleSelected(item.jamSessionRoleId);

                if (!this.state.dataOrdinaryTableLoaded && item.jamSessionRoleSpecialTable === false) {
                    this.setState({
                        dataOrdinaryTableLoaded: true
                    });
                    this.fillRoutes();
                }
            }
        }
        else {
            if (!e.target.checked) {
                array = this.state.roles;
                index = array.findIndex(o => o.jamSessionRoleId === e.target.value);
                item = array[index];
                array[index] = {
                    jamSessionRoleId: item.jamSessionRoleId,
                    jamSessionRoleName: item.jamSessionRoleName,
                    jamSessionRoleSpecialTable: item.jamSessionRoleSpecialTable,
                    checked: false
                };
                this.setState({
                    roles: array
                })
            } else {
                e.target.checked = false;
                toast.warn("Vous ne pouvez pas sélectionner plus de 2 rôles!", {
                    position: "top-center",
                    hideProgressBar: false
                });
            }
        }  
    }

    checkRoleSelected(roleId) {
        fetch(new Request("api/JamSession/CheckRoleSelected?JamSessionId=" + this.props.jamSessionId +"&RoleId=" + roleId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    if (response !== null) {
                        toast.info("Les membres suivants ont déjà sélectionné ce rôle : " + response.map(i=>i.fullName).join(", ") + "!", {
                            position: "top-left",
                            hideProgressBar: false
                        });
                    }
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    fillRoutes() {
        return fetch(new Request("api/Route/GetRoutesModules", {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        routes: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    getRouteProjets(e) {
        this.setState({
            routeValue: e.target.value,
            modules: this.state.routes.find(p => p.routeId === e.target.value).modules,
            projects: this.state.routes.find(p => p.routeId === e.target.value).projects
        });
    }

    getModules(e) { 
        this.setState({
            moduleValue: e.target.value
        });
    }

    handleProjectSelected(e) {
        this.setState({
            projectValue: e.target.value
        });
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleRadio(e) {
        if(this.props.hideSaveJamSession !== undefined){
            this.props.hideSaveJamSession(e.target.value !== "true");
        }
        
        this.setState({
            [e.target.name]: e.target.value === "true"
        });
        //if (e.target.value === "true") {
        //    this.setState({
        //        [e.target.name]: true
        //    });
        //}
        //else {
        //    this.setState({
        //        [e.target.name]: false
        //        //openDialogNotParticipation:true
        //    });
        //}
    }

    saveRegister() {
        this.setState({
            btnSaveLoading: true
        });

        fetch(new Request("api/JamSession/RegisterJamSession", {
            method: "POST",
            body: JSON.stringify({
                AttendingNextSession: this.state.roles.filter(f => f.checked),
                SpecialTableDescription: this.state.roles.some(r => r.checked && r.jamSessionRoleSpecialTable) ? this.state.specialTableDescription : null,
                Comment: this.state.comment,
                jamSessionId: this.props.jamSessionId,
                RouteId: this.state.roles.some(r => r.checked && r.jamSessionRoleSpecialTable === false) ? this.state.routeValue : null,
                ModuleId: this.state.roles.some(r => r.checked && r.jamSessionRoleSpecialTable === false) ? this.state.moduleValue : null,
                ProjectId: this.state.roles.some(r => r.checked && r.jamSessionRoleSpecialTable === false) ? this.state.projectValue : null
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    toast.success("La Jam Session a été modifiée!", {
                        position: "top-center",
                        hideProgressBar: false
                    });

                    if(response.notifyRegularTableRequestor){
                        this.notifyRegularTableRequestor();
                    }

                    this.setState({
                        showChoiceParticipation: false
                    });

                    if (this.props.participationJamSession !== null) {
                        this.props.ResetParticipation();
                    }

                    if (this.props.isAdmin !== undefined && !this.props.isAdmin && !this.props.isRegistered) {
                        this.props.handleRegistredState();
                    }
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    btnSaveLoading: false
                });
            });
    }

    notifyRegularTableRequestor(){
        fetch(new Request("api/JamSession/SendMessageTemplate", {
            method: "POST",
            body: JSON.stringify(this.props.jamSessionId),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }));
    }

    getMemberInscription() {
        return fetch(new Request("api/JamSession/GetRegisterJamSession?jamSessionId=" +
            this.props.jamSessionId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    var array = this.state.roles;
                    if (response.attendingNextSession !== null) {
                        var index;
                        response.attendingNextSession.forEach(item => {
                            index = array.findIndex(f => f.jamSessionRoleId === item.jamSessionRoleId);
                            if (index > -1) {
                                array[index].checked = true;
                            }

                        });
                    }

                    this.setState({
                        roles: array,
                        routeValue: response.routeId,
                        moduleValue: response.moduleId,
                        projectValue: response.projectId,
                        comment: response.comment || "",
                        specialTableDescription: response.specialTableDescription
                        //participation: response.participation
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    handleParticipationSelect() {
        this.setState({
            participation: true
            //openDialogNotParticipation:false
        });
    }

    handleShowChoiceParticipation(status) {
        this.setState({
            showChoiceParticipation: status
        });
    }

    render() {
        return (
            <div className="card pt-3 px-3" style={{ borderColor: "#3f51b5 !important" }}>
                {
                    !this.state.loadParticipation ? 
                        <React.Fragment>
                            {
                                this.state.showChoiceParticipation &&
                                <div className="form-group row">
                                    <label className="col-md-8 pt-2 siteStyle text-primary font-weight-bolder" style={{fontSize: '1.15rem'}}>
                                        Souhaitez-vous participer à cette Jam Session ?
                                    </label>
                                    <div className="col-md-4">
                                        <RadioGroup className="float-right" name="participation" row value={this.state.participation} onChange={this.handleRadio}>
                                            <FormControlLabel value={true} control={<Radio color="primary" />} label="Oui" disabled={this.props.jamSessionIsReading === "true"} />
                                            <FormControlLabel value={false} control={<Radio color="primary" />} label="Non" disabled={this.props.jamSessionIsReading === "true"} />
                                        </RadioGroup>
                                    </div>
                                </div>
                            }
                            
                            {
                                this.state.participation && 
                                <React.Fragment>
                                    <div className="card-title siteStyle">
                                        Quel rôle souhaitez vous avoir ? (Max 2)
                                        <OverlayTrigger trigger="click" placement="bottom" overlay={
                                            <Tooltip>
                                                Les membres participants à la session peuvent choisir de s'inscrire pour jouer un role dans le déroulement de la session les rôles sont décris dans le document suivant :
                                                <a className="text-light" target="blank" href="https://docs.google.com/document/d/1GIyRO6Sp3gF6CcDC43nEMf8PpA4XXeyDO3ndB2A-xSg/edit?usp=sharing"> Cliquez sur le lien</a>
                                            </Tooltip>
                                        }>
                                            <FontAwesomeIcon icon={faExclamationCircle} className="ml-2 pointer" />
                                        </OverlayTrigger>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            {
                                                (this.state.roles || []).map((row, index) => (
                                                    <div key={index} className="col-md-6 col-12">
                                                        <div className="form-check form-check-inline">
                                                            <Checkbox color="primary" onChange={this.handleRolesToggle} name="AttendingNextSession"
                                                                id={`customCheck${index}`} value={row.jamSessionRoleId} checked={row.checked}
                                                                disabled={this.props.jamSessionIsReading === "true"} />
                                                            <label className="form-check-label siteStyle" htmlFor={`customCheck${index}`}>{row.jamSessionRoleName}</label>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    {
                                        this.state.roles.some(r => r.checked && r.jamSessionRoleSpecialTable === false) &&
                                        <React.Fragment>
                                            <div className="form-group row">
                                                <label className="col-md-3 font-weight-bold siteStyle">Parcours</label>
                                                <div className="col-md-9 mb-sm-0">
                                                    <Select value={this.state.routeValue} className="w-100" onChange={this.getRouteProjets}
                                                        disabled={this.props.jamSessionIsReading === "true"} variant="filled">
                                                        {
                                                            (this.state.routes || []).map(row => (
                                                                <MenuItem value={row.routeId} key={row.routeId}>
                                                                    {row.routeName}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-3 font-weight-bold siteStyle">Module</label>
                                                <div className="col-md-9">
                                                    <Select value={this.state.moduleValue} className="w-100" onChange={this.getModules}
                                                        disabled={this.props.jamSessionIsReading === "true"} variant="filled">
                                                        {
                                                            (this.state.modules || []).map(row => (
                                                                <MenuItem value={row.moduleId} key={row.moduleId}>
                                                                    {row.moduleName}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-3 font-weight-bold siteStyle">Projet</label>
                                                <div className="col-md-9">
                                                    <Select value={this.state.projectValue} className="w-100" onChange={this.handleProjectSelected}
                                                        disabled={this.props.jamSessionIsReading === "true"} variant="filled">
                                                        {
                                                            (this.state.projects || []).map((item, index) => (
                                                                <MenuItem value={item.projectId} key={index}>{item.projectName}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {
                                        this.state.roles.some(r => r.checked && r.jamSessionRoleSpecialTable) && <div className="form-group row mb-4">
                                            <label className="col-md-4 pt-1 font-weight-bold siteStyle">
                                                Quel défis voudrais-tu soumettre à la SuccessTeam ?
                                                    <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                                    <Tooltip>
                                                        (La table spéciale te donne droit à 20-30 min de Mastermind/Intelligence Collective sur ton projet ou un sujet spécifique où tu souhaites la contribution des Akilis)
                                                        </Tooltip>
                                                }>
                                                    <FontAwesomeIcon icon={faExclamationCircle} className="ml-2" />
                                                </OverlayTrigger>
                                            </label>
                                            <div className="col-md-8">
                                                <TextField value={this.state.specialTableDescription} name="specialTableDescription" variant="filled"
                                                    disabled={this.props.jamSessionIsReading === "true"} multiline fullWidth label=""
                                                    rows="4" onChange={this.handleInput} />
                                            </div>

                                        </div>
                                    }
                                    <div className="form-group row">
                                        <label className="col-md-3 font-weight-bold siteStyle">
                                            Commentaire
                                                <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                                <Tooltip>
                                                    (Attentes particulières par rapport à la session? Suggestion d'amélioration?)
                                                    </Tooltip>
                                            }>
                                                <FontAwesomeIcon icon={faExclamationCircle} className="ml-2" />
                                            </OverlayTrigger>
                                        </label>
                                        <div className="col-md-9">
                                            <TextField value={this.state.comment} name="comment" variant="filled"
                                                disabled={this.props.jamSessionIsReading === "true"} multiline fullWidth
                                                rows="4" onChange={this.handleInput} />
                                            {
                                                this.props.jamSessionIsReading === "false" && <Button variant="contained" className="text-capitalize btn-success-custom float-right mt-4" disabled={this.state.btnSaveLoading} onClick={this.saveRegister}>
                                                    <FontAwesomeIcon icon={faSave} className="mr-2" /> Enregistrer
                                                    </Button>
                                            }
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                        : <div className="mx-auto mt-5" style={{ minHeight: "200px" }}>
                            <Skeleton />
                        </div>
                }
            </div>
        );
    }
}

const MapStateToProps = state => {
    return {
        participationJamSession: state.participationJamSession,
        jamSessionIsReading: state.jamSessionIsReading
    }
}

const MapDispatchToProps = dispatch => {
    return {
        ResetParticipation: () => {
            dispatch({
                type:"PARTICIPATION_RESET"
            });
        }
    }
}

export default connect(MapStateToProps, MapDispatchToProps)(JamSessionInscription);