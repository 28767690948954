import React, { useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { IListedMemberDto } from "../../api/services.generated";
import { useAsyncEffect, useForceUpdate } from "../../utils/react";
import { useAvatarDownloader } from "./AvatarDownloadContext";

export function ImageMember({ member }: { member: IListedMemberDto }) {
    const [imageLoading, setImageLoading] = useState(false);
    const [image, setImage] = useState<string | null>(null);

    const downloader = useAvatarDownloader();

    useAsyncEffect(async ({ wrap }) => {
        const userId = member.id;
        if (userId) {
            setImageLoading(true);
            try {
                const image = await wrap(downloader.getAvatar(userId));
                if (image) {
                    setImage(image);
                }
            } finally {
                setImageLoading(false);
            }
        }
    }, [member.id, downloader]);

    if (imageLoading) {
        return <Skeleton />;
    }

    if (!image) {
        return (
            <img
                src="./img/logo-successteam.png"
                className="mt-1 rounded-circle bg-white img-fluid mx-auto"
                alt="pho"
                style={{
                    width: "45px",
                    height: "45px",
                    border: "1px groove white",
                }}
            />
        );
    }
    return (
        <img
            src={"data:image/jpeg;base64," + image}
            className="mt-1 rounded-circle bg-white img-fluid mx-auto"
            alt="pho"
            style={{
                width: "45px",
                height: "45px",
                border: "1px groove white",
            }}
        />
    );
}
