import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faCalendarAlt, faUsers, faClock } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";

export default function HomeJamSessionCard({
    jamSessionId,
    jamSessionNumber,
    className,
    closed,
    theme,
    date,
    address,
    membersRegistered,
}: {
    jamSessionId: string;
    jamSessionNumber: string;
    className: string;
    closed: boolean;
    theme: string;
    date: string;
    address: string;
    membersRegistered: number;
}) {
    return (
        <Link className="pointer" to={"viewjamsession/" + jamSessionId} style={{ display: "contents" }}>
            <div className={"col-lg-3 col-md-6 siteStyle " + className}>
                <Card className="HookCard my-2 siteStyle shadow-lg cardImg-container">
                    <CardMedia
                        className="HookCardMedia cardImg-image"
                        component="img"
                        height="140"
                        //src="./img/meeting-image.jpg"
                        image={"https://picsum.photos/210/140?random=" + jamSessionNumber}
                    />
                    <div className="cardImg-overlay" />
                    <CardContent className="siteStyle px-3">
                        <div className="mb-2" style={{ color: "#fd7419" }}>
                            <span className="font-weight-bolder" style={{ fontSize: "16px" }}>
                                # {jamSessionNumber}
                            </span>
                            {closed ? (
                                <Chip
                                    label="Terminé"
                                    size="small"
                                    className="float-right"
                                    color="secondary"
                                    style={{ fontSize: "12px", position: "relative", top: "2px" }}
                                />
                            ) : (
                                <Chip
                                    label="A venir"
                                    size="small"
                                    className="float-right btn-success-custom"
                                    style={{ fontSize: "12px", position: "relative", top: "2px" }}
                                />
                            )}
                        </div>
                        <div className="text-truncate">
                            <strong>{theme}</strong>
                        </div>
                        <div className="mb-1 text-muted">
                            <span className="small text-dark">
                                <FontAwesomeIcon icon={faCalendarAlt} className="pr-1" />{" "}
                                {date.split(" ").slice(0, 3).join(" ")}
                            </span>
                        </div>
                        <div className="mb-1 text-muted">
                            <span className="small text-dark">
                                <FontAwesomeIcon icon={faClock} className="pr-1" />{" "}
                                {date.split(" ").slice(3, 7).join(" ")}
                            </span>
                        </div>
                        <div className="card-text text-truncate">
                            <span className="small" title={address}>
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="width-20 mr-2" /> {address}
                            </span>{" "}
                            <br />
                            <span className="small">
                                <FontAwesomeIcon icon={faUsers} className="width-20 mr-2" />
                                <Chip
                                    label={membersRegistered}
                                    size="small"
                                    style={{ background: "#fd7419", color: "white", fontSize: "12px" }}
                                />{" "}
                                Membre(s) est/sont déjà inscrit(s).
                            </span>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </Link>
    );
}
