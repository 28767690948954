import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import { Facebook, LinkedIn, Instagram, RssFeed } from '@material-ui/icons';
import { faCalendar, faEnvelope, faPhoneAlt, faUsers, faGlobe, faMapMarkedAlt, faPen, faTimesCircle, faSave, faUserLock, faLink, faBullseye, faShieldAlt, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from '@material-ui/lab/Skeleton';
import { toast } from 'react-toastify';
import './ShowProfile.css';
import Container from "@material-ui/core/Container";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from '@material-ui/core/Divider';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ProjectCard from "../projects/ProjectCard";
import SetExternalUrl from "./SetExternalUrl";
import Iframe from 'react-iframe';
// @ts-ignore
import { ReactTitle } from "react-meta-tags";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

class ShowProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadNotifyForNewAccount:false,
            notifyForNewAccount:false,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            userName: "",
            city: "",
            country: "",
            address: "",
            team: "",
            dataLoaded: false,
            birthDate: null,
            facebook: "",
            linkedIn: "",
            instagram: "",
            socialNetworkOther: "", 
            tabSelected: 0,
            maritalStatus: "",
            dependentChildren: null,
            professionalStatus: [],
            professionalStatusOther: null,
            avatar: null,
            training: null,
            expertiseArea: null,
            activityField: "",
            dialogTeamOpen: false,
            teams: [],
            teamId: "",
            routes:[],
            dialogRoleOpen: false,
            roles:[],
            roleId: undefined,
            roleName: undefined,
            saveRoleBtnLoading: false,
            externalUrl: null,
            openDialogExternalUrl: false,
            openDialogNotifyNewAccountCreated:false
        };

        this.handleCloseDialogTeam = this.handleCloseDialogTeam.bind(this);
        this.handleOpenDialogTeam = this.handleOpenDialogTeam.bind(this);
        this.handleOpenDialogRole = this.handleOpenDialogRole.bind(this);
        this.handleInputChanged = this.handleInputChanged.bind(this);
        this.saveTeamMember = this.saveTeamMember.bind(this);
        this.saveMemberRole = this.saveMemberRole.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleCloseDialogRole = this.handleCloseDialogRole.bind(this);
        this.handleCloseDialogExternalUrl = this.handleCloseDialogExternalUrl.bind(this);
        this.handleExternalUrl = this.handleExternalUrl.bind(this);
        this.handleNotifyNewAccount = this.handleNotifyNewAccount.bind(this);
    }

    componentDidMount() {
        this.getProfile();
        this.getAvatar();
        if (this.props.isAdmin) {
            this.getTeams();
        }
        if(this.props.isSuperAdmin) {
            this.getRoles();
        }

        this.getProjects();
    }

    getProfile() {
        fetch(new Request("api/Member/GetDetailProfile?memberId=" + this.props.match.params.userId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        notifyForNewAccount:response.notifyForNewAccountCreated,
                        firstName: response.firstName,
                        lastName: response.lastName,
                        email: response.email,
                        phone: response.phoneNumber,
                        userName: response.userName,
                        city: response.city,
                        country: response.country,
                        address: response.address,
                        team: response.teamName,
                        birthDate: response.birthDate,
                        facebook: response.facebook,
                        linkedIn: response.linkedIn,
                        instagram: response.instagram,
                        socialNetworkOther: response.socialNetworkOther,
                        dataLoaded: true,
                        dependentChildren: response.dependentChildren,
                        professionalStatus: response.professionalStatus,
                        maritalStatus: response.maritalStatus,
                        professionalStatusOther: response.professionalStatusOther,
                        training: response.training,
                        expertiseArea: response.expertiseArea,
                        activityField: response.activityField,
                        teamId: response.teamId,
                        roleId: response.roleId,
                        roleName: response.roleName,
                        externalUrl: response.externalUrl
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
            });
    }

    getAvatar() {
        fetch(new Request("api/Member/DownloadAvatar?memberId=" + this.props.match.params.userId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        avatar: response !== null ? "data:" + response.contentType + ";base64," + response.image : null
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
            });
    }

    isNotNull(word) {
        if (word !== null && word !== undefined && word !== "") {
            if (word.length > 0) {
                if (word[0] === "") {
                    return false;
                }
            }
            return true;
        }

        return false;
    }

    handleCloseDialogTeam() {
        this.setState({
            dialogTeamOpen:false
        })
    }

    handleOpenDialogTeam() {
        this.setState({
            dialogTeamOpen: true
        })
    }

    handleOpenDialogRole(){
        this.setState({
            dialogRoleOpen: true
        })
    }

    handleCloseDialogRole() {
        this.setState({
            dialogRoleOpen:false
        })
    }

    handleChangeTab(e, value) {
        this.setState({
            tabSelected: value
        });
    }

    getTeams() {
        fetch(new Request("api/Team/GetTeams"), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    teams: response
                })
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                hideProgressBar: false,
                position: "top-left"
            });
        });
    }

    handleExternalUrl(value) {
        this.setState({
            externalUrl: value
        });
    }

    handleInputChanged(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    saveTeamMember() {
        fetch(new Request("api/Member/SetTeamChanged"), {
            method: "POST",
            body: JSON.stringify({
                Id: this.props.match.params.userId,
                Team: { TeamId: this.state.teamId }
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    dialogTeamOpen: false,
                    team: this.state.teams.find(t => t.teamId === this.state.teamId).teamName
                });
                toast.success("La modification d'équipe a été sauvegardée !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                hideProgressBar: false,
                position: "top-left"
            });
        });
    }

    getProjects() {
        fetch(new Request("api/Project/GetUserProjets?userId=" + this.props.match.params.userId), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        routes: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
            });
    }

    getRoles(){
        fetch(new Request("api/Member/GetRoles"), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        roles: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
            });
    }

    saveMemberRole() {
        this.setState({
            saveRoleBtnLoading:true
        });

        fetch(new Request("api/Member/SetMemberRole"), {
            method: "POST",
            body: JSON.stringify({
                Roles: [this.state.roleId],
                Id:this.props.match.params.userId
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    dialogRoleOpen: false,
                    saveRoleBtnLoading:false,
                    roleName: this.state.roles.find(t => t.roleId === this.state.roleId).roleName
                });
                toast.success("Rôle affecté !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            this.setState({
                saveRoleBtnLoading:false
            });
            toast.warn(error + " !", {
                hideProgressBar: false,
                position: "top-left"
            });
        });
    }

    handleCloseDialogExternalUrl() {
        this.setState({
            openDialogExternalUrl:false
        })
    }

    resume(word) {
        var result = word.slice(0, 40);
        if (word.length > 40) {
            result = result + "...";
        }

        return result;
    }

    handleNotifyNewAccount(e,checked){

        this.setState({
            loadNotifyForNewAccount:true
        },()=>{
            fetch(new Request("api/Member/UpdateNotifyNewAccount?memberId="+this.props.match.params.userId), { 
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                })
            })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);
    
                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        notifyForNewAccount:checked
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
                this.setState({
                    notifyForNewAccount: !checked
                });
            })
            .finally(()=>{
                this.setState({
                    loadNotifyForNewAccount:false
                })
            });
        })

        
    }

    render() {
        
        return (
            <React.Fragment>
                <ReactTitle title={"Profil " + this.state.firstName + " " + this.state.lastName + " - Success Team"} />
                <nav className="siteStyle top-nav">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/members">Membres</Link>
                        </li>
                        <li className="breadcrumb-item active"><h1 className="siteTitle">{this.state.firstName + " " + this.state.lastName}</h1></li>
                    </ol>
                </nav>
                <div style={{ marginLeft: "-15px", marginRight: "-15px" }}>
                    {
                        !this.state.dataLoaded ?
                            <React.Fragment>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-6">
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <Skeleton />
                                    </div>
                                </div>
                            </React.Fragment> :
                            <React.Fragment>
                                <div className="jss486" />
                                <Container className="jss489" >
                                    <div className="MuiAvatar-root MuiAvatar-circle jss490">
                                        <img src={this.state.avatar === null ? "/img/logo-successteam.png" : this.state.avatar} className="MuiAvatar-img bg-white" alt="logo" />
                                    </div>
                                    <div className="jss491">
                                        <span className="MuiTypography-root MuiTypography-h6 font-weight-bold">{this.state.firstName + " " + this.state.lastName}</span>
                                    </div>
                                </Container>
                                <Container className="pb-5">
                                    <Tabs indicatorColor="primary" textColor="primary" value={this.state.tabSelected}
                                        onChange={this.handleChangeTab}>
                                        <Tab label="Information" />
                                        <Tab label="Projets" />
                                        <Tab label="Tests" />
                                    </Tabs>
                                    <Divider />
                                    <div className="jss484">
                                        <div hidden={this.state.tabSelected !== 0}>
                                            <Card className="mb-3">
                                                <CardContent>
                                                    <div className="form-group row">
                                                        {
                                                            this.isNotNull(this.state.birthDate) &&
                                                            <div className="col-sm-4 col-12 mb-3">
                                                                <FontAwesomeIcon icon={faCalendar} className="mr-2 xxx" />
                                                                <Chip color="primary" label={"Date de Naissance : " + this.state.birthDate} />
                                                            </div>
                                                        }
                                                        {
                                                            this.isNotNull(this.state.email) &&
                                                            <div className="col-sm-4 col-12 mb-3">
                                                                <FontAwesomeIcon icon={faEnvelope} className="mr-2 xxx" />
                                                                <Chip color="primary" label={"Email: " + this.state.email} />
                                                            </div>
                                                        }
                                                        {
                                                            this.isNotNull(this.state.phone) &&
                                                            <div className="col-sm-4 col-12 mb-3">
                                                                <FontAwesomeIcon icon={faPhoneAlt} className="mr-2 xxx" />
                                                                <Chip color="primary" label={"Téléphone: " + this.state.phone} />
                                                            </div>
                                                        }
                                                        <div className="col-sm-4 col-12 mb-3">
                                                            <FontAwesomeIcon icon={faUsers} className="mr-2 xxx" />
                                                            {
                                                                this.isNotNull(this.state.team) ? 
                                                                <Chip color="primary" label={"Equipe : " + this.state.team} /> : 
                                                                <Chip label="Aucune Equipe" />
                                                            }
                                                            {
                                                                this.props.isAdmin && <FontAwesomeIcon icon={faPen} className="ml-2 pointer" onClick={this.handleOpenDialogTeam} />
                                                            }
                                                        </div>
                                                        <div className="col-sm-4 col-12 mb-3">
                                                            <FontAwesomeIcon icon={faUserLock} className="mr-2 xxx" />
                                                            <Chip label={this.state.roleName === "Member" ? "Membre" : this.state.roleName} />
                                                            {
                                                                this.props.isSuperAdmin && <FontAwesomeIcon icon={faPen} className="ml-2 pointer" onClick={this.handleOpenDialogRole} />
                                                            }
                                                        </div>
                                                        {
                                                            this.props.isSuperAdmin && this.props.isAdmin &&
                                                            <div className="col-sm-4 col-12 mb-3">
                                                                <FontAwesomeIcon icon={faShieldAlt} className="mr-2" />
                                                                {
                                                                    this.state.loadNotifyForNewAccount ?
                                                                    <FontAwesomeIcon icon={faSync} className="fa-spin" /> : 
                                                                    <FormControlLabel control={<Switch checked={this.state.notifyForNewAccount} 
                                                                    disabled={!this.props.isSuperAdmin && !this.props.isAdmin} 
                                                                    onChange={this.handleNotifyNewAccount} />}
                                                                    label={this.state.notifyForNewAccount ? "Notifier nouveau compte créé" : 
                                                                    "Non notifier nouveau compte créé"} />
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            (this.props.isAdmin || this.props.isSuperAdmin) && 
                                                            <div className="col-sm-4 col-12 mb-3">
                                                                <FontAwesomeIcon icon={faLink} className="mr-2 xxx" />
                                                                <Chip 
                                                                    color="primary"
                                                                    className="pointer text-white"
                                                                    label={ !this.isNotNull(this.state.externalUrl) ? "Aucune url externe" : this.resume(this.state.externalUrl) } 
                                                                    component="a"
                                                                    href={this.state.externalUrl}
                                                                    target="_blank"
                                                                />
                                                                <FontAwesomeIcon icon={faPen} className="ml-2 pointer" onClick={()=>this.setState({openDialogExternalUrl:true})} />
                                                            </div>
                                                        }
                                                        {
                                                            this.isNotNull(this.state.city) && this.isNotNull(this.state.country) &&
                                                            <div className="col-sm-4 col-12 mb-3">
                                                                <FontAwesomeIcon icon={faGlobe} className="mr-2 xxx" />
                                                                <Chip color="primary" label={this.state.city + ", " + this.state.country} />
                                                            </div>
                                                        }
                                                        {
                                                            this.isNotNull(this.state.socialNetworkOther) &&
                                                            <div className="col-sm-4 col-12 mb-3">
                                                                <RssFeed size="sm" />
                                                                <Chip className="pointer text-white" color="primary" label={this.resume(this.state.socialNetworkOther)} component="a" href={this.state.socialNetworkOther} target="_blank" />
                                                            </div>
                                                        }
                                                        {
                                                            this.isNotNull(this.state.facebook) &&
                                                            <div className="col-sm-4 col-12 mb-3">
                                                                <Facebook size="sm" style={{ color: "#405e9b" }} />
                                                                <Chip className="pointer text-white" color="primary" label={this.resume(this.state.facebook)} component="a" href={this.state.facebook} target="_blank" />
                                                            </div>
                                                        }
                                                        {
                                                            this.isNotNull(this.state.linkedIn) &&
                                                            <div className="col-sm-4 col-12 mb-3">
                                                                <LinkedIn size="sm" style={{ color: "#127db6" }} />
                                                                <Chip className="pointer text-white" color="primary" label={this.resume(this.state.linkedIn)} component="a" href={this.state.linkedIn} target="_blank" />
                                                            </div>
                                                        }
                                                        {
                                                            this.isNotNull(this.state.instagram) &&
                                                            <div className="col-sm-4 col-12 mb-3">
                                                                <Instagram size="sm" style={{ color: "#fd7842" }} />
                                                                <Chip className="pointer text-white" color="primary" label={this.resume(this.state.instagram)} component="a" href={this.state.instagram} target="_blank" />
                                                            </div>
                                                        }
                                                        {
                                                            this.isNotNull(this.state.address) &&
                                                            <div className="col-sm-12 col-12 mb-3">
                                                                <FontAwesomeIcon icon={faMapMarkedAlt} className="mr-2 xxx" />
                                                                <Chip color="primary" label={this.state.address} />
                                                            </div>
                                                        }
                                                    </div>
                                                </CardContent>
                                            </Card>

                                            <Card>
                                                <CardContent>
                                                    {
                                                        this.isNotNull(this.state.maritalStatus) &&
                                                        <div className="form-group">
                                                            <label className="mr-2">Statut Matrimonial : </label>
                                                            <Chip color="primary" label={this.state.maritalStatus} />
                                                        </div>
                                                    }
                                                    {
                                                        this.isNotNull(this.state.dependentChildren) &&
                                                        <div className="form-group">
                                                            <label className="mr-2">Enfants à charge : </label>
                                                            <Chip color="primary" label={this.state.dependentChildren ? "OUI" : "NON"} />
                                                        </div>
                                                    }
                                                    {
                                                        this.isNotNull(this.state.professionalStatus) &&
                                                        <div className="form-group">
                                                            <label className="mr-2">Statut Professionnel : </label>
                                                            {
                                                                this.state.professionalStatus.map((item, i) => (
                                                                    <React.Fragment key={"professionnalStatus-" + i}>
                                                                        {
                                                                            item !== "OTHER" ? 
                                                                            <Chip color="primary" label={item} className="mr-2" /> : null
                                                                        }
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                            {
                                                                this.isNotNull(this.state.professionalStatusOther) &&
                                                                <Chip color="primary" label={this.state.professionalStatusOther} />
                                                            }
                                                        </div>
                                                    }
                                                
                                                    {
                                                        this.isNotNull(this.state.activityField) &&
                                                        <div className="form-group">
                                                            <label className="mr-2">Si travailleur ou travailleur indépendant, Votre profession / domaine d'activité : </label>
                                                            <Chip color="primary" label={this.state.activityField} />
                                                        </div>
                                                    }
                                                    {
                                                        this.isNotNull(this.state.training) &&
                                                        <div className="form-group">
                                                            <label className="mr-2">Vos formations : </label>
                                                            {
                                                                this.state.training.map((item, i) => (
                                                                    <React.Fragment key={"formation-" + i}>
                                                                        {
                                                                            item !== "" ? 
                                                                            <Chip color="primary" label={item} className="mr-2" /> : null
                                                                        }
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        this.isNotNull(this.state.expertiseArea) &&
                                                        <div className="form-group">
                                                            <label className="mr-2">Vos domaines de compétence / Votre savoir faire : </label>
                                                            {
                                                                this.state.expertiseArea.map((item, i) => (
                                                                    <React.Fragment>
                                                                        {
                                                                            item !== "" ? 
                                                                            <Chip key={"expertise-"+i} color="primary" label={item} className="mr-2" /> : null
                                                                        }
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </div>
                                                    }
                                                </CardContent>
                                            </Card>
                                        </div>
                                        <div hidden={this.state.tabSelected !== 1}>
                                            {
                                                (this.state.routes || []).map((route,index) => (
                                                    <Accordion key={"panel_"+index}>
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                            <p>{route.routeName}</p>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div className="row w-100">
                                                                {
                                                                    route.projects.map(item => (
                                                                        <ProjectCard projectId={item.projectId} key={item.projectId} routeName={route.routeName}
                                                                            projectName={item.projectName} granted={false} displayFullName={false} createdDate={item.createdDate} />
                                                                    ))
                                                                }
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))
                                            }
                                        </div>
                                        
                                        <div hidden={this.state.tabSelected !== 2}>
                                            {
                                                this.state.externalUrl !== null && this.state.externalUrl !== undefined && this.state.externalUrl !== "" && 
                                                <Iframe url={this.state.externalUrl}
                                                    width="100%"
                                                    height="600px"
                                                    id="myId"
                                                    className="myClassname border"
                                                    display="initial"
                                                    position="relative" 
                                                />
                                            }
                                        </div>
                                    </div>
                                </Container>
                            </React.Fragment>
                    }
                </div>

                <Dialog open={this.state.dialogTeamOpen} fullWidth maxWidth="sm"
                    onClose={this.handleCloseDialogTeam}>
                    <DialogTitle>Gestion d'équipe</DialogTitle>
                    <DialogContent>
                        <FormControl className="w-100">
                            <InputLabel>Equipe</InputLabel>
                            <Select value={this.state.teamId} name="teamId" onChange={this.handleInputChanged} variant="filled">
                                {
                                    (this.state.teams || []).map((row, i) => (
                                        <MenuItem key={"Team-" + i} value={row.teamId}>{row.teamName}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button className="text-initial" color="primary" variant="contained" onClick={this.saveTeamMember} size="small">
                            <FontAwesomeIcon icon={faSave} className="mr-2" size="sm" /> Enregistrer
                        </Button>
                        <Button className="text-initial" onClick={this.handleCloseDialogTeam} variant="contained" size="small">
                            <FontAwesomeIcon icon={faTimesCircle} className="mr-2" size="sm" /> Fermer
                        </Button>
                    </DialogActions>
                </Dialog>

                {
                    this.state.dialogRoleOpen && 
                    <Dialog open={true} fullWidth maxWidth="sm" onClose={this.handleCloseDialogRole}>
                        <DialogTitle>Gestion des Rôles</DialogTitle>
                        <DialogContent>
                            <FormControl className="w-100" variant="filled">
                                <InputLabel>Rôle</InputLabel>
                                <Select value={this.state.roleId} name="roleId" onChange={this.handleInputChanged} variant="filled">
                                    {
                                        (this.state.roles || []).map(row => (
                                            <MenuItem key={row.roleId} value={row.roleId}>{row.roleName}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button className="text-initial" color="primary" variant="contained" onClick={this.saveMemberRole} 
                            size="small" disabled={this.state.saveRoleBtnLoading}>
                                <FontAwesomeIcon icon={faSave} className="mr-2" size="sm" /> Enregistrer
                            </Button>
                            <Button className="text-initial" onClick={this.handleCloseDialogRole} variant="contained" size="small">
                                <FontAwesomeIcon icon={faTimesCircle} className="mr-2" size="sm" /> Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                {
                    this.state.openDialogExternalUrl && 
                        <SetExternalUrl memberId={this.props.match.params.userId} url={this.state.externalUrl} dialogClose={this.handleCloseDialogExternalUrl} handleExternalUrl={this.handleExternalUrl} />
                }
            </React.Fragment>
        );
    }
}


const MapStateToProps = state => {
    return {
        isAdmin: state.isAdmin,
        isSuperAdmin: state.isSuperAdmin
    }
}

export default connect(MapStateToProps)(ShowProfile)