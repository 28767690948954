import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';

export default class EditNote extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            message:"",
            saveDisable:false
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.saveNote = this.saveNote.bind(this);
    }

    componentDidMount() {
        if (this.props.noteId !== "") {
            this.getNote();
        }
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    getNote() {

        fetch(new Request("api/Note/GetNote?NoteId=" + this.props.noteId + "&ProjectId=" + this.props.projectId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {

                if (ok) {
                    this.setState({
                        title: response.title,
                        message: response.message
                    })
                } else {
                    this.setState({
                        saveDisable: false
                    });
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    saveNote() {
        this.setState({
            saveDisable: true
        });

        fetch(new Request("api/Note/SetNote"), {
            method: "POST",
            body: JSON.stringify({
                NoteId: this.props.noteId,
                Title: this.state.title,
                Message: this.state.message,
                ProjectId: this.props.projectId
            }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {

                if (ok) {
                    toast.success("Note sauvegardée !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                    this.props.closeDialog();
                } else {
                    this.setState({
                        saveDisable: false
                    });
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    handleClose() {
        this.props.closeDialog();
    }

    render() {
        return (
            <Dialog open onClose={this.handleClose} fullWidth maxWidth="sm">
                <DialogTitle>Note</DialogTitle>
                <DialogContent>
                    
                    <div className="form-group">
                        <TextField fullWidth name="title" value={this.state.title}
                            onChange={this.handleInput} label="Titre" variant="filled" />
                    </div>
                    <div className="form-group">
                        <TextField fullWidth name="message" value={this.state.message}
                            onChange={this.handleInput} multiline label="Message" rows="5" variant="filled" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" className="text-initial mr-2" color="primary" onClick={this.saveNote} disabled={this.state.saveDisable} size="small">
                        <FontAwesomeIcon icon={faSave} className="mr-1" size="sm" /> Sauvegarder
                    </Button>
                    <Button variant="contained" className="text-initial mr-2" onClick={this.handleClose} size="small">
                        <FontAwesomeIcon icon={faTimesCircle} className="mr-1" size="sm" /> Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}