import React, { useEffect, useState } from 'react'
import { useAuthorizedBackendApi } from '../../api/api'
import { BackendService, MemberShareProjectDto, ProjectLogoInfoDto, ProjectStepStatusDto } from '../../api/services.generated'
import { toast } from 'react-toastify';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import CardActions from '@material-ui/core/CardActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardContent from '@material-ui/core/CardContent';
import { faHeart, faShareAlt, faUnlock } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import Skeleton from '@material-ui/lab/Skeleton';
import ShareProject from './ShareProject';

interface MyProps{
    projectId?:string | null,
    granted:boolean,
    routeName?:string | null,
    projectName?:string | null,
    elevatorPitch?:string | null,
    fullName?:string | null,
    firstName?:string | null,
    createdDate?:string | null,
    createdById?:string | null,
    canShared:boolean,
    members:MemberShareProjectDto[],
    logoId?:string | null
}

const ProjectCard = (props:MyProps) =>{

    const api:BackendService | null = useAuthorizedBackendApi()
    const [loadingPercentage, setLoadingPercentage] = useState(false)
    const [percentage, setPercentage] = useState(0)
    const [totalSteps, setTotalSteps] = useState(0)
    const [numberStepsValidated, setNumberStepsValidated] = useState(0)
    const [openShareProjectModal, setOpenShareProjectModal] = useState(false)
    const [logo, setLogo] = useState<string | null>(null)
    const [contentType, setContentType] = useState<string | null>(null)

    useEffect(() => {
        getProjectLogo()
        getPercentageEvolution()
        getProjectStepStatus()
    }, [])
    
    async function getProjectLogo(){
        if(!api || !props.projectId || !props.logoId){
            return;
        } 
        try
        {
            const response:ProjectLogoInfoDto | null = await api.project_DownloadProjectLogo(props.projectId,props.logoId)
            if(response){
                setLogo(response.projectLogoFile ? response.projectLogoFile : null)
                setContentType(response.contentType ? response.contentType : null)
            }
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
    }

    async function getPercentageEvolution() {

        setLoadingPercentage(true)
        try
        {
            if(!api || !props.projectId){
                return;
            }

            const response:number = await api.project_GetPercentageEvolution(props.projectId)
            setPercentage(response ? response : 0)
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        }
        finally{
            setLoadingPercentage(false)
        }
    }

    async function getProjectStepStatus() {

        try{
            if(!api || !props.projectId){
                return;
            }
            setLoadingPercentage(true)
            const response: ProjectStepStatusDto | null = await api.project_GetProjectStepStatus(props.projectId)
            setTotalSteps(response ? response.totalSteps : 0)
            setNumberStepsValidated(response ? response.numberStepsValidated : 0)
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
        finally{
            setLoadingPercentage(false)
        }
    }

    return (
        <React.Fragment>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                <Card className="card custom-shadow pointer mb-1">
                    <CardHeader className="text-decoration-none" to={props.granted ? "/project/" + props.projectId : "#"} 
                        component={Link} avatar={<Avatar aria-label={props.routeName ? props.routeName : ""}>
                            {props.routeName ? props.routeName.substr(0, 1).toUpperCase() : ""}
                            </Avatar>}
                            action={<IconButton>
                            <FontAwesomeIcon icon={props.granted ? faUnlock : faUnlock}
                                className={props.granted ? "text-success" : "text-danger"} />
                        </IconButton>} 
                        title={props.projectName ? props.projectName : ""} />
                    <CardMedia component={Link} to={props.granted ? "/project/" + props.projectId : "#"}
                            image={logo ? "data:"+contentType+";base64,"+logo : 
                            "/img/empty_img.jpg"}
                            title={props.routeName ? props.routeName : ""} />
                    <CardContent>
                        <Link to={props.granted ? "/project/" + props.projectId : "#"}
                            className="elevatorPitchlabel text-center form-group text-truncate">
                            {props.elevatorPitch === null || props.elevatorPitch === undefined || 
                            props.elevatorPitch === "" ? "-" : props.elevatorPitch}
                        </Link>
                        {
                            <p className="project-created text-center">
                                Par <Tooltip title={"CLiques pour ouvrir le profil de: "+props.fullName}>
                                        <Link to={"/profil/"+props.createdById}>{props.firstName}</Link>
                                    </Tooltip>, le {props.createdDate}
                            </p>
                        }
                        <div className="projectPercentage">
                            {
                                !loadingPercentage ? 
                                <CircularProgressbar
                                    value={percentage}
                                    maxValue={100}
                                    //text={`${this.state.percentage.toFixed(0)}%`}
                                    text={`${numberStepsValidated+"/"+totalSteps}`}
                                    styles={buildStyles({
                                        // Colors
                                        pathColor: 'limegreen',
                                        textColor: 'black',
                                        backgroundColor: 'blue',
                                        textSize: "26px"
                                    })}
                                /> : <Skeleton />
                            }
                        </div>
                    </CardContent>
                    <CardActions>
                        <IconButton><FontAwesomeIcon icon={faHeart} /></IconButton>
                        {
                            props.canShared && 
                                <IconButton onClick={()=>setOpenShareProjectModal(true)}>
                                    <FontAwesomeIcon  icon={faShareAlt} />
                                </IconButton>
                        } 
                    </CardActions>
                </Card>         
            </div>
            {
                openShareProjectModal && 
                <ShareProject closeModal={()=>setOpenShareProjectModal(false)} projectName={props.projectName}
                    members={props.members} projectId={props.projectId} ownerName={props.fullName} />
            }
        </React.Fragment>
    )
}

export default ProjectCard


