import React from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import OutsideClickHandler from 'react-outside-click-handler';
import { SettingsPhoneTwoTone } from '@material-ui/icons';

export function EmojiPicker({show, setShow, text, setText}) {
    const addEmoji = (emoji) => {
        setText(text+emoji.native);
    }

    return (
        <React.Fragment>
            <div className="custom-emoji-picker" style={{ display: show ? 'block' : 'none' }}>
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setShow(false);
                    }}
                >
                    <Picker 
                        i18n={{ 
                            search: 'Recherche', 
                            categories: { 
                                search: 'Résultats de recherche', 
                                recent: 'Récents' } 
                            }
                        }
                        onSelect={addEmoji} 
                        native={true}
                        showPreview={false}
                        showSkinTones={false}
                    />
                </OutsideClickHandler>
            </div>
            <div className="d-block d-sm-none">
                <span 
                    onClick={() => { window.scrollTo({top: 0, behavior: 'smooth'}); }} 
                    className="text-center" 
                    style={{ 
                        cursor: 'pointer', 
                        backgroundColor: 'dodgerblue', 
                        color: 'white', 
                        fontSize: 10, 
                        paddingTop: '8px', 
                        position: 'absolute', 
                        right: '15px', 
                        bottom: '100px', 
                        borderRadius: '15px', 
                        width: '30px', 
                        height: '30px'
                    }}
                >
                    Top
                </span>
            </div>
        </React.Fragment>
    );
}
