import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faSave, faMapMarkerAlt, faUser, faEdit, faAlignLeft, faCalendar, faClock, faSync } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import EditJamSessionResponses from './EditJamSessionResponses';
import JamSessionInscription from './JamSessionInscription';
import EditJamSessionInvitation from './EditJamSessionInvitation';
import { connect } from 'react-redux';
import './jamSession.css';
import EditJamSessionAgendaMember from './EditJamSessionAgendaMember';
import Avatar from '../../Avatar';
import EditJamSessionAgenda from './EditJamSessionAgenda';

class EditJamSession extends Component {

    constructor(props) {
        super(props);

        this.state = {
            date: (new Date()).getFullYear() + "-" + ("0" + ((new Date()).getMonth() + 1)).slice(-2) + "-" + ("0" + (new Date()).getDate()).slice(-2),
            jamSessionId:"",
            jamSessionNumber: "( New )",
            theme: "",
            hourFrom: "00:00",
            hourTo: "00:00",
            address: "",
            moderatorId: "",
            generalEvaluatorId: "",
            description: "",
            membersResponse:[],
            tabValue: 0,
            members: [],
            jamSessionRoles: [],
            //jamSessionInvitation:[],
            btnSaveLoading: false,
            moderator: null,
            generalEvaluator: null,
            jamSessionClosed: false,
            //registerCount: "0 réponse",
            isRegistered: false, 
            agendas: [], 
            roles: [],
            loadedForm: false
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleAutocompleteGeneralEvaluator = this.handleAutocompleteGeneralEvaluator.bind(this);
        this.handleAutocompleteModerator = this.handleAutocompleteModerator.bind(this);
        this.saveJamSession = this.saveJamSession.bind(this);
        this.refreshGetJamSession = this.refreshGetJamSession.bind(this);
        this.handleToggleCheckbox = this.handleToggleCheckbox.bind(this);
        this.enableJamSession = this.enableJamSession.bind(this);
    }

    async componentDidMount() {
        if (this.props.jamSessionId !== undefined) {
            await this.getJamSession();
            
        }

        this.getAllRoles();
        this.getMembers();
    }

    enableJamSession() {
        this.props.ToggleIsReading(false);
    }

    handleClose() {
        this.props.onCloseModal();
    }

    handleTabChange(e,value) {
        this.setState({
            tabValue:value
        })
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleAutocompleteGeneralEvaluator(e, value) {
        this.setState({
            generalEvaluatorId: value.id,
            generalEvaluator: value
        });
    }

    handleAutocompleteModerator(e, value) {
        this.setState({
            moderatorId: value.id,
            moderator: value
        });
    }

    refreshGetJamSession() {
        this.getJamSession();
    }

     getMembers() {
        fetch(new Request("api/Member/GetMembersName"), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    if (this.props.jamSessionId !== undefined) {
                        this.setState({
                            moderator: response.find(f => f.id === this.state.moderatorId),
                            generalEvaluator: response.find(f => f.id === this.state.generalEvaluatorId)
                        });
                    }
                    this.setState({
                        members: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    saveJamSession() {

        if (this.state.generalEvaluatorId === this.state.moderatorId) {
            toast.warn("Un membre ne peut pas être modérateur et évaluateur général", {
                position: "top-left",
                hideProgressBar: false
            });
        }
        else {
            this.setState({
                btnSaveLoading: true
            });

            fetch(new Request("api/JamSession/SetJamSession"), {
                method: "POST",
                body: JSON.stringify({
                    JamSessionId: this.state.jamSessionId,
                    Theme: this.state.theme,
                    JamSessionDate: this.state.date,
                    JamSessionHourFrom: this.state.hourFrom,
                    JamSessionHourTo: this.state.hourTo,
                    Address: this.state.address,
                    Description: this.state.description,
                    Moderator: {
                        Id: this.state.moderatorId
                    },
                    GeneralEvaluator: {
                        Id: this.state.generalEvaluatorId
                    },
                    JamSessionRoles: this.state.roles.filter(r => r.checked)
                }),
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                })
            })
                .then(response => {
                    if (response.status !== 204)
                        return Promise.all([response.ok, response.json()]);

                    return Promise.all([response.ok, null]);
                })
                .then(([ok, response]) => {
                    if (ok) {
                        if (response !== undefined) {
                            this.setState({
                                jamSessionNumber: response.jamSessionNumber,
                                jamSessionId: response.jamSessionId,
                                btnSaveLoading: false
                            });
                        } else {
                            this.setState({
                                btnSaveLoading: false
                            });
                        }

                        toast.success("Jam Session sauvegardé !", {
                            position: "top-center",
                            hideProgressBar: false
                        });
                    } else {
                        throw new Error(response);
                    }
                })
                .catch(error => {
                    this.setState({
                        btnSaveLoading: false
                    });
                    toast.warn(error + " !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                });
        }
    }

    getJamSession() {
        this.setState({
            loadedForm: true
        });
        fetch(new Request("api/JamSession/GetJamSession?jamSessionId=" + this.props.jamSessionId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    var date = new Date(response.jamSessionDate);
                    this.setState({
                        jamSessionId: this.props.jamSessionId,
                        theme: response.theme,
                        jamSessionNumber: response.jamSessionNumber,
                        hourFrom: response.jamSessionHourFrom,
                        hourTo: response.jamSessionHourTo,
                        date: date.getUTCFullYear() + "-" + ("0" + (date.getUTCMonth() + 1)).slice(-2) + "-" + ("0" + date.getUTCDate()).slice(-2),
                        address: response.address,
                        moderatorId: response.moderator !== null ? response.moderator.id : "",
                        generalEvaluatorId: response.generalEvaluator !== null ? response.generalEvaluator.id : "",
                        description: response.description,
                        jamSessionRoles: response.jamSessionRoles !== null ? response.jamSessionRoles : [],
                        //jamSessionInvitation: response.invitation !== null ? response.invitation : [],
                        jamSessionClosed: response.closed,
                        //registerCount: response.registers !== null ? response.registers.length > 0 ? response.registers.length + " réponses" : 
                        //    response.registers.length + " réponse" : "0 réponse",
                        membersResponse: response.registers !== null ? response.registers : [],
                        isRegistered: response.isRegistered,
                        agendas: response.agenda,
                        loadedForm: false
                    });

                    this.props.setInvitations(response.invitation !== null ? response.invitation : []);
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    loadedForm: false
                });
            });
    }

    getAllRoles() {
        fetch(new Request("api/JamSessionRole/GetJamSessionRolesSimpleData"), {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    var array = [];

                    (response || []).map(item => (
                        array.push({
                            jamSessionRoleId: item.jamSessionRoleId,
                            jamSessionRoleName: item.jamSessionRoleName,
                            jamSessionRoleSpecialTable: item.jamSessionRoleSpecialTable,
                            checked: (this.state.jamSessionRoles || []).findIndex(j => j.jamSessionRoleId === item.jamSessionRoleId) !== -1
                        })
                    ));

                    this.setState({
                        roles: array
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    handleToggleCheckbox(e) {
        var array = this.state.roles;
        var index = this.state.roles.findIndex(r => r.jamSessionRoleId === e.target.value);
        array[index].checked = e.target.checked;
        this.setState({
            roles: array
        });
    }
    
    render() {
        return (
            <Dialog fullScreen open={true}>
                <AppBar color="default" position="relative">
                    <Toolbar>
                        <div className="row" style={{ flex:1 }}>
                            <div className="col-sm-6 col-7">
                                <IconButton edge="start" color="inherit" aria-label="close" onClick={this.handleClose}>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </IconButton>
                                <img style={{ height: "35px" }} src="./img/logo-successteam.png" alt="logo" />
                                <Typography variant="h6" className="ml-3 d-inline">Jam-Session</Typography>
                            </div>
                            <div className="col-sm-2 col-3 mt-1">
                                <TextField value={this.state.jamSessionNumber} name="jamSessionNumber" disabled fullWidth />
                            </div>
                            <div className="col-sm-3 mt-1 pb-2 text-right">
                                {
                                    this.props.jamSessionIsReading ? <Button variant="contained" color="primary"
                                        className="text-capitalize" onClick={this.enableJamSession}>
                                        <FontAwesomeIcon icon={faSave} className="mr-2" /> Editer
                                    </Button> : <React.Fragment>
                                            <Button variant="contained" color="primary" disabled={this.state.btnSaveLoading}
                                                onClick={this.saveJamSession} className="text-capitalize mr-2">
                                                <FontAwesomeIcon icon={faSave} className="mr-2" />  {this.state.jamSessionId !== "" ? "Mettre à jour" : "Enregistrer"}
                                            </Button>
                                            <Button variant="contained" className="text-capitalize btn-info-custom" onClick={this.refreshGetJamSession}>
                                                <FontAwesomeIcon icon={faSync} className="mr-2" /> Rafraîchir
                                            </Button>
                                        </React.Fragment>
                                }
                                
                            </div>
                            <div className="col-sm-1 text-right mt-2" style={{ position: 'absolute', right: '15px' }}>
                                <Avatar />
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                {
                    this.state.loadedForm ? <FontAwesomeIcon icon={faSync} className="fa-5x fa-spin" style={{ marginTop: "10%", marginLeft: "40%" }} /> : 
                        <div className="container-fluid mt-2 mt-sm-3">
                            <div className="row">
                                <div className={this.state.tabValue !== 1 || (this.state.tabValue === 1 && this.props.jamSessionIsReading)  ? "col-md-12" : "col-md-8"}>
                                    <Tabs className="mb-4" indicatorColor="primary" textColor="primary" variant="scrollable"
                                        value={this.state.tabValue} onChange={this.handleTabChange} scrollButtons="on"
                                        style={{ backgroundColor: "#F5F5F5" }}
                                    >
                                        <Tab label="Détails" className="text-capitalize" />
                                        <Tab label="Participants" className="text-capitalize" disabled={this.state.jamSessionId <= 0} />
                                        <Tab label="Agenda" className="text-capitalize" disabled={this.state.jamSessionId <= 0} />
                                        <Tab label="Inscription" className="text-capitalize" disabled={this.state.jamSessionId <= 0} />
                                    </Tabs>
                                    <div role="tabpanel" hidden={this.state.tabValue !== 0}>
                                        <div className="form-group row align-items-center">
                                            <div className="col-1 text-center pr-0">
                                                <FontAwesomeIcon icon={faCalendar} className="fa-lg" />
                                            </div>
                                            <div className="col-11 col-sm-4 col-md-5">
                                                <TextField type="date" variant="filled" name="date" fullWidth label="Date Jam Session"
                                                    onChange={this.handleInput} value={this.state.date} disabled={this.props.jamSessionIsReading} />
                                            </div>
                                            <div className="col-1 text-center pr-0">
                                                <FontAwesomeIcon icon={faClock} className="fa-lg" />
                                            </div>
                                            <div className="col-5 col-sm-3 col-md-2 mt-3 mt-sm-0">
                                                <TextField fullWidth variant="filled" type="time" className="mr-0" name="hourFrom"
                                                    label="Heure début" value={this.state.hourFrom} onChange={this.handleInput}
                                                    disabled={this.props.jamSessionIsReading} />

                                            </div>
                                            <div className="col-5 col-sm-3 col-md-2 mt-3 mt-sm-0">
                                                <TextField fullWidth variant="filled" type="time" label="Heure fin" name="hourTo"
                                                    value={this.state.hourTo} onChange={this.handleInput}
                                                    disabled={this.props.jamSessionIsReading} />
                                            </div>
                                        </div>
                                        <div className="form-group row align-items-center">
                                            <div className="col-1 text-center pr-0">
                                                <FontAwesomeIcon icon={faMapMarkerAlt} className="fa-lg" />
                                            </div>
                                            <div className="col-11 col-md-5 mb-sm-3 mb-md-0">
                                                <TextField variant="filled" fullWidth label="Lieu évènement" name="address"
                                                    value={this.state.address} onChange={this.handleInput}
                                                    disabled={this.props.jamSessionIsReading} />
                                            </div>
                                            <div className="col-1 text-center pr-0">
                                                <FontAwesomeIcon icon={faUser} className="fa-lg" />
                                            </div>
                                            <div className="col-11 col-md-5 mt-3 mt-sm-0">
                                                <Autocomplete onChange={this.handleAutocompleteModerator}
                                                    options={this.state.members} getOptionLabel={o => o.fullName}
                                                    value={this.state.moderator} disabled={this.props.jamSessionIsReading}
                                                    renderInput={params => (
                                                        <TextField {...params} fullWidth variant="filled" label="Modérateur" value={this.state.moderatorId} />
                                                    )} />
                                            </div>
                                        </div>

                                        <div className="form-group row align-items-center">
                                            <div className="col-1 text-center pr-0">
                                                <FontAwesomeIcon icon={faEdit} className="fa-lg" />
                                            </div>
                                            <div className="col-11 col-md-5 mb-sm-3 mb-md-0">
                                                <TextField variant="filled" value={this.state.theme} name="theme"
                                                    fullWidth label="Thème Jam Session" onChange={this.handleInput}
                                                    disabled={this.props.jamSessionIsReading === "true"} />
                                            </div>
                                            <div className="col-1 text-center pr-0">
                                                <FontAwesomeIcon icon={faUser} className="fa-lg" />
                                            </div>
                                            <div className="col-11 col-md-5 mt-3 mt-sm-0">
                                                <Autocomplete onChange={this.handleAutocompleteGeneralEvaluator} options={this.state.members}
                                                    getOptionLabel={o => o.fullName} value={this.state.generalEvaluator}
                                                    disabled={this.props.jamSessionIsReading === "true"} renderInput={params => (
                                                        <TextField {...params} fullWidth variant="filled" label="Evaluateur Général" />)} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-11 offset-1">
                                                <div className="card">
                                                    <div className="card-body pb-0">
                                                        <h5 className="card-title">Autres rôles nécessaires</h5>
                                                        <List dense component="div" role="list">
                                                            {
                                                                (this.state.roles || []).map(row => (
                                                                    <FormControlLabel key={row.jamSessionRoleId} label={row.jamSessionRoleName} control={
                                                                        <Checkbox checked={row.checked} value={row.jamSessionRoleId} 
                                                                            disabled={this.props.jamSessionIsReading === "true"}
                                                                            onChange={this.handleToggleCheckbox} color="primary" />} />
                                                                ))
                                                            }
                                                        </List>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row align-items-center">
                                            <div className="col-1 text-center pr-0">
                                                <FontAwesomeIcon icon={faAlignLeft} className="fa-lg" />
                                            </div>
                                            <div className="col-md-11 col-11">
                                                <TextField value={this.state.description} name="description" variant="filled" 
                                                    disabled={this.props.jamSessionIsReading === "true"} multiline fullWidth label="Description"
                                                    rows="6" onChange={this.handleInput} />
                                            </div>
                                        </div>
                                    </div>
                                    <div role="tabpanel" hidden={this.state.tabValue !== 1}>
                                        {
                                            this.state.jamSessionId !== "" && <EditJamSessionResponses responses={this.state.membersResponse}
                                                jamSessionId={this.state.jamSessionId} members={this.state.members}
                                                isAdmin={true} jamSessionClosed={this.state.jamSessionClosed} />
                                        }
                                    </div>
                                    <div role="tabpanel" hidden={this.state.tabValue !== 2}>
                                        {
                                            this.state.jamSessionId !== "" && !this.props.jamSessionIsReading &&
                                            <EditJamSessionAgenda jamSessionId={this.state.jamSessionId} agendas={this.state.agendas} />
                                        }
                                        {
                                            this.state.jamSessionId !== "" && this.props.jamSessionIsReading &&
                                            <EditJamSessionAgendaMember agendas={this.state.agendas} beginHour={this.state.hourFrom} />
                                        }
                                    </div>
                                    <div role="tabpanel" hidden={this.state.tabValue !== 3}>
                                        {
                                            this.state.jamSessionId !== "" &&
                                            <JamSessionInscription jamSessionRoles={this.state.jamSessionRoles}
                                                jamSessionId={this.state.jamSessionId} isRegistered={this.state.isRegistered} />
                                        }
                                    </div>
                                </div>

                                {
                                    this.state.tabValue === 1 && this.state.jamSessionId !== "" && !this.props.jamSessionIsReading &&
                                    <div className="col-md-4 border-left border-primary">
                                        <h5>Qui souhaitez-vous inviter ?</h5>
                                        <EditJamSessionInvitation members={this.state.members} jamSessionId={this.state.jamSessionId}
                                             isAdmin={true} />
                                    </div>
                                }
                            </div>
                        </div>
                }
            </Dialog>
        );
    }
}

const MapDispatchToProps = dispatch => {
    return {
        setInvitations: data => {
            dispatch({
                type: "FILL_INVITATION",
                invitatedList: data
            });
        },
        ToggleIsReading: isReading => {
            dispatch({
                type: "TOGGLE_IS_READING",
                isReading: isReading
            })
        }
    };
}

const mapStateToProps = state => {
    return {
        jamSessionIsReading: state.jamSessionIsReading
    }
}

export default connect(mapStateToProps, MapDispatchToProps)(EditJamSession);
