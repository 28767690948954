import { createContext, useContext, useEffect, useState } from "react";
import { IJamSessionDto } from "../../../api/services.generated";
import { makeSubscribable, Subscribable, SubscribableHandle } from "../../../utils/subscriptions";
import { useForceUpdate } from "../../../utils/react";

type Session = IJamSessionDto & { sessionId: string };
export type JamSessionContextType = {
    session: Subscribable<Session | null>;
    sessionChanged: SubscribableHandle;
    setSession: (s: Session) => void;
};

export const JamSessionContext = createContext<JamSessionContextType | null>(null);

export function makeJamSessionContext() {
    const session = makeSubscribable<Session | null>(true, null);
    const sessionChanged = makeSubscribable(false);
    return {
        session: session.subscribable,
        sessionChanged: sessionChanged,
        setSession: (s: Session | null) => {
            session.fire(s);
            sessionChanged.fire();
        },
    };
}

export function useJamSession() {
    const context = useContext(JamSessionContext);
    if (context === null) {
        throw new Error("Jam session context was not provided");
    }

    const [session, setSession] = useState<Session | null>(null);
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        const s = context.session((s) => {
            setSession(s);
        });
        return () => s.unsubscribe();
    }, [context.session]);

    useEffect(() => {
        const s = context.sessionChanged.subscribable((s) => {
            forceUpdate();
        });
        return () => s.unsubscribe();
    }, [context.sessionChanged]);

    return {
        session,
        notifySessionUpdated: () => {
            context.sessionChanged.fire();
        },
    };
}
