import { createContext, useContext, useEffect, useState } from "react";
import {
    ChatReadDto,
    IChatReadDto,
    IMessageDto,
    INotificationDto,
    IOnlineUserDto,
    MessageDto,
    NotificationDto,
} from "./services.generated";
import { makeSubscribable, Subscribable } from "../utils/subscriptions";
import { useAuthToken } from "./api";
import { HubConnection, HubConnectionBuilder, IRetryPolicy } from "@microsoft/signalr";
import { useConfig } from "../utils/config";
import { RetryContext } from "@microsoft/signalr/src/IRetryPolicy";
import { sleep } from "../utils/sleep";

export type SignalRContextType = {
    onMessage: Subscribable<IMessageDto>;
    onOnline: Subscribable<IOnlineUserDto>;
    onNotification: Subscribable<NotificationDto>;
    onChatRead: Subscribable<IChatReadDto>;
};
export const SignalRContext = createContext<SignalRContextType | null>(null);

class InfiniteReconnect implements IRetryPolicy {
    nextRetryDelayInMilliseconds(retryContext: RetryContext){
        return 10000;
    }
}

async function connectUntilSuccess(c: HubConnection) {
    while(true){
        try{
        await c.start();
        break;}
        catch(e) {
            console.error(e);
            await sleep(10000);
        }
    }

}

export function useSignalrProvider() {
    const token = useAuthToken();
    const config = useConfig();

    const [signalrContext, setSignlarContext] = useState<SignalRContextType | null>(null);
    useEffect(() => {
        if (token === null) {
            return;
        }
        const connection = new HubConnectionBuilder()
            .withAutomaticReconnect(new InfiniteReconnect())
            .withUrl(`${config.backendApiUrl}/signalr`, {
                accessTokenFactory(): string | Promise<string> {
                    return token;
                },
            })
            .build();

        const messageSubscriptions = makeSubscribable<IMessageDto>(false);
        const onlineUserSubscriptions = makeSubscribable<IOnlineUserDto>(false);
        const notificationsSubscriptions = makeSubscribable<NotificationDto>(false);
        const chatReadSubscriptions = makeSubscribable<IChatReadDto>(false);
        setSignlarContext({
            onMessage: messageSubscriptions.subscribable,
            onOnline: onlineUserSubscriptions.subscribable,
            onNotification: notificationsSubscriptions.subscribable,
            onChatRead: chatReadSubscriptions.subscribable,
        });

        connection.on("message", (data: IMessageDto) => {
            messageSubscriptions.fire(MessageDto.fromJS(data));
        });
        connection.on("online", (data: IOnlineUserDto) => {
            onlineUserSubscriptions.fire(data);
        });
        connection.on("notification", (data: INotificationDto) => {
            notificationsSubscriptions.fire(NotificationDto.fromJS(data));
        });
        connection.on("chat-read", (data: IChatReadDto) => {
            chatReadSubscriptions.fire(ChatReadDto.fromJS(data));
        });
        connection.onreconnecting(() => {
            console.warn("signalr reconnecting...");
        });
        connection.onreconnected(() => {
            console.warn("signalr reconnected");
        });
        connectUntilSuccess(connection);
        return () => {
            connection.stop();
        };
    }, [token, config.backendApiUrl]);

    return signalrContext;
}

export function useSignalr() {
    return useContext(SignalRContext);
}
