import React from 'react';
import { Link } from 'react-router-dom';
import { CardPost } from './CardPost';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

export function Advertisements() {
    
    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>Annonces - Success Team</title>
                    <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </MetaTags>
            </div>
        	<nav className="siteStyle top-nav">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to='/dashboard'>Dashboard</Link></li>
                    <li className="breadcrumb-item active">Annonces</li>
                </ol>
            </nav>
            <div className="jumbotron d-flex align-items-center bg-dark min-vh-25 mb-0" style={{ backgroundImage: "url('./img/Orange-Background-Free.png')" }}>
                <div className="container text-center">
                    <h1 className="text-white siteStyle font-weight-bolder custom-title">ANNONCES</h1>
                </div>
            </div>

            <div className="bg-white custom-margin">
                <div className="container row mx-auto">
                    <div className="col-md-12">
                        <div className="siteStyle text-center custom-margin-bottom">
                            <h2 className="custom-title2">Nos publications</h2>
                            <p className="custom-subtitle mt-3">Des actualités, conseils, articles et cours pour vous améliorer.</p>
                        </div>
                    </div>
                    <CardPost
                        id="1" author="Cyrille" className=""
                        date="25 avril 2020" title="La Success Team ouvre ses portes"
                        numberComments={2} tags="Annonce, Equipe"
                        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    />
                    <CardPost
                        id="2" author="Ghislain" className=""
                        date="26 avril 2020" title="La Success Team ouvre ses portes"
                        numberComments={2} tags="Annonce, Equipe"
                        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    />
                    <CardPost
                        id="3" author="Aloys" className=""
                        date="27 avril 2020" title="La Success Team ouvre ses portes"
                        numberComments={2} tags="Annonce, Equipe"
                        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
