import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function NestedList({module, i, stepId, count, handleStepId, handleClose}) {
    const classes = useStyles();
    //const [module, setModule] = React.useState([]);
    const [open, setOpen] = React.useState(i===0 ? true : false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <React.Fragment>
            <Divider />
            <ListItem button onClick={handleClick} style={{backgroundColor: "#f2f3f5", fontWeight: "bold"}}>
                <ListItemText disableTypography style={{ color: "#4059bc", fontWeight: "bold" }} primary={"Module " + (i + 1) + ": " + module.moduleName} style={{marginRight: "20px", fontWeight: "bold", color: "#333"}} />
                {!open ? <FontAwesomeIcon icon={faChevronDown} size="sm" /> : <FontAwesomeIcon icon={faChevronUp} size="sm" />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                {
                    (module.steps || []).map((item, index) => (
                        <ListItem button key={item.stepId} className="pl-4" selected={item.stepId===stepId} onClick={() => { handleStepId(item.stepId, count + index); }}>
                            <ListItemText primary={count + (index + 1) + "- " + item.stepName} />
                        </ListItem>
                    ))
                }
                </List>
            </Collapse>
        </React.Fragment>
    );
}
