import React, { Component } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSync, faPen } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import EditNote from "./EditNote";

export default class ProjectNotesManagement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            notes: [],
            noteId:"",
            openNoteDialog:false
        }

        this.handleGetNotes = this.handleGetNotes.bind(this);
        this.handleOpenNoteDialog = this.handleOpenNoteDialog.bind(this);
        this.handleCloseNoteDialog = this.handleCloseNoteDialog.bind(this);
    }

    componentDidMount() {
        this.handleGetNotes();
    }

    handleGetNotes() {
        fetch(new Request("api/Note/GetNotes?projectId=" + this.props.projectId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {

                if (ok) {
                    this.setState({
                        notes: response
                    })
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    handleOpenNoteDialog() {
        this.setState({
            openNoteDialog: true,
            noteId:""
        })
    }

    handleOpenEditNoteDialog(noteId) {
        this.setState({
            openNoteDialog: true,
            noteId: noteId
        })
    }

    handleCloseNoteDialog() {
        this.setState({
            openNoteDialog: false
        })
    }

    render() {
        return (
            <React.Fragment>
                {
                    !this.props.isViewer && 
                    <div className="alert alert-secondary">
                        <Button variant="contained" className="text-initial mr-2" onClick={this.handleOpenNoteDialog} size="small" color="primary">
                            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm" /> Nouvelle
                        </Button>
                        <Button variant="contained" className="text-initial btn-info-custom" onClick={this.handleGetNotes} size="small">
                            <FontAwesomeIcon icon={faSync} className="mr-1" size="sm" /> Rafraîchir
                        </Button>
                    </div>
                }
                
                <Table size="small">
                    <TableHead style={{ backgroundColor: "#000" }}>
                        <TableRow>
                            <TableCell />
                            <TableCell style={{ color: "#FFF" }}>Titre</TableCell>
                            <TableCell style={{ color: "#FFF" }}>Créé le</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (this.state.notes || []).map(item => (
                                <TableRow key={item.noteId}>
                                    <TableCell>
                                        <FontAwesomeIcon icon={faPen} className="pointer"
                                            onClick={() => this.handleOpenEditNoteDialog(item.noteId)} />
                                    </TableCell>
                                    <TableCell>{item.title}</TableCell>
                                    <TableCell>{item.created}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>

                {
                    this.state.openNoteDialog && <EditNote closeDialog={this.handleCloseNoteDialog}
                        projectId={this.props.projectId} noteId={this.state.noteId} />
                }
            </React.Fragment>
        );
    }
}