import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { faBell, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import { useSignalr } from "../api/Signalr";
import {
    JamSessionAddressChangeNotificationDto,
    JamSessionMainRoleAssignmentNotificationDto,
    NotificationDto,
} from "../api/services.generated";
import { useAsyncCallback, useAsyncEffect, useForceUpdate } from "../utils/react";
import { useAuthorizedApi } from "../api/api";
import { useHistory } from "react-router-dom";

import { formatRelative } from "date-fns";
import styled from "styled-components";
import { FlexColumn } from "../utils/StyleHelpers";
import { AsyncButton } from "../utils/AsyncButton";

type NotificationContextType = { readNotification: () => void };
const NotificationContext = createContext<NotificationContextType>(undefined!);

export function Notifications() {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [notifications, setNotifications] = useState<NotificationDto[]>([]);
    const [unread, setUnread] = useState(0);
    const [error, setError] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const api = useAuthorizedApi();

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const signalr = useSignalr();
    useEffect(() => {
        if (signalr) {
            const s = signalr.onNotification((n) => {
                setNotifications((x) => {
                    const index = x.findIndex((y) => y.id === n.id);
                    if (index >= 0) {
                        x[index] = n;
                        return [...x];
                    } else {
                        return [n, ...x];
                    }
                });
                setUnread((x) => x + 1);
            });

            return () => s.unsubscribe();
        }
    }, [signalr]);

    useAsyncEffect(
        async function ({ wrap }) {
            if (!api) {
                return;
            }
            try {
                const r = await wrap(api.member_GetNotifications(null));
                if (r) {
                    setNotifications(r.notifications || []);
                    setUnread(r.totalUnread);
                }
            } catch (e) {
                setError(true);
            }
        },
        [api]
    );

    const loadMore = useAsyncCallback(
        async function ({ wrap }) {
            if (!api) {
                return;
            }
            const r = await wrap(api.member_GetNotifications(notifications[notifications.length - 1]?.id));
            if (r) {
                if (!r.notifications?.length) {
                    setHasMore(false);
                }
                setNotifications([...notifications, ...(r.notifications || [])]);
                setUnread(r.totalUnread);
            }
        },
        [api, notifications]
    );

    const context = useMemo<NotificationContextType>(() => ({ readNotification: () => setUnread((x) => x - 1) }), []);

    return (
        <>
            <Badge badgeContent={error ? "!" : unread} color="error" onClick={handleClick}>
                <FontAwesomeIcon
                    className="mx-2"
                    size="2x"
                    style={{ cursor: "pointer", color: "#3f51b5" }}
                    icon={faBell}
                />
            </Badge>
            <Popover
                id={id}
                open={open}
                keepMounted={true}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <NotificationContext.Provider value={context}>
                    <NotificationsList
                        initialNotifications={notifications}
                        onLoadMore={() => loadMore()}
                        showMore={hasMore}
                    />
                </NotificationContext.Provider>
            </Popover>
        </>
    );
}

export function NotificationsList({
    initialNotifications,
    onLoadMore,
    showMore,
}: {
    initialNotifications: NotificationDto[];
    onLoadMore: () => Promise<void>;
    showMore: boolean;
}) {
    return (
        <div className="d-flex flex-column flex-fill">
            {initialNotifications.map((x) => (
                <Notification key={x.id || ""} notification={x} />
            ))}
            {showMore && <AsyncButton onClickAsync={onLoadMore}>Load more...</AsyncButton>}
        </div>
    );
}

export function Notification({ notification }: { notification: NotificationDto }) {
    if (notification instanceof JamSessionMainRoleAssignmentNotificationDto) {
        return <JamSessionMainRoleAssignmentNotification notification={notification} />;
    }

    if (notification instanceof JamSessionAddressChangeNotificationDto) {
        return <JamSessionAddressChangeNotification notification={notification} />;
    }

    return <></>;
}

const StyledNotificationsContainer = styled.div<{ isRead: boolean }>`
    background: ${(props) => (props.isRead ? undefined : "#edf2fa")};
    border-bottom: 1px solid #dddfe2;
    display: flex;
    padding: 5px;
    cursor: pointer;

    :hover {
        background: #e5eaf2;
    }
`;

export function NotificationHelper({
    children,
    notification,
    to,
}: {
    children: ReactNode;
    notification: NotificationDto;
    to: string;
}) {
    const api = useAuthorizedApi();
    const history = useHistory();
    const fu = useForceUpdate();
    const ctx = useContext(NotificationContext);

    return (
        <StyledNotificationsContainer isRead={notification.isRead} onClick={() => history.push(to)}>
            <FlexColumn style={{ flex: "1 0 auto" }}>
                <div style={{ flex: "1 1 auto" }}>{children}</div>
                <div>{formatRelative(notification.timestamp, new Date())}</div>
            </FlexColumn>
            {!notification.isRead ? (
                <div
                    style={{
                        cursor: "pointer",
                        flex: "0 0 30px",
                        width: "30px",
                        alignSelf: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        size="sm"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (api && notification?.id) {
                                try {
                                    notification.isRead = false;
                                    fu();
                                    ctx.readNotification();
                                    api.member_ReadNotification([notification.id]);
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        }}
                    />
                </div>
            ) : (
                <div style={{ flex: "0 0 30px", width: "30px" }} />
            )}
        </StyledNotificationsContainer>
    );
}

export function JamSessionMainRoleAssignmentNotification({
    notification,
}: {
    notification: JamSessionMainRoleAssignmentNotificationDto;
}) {
    return (
        <NotificationHelper notification={notification} to={"/viewjamsession/" + notification.jamSessionId}>
            <div className="d-flex">
                <div>Vous avez le rôle:</div>
                <div>{notification.role}</div>
            </div>
            <div className="d-flex">
                <div className="flex-fill">dans la JAM SESSION N° {notification.number}</div>
                <div className="flex-fill" style={{ marginLeft: "10px" }}>
                    {notification.theme}
                </div>
            </div>
        </NotificationHelper>
    );
}

export function JamSessionAddressChangeNotification({
    notification,
}: {
    notification: JamSessionAddressChangeNotificationDto;
}) {
    return (
        <NotificationHelper notification={notification} to={"/viewjamsession/" + notification.jamSessionId}>
            <div className="d-flex">
                <div>Location changed to:</div>
                <div>{notification.newAddress}</div>
            </div>
            <div className="d-flex">
                <div className="flex-fill">pour la JAM SESSION N° {notification.number}</div>
                <div className="flex-fill" style={{ marginLeft: "10px" }}>
                    {notification.theme}
                </div>
            </div>
        </NotificationHelper>
    );
}
