import React, {useState, useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { UnauthorizedBackendApiContext } from '../../api/api';
import { BackendService } from '../../api/services.generated';

export default function ForgotPassword({onClose}:{onClose:()=>void}){
    
    const api: BackendService | null = useContext(UnauthorizedBackendApiContext)
    const [email, setEmail] = useState<string | null>("");
    const [open, setOpen] = useState<boolean>(true);
    const [btnSendLoading, setBtnSendLoading] = useState<boolean>(false);

    function closeModal(){
        setOpen(false);
        onClose();
    }

    function hanleInput(e:React.ChangeEvent<HTMLInputElement>) {
        setEmail(e.target.value);
    }

    async function sendRequest(){
        if (!api) {
            return;
        }

        setBtnSendLoading(true);
        try
        {
            const response:any = await api.member_ForgotPassword(email);
            if(response){
                toast.success(response.message + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
                closeModal();
            }
        }
        catch(error) {
            setBtnSendLoading(false);
            toast.warn(error + " !", {
                hideProgressBar: false,
                position: "top-left"
            });
        };
    }

    return(
        <Modal show={open} onHide={()=>closeModal()}>
            <Modal.Header closeButton>
                    <Modal.Title>Mot de passe oublié</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group row">
                    <label className="col-md-2">Email</label>
                    <div className="col-md-10">
                        <input type="email" onChange={(e)=>hanleInput(e)} name="email" className="form-control" />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                
                <Button variant="contained" color="primary" onClick={()=>sendRequest()} size="small" className="text-initial mr-2"
                    disabled={btnSendLoading}>
                    <FontAwesomeIcon icon={faPaperPlane} className="mr-2" /> Envoyer
                </Button>
                <Button variant="contained" color="default" onClick={()=>closeModal()} size="small" className="text-initial">
                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> Fermer
                </Button>
            </Modal.Footer>
        </Modal>

    );
}