import React, { Component } from 'react';
import RegisterJamSession from './Register/RegisterJamSession';
import MembersJamSession from './ListMembersJamSession';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faUsers, faChartPie } from "@fortawesome/free-solid-svg-icons";
import Badge from '@material-ui/core/Badge';
import { toast } from 'react-toastify';

export default class DetailsJamSession extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tabValue: 0,
            showRegister: true,
            showMembers: false,
            membersRegisteredCount:0
        }

        this.handleChangeTab = this.handleChangeTab.bind(this);
    }

    componentDidMount() {
        fetch(new Request("api/JamSession/GetMembersRegisteredCount?jamSessionId=" + this.props.location.state.jamSessionId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        membersRegisteredCount: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
            })
    }

    handleChangeTab(e, value) {
        this.setState({
            tabValue: value,
            showMembers: value === 1 && !this.state.showMembers ? true : true
        });
    }

    render() {
        return (
            <Paper className="p-4">
                <Tabs id="jamSessionTabs" style={{ borderRight: "1px solid #0000001f" }}
                         variant="fullWidth" value={this.state.tabValue}
                        textColor="primary" onChange={this.handleChangeTab} aria-label="disabled tabs example">
                    <Tab label="Inscription" icon={<FontAwesomeIcon icon={faPen} className="fa-lg" />} />
                    <Tab label="Inscrits" icon={
                        <Badge className="p-1" color="primary" badgeContent={this.state.membersRegisteredCount}>
                            <FontAwesomeIcon icon={faUsers} className="fa-lg" />
                        </Badge>
                    } />
                    <Tab label="Graphe" icon={<FontAwesomeIcon icon={faChartPie} />} className="fa-lg" />
                </Tabs>
                <div role="tabpanel" hidden={this.state.tabValue !== 0} className="m-4">
                        {
                        this.state.showRegister && <RegisterJamSession isRegistered={this.props.location.state.isRegistered}
                            jamSessionId={this.props.location.state.jamSessionId} />
                        }
                </div>
                <div role="tabpanel" hidden={this.state.tabValue !== 1} className="m-4 w-100">
                    {
                        this.state.tabValue === 1 && <MembersJamSession  jamSessionId={this.props.location.state.jamSessionId} />
                    }
                </div>
            </Paper>
            );
    }
}

