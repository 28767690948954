import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faComment } from '@fortawesome/free-solid-svg-icons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

export function CardPost({id, className, title, date, content, tags, numberComments, author} : {id: string, className: string, title: string, date: string, content: string, tags: string, numberComments: number, author: string}) {
    return (
        <Link className="pointer" to={"post/" + id} style={{ display: 'contents' }}>
            <div className={"col-md-4 siteStyle " + className}>
                <Card className="HookCard my-2 siteStyle shadow-lg cardImg-container">
                    <CardMedia
                        className="HookCardMedia cardImg-image"
                        component="img"
                        height="140"
                        //src="./img/meeting-image.jpg"
                        image={"https://picsum.photos/400/180?random=" + id}
                    />
                    <CardContent className="siteStyle px-3">
                        <div className="custom-text font-weight-bolder mb-2">{title}</div>
                        <div className="mt-2">
                            <span className="text-left">Par <strong>{author}</strong></span>
                            <span className="float-right">{date}</span>
                        </div>
                        <div className="mt-2">{content}</div>
                        <div className="mt-4">
                            <span className="text-left">
                                <FontAwesomeIcon className="mr-1" icon={faTag} size="sm" /> {tags}
                            </span>
                            <span className="float-right">
                                <FontAwesomeIcon className="mr-1" icon={faComment} size="sm" /> {numberComments}
                            </span>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </Link>
    );
}
