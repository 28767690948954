import React, { useState } from "react";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import { TextField } from "@material-ui/core";
import { BackendService, FileResponse } from "../../api/services.generated";
import { useAuthorizedApi, useAuthorizedBackendApi } from "../../api/api";

interface TodoProps {
    stepId: string,
    todoId: string | null | undefined,
    title: string,
    description: string,
    validated: string,
    refresh: () => void
}

interface TodoDto {
    toDoId?: string | null | undefined, 
    title?: string | null | undefined, 
    description?: string | null | undefined, 
    validated?: boolean | undefined, 
    stepId?: string | null | undefined, 
    projectId?: string | null | undefined
}

export function StepEditTodo(props: TodoProps) {
    const api: BackendService | null = useAuthorizedBackendApi();
    const [stepId, setStepId] = useState<string>(props.stepId);
    const [todoId, setTodoId] = useState<string | null | undefined>(props.todoId);
    const [title, setTitle] = useState<string | null | undefined>(props.title);
    const [description, setDescription] = useState<string | null | undefined>(props.description);
    const [validated, setValidated] = useState<string | undefined>(props.validated);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    
    function initFields() {
        setTitle("");
        setDescription("");
        setValidated(undefined);
        setLoadingData(false);
    }

    async function saveTodo() {
        setLoadingData(true);
        /*
        if(!api) {
            return;
        }

        try {
            const response:FileResponse | null = await api.toDo_SetTodo({
                toDoId: todoId, 
                title: title, 
                description: description, 
                validated: validated, 
                stepId: stepId,
                projectId: undefined
            } as TodoDto);

            if (!response) {
                return;
            }

            initFields();

            toast.success("Mise à jour effectuée !", {
                position: "top-center",
                hideProgressBar: false
            });
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
        finally {
            setLoadingData(false);
        }
        */
        
        fetch(new Request("api/Todo/SetTodo", {
            method: "POST",
            body: JSON.stringify({
                StepId: stepId,
                TodoId: todoId || "",
                Title: title,
                Validated: validated || false,
                Description: description
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);

        })
        .then(([ok, response]) => {
            if (ok) {
                initFields();

                toast.success("Mise à jour effectuée !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                props.refresh();
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
            setLoadingData(false);
        });
    }

    return (
        <React.Fragment>
            <div className="alert alert-secondary">
                <Button variant="contained" size="small" className="mr-1 text-capitalize btn-success-custom"
                    onClick={saveTodo} disabled={loadingData}>
                    <FontAwesomeIcon icon={faSave} className="mr-1" size="sm" /> Enregistrer
                </Button>
            </div>

            <div className="form-group">
                <TextField
                    fullWidth name="title" value={title}
                    onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setTitle(e.target.value)}
                    label="Titre" variant="filled"
                />
            </div>
            <div className="form-group">
                {validated}
            </div>
            <div className="form-group">
                <TextField
                    fullWidth name="description" value={description}
                    onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setDescription(e.target.value)}
                    label="Description" variant="filled" multiline rows="3"
                />
            </div>
        </React.Fragment>
    );
}