import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import EditJamSession from './EditJamSession/EditJamSession';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import MembersJamSession from './ListMembersJamSession';
import JamSessionTableView from './JamSessionsTableView';
import JamSessionsCardView from './JamSessionsCardView';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

class JamSessionsList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openModalEditJamSession: false,
            jamSessionsTab: [],
            jamSessions: [],
            jamSessionId: "",
            openModalMembersJamSession: false,
            loadJamSessionData: true,
            visibility: true,
            jamSessionsComing: [],
            jamSessionsClosed: [],
            viewTable: localStorage.getItem("jamSessionTableView") !== null ? 
                localStorage.getItem("jamSessionTableView") === "true" : false
        };


        this.handleOpenModalJamSession = this.handleOpenModalJamSession.bind(this);
        this.handleCloseModalEditJamSession = this.handleCloseModalEditJamSession.bind(this);
        this.refreshJamSessionTable = this.refreshJamSessionTable.bind(this);
        this.handleCloseModalMembersJamSession = this.handleCloseModalMembersJamSession.bind(this);
        this.changeViewJamSession = this.changeViewJamSession.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem("participation") !== undefined) {
            this.props.participationJamSession(localStorage.getItem("participation"),
                localStorage.getItem("jamSessionId"));

            localStorage.removeItem("participation");
            localStorage.removeItem("jamSessionId");
        }

        this.refreshJamSessionTable();
    }

    handleOpenModalJamSession() {
        this.setState({
            openModalEditJamSession: true
        });
    }

    handleCloseModalEditJamSession() {
        this.setState({
            openModalEditJamSession: false
        });
    }

    refreshJamSessionTable() {
        this.setState({
            loadJamSessionData: true
        });

        fetch(new Request("api/JamSession/GetJamSessions", {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        jamSessionsTab: response,
                        jamSessions: response,
                        loadJamSessionData: false
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    loadJamSessionData: false
                });
            });
    }

    handleCloseModalMembersJamSession() {
        this.setState({
            jamSessionId: "",
            openModalMembersJamSession:false
        });
    }

    changeViewJamSession() {
        this.setState({
            viewTable: !this.state.viewTable
        });
        localStorage.setItem("jamSessionTableView", !this.state.viewTable);
    }

    filterJamSessions(visibility) {
        /*
        if (visibility === 'all') {
            this.setState({jamSessionsTab: jamSessions.filter(elm => elm.isClosed === false) })
        }
        */
    }

    render() {
        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>JamSessions - Success Team</title>
                        <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                    </MetaTags>
                </div>
                <nav className="siteStyle top-nav">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to='/dashboard'>Dashboard</Link></li>
                        <li className="breadcrumb-item active"><h1 className="siteTitle">Jamsessions</h1></li>
                    </ol>
                </nav>
                <div className="card-body">
                    <div>
                        <div className="alert alert-secondary w-100" style={{ display: "flow-root" }}>
                            {
                                this.props.isAdmin && <React.Fragment>
                                    <Button component={Link} to="/jamsession" className="mr-2 text-capitalize" color="primary" variant="contained" size="small" /*onClick={this.handleOpenModalJamSession}*/>
                                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm" /> Nouveau
                                    </Button>
                                </React.Fragment>
                            }
                            <Button className="text-capitalize btn-info-custom" variant="contained" size="small" onClick={this.refreshJamSessionTable}>
                                <FontAwesomeIcon icon={faSync} className="mr-1" size="sm" /> Rafraîchir
                            </Button>
                        </div>

                        {
                            this.state.loadJamSessionData ?
                                <div className="mx-auto text-center"><Skeleton /></div>
                                : this.state.viewTable ? <JamSessionTableView isAdmin={this.props.isAdmin} jamSessions={this.state.jamSessionsTab} />
                                    : <JamSessionsCardView isAdmin={this.props.isAdmin} jamSessions={this.state.jamSessionsTab} />
                        }

                    </div>
                </div>
                {
                    this.state.openModalEditJamSession && <EditJamSession onCloseModal={this.handleCloseModalEditJamSession} />
                }

                {
                    this.state.openModalMembersJamSession && <MembersJamSession jamSessionId={this.state.jamSessionId}
                        onCloseModal={this.handleCloseModalMembersJamSession} />
                }
            </React.Fragment>
            );
    }
}

const MapDispatchToProps = dispatch => {
    return {
        participationJamSession: (participation, jamSessionId) => {
            dispatch({
                type: "PARTICIPATION_STATUS",
                participationJamSession: participation,
                jamSessionId: jamSessionId
            });
        }
    };
}

export default connect(null, MapDispatchToProps)(JamSessionsList);
