import React, { Component } from "react";
import { DesignTest } from "./DesignTest";

export class Journeys extends Component {
    static displayName = Journeys.name;

    constructor(props) {
        super(props);
        this.state = {
            currentCount: 0,
            openModalComingSoon: false,
            firstJourney: [
                {
                    "title": "Evaluation",
                    "description": "Mesure de l'efficacité du plan du succès. Ajustement et amélioration si besoin.",
                    "icon": "fa fa-check",
                    "style": "bg-success"
                },
                {
                    "title": "Progression",
                    "description": "Suivi du plan du succès. Développement du leadership.",
                    "icon": "fa fa-chart-area",
                    "style": "bg-primary"
                },
                {
                    "title": "Engagement",
                    "description": "Participation aux Jamsession et aux activités des équipes.",
                    "icon": "fa fa-user",
                    "style": "bg-warning"
                },
                {
                    "title": "Apprentissage",
                    "description": "Suivi du catalogue de formation.",
                    "icon": "fa fa-graduation-cap",
                    "style": "bg-secondary"
                },
                {
                    "title": "Définition du succès",
                    "description": "Session de définition de la vision et des objectifs. Elaboration du plan de succès.",
                    "icon": "fa fa-bolt",
                    "style": "bg-danger"
                }
            ]
        };
        this.incrementCounter = this.incrementCounter.bind(this);
        this.handleOpenModalComingSoon = this.handleOpenModalComingSoon.bind(this);
    }

    //componentDidMount() {
    //    var arr1 = [
    //        { "title": "Evaluation", "description": "Mesure de l'efficacité du plan du succès. <br /> Ajustement et amélioration si besoin.", "icon": "fa fa-check" },
    //        { "title": "Progression", "description": "Suivi du plan du succès. <br /> Développement du leadership.", "icon": "fa fa-chart-area" },
    //        { "title": "Engagement", "description": "Participation aux Jamsession et aux activités des équipes.", "icon": "fa fa-user" },
    //        { "title": "Apprentissage", "description": "Suivi du catalogue de formation.", "icon": "fa fa-graduation-cap" },
    //        { "title": "Définition du succès", "description": "Session de définition de la vision et des objectifs. <br /> Elaboration du plan de succès.", "icon": "fa fa-bolt" }
    //    ];
    //}

    incrementCounter() {
        this.setState({
            currentCount: this.state.currentCount + 1
        });
    }

    handleOpenModalComingSoon() {
        this.setState({
            openModalComingSoon: !this.state.openModalComingSoon
        });
    }

    render() {
        return (
            <div className="px-0">
                <DesignTest steps={this.state.firstJourney}/>
            </div>
        );
    }
}
