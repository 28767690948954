import React, { Component } from 'react';
//import myConfig from '../../config/Config';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import { VerifiedInput } from '../../global/VerifiedInput';
import TextField from "@material-ui/core/TextField";
import axios from 'axios';
import { getBase64 } from '../../../api/api';

export default class EditTeam extends Component {

    constructor(props) {
        super(props);

        this.state = {
            teamId: this.props.teamId, 
            teamName: "", 
            teamDescription:"",
            teamLogo:undefined
        }

        this.saveTeam = this.saveTeam.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleLogoFile = this.handleLogoFile.bind(this);
    }

    componentDidMount(){
        if(this.props.teamId !== "" && this.props.teamId !== undefined && this.props.teamId !== null){
            this. getTeam();
            this.handleDownloadLogoFile();
        }
    }

    getTeam(){
        fetch(new Request("api/Team/GetTeamEdit?teamId=" + this.props.teamId), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if(ok){
                    this.setState({
                        teamName: response.teamName,
                        teamDescription: response.description
                    });
                }else{
                    throw new Error(response);
                }
                
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    handleLogoFile(e){
        if(this.state.teamId !== "" && this.state.teamId !== undefined && this.state.teamId !== null){
            var formData = new FormData();
            var file = e.target.files[0];
            formData.append("TeamId", this.state.teamId);
            formData.append("File", file);

            axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
            axios.post("api/Team/UploadTeamLogo", formData)
                .then(() => {
                    getBase64(file,(result)=>{
                        this.setState({
                            teamLogo:result
                        });
                    },()=>{
                        this.setState({
                            teamLogo:null
                        });
                    })
                })
                .catch(error => {
                    this.setState({
                        teamLogo:null
                    });
                    toast.warn(error + " !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                });
        }else{
            toast.info("Vous devez d'abord créer une équipe. Ensuite vous pouvez charger son logo !", {
                position: "top-left",
                hideProgressBar: false
            });
        }
    }

    handleDownloadLogoFile() {
        fetch(new Request("api/Team/DownloadTeamLogo?teamId=" + this.props.teamId), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if(ok){
                    if(response !== null){
                        this.setState({ 
                            teamLogo: "data:"+response.contentType+";base64,"+response.img64
                        });
                    }
                    
                }else{
                    throw new Error(response);
                }
                
            })
            .catch(error => {
                this.setState({
                    teamLogo: null
                });
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    saveTeam() {
        this.setState({
            loadingTeam: true
        });

        fetch(new Request("api/Team/SetTeam", {
            method: "POST",
            body: JSON.stringify({
                TeamId: this.props.teamId,
                TeamName: this.state.teamName,
                Description: this.state.teamDescription
            }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        teamId: "",
                        teamName: ""
                    });

                    toast.success("Mise à jour effectuée !", {
                        hideProgressBar: false,
                        position: "top-left"
                    });
                    this.props.refresh();
                    this.setState({
                        loadingTeam: false
                    });
                } else {
                    throw new Error(response);
                }
                
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
                this.setState({
                    loadingTeam: false
                });
            });
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="alert alert-secondary">
                    {
                        this.props.isAdmin && 
                        <Button variant="contained" size="small" className="mr-1 text-capitalize btn-success-custom" onClick={this.saveTeam}>
                            <FontAwesomeIcon icon={faSave} className="mr-1" size="sm" /> Enregistrer
                        </Button>
                    }
                    <Button variant="contained" size="small" className="mr-1 text-capitalize" onClick={()=>this.props.onClose()}>
                        <FontAwesomeIcon icon={faTimesCircle} className="mr-1" size="sm" /> Fermer
                    </Button>
                    
                </div>
                
                <div className="form-group">
                    <label>Logo Equipe</label>
                    <div className="text-center">
                        <img src={!this.state.teamLogo ? "/img/empty_img.jpg" : this.state.teamLogo} className={this.props.isAdmin ? "pointer " : "" + "rounded"} 
                        style={{height:"200px",width:"200px"}} onClick={()=>this.props.isAdmin && document.getElementById("logoFile").click()} />
                    </div>
                </div>
                <div className="form-group">
                    <TextField name="teamName" variant="filled" label="Nom équipe" value={this.state.teamName} onChange={this.handleInput} 
                        disabled={!this.props.isAdmin} className="w-100" />
                </div>
                <div className="form-group">
                    <TextField multiline rows={5} variant="filled" label="Description" value={this.state.teamDescription}
                        onChange={this.handleInput} className="w-100" disabled={!this.props.isAdmin} name="teamDescription"  />
                </div>
                {
                    this.props.isAdmin && <input type="file" hidden onChange={this.handleLogoFile} id="logoFile" />
                }
                
            </React.Fragment>
        );
    }
}