import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import './jamSession.css';
import { connect } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';


class EditJamSessionInvitation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            memberTypeInvitation: this.props.isAdmin ? true : false,
            firstName: "",
            lastName: "",
            email: "",
            comment: "",
            membersSelected: [],
            btnSendInvitation: false,
            openSnackbar: false,
            snackbarMessage: "",
            members: this.props.members
        }

        this.handleRadioChanged = this.handleRadioChanged.bind(this);
        this.sendInvitationMember = this.sendInvitationMember.bind(this);
        this.handleAutocompleteMemberInvitation = this.handleAutocompleteMemberInvitation.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    }

    componentDidMount() {
        if (this.props.members === undefined) {
            this.getMembers();
        }
    }

    getMembers() {
        fetch(new Request("api/Member/GetMembersName"), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        members: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    handleRadioChanged() {
        this.setState({
            memberTypeInvitation: !this.state.memberTypeInvitation
        });
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleAutocompleteMemberInvitation(event, value) {
        var array = [];
        value.map(item => array.push(item.id));
        this.setState({
            membersSelected: array
        });
    }

    handleSnackbarClose() {
        this.setState({
            openSnackbar: false
        });
    }

    sendInvitationMember() {
        var body;

        this.setState({
            btnSendInvitation: true
        });

        if (this.state.memberTypeInvitation) {
            var array = [];
            this.state.membersSelected.map(item => array.push({ UserId: item }));
            body = JSON.stringify({
                jamSessionId: this.props.jamSessionId,
                InvitedMembers: array
            });
        } else {
            body = JSON.stringify({
                jamSessionId: this.props.jamSessionId,
                InvitedMembers: [{
                    UserId: "", FirstName: this.state.firstName, LastName: this.state.lastName,
                    Email: this.state.email, Comment: this.state.comment
                }]
            });
        }

        fetch(new Request("api/JamSession/SendInvitationMembers", {
            method: "POST",
            body: body,
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    var array = [];
                    response.names.map(item => array.push({
                        fullName: item,
                        isMember: this.state.memberTypeInvitation,
                        invitedByName: response.invitedByName
                    }));

                    this.setState({
                        lastName: "",
                        firstName: "",
                        email: "",
                        comment: "",
                        btnSendInvitation: false
                    });

                    this.props.addInvitation(array);

                    this.setState({
                        snackbarMessage: "Invitation envoyée",
                        openSnackbar: true
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                this.setState({
                    btnSendInvitation: false
                });

                toast.warn(error+" !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.isAdmin && <div className="form-group">
                        <Radio name="memberRadio" onClick={this.handleRadioChanged}
                            checked={this.state.memberTypeInvitation} id="memberRadio" color="primary" /> <label htmlFor="memberRadio">Membre</label>
                        <Radio name="memberRadio" onClick={this.handleRadioChanged}
                            checked={!this.state.memberTypeInvitation} id="NotMemberRadio" color="primary" /> <label htmlFor="NotMemberRadio">Non Membre</label>
                    </div>
                }
                

                {
                    this.props.isAdmin && this.state.memberTypeInvitation && <div className="form-group">
                        <Autocomplete multiple options={this.state.members} getOptionLabel={o => o.fullName}
                            onChange={this.handleAutocompleteMemberInvitation} renderInput={params => (
                                <TextField {...params} label="Sélectionner membres" fullWidth variant="filled" />
                            )} />
                    </div>
                }

                {
                    !this.state.memberTypeInvitation &&
                    <React.Fragment>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-6 mb-3 mb-sm-0">
                                    <TextField label="Prénom" value={this.state.firstName} name="firstName" onChange={this.handleInput} fullWidth variant="filled" />
                                </div>
                                <div className="col-md-6">
                                    <TextField label="Nom" value={this.state.lastName} name="lastName" onChange={this.handleInput} fullWidth variant="filled" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <TextField type="email" value={this.state.email} name="email" onChange={this.handleInput}
                                label="Email" fullWidth variant="filled" FormHelperTextProps={{
                                    className: "dominantColor"
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <TextField multiline rows="2" value={this.state.comment} name="comment" onChange={this.handleInput} label="Commentaire" fullWidth variant="filled" />
                        </div>
                    </React.Fragment>
                }

                <div className="form-group">
                    <Button variant="contained" color="primary" className="text-capitalize" onClick={this.sendInvitationMember} disabled={this.state.btnSendInvitation}>
                        <FontAwesomeIcon icon={faPaperPlane} className="mr-2" /> Inviter
                    </Button>
                </div>

                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }} open={this.state.openSnackbar} onClose={this.handleSnackbarClose} autoHideDuration={5000}
                    action={[<IconButton key="close" aria-label="close" color="inherit"
                        className="p-1" onClick={this.handleSnackbarClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>]}>
                    <MuiAlert onClose={this.handleSnackbarClose} severity="success">
                        {this.state.snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </React.Fragment>
        );
    }
}

const MapDispatchToProps = dispatch => {
    return {
        addInvitation: item => {
            dispatch({
                type: "ADD_INVITATION",
                invitationItem: item
            });

        }
    }
}

const MapStateToProps = state => {
    return {
        isAdmin: state.isAdmin
    };
}

export default connect(MapStateToProps, MapDispatchToProps)(EditJamSessionInvitation);