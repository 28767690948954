import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import HomeJamSessionCard from './HomeJamSessionCard';
// @ts-ignore
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSign, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { FileResponse, JamSessionDto } from '../api/services.generated';
import { useAuthorizedApi } from '../api/api';

export function HomeJamSessions() {
    const api = useAuthorizedApi();
    const [modal, setModal] = useState<boolean>(false);
    const [jamSessionsTab, setJamSessionsTab] = useState<Array<any>>([]);
    const [loadJamSessionData, setLoadJamSessionData] = useState<boolean>(false);
    const [jamSessionId, setJamSessionId] = useState<string>("");

    useEffect(() => {
        //refreshJamSessionTable();
        getJamSessions();
    }, []);
    
    async function getJamSessions() {
        setLoadJamSessionData(true);

        try {
            if (!api) {
                return;
            }

            const response: FileResponse | null = await api.jamSession_GetJamSessions()
            if (!response) {
                return;
            }

            console.log(response);
            //setJamSessionsTab(response);
            setLoadJamSessionData(false);
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }
        finally
        {
            setLoadJamSessionData(false);
        }
    }
    
    function refreshJamSessionTable() {
        setLoadJamSessionData(true);
        
        fetch(new Request("api/JamSession/GetJamSessions", {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }

            return response.json();
        })
        .then(response => {
            setJamSessionsTab(response.slice(0, 3));
            setLoadJamSessionData(false);
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
            setLoadJamSessionData(false);
        });
    }

    function toggle() {
        setModal(!modal);
    }

    return (
        <div className="row mt-4">
            {
                !loadJamSessionData ?
                    jamSessionsTab.map(row => (
                        <React.Fragment key={row.jamSessionId}>
                            <HomeJamSessionCard jamSessionNumber={row.jamSessionNumber}
                                theme={row.theme} date={row.date} address={row.address} closed={row.closed}
                                jamSessionId={row.jamSessionId} membersRegistered={row.members !== null ? row.members.length : 0}
                                className="px-4" 
                            />
                        </React.Fragment>
                    )) :
                    <React.Fragment>
                        <div className="col-lg-3 col-md-6 px-4">
                            <Skeleton />
                        </div>
                        <div className="col-lg-3 col-md-6 px-4">
                            <Skeleton />
                        </div>
                        <div className="col-lg-3 col-md-6 px-4">
                            <Skeleton />
                        </div>
                        <div className="col-lg-3 col-md-6 px-4">
                            <Skeleton />
                        </div>
                    </React.Fragment>
            }

            <Modal isOpen={modal} toggle={toggle} className="siteStyle" style={{ position: "relative", top: "25vh" }}>
                <ModalHeader toggle={toggle}>Connexion</ModalHeader>
                <ModalBody>
                    <p>Vous avez vous déjà un compte? Cliquez sur "s'identifier"</p>
                    <p>Sinon, cliquez sur "S'inscrire" pour rejoindre le groupe Success Team!</p>
                </ModalBody>
                <ModalFooter>
                    <Link to="/signup" className="pointer">
                        <Button className="text-capitalize btn-warning-custom mr-3" size="medium" variant="contained">
                            <FontAwesomeIcon icon={faSignInAlt} className="mr-1" /> S'inscrire
                        </Button>
                    </Link>
                    <Link to="login" className="pointer">
                        <Button className="text-capitalize bg-white" size="medium" variant="contained" color="default">
                            <FontAwesomeIcon icon={faSign} className="mr-1" /> S'identifier
                        </Button>
                    </Link>
                </ModalFooter>
            </Modal>
        </div>
    );
}
