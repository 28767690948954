import React, { Component } from 'react';
import { connect } from 'react-redux';

class Layout extends Component {
  static displayName = Layout.name;
    
    constructor(props) {
        super(props);
        this.state = {
            heightContent: (window.innerHeight - 250) + "px",
            memberDropdownShow: false,
            masterDataDropdownShow:false,
            openModalCreateMember: false,
            title: this.props.isAdmin ? "MEMBERS" : "JAM SESSIONS",
            openCollapse:false
        };

        this.handleMemberDropdownMenu = this.handleMemberDropdownMenu.bind(this);
        this.handleMemberCloseDropDown = this.handleMemberCloseDropDown.bind(this);
        this.HandleCloseModalCreateMember = this.HandleCloseModalCreateMember.bind(this);
        this.handleOpenModalCreateMember = this.handleOpenModalCreateMember.bind(this);
        this.handleActiveEventTab = this.handleActiveEventTab.bind(this);
        this.handleActivePageMember = this.handleActivePageMember.bind(this);
        this.handleActivePageEquipe = this.handleActivePageEquipe.bind(this);
        this.handleActivePageJamSessionRole = this.handleActivePageJamSessionRole.bind(this);
        this.handleActivePageProgram = this.handleActivePageProgram.bind(this);
        this.handleActivePageModule = this.handleActivePageModule.bind(this);
        this.handleActivePageProject = this.handleActivePageProject.bind(this);
        this.handleMasterDataDropdownMenu = this.handleMasterDataDropdownMenu.bind(this);
        this.handleMasterDataCloseDropDown = this.handleMasterDataCloseDropDown.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleMemberDropdownMenu() {
        this.setState({ memberDropdownShow: true }, () => {
            document.addEventListener("click", this.handleMemberCloseDropDown);
        });
    }

    handleMemberCloseDropDown() {
        this.setState({ memberDropdownShow: false }, () => {
            document.removeEventListener("click", this.handleMemberCloseDropDown);
        });
    }

    handleMasterDataDropdownMenu() {
        this.setState({ masterDataDropdownShow: true }, () => {
            document.addEventListener("click", this.handleMasterDataCloseDropDown);
        });
    }

    handleMasterDataCloseDropDown() {
        this.setState({ masterDataDropdownShow: false }, () => {
            document.removeEventListener("click", this.handleMasterDataCloseDropDown);
        });
    }

    HandleCloseModalCreateMember() {
        this.setState({
            openModalCreateMember: false
        });
    }

    handleOpenModalCreateMember() {
        this.setState({
            openModalCreateMember: true
        });
    }

    handleActiveEventTab() {
        this.setState({
            title: "JAM SESSIONS"
        });
        this.props.pageSelected(false, true,false);
    }

    handleActivePageMember() {
        this.setState({
            title:"MEMBERS"
        });
        this.props.pageSelected(true, false, false);
    }

    handleActivePageEquipe() {
        this.setState({
            title: "EQUIPE"
        });
        this.props.pageSelected(false, false, true);
    }

    handleActivePageJamSessionRole() {
        this.setState({
            title: "ROLE"
        });
        this.props.pageSelected(false, false, true);
    }

    handleActivePageProgram() {
        this.setState({
            title: "PROGRAMMME"
        });
        this.props.pageSelected(false, false, true);
    }

    handleActivePageModule() {
        this.setState({
            title: "MODULE"
        });
        this.props.pageSelected(false, false, true);
    }

    handleActivePageProject() {
        this.setState({
            title: "PROJET"
        });
        this.props.pageSelected(false, false, true);
    }

    render() {

        return (
            <React.Fragment>
                { this.props.children }
            </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        isAdmin: state.isAdmin,
        activePageMember: state.activePageMember,
        activePageJamSession: state.activePageJamSession
    };
}

const mapDispatchToProps = dispatch => {
    return {
        pageSelected: (member, jamsession, masterdata) => {
            dispatch({
                type:"PAGE_CHANGED",
                pageMemberSelected: member,
                pageJamSessionSelected: jamsession,
                pageMasterDataSelected:masterdata
            });
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
