import React from "react";
import ReactExport from "react-export-excel";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export class ButtonExcel extends React.Component {
    static displayName = ButtonExcel.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ExcelFile 
                element={
                    <Button variant="contained" size="small" color="primary" className="mr-2 btn-success-custom text-initial">
                        <FontAwesomeIcon icon={faFileExcel} className="mr-1" size="sm" /> Télécharger la liste
                    </Button>
                }
                filename={"Membres-SuccessTeam-" + (new Date()).toISOString().replace(/^(?<year>\d+)-(?<month>\d+)-(?<day>\d+)T.*$/, '$<day>-$<month>-$<year>')}
                //fileExtension="csv"
            >
                <ExcelSheet data={this.props.data} name="Membres">
                    <ExcelColumn label="Noms et prénoms" value="fullName"/>
                    <ExcelColumn label="Equipe" value="teamName"/>
                    <ExcelColumn label="Genre" value="genre"/>
                    <ExcelColumn label="Téléphone" value="phoneNumber"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn label="Ville" value="city"/>
                    <ExcelColumn label="Pays" value="country"/>
                    <ExcelColumn label="Bloqué ?" value={(col) => col.locked ? "Oui" : "Non"}/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}