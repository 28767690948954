import React, { Component } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

export class VerifiedInput extends Component {
    displayName = VerifiedInput.name

    constructor(props) {
        super(props);
        this.state = { passwordIsMasked: true, isAlert: this.props.checked || false };

        this.checkValidation = this.checkValidation.bind(this);
        this.checkValue = this.checkValue.bind(this);
        this.checkDateValue = this.checkDateValue.bind(this);
        this.togglePasswordMask = this.togglePasswordMask.bind(this);
    }

    togglePasswordMask() {
        this.setState({ passwordIsMasked: !this.state.passwordIsMasked });
    }

    checkValidation() {
        if (this.props.value === "") {
            this.setState({ isAlert: true });
        }
        else {
            this.setState({ isAlert: false });
        }
    }

    checkValue(e) {
        if (e.target !== undefined) {
            if (e.target.value === "") {
                this.setState({ isAlert: true });
            }
            else {
                this.setState({ isAlert: false });
            }
        }
        else {
            this.setState({ isAlert: true });
        }
    }

    checkDateValue(e) {
        if (typeof e === 'undefined') {
            this.setState({ isAlert: true });
        }
        else {
            this.setState({ isAlert: false });
        }
    }

    render() {
        if (this.props.type === "textarea") {
            return (
                <React.Fragment>
                    <TextField
                        error={this.props.checked}
                        className={"site-input " + this.props.className}
                        fullWidth multiline rowsMax={this.props.rowsMax} rows={this.props.rows}
                        variant="filled" label={this.props.title} name={this.props.name}
                        value={this.props.value} onChange={(e) => { this.props.onChange(e); }}
                        helperText={this.props.checked ? this.props.errorMessage : ""}
                        disabled={this.props.disabled !== undefined ? this.props.disabled : false}
                    />
                </React.Fragment>
            );
        }
        if (this.props.type === "date") {
            return (
                <React.Fragment>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            //margin="normal"
                            autoOk
                            variant="inline"
                            inputVariant="filled"
                            id={this.props.id}
                            label={this.props.title}
                            format="dd/MM/yyyy"
                            value={this.props.value}
                            onChange={(e) => { this.props.onChange(e); }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date'
                            }}
                            className="w-100"
                        />
                    </MuiPickersUtilsProvider>
                    {
                        this.props.checked ? <FormHelperText id={"error-" + this.props.id}>{this.props.errorMessage}</FormHelperText> : null
                    }
                </React.Fragment>
            );
        }
        else if (this.props.type === "number") {
            return (
                <React.Fragment>
                    <TextField
                        error={this.props.checked} type="number"
                        className={"site-input " + this.props.className} fullWidth
                        variant="filled" label={this.props.title} name={this.props.name}
                        value={this.props.value} onChange={(e) => { this.props.onChange(e); }}
                        helperText={this.props.checked ? this.props.errorMessage : ""}
                    />
                </React.Fragment>
            );
        }
        else if (this.props.type === "password") {
            return (
                <React.Fragment>
                    <TextField
                        error={this.props.checked} fullWidth
                        className={"site-input " + this.props.className}
                        type="password"
                        variant="filled" label={this.props.title} name={this.props.name}
                        value={this.props.value} onChange={(e) => { this.props.onChange(e); }}
                        //onBlur={(e) => { this.props.onBlur(e); } || undefined }
                        helperText={this.props.checked ? this.props.errorMessage : ""}
                        InputProps={{
                            type: "password",
                            autoComplete: 'new-password',
                        }}
                    />
                </React.Fragment>
            );
        }
        else if (this.props.type === "email") {
            return (
                <React.Fragment>
                    <TextField
                        error={this.props.checked} type="email"
                        className={"site-input " + this.props.className} fullWidth
                        variant="filled" label={this.props.title} name={this.props.name}
                        value={this.props.value} onChange={(e) => { this.props.onChange(e); }}
                        //onBlur={(e) => { this.props.onBlur(e); }}
                        helperText={this.props.checked ? this.props.errorMessage : ""}
                        disabled={this.props.disabled !== undefined ? this.props.disabled : false}
                    />
                </React.Fragment>
            );
        }
        else if (this.props.type === "select") {
            return (
                <React.Fragment>
                    <TextField
                        error={this.props.checked}
                        className={"site-input " + this.props.className} fullWidth select
                        variant="filled" label={this.props.title} name={this.props.name}
                        value={this.props.value} onChange={(e) => { this.props.onChange(e); }}
                        helperText={this.props.checked ? this.props.errorMessage : ""}
                        disabled={this.props.disabled !== undefined ? this.props.disabled : false}
                    >
                        {this.props.options.map(option => (
                            <MenuItem key={"123" + option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <TextField
                        error={this.props.checked}
                        className={"site-input " + this.props.className} fullWidth
                        variant="filled" label={this.props.title} name={this.props.name}
                        value={this.props.value} onChange={(e) => { this.props.onChange(e); }}
                        helperText={this.props.checked ? this.props.errorMessage : ""}
                        disabled={this.props.disabled !== undefined ? this.props.disabled : false}
                    />
                </React.Fragment>
            );
        }
    }
}
