import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

export function CardDefault({src, title, description, link} : {src: string, title: string, description: string, link: string}) {
    return (
        <Card className="HookCard my-2 siteStyle shadow">
            <CardMedia
                className="HookCardMedia"
                component="img"
                height="140px"
                src={src}
                title={title + " description"}
            />
            <CardContent className="siteStyle px-3 pb-0">
                <div className="h6 mb-1"><strong>{title}</strong></div>
                <p><small>{description}</small></p>
            </CardContent>
            <CardActions className="px-3 pb-3 text-center">
                <Button component={Link} to={link} className="text-capitalize btn-warning-custom mx-auto" size="small" variant="contained" color="default">
                    Découvrez
                </Button>
            </CardActions>
        </Card>
    );
}
