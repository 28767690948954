
import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BackendService, MemberExternalUrlDto } from '../../api/services.generated'
import { useAuthorizedBackendApi } from '../../api/api'
import { toast } from 'react-toastify'


export default function SetExternalUrl({memberId, url, dialogClose, handleExternalUrl}:{memberId:string, url:string | null, dialogClose:() => void, handleExternalUrl:(value: string|null) => void}) {
    const api: BackendService | null = useAuthorizedBackendApi()

    const [externalUrl, setExternalUrl] = useState<string | null>(url)
    const [btnSaveDisable, setBtnSaveDisable] = useState<boolean>(false)

    function handleCloseDialog() {
        dialogClose()
    }

    async function saveExternalUrl() {
        if(!api) {
            return;
        }

        setBtnSaveDisable(true)
        try
        {
            await api.member_SetExternalUrl({
                memberId: memberId,
                externalUrl: externalUrl
            } as MemberExternalUrlDto)
            toast.success("Url externe sauvegardée", {
                hideProgressBar: false,
                position: "top-left"
            });
            handleExternalUrl(externalUrl);
            dialogClose();
        }
        catch(error){
            toast.warn(error+" !", {
                hideProgressBar: false,
                position: "top-left"
            });
        }finally{
            setBtnSaveDisable(false)
        }
    }

    return (
        <Dialog open={true} onClose={handleCloseDialog} fullWidth={true} maxWidth="sm">
            <DialogTitle>Url Externe</DialogTitle>
            <DialogContent>
                <div className="form-group">
                    <TextField 
                        variant="filled"
                        fullWidth label="Url externe dans projet" 
                        value={externalUrl} multiline rows={2} 
                        onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setExternalUrl(e.target.value)} 
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" className="text-initial mr-1" color="primary" disabled={btnSaveDisable} onClick={saveExternalUrl} size="small">
                    <FontAwesomeIcon icon={faSave} className="mr-2" size="sm" /> Sauvegarder
                </Button>
                <Button variant="contained" className="text-initial" onClick={handleCloseDialog} size="small">
                    <FontAwesomeIcon icon={faTimesCircle} className="mr-1" size="sm" /> Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
