import React, { Component } from "react";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronLeft, faSave, faTimesCircle, faExpand } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "react-router-dom";
import ProjectNotesManagement from "./documentation/ProjectNotesManagement";
import Switch from '@material-ui/core/Switch';
import ProjectFiles from "./documentation/ProjectFiles";
import ProjectStep from "./ProjectStep";
import ProjectFeedback from "./documentation/ProjectFeedback";
import List from '@material-ui/core/List';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Skeleton from '@material-ui/lab/Skeleton';
import MuiAlert from '@material-ui/lab/Alert';
import CirclesProjectTypeActivitiesList from "./CirclesProjectTypeActivitiesList";
import { ProjectStepDetails } from "./ProjectStepDetails";
import axios from "axios";
import NestedList from "../global/NestedList";
import ProjectDescription from "./ProjectDescription";
import SharedMembers from "./documentation/SharedMembers";
import Iframe from 'react-iframe';
import { MenuList } from "@material-ui/core";

export default class EditProject extends Component {

    constructor(props) {
        super(props);

        this.state = {
            projectId:"",
            projectName: "",
            routeId: undefined,
            projectCreatedDate: "",
            projectCreatedBy: "",
            routes: [],
            steps: [],
            modules:[],
            saveDisabled: false,
            tabSelected: 0,
            numPages: null,
            pageNumber: 1,
            stepBase64: "",
            loadingFile: false,
            routeDisabled: false,
            isPublic: false,
            isProjectOwner: false,
            whyRealize: "",
            forWhomSolve: "",
            existingSolutions: "",
            whichSolution: "",
            whatPeculiarity: "",
            howBuild: "",
            howMeasureSuccess: "",
            openMenuProject: false,
            anchorEl: null,
            selected: false,
            stepId: null,
            stepIndex: null,
            nextIndex: null,
            prevIndex: null,
            supportFiles: [],
            loadSupportFiles: false,
            canCreateProject: this.props.match.params.projectId !== undefined,
            loadingProject:true,
            elevatorPitch: "",
            projectLogoLoading:false,
            projectLogoFileBase64: null,
            sharingMembers: null,
            isViewer: true,
            isContributor: false,
            externalUrl: "",
            moduleSteps: [],
            bottomSteps: []
        }

        this.handleInput = this.handleInput.bind(this);
        this.saveProject = this.saveProject.bind(this);
        this.handleTabSelected = this.handleTabSelected.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.togglePublicProject = this.togglePublicProject.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSelected = this.handleSelected.bind(this);
        this.gotoPrevIndex = this.gotoPrevIndex.bind(this);
        this.gotoNextIndex = this.gotoNextIndex.bind(this);
        this.handleChangeProjectLogo =  this.handleChangeProjectLogo.bind(this);
        this.handleElevatorPitch = this.handleElevatorPitch.bind(this);
        this.handleStepId = this.handleStepId.bind(this);
    }

    componentDidMount() {
        this.getRoutes();

        if (this.props.match.params.projectId !== undefined) {
            this.getProject(this.props.match.params.projectId);
            this.downloadProjectLogo(this.props.match.params.projectId);
        } else {
            this.canCreateProject();
        }
    }

    getRouteDetails() {
        fetch(new Request("api/Route/GetRouteDetails?routeId=" + this.state.routeId, {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                var mods = response.modulesId;
                var url_ = "";
                mods.forEach(item => { url_ += "modulesId=" + encodeURIComponent("" + item) + "&"; });
                this.getModuleDetails(url_.slice(0, -1), response);
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    getModuleDetails(modules, initialRoute) {
        fetch(new Request("api/Module/GetModuleDetails?" + modules, {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                // Steps merged with their moduleId, useful for CirclesProjectTypeActivities
                var stepsModule = [];
                var c = 0;
                var color = 0;
                response.forEach((mod, index, array) => { 
                    mod.stepsId.forEach((step) => { 
                        if (c < array[index].stepsId.length) {
                            c = c + 1;
                        }
                        else {
                            color = color + 1;
                            c = 1;
                        }
                        
                        stepsModule.push({
                            stepId: step, 
                            moduleId: mod.moduleId, 
                            color: color
                        })
                    }) 
                });
                
                var steps = [];
                var url_ = "";
                for (var i = 0; i < response.length; i++) {
                    steps = response[i].stepsId;
                    url_ = "";
                    steps.forEach(item => { url_ += "stepsId=" + encodeURIComponent("" + item) + "&"; });
                    this.getStepDetails(url_.slice(0, -1), stepsModule, response);
                }
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    getStepDetails(steps, stepsModule, mods) {
        fetch(new Request("api/Step/GetStepDetails?" + steps, {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({ moduleSteps: [...this.state.moduleSteps, response]}, 
                () => {
                    if (this.state.moduleSteps.length === mods.length) {
                        var final = mods.map((item, i) => { return {moduleId: item.moduleId, moduleName: item.moduleName, steps: this.state.moduleSteps[i]} });
                        var bottom = final.map(item => {return item.steps}).flat(1);
                        
                        this.setState({ 
                            modules: final,
                            bottomSteps: bottom.map((item, i) => Object.assign({}, item, stepsModule[i]))
                        });
                    }
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }
    
    getProject(projectId) {
        fetch(new Request("api/Project/GetProject?projectId=" + projectId), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {

            if (ok) {
                this.setState({
                    projectId: projectId,
                    projectName: response.projectName,
                    routeId: response.routeId,
                    projectCreatedDate: response.createdDate,
                    projectCreatedBy: response.fullName,
                    steps: response.modules != null ? response.modules.map(s => s.steps).reduce((x, y) => {
                        return x.concat(y);
                    }, []) : null, //response.steps,
                    routeDisabled: true,
                    isPublic: response.isPublic,
                    isProjectOwner: response.isProjectOwner,
                    whyRealize: response.whyRealize,
                    forWhomSolve: response.forWhomSolve,
                    existingSolutions: response.existingSolutions,
                    whichSolution: response.whichSolution,
                    whatPeculiarity: response.whatPeculiarity,
                    howBuild: response.howBuild,
                    howMeasureSuccess: response.howMeasureSuccess,
                    elevatorPitch: response.elevatorPitch || "",
                    //modules: response.modules,
                    loadingProject: false,
                    sharingMembers: response.sharingMembers,
                    isViewer: response.isViewer,
                    isContributor: response.isContributor,
                    externalUrl: response.externalUrl
                }, () => { this.getRouteDetails(); });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    downloadProjectLogo(projectId){
        this.setState({
            projectLogoLoading:true
        });

        fetch(new Request("api/Project/GetProjectLogo?projectId=" + projectId), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response])=>{
            if(ok){
                this.setState({
                    projectLogoFileBase64: response.projectLogoFile !== null ? "data:"+response.contentType+";base64,"+response.projectLogoFile : null,
                    projectLogoLoading: false
                });
            }else{
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
            this.setState({
                projectLogoLoading: false
            });
        });
    }

    canCreateProject() {
        fetch(new Request("api/Project/CanCreateProject"), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    canCreateProject: response,
                    loadingProject: false
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }
    
    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleElevatorPitch(e) {
        if(e.target.value.length <= 150){
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    saveProject() {
        this.setState({
            saveDisabled: true
        });
        fetch(new Request("api/Project/SetProject"), {
            method: "POST",
            body: JSON.stringify({
                ProjectId: this.state.projectId,
                ProjectName: this.state.projectName,
                Route: { RouteId: this.state.routeId },
                WhyRealize: this.state.whyRealize,
                ForWhomSolve: this.state.forWhomSolve,
                ExistingSolutions: this.state.existingSolutions,
                WhichSolution: this.state.whichSolution,
                WhatPeculiarity: this.state.whatPeculiarity,
                HowBuild: this.state.howBuild,
                HowMeasureSuccess: this.state.howMeasureSuccess,
                ElevatorPitch: this.state.elevatorPitch
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {
            

            if (ok) {
                toast.success("Projet sauvegardé !", {
                    position: "top-left",
                    hideProgressBar: false
                });

                if (response !== null) {
                    this.setState({
                        projectId: response.projectId,
                        projectCreatedDate: response.createdDate,
                        projectCreatedBy: response.fullName,
                        steps: response.steps,
                        saveDisabled: false,
                        routeDisabled: true,
                        isProjectOwner: true,
                        canCreateProject: true
                    })
                } else {
                    this.setState({
                        saveDisabled: false
                    });
                }
            } else {
                this.setState({
                    saveDisabled: false
                });
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    getRoutes() {
        fetch(new Request("api/Route/GetRoutesSimpleData"), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    routes: response
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    handleTabSelected(event, value) {
        this.setState({
            tabSelected: value
        });
    }

    onDocumentLoadSuccess({ numPages }) {
        this.setState({
            numPages: numPages
        });
    }

    togglePublicProject() {
        fetch(new Request("api/Project/TogglePublicProject"), {
            method: "POST",
            body: JSON.stringify({
                ProjectId: this.state.projectId
            }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {
            if (ok) {
                toast.success(!this.state.isPublic ?
                    "Votre projet est public maintenant !" : "Votre project est privé maintenant !", {
                    position: "top-left",
                    hideProgressBar: false
                });

                this.setState({
                    isPublic: !this.state.isPublic
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    handleOpen(event) {
        var elm = document.getElementById("project-menu");
        this.setState({ openMenuProject: !this.state.openMenuProject, anchorEl: elm });
    }

    handleClose() {
        this.setState({ openMenuProject: false, anchorEl: null });
    }

    handleSelected() {
        this.setState({ selected: !this.state.selected });
    }

    handleStepId(value, index) {
        if (value !== "") {
            this.setState({
                loadSupportFiles: true
            });
    
            this.setState({ stepId: value, stepIndex: index });
    
            if (index > 0) {
                this.setState({ prevIndex: index - 1 });
            }
            else {
                this.setState({ prevIndex: null });
            }
    
            if (index < this.state.steps.length - 1) {
                this.setState({ nextIndex: index + 1 });
            }
            else {
                this.setState({ nextIndex: null });
            }
    
            fetch(new Request("api/Step/GetSupportFiles?stepId=" + value), {
                method: "GET",
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                })
            })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        supportFiles: response === null ? [] : response,
                        loadSupportFiles: false
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                this.setState({loadSupportFiles: false});
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
        }
        else {
            this.setState({
                stepId: null,
                stepIndex: null,
                nextIndex: null,
                prevIndex: null,
                supportFiles: [],
                loadSupportFiles: false
            })
        }
    }

    downloadSupportFile(fileName, stepId) {
        fetch(new Request("api/Step/DownloadSupportFile?StepId=" + stepId + "&FileName=" + fileName), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204) {
                if (response.ok) 
                    return Promise.all([response.ok, response.blob()]);
                else
                    return Promise.all([response.ok, response.json()]);
            }

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(response);
                link.download = fileName;
                link.click();
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    gotoPrevIndex() {
        if (this.state.prevIndex !== null && this.state.prevIndex >= 0) {
            this.handleStepId(this.state.steps[this.state.prevIndex].stepId, this.state.prevIndex); 
            this.handleClose();
        }
    }

    gotoNextIndex() {
        if (this.state.nextIndex !== null && this.state.nextIndex < this.state.steps.length - 1) {
            this.handleStepId(this.state.steps[this.state.nextIndex].stepId, this.state.nextIndex); 
            this.handleClose();
        }
    }

    handleChangeProjectLogo(e) {
        var file = e.target.files[0];
       
        var formData = new FormData();
        formData.append("ProjectFile", file);
        formData.append("ProjectId", this.state.projectId);
        
        const config = {
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'content-type': 'multipart/form-data'
            },
        };

        this.setState({
            projectLogoLoading: true
        });

        axios.post("api/Project/ChangeProjectLogo", formData,config)
        .then(() => {
            this.getBase64(file, (result) => {

                this.setState({
                    projectLogoFileBase64:result,
                    projectLogoLoading: false
                });
            });
        })
        .catch(error => {

            this.setState({
                projectLogoFileBase64: null,
                projectLogoLoading: false
            });
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            this.setState({
                projectLogoFileBase64: null
            })
        };
    }

    countLastStepsModule(limit) {
        var count = 0;
        for (var i = 0; i < limit; i++) {
            count += this.state.modules[i].steps.length;
        }
        return count; 
    }

    compare(a, b) {
        if (a.stepPosition < b.stepPosition) {
            return -1;
        }
        if (a.stepPosition > b.stepPosition) {
            return 1;
        }
        return 0;
    }
    
    render() {
        return (
            <React.Fragment>
                <nav className="siteStyle top-nav">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/projects">Projets</Link></li>
                        <li className="breadcrumb-item active"><h1 className="siteTitle">Projet - {this.state.projectName}</h1></li>
                    </ol>
                </nav>
                <div className="px-0 py-3 px-sm-4 py-sm-4">
                    <div className="row border-bottom mb-4">
                        <div className="col-12 mb-4 border-bottom">
                            <h3 className="font-weight-bolder siteStyle" style={{color: "#3f51b5", fontSize: '22px'}}>{this.state.projectName}</h3>
                        </div>
                        <div className="col-5">
                            <div className="row">
                                <div className="col-4">
                                    <Card style={{height: "120px"}}>
                                        <CardContent className="text-center">
                                            {
                                                this.state.projectLogoLoading ? 
                                                <Skeleton /> : 
                                                    <img src={this.state.projectLogoFileBase64 !== null ? this.state.projectLogoFileBase64 : "/img/empty_img.jpg"} 
                                                    alt="project logo" className={this.state.isProjectOwner ? "pointer img-fluid" : "img-fluid"} style={{maxHeight:"88px"}} 
                                                    onClick={() => this.state.isProjectOwner && document.getElementById("logoProjectFile").click()} /> 
                                                
                                            }

                                        </CardContent>
                                    </Card>
                                    <input type="file" hidden id="logoProjectFile" onChange={this.handleChangeProjectLogo} />
                                </div>
                                <div className="col-8">
                                    {
                                        this.state.projectId !== "" &&
                                        <React.Fragment>
                                            <div className="siteStyle text-little">
                                                <label className="mb-3 text-secondary text-length">Auteur : </label>
                                                <span className="font-weight-bolder">{this.state.projectCreatedBy}</span>
                                            </div>
                                            <div className="siteStyle text-little">
                                                <label className="mb-3 text-secondary text-length">Catégorie : </label>
                                                <span className="font-weight-bolder">{this.state.routes.length !== 0 && this.state.routeId !== undefined ? this.state.routes.find(row => row.routeId === this.state.routeId).routeName : "En cours"}</span>
                                            </div>

                                            <div className="siteStyle text-little">
                                                <label className="mb-3 text-secondary text-length">Date de création : </label>
                                                <span className="font-weight-bolder">{this.state.projectCreatedDate}</span>
                                            </div>
                                            <div className="siteStyle">
                                                <span className="font-weight-bolder">Mis à jour par Aloys il y a 2 jours</span>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="float-right mt-4">
                                        {
                                            this.state.canCreateProject && !this.state.loadingProject &&
                                            <Button variant="contained" color="primary" onClick={this.saveProject}
                                                disabled={this.state.saveDisabled} className="text-initial group-button mr-2" size="small">
                                                <FontAwesomeIcon icon={faSave} className="mr-0 mr-sm-1" size="sm" /> <div className="d-none d-sm-block">Sauvegarder</div>
                                            </Button>
                                        }
                                        
                                        <Link to="/projects" className="text-decoration-none">
                                            <Button variant="contained" className="text-initial group-button mr-2" size="small">
                                                <FontAwesomeIcon icon={faTimesCircle} className="mr-0 mr-sm-1" size="sm" /> <div className="d-none d-sm-block">Fermer</div>
                                            </Button>
                                        </Link>
                                        {
                                            this.state.canCreateProject && this.state.isProjectOwner &&
                                            <Button className="text-initial bg-white" variant="contained" onClick={this.togglePublicProject} size="small">
                                                Public ? <Switch color="primary" checked={this.state.isPublic} size="small" />
                                            </Button>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 mt-4">
                                    <Tabs className="mb-0" indicatorColor="secondary" textColor="primary" variant="scrollable"
                                        value={this.state.tabSelected} onChange={this.handleTabSelected} scrollButtons="on"
                                        style={{ backgroundColor: "#FFF" }} TabIndicatorProps={{ style: { background: '#fd7419' } }}
                                    >
                                        <Tab label="Parcours" />
                                        <Tab label="Description" disabled={this.state.projectId === "" || !this.state.canCreateProject} />
                                        <Tab label="Documentation" disabled={this.state.projectId === "" || !this.state.canCreateProject} />
                                        <Tab label="Feedback" disabled={this.state.projectId === "" || !this.state.canCreateProject} />
                                        <Tab label="Test" disabled={this.state.projectId === "" || !this.state.canCreateProject} />
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div hidden={this.state.tabSelected !== 0}>
                        {
                            this.state.loadingProject ?
                            <React.Fragment> 
                                <div className="form-group row">
                                    <div className="col-12"><Skeleton animation='wave' variant="rect" className="w-100" height={30} /></div>
                                </div>
                                <Card>
                                    <CardContent>
                                        <div className="row">
                                            <div className="col-6"><Skeleton animation='wave' variant="text" /></div>
                                            <div className="col-6"><Skeleton animation='wave' variant="text" /></div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </React.Fragment> :
                            !this.state.canCreateProject ?
                                <MuiAlert elevation={6} variant="filled" severity="warning">
                                    Vous ne pouvez plus créer de projet car un projet a déjà été créé avec votre compte.
                                </MuiAlert> :
                                <React.Fragment>
                                        <div className="alert alert-secondary mt-2">
                                            <IconButton style={{color: "#333", backgroundColor: "#fff", padding: "4px", fontSize: "12px"}} onClick={this.handleOpen} size="small">
                                                <FontAwesomeIcon icon={faBars} />
                                            </IconButton>
                                            {
                                                this.state.stepId !== null ? 
                                                <span className="siteStyle font-weight-bold ml-2">
                                                    {(this.state.stepIndex + 1) + "- " + this.state.steps[this.state.stepIndex].stepName}
                                                </span> : <span className="font-weight-bold ml-2">Liste des étapes</span>
                                            }
                                            
                                        </div>
                                        <div className="row my-2">
                                            <div id="project-menu" className={this.state.openMenuProject ? "col-md-3" : "d-none"}>
                                                <Card>
                                                    <CardContent className="p-0">
                                                        <MenuList
                                                            className="custom-menu py-0"
                                                            open={this.state.openMenuProject}
                                                            onClose={this.handleClose}
                                                        >
                                                            <MenuItem component={Link} to="/projects">
                                                                <FontAwesomeIcon className="mr-1" icon={faChevronLeft} size="sm" /> Retour aux projets
                                                            </MenuItem>
                                                            <MenuItem onClick={() => { this.handleStepId("", 0); }}>Introduction</MenuItem>
                                                            {
                                                                <List component="nav" className="py-0">
                                                                    {
                                                                        (this.state.modules || []).map((module, i) => (
                                                                            <React.Fragment key={"module_" + i}>
                                                                                <NestedList 
                                                                                    i={i} module={module} stepId={this.state.stepId}
                                                                                    count={this.countLastStepsModule(i)} 
                                                                                    handleStepId={this.handleStepId} handleClose={this.handleClose} 
                                                                                />
                                                                            </React.Fragment>
                                                                        ))
                                                                    }
                                                                </List>
                                                            }
                                                        </MenuList>        
                                                    </CardContent>
                                                </Card>
                                            </div>
                                            <div className={this.state.openMenuProject ? "col-md-9" : "col-md-12"}>
                                                {
                                                    this.state.stepId !== null ?
                                                    <div className="row">
                                                        <ProjectStep 
                                                            id={this.state.stepId} 
                                                            index={this.state.stepIndex} 
                                                            steps={this.state.steps} 
                                                        />
                                                        <ProjectStepDetails 
                                                            id={this.state.stepId} index={this.state.stepIndex} 
                                                            prevIndex={this.state.prevIndex} nextIndex={this.state.nextIndex} 
                                                            gotoNextIndex={this.gotoNextIndex} gotoPrevIndex={this.gotoPrevIndex} 
                                                            steps={this.state.steps} loadSupportFiles={this.state.loadSupportFiles} 
                                                            supportFiles={this.state.supportFiles} downloadSupportFile={this.downloadSupportFile}
                                                            projectId={this.state.projectId} isOwner={this.state.isProjectOwner}
                                                            projectName={this.state.projectName}
                                                        />
                                                    </div> :
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Card>
                                                                <CardContent>
                                                                    <div className="form-group row">
                                                                        <div className="col-md-6 mb-3">
                                                                            <FormControl fullWidth variant="filled">
                                                                                <InputLabel>Parcours</InputLabel>
                                                                                <Select value={this.state.routeId} name="routeId"
                                                                                    onChange={this.handleInput} disabled={this.state.routeDisabled}>
                                                                                    {
                                                                                        (this.state.routes || []).map(row => (
                                                                                            <MenuItem key={row.routeId} value={row.routeId}>
                                                                                                {row.routeName}
                                                                                            </MenuItem>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </FormControl>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <TextField fullWidth label="Nom du projet" name="projectName" onChange={this.handleInput}
                                                                            disabled={this.state.projectId !== ""} value={this.state.projectName} variant="filled" />
                                                                        </div>
                                                                    </div>
                                                                </CardContent>
                                                            </Card>
                                                            <Accordion className="mt-3">
                                                                <AccordionSummary expandIcon={<FontAwesomeIcon icon={faExpand} />} aria-controls="panel1a-content" id="panel1a-header">
                                                                    <div className="mt-2 mb-2 font-weight-bolder" style={{color: "#3f51b5", fontSize: "1.5em"}}>
                                                                        Résumé du parcours  
                                                                        {
                                                                            this.state.routes.length !== 0 && this.state.routeId !== undefined ? 
                                                                                " " + this.state.routes.find(row => row.routeId === this.state.routeId).routeName : "En cours"
                                                                        }
                                                                    </div>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    {
                                                                        this.state.bottomSteps.length !== 0 ? 
                                                                        <CirclesProjectTypeActivitiesList 
                                                                            steps={this.state.bottomSteps.sort(this.compare)} 
                                                                        /> : <Skeleton />
                                                                    }
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>
                                                    </div>  
                                                }
                                            </div>
                                        </div>
                                </React.Fragment>
                        }
                    </div>
                    <div hidden={this.state.tabSelected !== 1}>
                        <Card>
                            <CardContent>
                                <ProjectDescription 
                                    whyRealize={this.state.whyRealize} 
                                    forWhomSolve={this.state.forWhomSolve} 
                                    existingSolutions={this.state.existingSolutions} 
                                    whichSolution={this.state.whichSolution} 
                                    whatPecuriality={this.state.whatPecuriality} 
                                    howBuild={this.state.howBuild} 
                                    howMeasureSuccess={this.state.howMeasureSuccess} 
                                    elevatorPitch={this.state.elevatorPitch}
                                    handleInput={this.handleInput}
                                    handleElevatorPitch={this.handleElevatorPitch}
                                />
                            </CardContent>
                        </Card>
                    </div>
                    <div hidden={this.state.tabSelected !== 2}>
                        <div className="row">
                            <div className="col-md-6">
                                <Card>
                                    <CardHeader title="Note" className="pb-0 mb-2" />
                                    <CardContent className="pt-0">
                                        {
                                            this.state.projectId !== "" &&
                                            <ProjectNotesManagement projectId={this.state.projectId} />
                                        }
                                    </CardContent>
                                </Card>
                                {
                                    !this.state.isPublic && this.state.isProjectOwner && 
                                    <Card className="mt-4">
                                        <CardHeader title="Fichiers" className="pb-0 mb-2" />
                                        <CardContent className="pt-0">
                                            {
                                                this.state.projectId !== "" &&
                                                <ProjectFiles projectId={this.state.projectId} />
                                            }
                                        </CardContent>
                                    </Card>
                                }
                            </div>
                            <div className="col-md-6">
                                {
                                    !this.state.isPublic && this.state.isProjectOwner && <Card>
                                        <CardHeader title="Gestion partage du projet" className="pb-0 mb-2" />
                                        <CardContent className="pt-0">
                                            {
                                                this.state.projectId !== "" &&
                                                <SharedMembers projectId={this.state.projectId} fullName={this.state.projectCreatedBy}
                                                projectName={this.state.projectName} />
                                            }
                                        </CardContent>
                                    </Card>
                                }
                                {
                                    (this.state.isPublic || !this.state.isProjectOwner) && 
                                    <Card>
                                        <CardHeader title="Fichiers" className="pb-0 mb-2" />
                                        <CardContent className="pt-0">
                                            {
                                                this.state.projectId !== "" &&
                                                <ProjectFiles projectId={this.state.projectId} />
                                            }
                                        </CardContent>
                                    </Card>
                                }
                            </div>
                        </div>
                    </div>
                    <div hidden={this.state.tabSelected !== 3}>
                        {
                            this.state.projectId !== "" &&
                            <Card>
                                <CardContent>
                                    <ProjectFeedback projectId={this.state.projectId} steps={this.state.steps} 
                                    isViewer={this.state.isViewer} />
                                </CardContent>
                            </Card>
                        }
                    </div>
                    <div hidden={this.state.tabSelected !== 4}>
                        {
                            this.state.externalUrl !== null && this.state.externalUrl !== undefined && this.state.externalUrl !== "" && 
                            <Iframe url={this.state.externalUrl}
                                width="100%"
                                height="600px"
                                id="myId"
                                className="myClassname border"
                                display="initial"
                                position="relative" 
                            />
                        }
                        
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
