import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSync, faPen } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import EditRole from './EditRole';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

export default class RolesList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            jamSessionRoles: [],
            jamSessionRoleName: "",
            jamSessionRoleId: "",
            jamSessionRoleSpecialTable: null,
            jamSessionRoleDescription:"",
            loadingData: false, 
            loadingJamSessionRole: false
        }

        this.newJamSessionRole = this.newJamSessionRole.bind(this);
        this.refreshTableJamSessionRole = this.refreshTableJamSessionRole.bind(this);

        this.EditRoleRef = React.createRef();
    }

    componentDidMount() {
        this.refreshTableJamSessionRole();
    }

    refreshTableJamSessionRole() {
        fetch(new Request("api/JamSessionRole/GetJamSessionRolesSimpleData", {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        jamSessionRoles: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    newJamSessionRole() {
        this.EditRoleRef.current.initFiels();
    }

    getEditRole(roleId) {
        this.EditRoleRef.current.getRole(roleId);
    }

    render() {
        return (
            <React.Fragment>
                <div className="wrapper">
                <MetaTags>
                    <title>Rôles - Success Team</title>
                    <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </MetaTags>
            </div>
                <nav className="siteStyle top-nav">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to='/dashboard'>Dashboard</Link></li>
                        <li className="breadcrumb-item active"><h1 className="siteTitle">Rôles</h1></li>
                    </ol>
                </nav>
                <div className="row py-3 px-0 mx-3">
                    <div className="col-md-8">
                        <div>
                            <div>
                                <div className="alert alert-secondary">
                                    <Button variant="contained" size="small" color="primary" className="mr-2 text-initial" onClick={this.newJamSessionRole}>
                                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm" /> Nouveau
                                    </Button>
                                    <Button variant="contained" size="small" className="text-initial btn-info-custom" onClick={this.refreshTableJamSessionRole}>
                                        <FontAwesomeIcon icon={faSync} className="mr-1" size="sm" /> Rafraîchir
                                    </Button>
                                </div>
                                <div className="table-responsive">
                                    <Table className="w-100" size="small">
                                        <TableHead style={{ backgroundColor: "#000" }}>
                                            <TableRow>
                                                <TableCell style={{ color: "#fff", width: "20px" }}></TableCell>
                                                <TableCell style={{ color: "#fff", width: "200px" }}>Rôle</TableCell>
                                                <TableCell style={{ color: "#fff", width: "175px" }}>Type de table</TableCell>
                                                <TableCell style={{ color: "#fff" }}>Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.jamSessionRoles.map(row => (
                                                    <TableRow key={row.jamSessionRoleId}>
                                                        <TableCell align="center">
                                                            <FontAwesomeIcon icon={faPen} className="pointer"
                                                                onClick={() => this.getEditRole(row.jamSessionRoleId)} />
                                                        </TableCell>
                                                        <TableCell>{row.jamSessionRoleName}</TableCell>
                                                        <TableCell>
                                                            {
                                                                row.jamSessionRoleSpecialTable !== null ? (row.jamSessionRoleSpecialTable ?
                                                                    <Chip label="Table Spéciale" />
                                                                    : <Chip label="Table Ordinaire" color="primary" />) :
                                                                    <Chip label="Général" />
                                                            }
                                                        </TableCell>
                                                        <TableCell>{row.jamSessionRoleDescription}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <EditRole ref={this.EditRoleRef} refresh={this.refreshTableJamSessionRole} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}