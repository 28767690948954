import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faPlusCircle, faSync, faPen, faCheckCircle, faTimesCircle, faFileImport, faCaretDown, faTrash, faFile, faTasks, faLink } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import axios from 'axios';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Alert from '@material-ui/lab/Alert';
import Modal from 'react-bootstrap/Modal';
import StepTodo from "./StepTodo";
import { Chip } from "@material-ui/core";
import StepLink from "./StepLink";
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

export default class StepsManagement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stepName: "",
            stepId: "",
            stepFile: null,
            stepNumber: 0,
            description: "",
            videoUrl: "",
            stepType: "",
            type: 1,
            steps: [],
            btnSaveDisabled: false,
            openMenu: false,
            anchorEl: null,
            supportFiles: [],
            principalFileExists: false,
            importDisabled: true,
            openModal: false,
            openModal2: false,
            loadStep: false
        };

        this.handleInput = this.handleInput.bind(this);
        this.getSteps = this.getSteps.bind(this);
        this.saveStep = this.saveStep.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.addSupportFiles = this.addSupportFiles.bind(this);
        this.addPrincipalFile = this.addPrincipalFile.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleOpenModal2 = this.handleOpenModal2.bind(this);

        this.inputPrincipalFile = React.createRef();
        this.inputSupportFile = React.createRef();
    }

    componentDidMount() {
        this.getSteps();
    }

    handleInput(e) {
        console.log(e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    addPrincipalFile(e) {
        this.setState({
            importDisabled: true
        });

        var formData = new FormData();
        formData.append("StepFile", e.target.files[0]);
        formData.append("StepId", this.state.stepId);

        const config = {
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'content-type': 'multipart/form-data'
            },
        };

        axios.post("api/Step/SetStepPrincipalFile", formData, config)
            .then(() => {
                toast.success("Fichier principal importé !", {
                    position: "top-left",
                    hideProgressBar: false
                });

                this.setState({
                    principalFileExists: true,
                    importDisabled: false
                });
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    importDisabled: false
                });
            });
    }

    saveStep() {
        this.setState({
            btnSaveDisabled: true
        });

        fetch(new Request("api/Step/SetStep"), {
            method: "POST",
            body: JSON.stringify({
                StepId: this.state.stepId,
                StepName: this.state.stepName,
                StepPosition: this.state.stepNumber,
                Description: this.state.description,
                Type: this.state.type,
                VideoUrl: this.state.videoUrl
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    toast.success("Etape sauvegardée !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                    if (response === null) {
                        this.clearFields();
                    } else {
                        this.setState({
                            stepId: response,
                            importDisabled: false
                        });
                    }
                    
                    this.getSteps();
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                this.setState({
                    btnSaveDisabled: false
                });
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    getSteps() {
        fetch(new Request("api/Step/GetSteps"), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        steps: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    getStep(stepId) {
        this.setState({ loadStep: true });
        
        fetch(new Request("api/Step/GetStep?stepId=" + stepId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    stepId: stepId,
                    stepName: response.stepName,
                    description: response.description == null ? "" : response.description,
                    type: response.type.toUpperCase() == "BONUS" ? 0 : 1,
                    stepNumber: response.stepPosition == null ? 0 : response.stepPosition,
                    videoUrl: response.videoUrl == null ? "" : response.videoUrl,
                    supportFiles: response.supportFiles,
                    principalFileExists: response.principalFileExists,
                    importDisabled: false,
                    loadStep: false
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
            this.setState({ loadStep: false });
        });
    }

    clearFields() {
        this.setState({
            stepId: "",
            stepName: "",
            stepNumber: 0,
            description:"",
            type: 1,
            videoUrl: "",
            btnSaveDisabled: false,
            importDisabled: true,
            principalFileExists: false,
            supportFiles:[]
        });
    }

    handleOpen(event) {
        this.setState({ openMenu: true, anchorEl: event.currentTarget });
    }

    handleClose() {
        this.setState({ openMenu: false, anchorEl: null });
    }

    handleAddSupportFiles() {
        document.getElementById("supportFiles").click();
        this.setState({ openMenu: false, anchorEl: null });
    }

    handleAddPrincipalFile() {
        document.getElementById("principalFile").click();
        this.setState({ openMenu: false, anchorEl: null });
    }

    addSupportFiles(e) {
        this.setState({
            importDisabled: true
        });

        var formData = new FormData();
        formData.append("StepFile", e.target.files[0]);
        formData.append("StepId", this.state.stepId);
        var file = e.target.files[0];

        const config = {
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'content-type': 'multipart/form-data'
            },
        };

        axios.post("api/Step/SetStepSupportFiles", formData, config)
            .then(() => {
                toast.success("Fichier support sauvegardé !", {
                    position: "top-left",
                    hideProgressBar: false
                });

                this.setState({
                    supportFiles: [...this.state.supportFiles, { fileName: file.name, contentType: file.type}],
                    importDisabled:false
                });
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    importDisabled: false
                });
            });
    }

    removeSupportFile(fileName) {
        fetch(new Request("api/Step/DeleteSupportFile"), {
            method: "DELETE",
            body: JSON.stringify({
                StepId: this.state.stepId,
                FileName:fileName
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {
            if (ok) {
                var array = this.state.supportFiles;
                var index = this.state.supportFiles.findIndex(f => f.fileName === fileName);
                array.splice(index, 1);
                this.setState({
                    supportFiles: array
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
    }

    deletePrincipalFile(stepId) {
        fetch(new Request("api/Step/DeletePrincipalFile"), {
            method: "DELETE",
            body: JSON.stringify({
                StepId: stepId
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        principalFileExists: false
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    handleOpenModal() {
        this.setState({
            openModal: !this.state.openModal
        });
    }

    handleOpenModal2() {
        this.setState({
            openModal2: !this.state.openModal2
        });
    }

    render() {
        const typeOptions = [
            { value: 1, label: "Cursus" },
            { value: 0, label: "Bonus" }
        ];

        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Etapes - Success Team</title>
                        <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                    </MetaTags>
                </div>
                <nav className="siteStyle top-nav">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to='/dashboard'>Dashboard</Link></li>
                        <li className="breadcrumb-item active"><h1 className="siteTitle">Etapes</h1></li>
                    </ol>
                </nav>
                <div className="row py-3 px-0 mx-3">
                    <div className="col-md-7">
                        <div className="alert alert-secondary">
                            <Button variant="contained" onClick={this.clearFields} className="text-initial mr-2" color="primary" size="small">
                                <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm" />
                                Nouvelle
                            </Button>
                            <Button variant="contained" className="text-initial btn-info-custom" onClick={this.getSteps} color="primary" size="small">
                                <FontAwesomeIcon icon={faSync} className="mr-1" size="sm" />
                                Rafraîchir
                            </Button>
                        </div>
                        <Table size="small">
                            <TableHead style={{ backgroundColor: "#000" }}>
                                <TableRow>
                                    <TableCell />
                                    <TableCell style={{ color: "#FFF" }}>Etape</TableCell>
                                    <TableCell style={{ color: "#FFF" }}>Nombre de tâches</TableCell>
                                    <TableCell style={{ color: "#FFF" }}>Fichier principal</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.steps.map(row => (
                                        <TableRow key={row.stepId}>
                                            <TableCell>
                                                <FontAwesomeIcon icon={faPen} className="pointer" onClick={() => this.getStep(row.stepId)} size="sm" />
                                            </TableCell>
                                            <TableCell>{row.stepName}</TableCell>
                                            <TableCell><Chip label={row.toDoCount} color="primary" size="small" /></TableCell>
                                            <TableCell>
                                                {
                                                row.isFileExists ?
                                                    <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                                    : <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </div>
                    {
                        !this.state.loadStep ?
                        <div className="col-md-5">
                            <div>
                                <div>
                                    <div className="alert alert-secondary">
                                        <Button className="text-initial btn-success-custom" variant="contained" onClick={this.saveStep}
                                            disabled={this.state.btnSaveDisabled} size="small">
                                            <FontAwesomeIcon icon={faSave} className="mr-1" size="sm" /> Enregistrer
                                        </Button>
                                        <Button className="text-initial bg-white float-right" variant="contained" onClick={this.handleOpen}
                                            disabled={this.state.importDisabled} size="small">
                                            <FontAwesomeIcon icon={faFileImport} className="mr-1" size="sm" /> Importer <FontAwesomeIcon icon={faCaretDown} className="ml-1" size="sm" />
                                        </Button>
                                        <Button className="text-initial float-right mr-2" variant="contained" color="primary" onClick={this.handleOpenModal}
                                            disabled={this.state.stepId === ""} size="small">
                                            <FontAwesomeIcon icon={faTasks} className="mr-1" size="sm" /> Tâches
                                        </Button>
                                        <Button className="text-initial float-right mr-2" variant="contained" color="primary" onClick={this.handleOpenModal2}
                                            disabled={this.state.stepId === ""} size="small">
                                            <FontAwesomeIcon icon={faLink} className="mr-1" size="sm" /> Liens utiles
                                        </Button>
                                        <Menu
                                            id="project-menu"
                                            className="custom-menu"
                                            anchorEl={this.state.anchorEl}
                                            keepMounted
                                            open={this.state.openMenu}
                                            onClose={this.handleClose}
                                            style={{ top: "36px" }}
                                        >
                                            <MenuItem onClick={() => { this.handleAddPrincipalFile() }}>
                                                Fichier principal
                                            </MenuItem>
                                            <MenuItem onClick={() => { this.handleAddSupportFiles() }}>
                                                Fichiers supports
                                            </MenuItem>
                                        </Menu>

                                        <input multiple type="file" hidden onChange={this.addSupportFiles}
                                            id="supportFiles" inputRef={this.inputSupportFile} />
                                    </div>
                                    <div className="form-group">
                                        <TextField label="Etape" fullWidth name="stepName" variant="filled"
                                            value={this.state.stepName} onChange={this.handleInput} />
                                    </div>
                                    {
                                        this.state.type === 1 && 
                                        <div className="form-group">
                                            <TextField label="Numéro" fullWidth name="stepNumber" variant="filled"
                                                value={this.state.stepNumber} onChange={this.handleInput} type="number" />
                                        </div>
                                    }
                                    <div className="form-group">
                                        <TextField
                                            fullWidth select variant="filled" label="Type" name="type"
                                            value={this.state.type} onChange={this.handleInput}
                                        >
                                            {typeOptions.map(option => (
                                                <MenuItem key={"Type" + option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="form-group">
                                        <TextField label="Description" fullWidth name="description" variant="filled"
                                            value={this.state.description} onChange={this.handleInput} multiline rows="4" />
                                    </div>
                                    <div className="form-group">
                                        <TextField label="URL de la vidéo" fullWidth name="videoUrl" variant="filled"
                                            value={this.state.videoUrl} onChange={this.handleInput} />
                                    </div>
                                    <List className="py-0 mb-3" component="nav">
                                        <ListItem className="p-0">
                                            {
                                                !this.state.principalFileExists ? <Alert className="siteStyle w-100" severity="warning">Aucun fichier principal sauvegardé</Alert> :
                                                    <React.Fragment>
                                                        <ListItemIcon>
                                                            <FontAwesomeIcon icon={faTrash} className="text-danger pointer"
                                                                onClick={() => this.deletePrincipalFile(this.state.stepId)} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Alert severity="success">Fichier principal</Alert>} />
                                                    </React.Fragment>
                                            }
                                        </ListItem>
                                    </List>
                                    <input type="file" hidden onChange={this.addPrincipalFile} id="principalFile" inputRef={this.inputPrincipalFile} />
                                </div>
                            </div>
                            <Card>
                                <CardHeader className="pb-0" title="Fichiers supports" />
                                <CardContent>
                                    <Table size="small">
                                        <TableHead style={{ backgroundColor: "#000" }}>
                                            <TableRow>
                                                <TableCell style={{ width: "20px" }} />
                                                <TableCell style={{ color: "#FFF" }}>Fichier</TableCell>
                                                <TableCell style={{ width: "20px" }} />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                (this.state.supportFiles || []).map((row, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell className="float-right">
                                                            <FontAwesomeIcon icon={faFile} />
                                                        </TableCell>
                                                        <TableCell style={{ maxWidth: "40px" }}> {row.fileName} </TableCell>
                                                        <TableCell className="float-right">
                                                            <FontAwesomeIcon icon={faTrash} className="text-danger pointer" onClick={() => this.removeSupportFile(row.fileName)} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </div> : 
                        <div className="col-md-5">
                            <FontAwesomeIcon icon={faSync} className="fa-5x fa-spin" style={{ marginLeft: "40%" }} />
                        </div>
                    }
                    
                </div>

                <Modal size="xl" dialogClassName="modal-90w" show={this.state.openModal} onHide={this.handleOpenModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className="siteStyle">
                            <strong>Tâches - {this.state.stepName}</strong>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.openModal ? 
                            <StepTodo stepId={this.state.stepId} /> : null
                        }
                    </Modal.Body>
                </Modal>

                <Modal size="xl" dialogClassName="modal-90w" show={this.state.openModal2} onHide={this.handleOpenModal2}>
                    <Modal.Header closeButton>
                        <Modal.Title className="siteStyle">
                            <strong>Liens utiles - {this.state.stepName}</strong>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.openModal2 ? 
                            <StepLink stepId={this.state.stepId} /> : null
                        }
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}