import React, { Component } from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSync, faTrash, faFileDownload, faFile } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import Button from "@material-ui/core/Button";
import axios from 'axios';

export default class ProjectFiles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            uploadFiles:null,
            AddFileBtnDisable:false,
            filesLoading: false
        }

        this.handleAddFiles = this.handleAddFiles.bind(this);
        this.handleGetFiles = this.handleGetFiles.bind(this);
        this.addFiles = this.addFiles.bind(this);
    }

    componentDidMount() {
        this.handleGetFiles();
    }

    handleGetFiles() {
        this.setState({
            filesLoading: true
        })
        fetch(new Request("api/Project/GetFiles?projectId=" + this.props.projectId), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        files: response,
                        filesLoading: false
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                this.setState({
                    filesLoading: false
                })
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    handleAddFiles() {
        document.getElementById("projectFiles").click();
    }

    addFiles(e) {
        var formData = new FormData();
        formData.append("ProjectFile", e.target.files[0]);
        formData.append("ProjectId", this.props.projectId);

        this.setState({
            AddFileBtnDisable: true
        });

        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
        axios.post("api/Project/AddFiles", formData)
            .then(() => {
                this.handleGetFiles();
                this.setState({
                    AddFileBtnDisable: false
                });
            })
            .catch(error => {
                this.setState({
                    AddFileBtnDisable: false
                });
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    handleDownloadFile(fileName) {
        fetch(new Request("api/Project/DownloadProjectFile?ProjectId=" + this.props.projectId+"&FileName="+fileName), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                {
                    if(response.ok)
                        return Promise.all([response.ok, response.blob()]);
                    else
                        return Promise.all([response.ok, response.json()]);
                }
                    

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if(ok){
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(response);
                    link.download = fileName;
                    link.click();
                }else{
                    throw new Error(response);
                }
                
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    handleDeleteFile(fileName,index) {
        fetch(new Request("api/Project/DeleteProjectFile"), {
            method: "DELETE",
            body: JSON.stringify({
                ProjectId: this.props.projectId,
                FileName: fileName
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    var array = this.state.files;
                    array.splice(index, 1);
                    this.setState({
                        files: array
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                {
                    !this.props.isViewer && 
                    <div className="alert alert-secondary">
                        <Button className="text-initial mr-2" color="primary" variant="contained" onClick={this.handleAddFiles} 
                        size="small" disabled={this.state.AddFileBtnDisable}>
                            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm" /> Ajouter
                        </Button>
                        <Button className="text-initial btn-info-custom" variant="contained" onClick={this.handleGetFiles} size="small">
                            <FontAwesomeIcon icon={faSync} className="mr-1" size="sm" /> Rafraîchir
                        </Button>
                    </div>
                }
                
                {
                    this.state.filesLoading ? 
                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            <FontAwesomeIcon icon={faSync} className="fa-spin fa-4x" />
                        </div>
                    </div> : 
                    <List component="nav" aria-label="fichiers projet">
                    {
                        (this.state.files || []).length === 0 ? 
                        <ListItem >
                            <ListItemText primary="Aucun Fichier"  />
                        </ListItem> :
                        this.state.files.map((item, index) => (
                            <ListItem key={index}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faFile} />
                                </ListItemIcon>
                                <ListItemText primary={item.fileName} />
                                <ListItemSecondaryAction>
                                    <FontAwesomeIcon icon={faFileDownload} className="mr-3 text-primary pointer"
                                        onClick={() => this.handleDownloadFile(item.fileName)} />
                                    <FontAwesomeIcon icon={faTrash} className="text-danger pointer"
                                        onClick={() => this.handleDeleteFile(item.fileName, index)} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                        
                        
                    }
                    </List>
                }
                
               
                <input type="file" hidden onChange={this.addFiles} id="projectFiles" />
            </React.Fragment>
        );
    }
}