import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

export default class MembersJamSession extends Component {

    constructor(props) {
        super(props);

        this.state = {
            members: []
        }
    }

    componentDidMount() {
        
        fetch(new Request("api/JamSession/GetMembersJamSession?jamSessionId=" + this.props.jamSessionId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, []]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        members: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
                this.setState({
                    members: []
                });
            })
    }

    render() {
        return (

            <Table className="w-100">
                <TableHead>
                    <TableRow>
                        <TableCell>Membre</TableCell>
                        <TableCell>Equipe</TableCell>
                        <TableCell>Rôles</TableCell>
                        <TableCell>Défis table</TableCell>
                        <TableCell>Commentaire</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.state.members.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.fullName}</TableCell>
                                <TableCell>{row.teamName}</TableCell>
                                <TableCell>{
                                    (row.jamSessionRoles || []).map((item, index) => (
                                        <span className="badge badge-info mr-2" key={index}>{item}</span>
                                    ))
                                }</TableCell>
                                <TableCell>{row.specialTableDescription}</TableCell>
                                <TableCell>{row.comment}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            
            
            
            );
    }
}