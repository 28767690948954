import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import { toast } from 'react-toastify';

export default class EditJamSessionChart extends Component {


    constructor(props) {
        super(props);

        this.state = {
            options: {},
            series: [],
            labels:[]
        }
    }

    componentDidMount() {
        fetch(new Request("api/JamSession/GetDataChart?jamSessionId=" + this.props.jamSessionId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    var series = [];

                    if (response.members !== null) {
                        response.teams.map(row => (
                            series.push(response.members.filter(r => r.teamId === row.teamId).length)
                        ));
                    }

                    this.setState({
                        series: series,
                        options: {
                            labels: response.teams.map(s => s.teamName)
                        }
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    render() {
        return (
            <div className="justify-content-md-center">
                <div className="donut">
                    <Chart
                        options={this.state.options}
                        series={this.state.series}
                        labels={this.state.labels}
                        type="donut"
                    />
                </div>
            </div>
            
            );
    }
}