import React from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

export function About() {
    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>A propos - Success Team</title>
                    <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </MetaTags>
            </div>
        	<nav className="siteStyle top-nav">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to='/home'>Accueil</Link></li>
                    <li className="breadcrumb-item active"><h1 className="siteTitle">A propos</h1></li>
                </ol>
            </nav>
            <div className="jumbotron d-flex align-items-center bg-dark min-vh-25 mb-0" style={{ backgroundImage: "url('./img/Orange-Background-Free.png')" }}>
                <div className="container text-center">
                    <h1 className="text-white siteStyle font-weight-bolder custom-title">A PROPOS</h1>
                </div>
            </div>

            <div className="bg-white custom-margin">
                <div className="container row mx-auto">
                    <div className="col-md-12">
                        <div className="siteStyle text-center custom-margin-bottom">
                            <h2 className="custom-title2">Qui sommes-nous?</h2>
                            <p className="custom-subtitle mt-3">En savoir un peu plus sur l'origine de Success Team</p>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <img src="./img/meeting-image.jpg" className="rounded img-fluid" alt="team" />
                    </div>
                    <div className="col-md-6">
                        <p className="siteStyle custom-text">
                            Nous sommes un groupe de personnes animées par des valeurs, des visions et des objectifs différents. 
                            Cependant notre équipe naît lorsque nous échangeons, lorsque nous apprenons, lorsque nous nous mobilisons 
                            et lorsque nous avançons ensemble. Ce qui nous réunit est notre désir de nous construire en tant que porteur 
                            de projet, de réaliser notre succès, et d’atteindre l’excellence.
                            Le parcours de toute une vie, que nous ne pensons réalisable qu’à travers une collaboration entre nos membres 
                            et un travail régulier sur soi. Nous croyons en l’humain plus qu’au projet car il n’y a que le porteur de projet 
                            qui détient la clé de son succès.
                        </p>
                    </div>
                </div>
            </div>
            <div className="custom-margin" style={{ backgroundColor: "rgb(243, 245, 249)" }}>
                <div className="container row mx-auto">
                    <div className="col-md-12">
                        <div className="siteStyle text-center custom-margin-bottom">
                            <h2 className="custom-title2">Nos valeurs</h2>
                            <p className="custom-subtitle mt-3">Ce qui nous rend uniques et efficaces</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card custom-shadow">
                            <div className="card-body siteStyle">
                                <img src="./img/meeting.png" className="mx-auto custom-image my-2" alt="value" />
                                <div className="custom-subtitle font-weight-bold text-center mt-3">Collaboration</div>
                                <div className="text-center mt-2 mx-2" style={{ lineHeight: "20px" }}>
                                    Rejoindre une plateforme composée de nombreux entrepreneurs et profiter des avantages 
                                    des échanges d'idées, outils et services; est désormais possible.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card custom-shadow">
                            <div className="card-body siteStyle">
                                <img src="./img/diagram.png" className="mx-auto custom-image my-2" alt="value" />
                                <div className="custom-subtitle font-weight-bold text-center mt-3">Régularité</div>
                                <div className="text-center mt-2 mx-2" style={{ lineHeight: "20px" }}>
                                    Aucun projet ne marche sans régularité. Avec Success Team, obtenez la discipline 
                                    qui vous permettra de hisser votre projet à son potentiel maximum.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card custom-shadow">
                            <div className="card-body siteStyle">
                                <img src="./img/excellence.png" className="mx-auto custom-image my-2" alt="value" />
                                <div className="custom-subtitle font-weight-bold text-center mt-3">Excellence</div>
                                <div className="text-center mt-2 mx-2" style={{ lineHeight: "20px" }}>
                                    Nous nous assurons toujours de vous fournir l'encadrement nécessaire 
                                    afin de vous permettre d'atteindre l'excellence dans tous vos projets.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
