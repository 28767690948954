import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faEye } from '@fortawesome/free-solid-svg-icons';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import EditJamSession from './EditJamSession/EditJamSession';
import EditJamSessionMember from './EditJamSession/EditJamSessionMember';
import { connect } from 'react-redux';

class JamSessionTableView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openModalEditJamSession: false,
            jamSessionId: "",
            isRegistered:false
        };

        this.handleCloseModalJamSession = this.handleCloseModalJamSession.bind(this);
        this.handleCloseModalEditJamSession = this.handleCloseModalEditJamSession.bind(this);
        this.updateJamSession = this.updateJamSession.bind(this);
    }

    //componentDidMount() {
    //    if (this.props.jamSessionId !== null) {
    //        this.setState({
    //            openModalEditJamSession: true,
    //            jamSessionId: this.props.jamSessionId
    //        });
    //    }
    //}

    //handleCloseModalJamSession() {
    //    this.setState({
    //        openModalEditJamSession: false,
    //        jamSessionId: null
    //    });
    //}

    handleCloseModalJamSession(openEditJamSession) {
        if (openEditJamSession) {
            this.setState({
                openModalReadJamSession: false,
                openModalEditJamSession: openEditJamSession
            });
        } else {
            this.setState({
                openModalReadJamSession: false,
                jamSessionId: null
            });
        }

    }

    handleCloseModalEditJamSession() {
        this.setState({
            openModalEditJamSession: false
        });
    }

    updateJamSession(e) {
        this.setState({
            openModalReadJamSession: true,
            jamSessionId: e.currentTarget.dataset.id
        });

    }

    render() {
        return (
            <React.Fragment>
                <div style={{ overflowX: "auto" }}>
                    <Table className="w-100" size="small">
                        <TableHead style={{ backgroundColor: "#000" }}>
                            <TableRow>
                                <TableCell style={{ color: "#fff" }}></TableCell>
                                <TableCell style={{ color: "#fff" }}></TableCell>
                                <TableCell style={{ color: "#fff" }}>Numéro</TableCell>
                                <TableCell style={{ color: "#fff" }}>Thème</TableCell>
                                <TableCell style={{ color: "#fff" }}>Date</TableCell>
                                <TableCell style={{ color: "#fff" }}>Adresse</TableCell>
                                <TableCell style={{ color: "#fff" }}>Inscrits</TableCell>
                                <TableCell style={{ color: "#fff" }}>Créé par</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.props.jamSessions.map(row => (

                                    <TableRow key={row.jamSessionId} >
                                        <TableCell align="center">
                                            <Link className="pointer text-dark" to={"/jamsession/" + row.jamSessionId}>
                                                <FontAwesomeIcon icon={faPen} />
                                            </Link>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Link className="pointer text-dark" to={"/viewjamsession/" + row.jamSessionId}>
                                                <FontAwesomeIcon icon={faEye} />
                                            </Link>
                                        </TableCell>
                                        <TableCell>{row.jamSessionNumber}</TableCell>
                                        <TableCell>{row.theme}</TableCell>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell>{row.address}</TableCell>
                                        <TableCell>
                                            <OverlayTrigger trigger="hover" placement="left" overlay={
                                                <Popover>
                                                    <Popover.Title as="h3">Membres</Popover.Title>
                                                    <Popover.Content>
                                                        <ul>
                                                            {
                                                                (row.members || []).map((item, index) => (
                                                                    <li key={index}>- {item.fullName} (<strong>{item.team}</strong>)</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </Popover.Content>
                                                </Popover>
                                            }>
                                                <Chip color="primary" label={row.members != null ? row.members.length : 0} size="small" />
                                            </OverlayTrigger>
                                        </TableCell>
                                        <TableCell>{row.owner}</TableCell>
                                    </TableRow>
                                ))
                            }
                    
                        </TableBody>
                    </Table>
                </div>
                {
                    this.state.openModalEditJamSession && this.props.isAdmin && <EditJamSession jamSessionId={this.state.jamSessionId}
                        onCloseModal={this.handleCloseModalEditJamSession} />
                }
                {
                    this.state.openModalReadJamSession && <EditJamSessionMember jamSessionId={this.state.jamSessionId}
                        onCloseModal={this.handleCloseModalJamSession} />
                }
            </React.Fragment>
        );
    }
}

const MapStateToProps = state =>{
    return {
        jamSessionId: state.jamSessionId,
        participationJamSession: state.participationJamSession
    };
}

export default connect(MapStateToProps)(JamSessionTableView);