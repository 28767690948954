import React from 'react';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPaperPlane, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { AuthorizedBackendApiContext } from "../../api/api";
import Modal from 'react-bootstrap/Modal';
import ProjectStepEvaluationRequest from './ProjectStepEvaluationRequest';

export class ProjectStepEvaluation extends React.Component {
    static displayName = ProjectStepEvaluation.name;
    static contextType = AuthorizedBackendApiContext;

    constructor (props) {
        super(props);
        this.state = { 
            modal: false,
            loadMembers: false,
            strongPoints: "",
            learnPoints: "",
            excellentPoints: "",
            generalEvaluatorId: "",
            generalEvaluator: null,
            membersShared: [],
            members: [],
            memberSelected: null, 
            allChecks: false,
            todosSelected: [],
            todos:[],
            stepValidated: false, 
            loadingStep: false,
            isStepEvaluator: false,
            opeModal: false
        };

        this.handleInput = this.handleInput.bind(this);
        this.handleAutocompleteGeneralEvaluator = this.handleAutocompleteGeneralEvaluator.bind(this);
        this.evaluateStep = this.evaluateStep.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.getTodos = this.getTodos.bind(this);
        this.getTodosSelected = this.getTodosSelected.bind(this);
        this.getStepEvaluation = this.getStepEvaluation.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.checkStepValidated = this.checkStepValidated.bind(this);
    }

    componentDidMount() {
        this.checkStepValidated();
        this.getMembers();
        this.getTodos();
    }

    componentDidUpdate(prevProps) {
        if (this.props.stepId !== prevProps.stepId)
        {
            this.checkStepValidated();
            this.getMembers();
            this.getTodos();
        }
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleOpenModal() {
        this.setState({
            openModal: !this.state.openModal
        });
    }

    handleAutocompleteGeneralEvaluator(e, value) {
        if(value !== null) {
            this.setState({
                generalEvaluatorId: value.id,
                generalEvaluator: value
            });
        }
        else {
            this.setState({
                generalEvaluatorId: "",
                generalEvaluator: null
            });
        }
    }

    getMembers() {
        this.setState({ loadMembers: true });
        fetch(new Request("api/Member/GetMembersName"), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    // Bad code, brute force - temporary solution
                    this.setState({
                        members: response.filter(elm => elm.fullName.split(' ')[0] !== localStorage.getItem("firstName")),
                        loadMembers: false
                    }, () => { this.getStepEvaluation(); });
                } else {
                    this.setState({ loadMembers: false });
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    checkStepValidated() {
        this.setState({ loadingStep: true });
        fetch(new Request("api/Project/CheckProjectStepValidated?ProjectId=" + this.props.projectId + "&StepId=" + this.props.stepId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({ stepValidated: response });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
            this.setState({ loadingStep: false });
        });
    }

    getStepEvaluation() {
        this.setState({
            loadingStep: true
        });

        fetch(new Request("api/Project/GetStepEvaluation?ProjectId=" + this.props.projectId + "&StepId=" + this.props.stepId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    if(response !== null && response.length !== 0){
                        this.setState({
                            generalEvaluatorId: response.evaluatorId !== null ? response.evaluatorId : "",
                            generalEvaluator: response.evaluatorId !== null ? {
                                "fullName": response.evaluator,
                                "id":  response.evaluatorId
                            } : null,
                            strongPoints: response.strongPoints,
                            learnPoints: response.pointsImprove,
                            excellentPoints: response.excellencePoints,
                            isStepEvaluator: response.isStepEvaluator,
                            loadingStep: false
                        });
                    }
                    else {
                        this.setState({
                            generalEvaluatorId: "",
                            generalEvaluator: null,
                            strongPoints: "",
                            learnPoints: "",
                            excellentPoints: "",
                            loadingStep: false
                        });
                    }
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                this.setState({ loadingStep: false });
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    evaluateStep() {
        this.setState({
            loadingStep: true
        });

        fetch(new Request("api/Project/SetStepValidated", {
            method: "POST",
            body: JSON.stringify({
                EvaluatorId: this.state.generalEvaluatorId,
                ProjectId: this.props.projectId,
                StepId: this.props.stepId,
                Evaluation: {
                    "StrongPoints": this.state.strongPoints,
                    "PointsImprove": this.state.learnPoints,
                    "ExcellencePoints": this.state.excellentPoints
                },
                Todos: this.state.todosSelected
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);

            })
            .then(([ok, response]) => {
                if (ok) {
                    toast.success("Mise à jour effectuée !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                    //this.props.refresh();
                    if (this.state.learnPoints !== "" && this.state.excellentPoints !== "" && this.state.strongPoints !== "" && this.state.todos.length === this.state.todosSelected.length && this.state.todos.length !== 0 && this.state.generalEvaluatorId !== "") {
                        this.setState({ stepValidated: true });
                    }
                    this.setState({ loadingStep: false });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    loadingStep: false
                });
            });
    }

    handleCheckbox(todo) {
        const index = (this.state.todosSelected || []).findIndex(s => s.toDoId === todo.toDoId);
        if (index >= 0) {
            var array = this.state.todosSelected;
            array.splice(index, 1);
            this.setState({
                todosSelected: array
            });
        } else {
            this.setState({
                todosSelected: [...this.state.todosSelected, todo]
            });
        }
    }

    getTodos() {
        fetch(new Request("api/Todo/GetToDos?stepId=" + this.props.stepId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    if (response !== null) {
                        this.setState({
                            todos: response
                        });
                    }
                    else {
                        this.setState({
                            todos: []
                        });
                    }
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    getTodosSelected() {
        fetch(new Request("api/Todo/GetToDosProject?stepId=" + this.props.stepId + "&projectId=" + this.props.projectId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        todosSelected: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }
    
    render () {
        return (
            <React.Fragment>
                {
                    !this.state.loadingStep ? 
                    <div>
                        {
                            this.props.isOwner || this.state.isStepEvaluator ? 
                            <React.Fragment>
                                {
                                    !this.state.stepValidated ? null : 
                                    <div className="alert alert-primary siteStyle" role="alert">
                                        Cette étape a déjà été évaluée
                                    </div>
                                }

                                <div className="form-group">
                                    <Autocomplete onChange={this.handleAutocompleteGeneralEvaluator} options={this.state.members}
                                        getOptionLabel={o => o.fullName} value={this.state.generalEvaluator}
                                        renderInput={params => (
                                            <TextField {...params} fullWidth variant="filled" label="Evaluateur" 
                                        />)} 
                                        disabled={this.state.stepValidated}
                                    />
                                </div>

                                <div className="table-responsive w-100 border mb-3">
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>Objectifs</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.todos !== null ? 
                                                (this.state.todos || []).map(row => (
                                                    <TableRow key={row.toDoId}>
                                                        <TableCell>
                                                            <Checkbox checked={!this.state.stepValidated ? (this.state.todosSelected || []).some(s => s.toDoId === row.toDoId) : true}
                                                                color="primary" onChange={() => this.handleCheckbox(row)} size="small"
                                                                disabled={!this.state.isStepEvaluator || this.state.stepValidated} 
                                                            />
                                                        </TableCell>
                                                        <TableCell>{row.title}</TableCell>
                                                    </TableRow>
                                                ))
                                                : null
                                            }
                                        </TableBody>
                                    </Table>
                                </div>

                                {
                                    this.state.todos !== null ? 
                                    <React.Fragment>
                                        <div className="form-group">
                                            <TextField disabled={this.state.stepValidated || !this.state.isStepEvaluator} onChange={this.handleInput} fullWidth multiline rows="3" label="Points forts"
                                                name="strongPoints" value={this.state.strongPoints} variant="filled" />
                                        </div>
                                        <div className="form-group">
                                            <TextField disabled={this.state.stepValidated || !this.state.isStepEvaluator} onChange={this.handleInput} fullWidth multiline rows="3" label="Points à améliorer"
                                                name="learnPoints" value={this.state.learnPoints} variant="filled" />
                                        </div>
                                        <div className="form-group">
                                            <TextField disabled={this.state.stepValidated || !this.state.isStepEvaluator} onChange={this.handleInput} fullWidth multiline rows="3" label="Points d'excellence"
                                                name="excellentPoints" value={this.state.excellentPoints} variant="filled" />
                                        </div>
                                    </React.Fragment> : null
                                }
                                
                                {
                                    this.state.isStepEvaluator ? 
                                    <Button
                                        className="float-right text-initial" color="primary" variant="contained" size="small"
                                        onClick={this.evaluateStep} disabled={!(this.state.learnPoints !== "" && this.state.excellentPoints !== "" && this.state.strongPoints !== "" && this.state.todos.length === this.state.todosSelected.length && this.state.todos.length !== 0 && this.state.generalEvaluatorId !== "")}
                                    >
                                        <FontAwesomeIcon className="mr-1" icon={faCheck} size="sm" /> { !this.state.stepValidated ? <span>Valider</span> : <span>Tache validée</span>}
                                    </Button> : 
                                    <Button
                                        className="float-right text-initial" color="primary" variant="contained" size="small"
                                        onClick={this.evaluateStep} disabled={this.state.generalEvaluatorId === "" || this.state.stepValidated}
                                    >
                                        <FontAwesomeIcon className="mr-1" icon={faUserEdit} size="sm" /> Modifier l'évaluateur
                                    </Button>
                                }
                                <Button className="float-left text-initial btn-info-custom" variant="contained" size="small" onClick={this.handleOpenModal} disabled={this.state.stepValidated} >
                                    <FontAwesomeIcon className="mr-1" icon={faPaperPlane} size="sm" /> Demande d'évaluation
                                </Button>
                            </React.Fragment> : 
                            <div className="alert alert-warning siteStyle" role="alert">
                                Vous n'avez pas accès à l'évaluation de cette étape
                            </div>
                        }
                    </div>
                    : <Skeleton />
                }
                <Modal size="xl" dialogClassName="modal-90w" show={this.state.openModal} onHide={this.handleOpenModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className="siteStyle">
                            <strong>Demander une évaluation</strong>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.openModal ? 
                            <ProjectStepEvaluationRequest 
                                projectId={this.props.projectId} 
                                stepId={this.props.stepId} 
                                steps={this.props.steps}
                                projectName={this.props.projectName}
                                closeModal={this.handleOpenModal}
                            /> : null
                        }
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
