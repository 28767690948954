import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { HomeJamSessions } from './HomeJamSessions';
import { CardDefault } from './CardDefault';
import { CardTestimonial } from './CardTestimonial';
import { VideoBanner } from './VideoBanner';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

export function Home() {
    return (
        <div className="row" style={{ overflowX: "hidden" }}>
            <div className="wrapper">
                <MetaTags>
                    <title>Communauté d'entrepreneurs - Success Team</title>
                    <meta name="description" content="Accelerer son projet? Entreprendre? Rejoignez une communauté de divers entrepreneurs pour booster votre projet entrepreneurial." />
                </MetaTags>
            </div>
            <VideoBanner />

            {/* Our first proposition */}
            <div className="custom-margin w-100 px-md-5 px-2" style={{ backgroundColor: "#f9f9fc" }}>
                <div className="container">
                    <div className="row h-100">
                        <div className="col-md-6 my-auto">
                            <div className="">
                                <p className="h4 text-dark homeTitle">
                                    Vous etes aspirant entrepreneur, entrepreneur, une equipe d'entrepreneurs, ou simplement porteur de projets désirant s'entourer d'une équipe dynamique pour mieux reussir son projet, et devenir une meilleur version de vous meme.
                                </p>
                                <Button component={Link} to="/signup" className="text-initial btn-warning-custom mt-2 mb-1" variant="contained" color="primary" size="medium">
                                    Rejoignez nous
                                </Button>
                            </div>
                        </div>
                        <div className="col-md-6 pt-md-0 pt-3">
                            <img src="./img/teamwork.jpg" className="img-fluid homeImage" alt="fluid" style={{
                                borderBottomRightRadius: "30px", borderTopLeftRadius: "30px", border: "2px solid #E5E5E5", float: "right"
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Our second proposition */}
            <div className="custom-margin w-100 px-md-5 px-2 siteBackColor">
                <div className="container">
                    <div className="row h-100">
                        <div className="col-md-6">
                            <img src="./img/teambuilding.jpg" className="img-fluid homeImage" alt="fluid" style={{
                                borderBottomLeftRadius: "30px", borderTopRightRadius: "30px", border: "2px solid #E5E5E5"
                            }} />
                        </div>
                        <div className="col-md-6 pt-md-0 pt-3 my-auto">
                            <div className="">
                                <p className="h4 text-white homeTitle text-right">
                                    Vous avez besoin d'aide pour votre projet ? <br />
                                    {"Trouvez des services et outils disponibles dans la communauté"}
                                </p>
                                <Button component={Link} to="/services" className="text-initial mt-2 mb-1 bg-white float-right" variant="contained" color="default" size="medium">
                                    Consultez nos services
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Our services */}
            <div className="custom-margin w-100" style={{ backgroundColor: "#f3f5f9" }}>
                <div className="container">
                    <div className="h2 mb-4 homeTitle text-center">Développez vous tout en développant les autres</div>
                    <div className="row py-md-2 py-1">
                        <div className="col-lg-3 col-md-6 px-4">
                            <CardDefault
                                link="/routes"
                                title="Parcours"
                                src="./img/home-routes.jpg"
                                description="La Success Team vous propose plusieurs modules d'apprentissage. Vous souhaitez améliorer votre gestion du temps, développer votre marketing, ou suivre un projet..."
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 px-4">
                            <CardDefault
                                link="/events"
                                title="Evénements"
                                src="./img/home-events.jpg"
                                description="Inscrivez-vous et venez rencontrer en exclusivité les personnes qui font vibrer la Success Team, participez à une JAM session, ou venez soutenir nos porteurs de projets."
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 px-4">
                            <CardDefault
                                link="/posts"
                                title="Annonces"
                                src="./img/home-ads.png"
                                description="Nous rassemblons pour vous les récentes nouvelles de l'entrepreneuriat, des conseils et articles, pour vous aider à devenir l'entrepreneur conscient de demain."
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 px-4">
                            <CardDefault
                                link="/shops"
                                title="Boutiques"
                                src="./img/home-e-commerce.jpg"
                                description="Notre boutique en ligne rassemble les créations de nos membres. Nous avons à cœur de les soutenir, mais aussi de vous faire découvrir un travail de qualité."
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Our testimonials */}
            <div className="custom-margin w-100 bg-white">
                <div className="container">
                    <div className="h2 mb-4 homeTitle text-center">Plusieurs entrepreneurs nous font confiance</div>
                    <div className="row py-md-2 py-1">
                        <div className="col-lg-4 col-md-6 px-4">
                            <CardTestimonial
                                name="Amina" image="./img/photo-amina.png"
                                description="Avec vous il est devient désormais interdit de s’abandonner à soi même ! Ensemble plus fort que 1+1 = 2 <br> Une force et alchimie exponentielle!"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 px-4">
                            <CardTestimonial
                                name="Maurice" image="./img/photo-maurice.png"
                                description="Telle une machine bien rodée, nous nous sommes mis au taf et nous avons donné la meilleure version de nous mêmes sur le moment. <br> (C’était vraiment une construction d’une Team qui je le rappelle signifie Together each achieve more!) <br> Ensemble nous sommes vraiment fort et smart! <br> Longue vie à nous la ST."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 px-4">
                            <CardTestimonial
                                name="Didier" image="./img/photo-mdidier.png"
                                description="L'expérience confirme à travers les siècles et les lieux que L'UNION FAIT LA FORCE... quand elle est bien canalisée... <br> Convaincu que la ST est une bonne formule, J'y suis venu pour partager mes expériences et ensemble contribuer modestement à renforcer nos identités fortes, nos croyances stimulantes, nos pensées énergisantes..."
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Our current jam Sessions */}
            <div className="custom-margin w-100" style={{ backgroundColor: "#f3f5f9" }}>
                <div className="container">
                    <div className="mx-auto py-md-0 py-1 text-center">
                        <span className="h2 text-dark homeTitle">Nos sessions de travail en cours</span>
                    </div>
                    <HomeJamSessions />
                </div>
            </div>

            {/* Our goals */}
            <div className="custom-margin w-100 px-md-5 px-2" style={{ background: "linear-gradient(to bottom right,#fd7419,rgba(244,142,54,0.95))" }}>
                <div className="container text-center px-4">
                    <p className="text-white"><strong className="h2 homeTitle text-white">A qui s'adresse SuccessTeam ?</strong></p>
                    <p className="text-white siteStyle my-3" style={{ fontSize: "1.45em" }}>
                        A tout aspirant entrepreneur, entrepreneur, équipe d'entrepreneurs, ou porteur de projets désirant 
                        s'entourer d'une équipe dynamique pour mieux réussir son projet, et devenir une meilleure version de lui-méme.
                    </p>
                    <Button component={Link} to="/signup" className="text-initial bg-white mt-3" variant="contained" size="medium" style={{ width: "300px !important" }}>
                        <strong>Nous rejoindre</strong>
                    </Button>
                </div>
            </div>
        </div>
    );
}
