import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function CirclesProjectTypeActivitiesList(props: {steps: any}) {
    const colors = ["bg-primary", "bg-secondary", "bg-danger", "bg-info", "bg-warning", "bg-success"];
    
    return (
        <div className="row siteStyle" style={{ margin: "0 auto" }}>
            <div className="col-lg-12 col-md-12 col-12 activitiesGroup">
                <div className="row">
                    {
                        props.steps.map((row: any, i: any) => (
                            <React.Fragment key={i}>
                                <div className="col-lg-2 col-md-3 col-sm-4 col-12 px-0">
                                    <div className={colors[row.color] + " rounded-circle custom-circle"}>
                                        <div className="text-white text-center font-weight-bolder">{row.stepPosition}</div>
                                        <p className="text-white text-center">{row.stepName}</p>
                                    </div>
                                    
                                    <React.Fragment>
                                        {
                                            (i === 5 || i === 6) &&
                                            <React.Fragment>
                                                <div className="custom-arrow-vertical" />
                                                <FontAwesomeIcon icon={faChevronDown} className="custom-arrow-down" />
                                            </React.Fragment>
                                        }
                                        {
                                            (i >= 0 && i < 5) &&
                                            <React.Fragment>
                                                <div className="custom-arrow" />
                                                <FontAwesomeIcon icon={faChevronRight} className="custom-arrow-head" />
                                            </React.Fragment>
                                        }
                                        {
                                            (i >= 6 && i < 11) &&
                                            <React.Fragment>
                                                <div className="custom-arrow" />
                                                <FontAwesomeIcon icon={faChevronLeft} className="custom-arrow-back" />
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                </div>
                            </React.Fragment>
                        ))
                    }
                </div>
            </div> 
        </div>
    );
}