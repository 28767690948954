import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSync, faPen } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { BackendService, FileResponse, ModelToDoDto, TO_DO } from '../../api/services.generated';
import { StepEditTodo } from './StepEditTodo';
import { useAuthorizedBackendApi } from '../../api/api';

export default function StepTodo(props: {stepId: string}) {
    const api:BackendService | null = useAuthorizedBackendApi();
    const [todoId, setTodoId] = useState<string | null | undefined>("");
    const [stepId, setStepId] = useState<string>(props.stepId);
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [validated, setValidated] = useState<string>("");
    const [todos, setTodos] = useState<any>([]);
    const [loadingTodo, setLoadingTodo] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    useEffect(() => {
        refreshTodoTable();
    }, []);
    
    async function refreshTodoTable() {
        if(!api) {
            return;
        }

        try {
            const response:FileResponse | null = await api.toDo_GetToDos(props.stepId);

            if (!response) {
                return;
            }

            setTodos(response);
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
        }

        /*
        fetch(new Request("api/Todo/GetToDos?stepId=" + props.stepId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                setTodos(response);
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        });
        */
    }

    async function newStepTodo() {
        setLoadingTodo(true);
        
        if(!api) {
            return;
        }

        try {
            const response:FileResponse | null = await api.toDo_GetToDos(props.stepId);

            if (!response) {
                return;
            }

            setTodoId("");
            setTodos(response);
            setTitle("");
            setDescription("");
            setLoadingTodo(false);
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
            setLoadingTodo(false);
            setTodos([]);
        }

        /*
        fetch(new Request("api/Todo/GetToDos?stepId=" + props.stepId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                console.log(response);
                setTodoId("");
                setTodos(response);
                setTitle("");
                setDescription("");
                setLoadingTodo(false);
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                hideProgressBar: false,
                position: "top-left"
            });
            setLoadingTodo(false);
            setTodos([]);
        });
        */
    }

    async function getEditTodo(todoId: string | null | undefined) {
        setLoadingTodo(true);
        
        if(!api) {
            return;
        }

        try {
            const response:any | null = await api.toDo_GetToDo(todoId, null, null, undefined, props.stepId, null);

            if (!response) {
                return;
            }

            setTodoId(todoId);
            setTitle(response.title);
            setDescription(response.description);
            setLoadingTodo(false);
        }
        catch (error) {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
            setLoadingTodo(false);
        }
        /*
        fetch(new Request("api/Todo/GetToDo?StepId="+ props.stepId + "&TodoId=" + todoId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null]);
        })
        .then(([ok, response]) => {
            if (ok) {
                setTodoId(todoId);
                setTitle(response.title);
                setDescription(response.description);
                setLoadingTodo(false);
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
            setLoadingTodo(false);
        });
        */
    }

    return (
        <div className="row card-body">
            <div className="col-md-8">
                <div>
                    <div>
                        <div className="alert alert-secondary">
                            <Button variant="contained" size="small" color="primary" className="mr-2 text-capitalize" onClick={newStepTodo}>
                                <FontAwesomeIcon icon={faPlusCircle} className="mr-1" size="sm" /> Nouveau
                            </Button>
                            <Button variant="contained" size="small" className="text-capitalize btn-info-custom" onClick={refreshTodoTable}>
                                <FontAwesomeIcon icon={faSync} className="mr-1" size="sm" /> Rafraîchir
                            </Button>
                        </div>
                        <div className="table-responsive">
                            <Table className="w-100" size="small">
                                <TableHead style={{ backgroundColor: "#000" }}>
                                    <TableRow>
                                        <TableCell style={{ color: "#fff" }}></TableCell>
                                        <TableCell style={{ color: "#fff" }}>Titre</TableCell>
                                        <TableCell style={{ color: "#fff" }}>Description</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        todos !== null ? 
                                        todos.map((row: ModelToDoDto, i: string | number| undefined) => (
                                            <TableRow key={i}>
                                                <TableCell align="center">
                                                    <FontAwesomeIcon icon={faPen} className="pointer"
                                                        onClick={() => getEditTodo(row.toDoId)} />
                                                </TableCell>
                                                <TableCell>{row.title}</TableCell>
                                                <TableCell>{row.description}</TableCell>
                                            </TableRow>
                                        ))
                                        : null
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                {
                    !loadingTodo ?
                        <StepEditTodo refresh={refreshTodoTable} stepId={props.stepId} todoId={todoId} title={title} validated={validated} description={description}  /> :
                        <FontAwesomeIcon icon={faSync} className="fa-5x fa-spin" style={{ marginLeft: "40%" }} />
                }
            </div>
        </div>
    );
}
