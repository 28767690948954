import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faExclamationCircle, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Redirect } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import JamSessionResume from './JamSessionResume';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from '@material-ui/lab/Skeleton';


export default class RegisterJamSession extends Component {

    constructor(props) {
        super(props);

        this.state = {
            attendingNextSession: [],
            specialTableDescription: "",
            comment: "",
            redirectionJamSessionsList: false,
            loadingData: true,
            ordinaryTable: false,
            specialTable: false,
            programs: [],
            projectsGroup:[],
            projects: [],
            programValue: "",
            projectGroupValue:"",
            projectValue:""
        }
        
        this.handleInput = this.handleInput.bind(this);
        this.handleAttendingNextSessionSelected = this.handleAttendingNextSessionSelected.bind(this);
        this.saveJamSessionMember = this.saveJamSessionMember.bind(this);
        this.getProgramProjets = this.getProgramProjets.bind(this);
        this.getProjectGroup = this.getProjectGroup.bind(this);
        this.handleProjectSelected = this.handleProjectSelected.bind(this);
    }

    componentDidMount() {
        fetch(new Request("api/JamSession/GetJamSessionRoles?jamSessionId=" + this.props.jamSessionId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    var tab = [];
                    response.forEach(item => {
                        tab.push({
                            jamSessionRoleId: item.jamSessionRoleId,
                            jamSessionRoleName: item.jamSessionRoleName,
                            jamSessionRoleSpecialTable: item.jamSessionRoleSpecialTable,
                            checked: false
                        });
                    });

                    this.setState({
                        attendingNextSession: tab
                    });

                    this.fillPrograms(tab.some(s => s.jamSessionRoleSpecialTable === false));
                    this.getRegisterJamSession();
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });

                this.setState({
                    loadingData: false
                });
            });
    }

    fillPrograms(ordinaryTable) {

        if (ordinaryTable) {
            fetch(new Request("api/Program/GetPrograms", {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                })
            }))
                .then(response => {
                    if (response.status !== 204)
                        return Promise.all([response.ok, response.json()]);

                    return Promise.all([response.ok, null]);
                })
                .then(([ok, response]) => {
                    if (ok) {
                        this.setState({
                            programs: response
                        });
                    } else {
                        throw new Error(response);
                    }
                })
                .catch(error => {
                    toast.warn(error + " !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                });
        }
    }

    getRegisterJamSession() {
        if (this.props.isRegistered) {
            fetch(new Request("api/JamSession/GetRegisterJamSession?jamSessionId=" +
                this.props.jamSessionId, {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    })
                }))
                .then(response => {
                    if (response.status !== 204)
                        return Promise.all([response.ok, response.json()]);

                    return Promise.all([response.ok, null]);
                })
                .then(([ok, response]) => {
                    if (ok) {
                        var tabs = [];
                        this.state.attendingNextSession.forEach(item => {
                            var jamSession = response.attendingNextSession.find(a => a.jamSessionRoleId === item.jamSessionRoleId);
                            if (jamSession !== undefined) {
                                tabs.push({
                                    jamSessionRoleId: item.jamSessionRoleId,
                                    jamSessionRoleName: item.jamSessionRoleName,
                                    jamSessionRoleSpecialTable: item.jamSessionRoleSpecialTable,
                                    checked: true
                                });

                                if (jamSession.jamSessionRoleSpecialTable !== null && jamSession.jamSessionRoleSpecialTable) {
                                    this.setState({
                                        specialTable: true
                                    });
                                } else if (jamSession.jamSessionRoleSpecialTable !== null && !jamSession.jamSessionRoleSpecialTable) {
                                    this.setState({
                                        ordinaryTable: true,
                                        programValue: response.programId,
                                        projectGroupValue: response.projectGroupId,
                                        projectValue: response.projectId
                                    });
                                }
                            } else {
                                tabs.push(item);
                            }
                        });

                        const projectsGroup = this.state.programValue !== undefined && this.state.programValue !== null ?
                            this.state.programs.find(p => p.programId === this.state.programValue).projectsGroup : [];
                        var projects = projectsGroup !== undefined && projectsGroup !== null ?
                            projectsGroup.find(p => p.moduleId === this.state.projectGroupValue).projects : [];

                        this.setState({
                            attendingNextSession: tabs,
                            specialTableDescription: response.specialTableDescription,
                            comment: response.comment,
                            loadingData: false,
                            projectsGroup: projectsGroup,
                            projects: projects
                        });
                    } else {
                        throw new Error(response);
                    }
                })
                .catch(error => {
                    toast.warn(error + " !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                    this.setState({
                        loadingData: false
                    });
                });


        } else {
            this.setState({
                loadingData: false
            });
        }
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleAttendingNextSessionSelected(e) {
        var tabs = [];

        if (this.state.attendingNextSession.filter(f => f.checked).length < 2 && e.target.checked) {

            this.state.attendingNextSession.forEach(item => {
                if (item.jamSessionRoleId === e.target.value) {
                    if (this.state.attendingNextSession.some(a => a.checked &&
                        a.jamSessionRoleSpecialTable !== null) && item.jamSessionRoleSpecialTable !== null) {
                        tabs.push(item);
                            toast.warn("Vous ne pouvez pas sélectionner une table spéciale et ordinaire !", {
                                position: "top-center",
                                hideProgressBar: false
                            });
                    } else {
                        if (item.jamSessionRoleSpecialTable === false) {
                            this.setState({
                                ordinaryTable: true
                            });
                        } else if (item.jamSessionRoleSpecialTable) {
                            this.setState({
                                specialTable: true
                            });
                        }

                        tabs.push({
                            jamSessionRoleId: e.target.value,
                            jamSessionRoleName: item.jamSessionRoleName,
                            jamSessionRoleSpecialTable: item.jamSessionRoleSpecialTable,
                            checked: true
                        });
                    }
                } else {
                    tabs.push(item);
                }
            });

            this.setState({
                attendingNextSession: [...tabs]
            });
            
            
        } else {
            if (!e.target.checked) {
                this.state.attendingNextSession.forEach(item => {
                    if (item.jamSessionRoleId === e.target.value) {
                        if (item.jamSessionRoleSpecialTable === false) {
                            this.setState({
                                ordinaryTable: false
                            });
                        } else if (item.jamSessionRoleSpecialTable) {
                            this.setState({
                                specialTable: false
                            });
                        }

                        tabs.push({
                            jamSessionRoleId: e.target.value,
                            jamSessionRoleName: item.jamSessionRoleName,
                            jamSessionRoleSpecialTable: item.jamSessionRoleSpecialTable,
                            checked: false
                        });
                    } else {
                        tabs.push(item);
                    }
                });
                this.setState({
                    attendingNextSession: [...tabs]
                });
            } else {
                e.target.checked = false;
                toast.warn("Vous pouvez sélectionner que 2 rôles max !", {
                    position: "top-center",
                    hideProgressBar: false
                });
            }
        }
    }

    saveJamSessionMember() {
        this.setState({
            btnSaveLoading: true
        });

        fetch(new Request("api/JamSession/RegisterJamSession", {
            method: "POST",
            body: JSON.stringify({
                AttendingNextSession: this.state.attendingNextSession.filter(f => f.checked),
                SpecialTableDescription: this.state.specialTableDescription,
                Comment: this.state.comment,
                jamSessionId: this.props.jamSessionId,
                UserId: this.props.memberId,
                ProgramId: this.state.ordinaryTable ? this.state.programValue : null,
                ProjectGroupId: this.state.ordinaryTable ? this.state.projectGroupValue : null,
                ProjectId: this.state.ordinaryTable ? this.state.projectValue : null
            }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    toast.success("Jam Session saved", {
                        position: "top-center",
                        hideProgressBar: false
                    });

                    this.setState({
                        redirectionJamSessionsList: true
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    btnSaveLoading: false
                });
            });
    }

    getProgramProjets(e) {
        this.setState({
            programValue: e.target.value,
            projectsGroup: this.state.programs.find(p => p.programId === e.target.value).projectsGroup
        });
    }

    getProjectGroup(e) {
        this.setState({
            projectGroupValue: e.target.value,
            projects: this.state.projectsGroup.find(p => p.moduleId === e.target.value).projects
        });
    }

    handleProjectSelected(e) {
        this.setState({
            projectValue: e.target.value
        });
    }

    render() {

        if (this.state.redirectionJamSessionsList)
            return <Redirect to= "/jamSessions" />
        return (
            <div className="row">
                <div className="col-md-4">
                    <JamSessionResume jamSessionId={this.props.jamSessionId} />
                </div>
                <div className="col-md-8">
                    <Card>
                        <CardContent>
                            {
                                this.state.loadingData ?
                                <Skeleton /> :
                                <React.Fragment>
                                <div className="alert alert-secondary mt-2">
                                    <button type="button" className="btn btn-success" onClick={this.saveJamSessionMember}>
                                        <FontAwesomeIcon icon={faSave} size="sm" /> Enregistrer
                                    </button>
                                </div>

                                <div className="form-group row mb-4">
                                    <label className="col-md-3 font-weight-bold">
                                        Quel rôle souhaites-tu avoir ? (Max 2)
                            <OverlayTrigger trigger="hover" placement="bottom" overlay={
                                            <Tooltip>
                                                Les membres participants à la session peuvent choisir de s'inscrire pour jouer un role dans le déroulement de la session les rôles sont décris dans le document suivant:
                                <a target="blank" href="https://docs.google.com/document/d/1GIyRO6Sp3gF6CcDC43nEMf8PpA4XXeyDO3ndB2A-xSg/edit?usp=sharing">Cliquez sur le lien</a>
                                            </Tooltip>
                                        }>
                                            <FontAwesomeIcon icon={faExclamationCircle} className="ml-2" />
                                        </OverlayTrigger>
                                    </label>
                                    <div className="col-md-9">
                                        {
                                            this.state.attendingNextSession.map((row, index) => (

                                                <div key={index} className="form-check form-check-inline">
                                                    <Checkbox color="primary" onChange={this.handleAttendingNextSessionSelected} name="AttendingNextSession"
                                                        id={`customCheck${index}`} value={row.jamSessionRoleId} checked={row.checked} />
                                                    <label className="form-check-label" htmlFor={`customCheck${index}`}>{row.jamSessionRoleName}</label>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                                {
                                    this.state.ordinaryTable &&
                                    <React.Fragment>
                                        <div className="form-group row mb-4">
                                            <label className="col-md-3 font-weight-bold">Programme</label>
                                            <div className="col-md-3">
                                                <Select value={this.state.programValue} className="w-100" onChange={this.getProgramProjets}>
                                                    {
                                                        (this.state.programs || []).map(row => (
                                                            <MenuItem value={row.programId} key={row.programId}>
                                                                {row.programName}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                            <label className="col-md-3 font-weight-bold">Module</label>
                                            <div className="col-md-3">
                                                <Select value={this.state.projectGroupValue} className="w-100" onChange={this.getProjectGroup}>
                                                    {
                                                        (this.state.projectsGroup || []).map(row => (
                                                            <MenuItem value={row.moduleId} key={row.moduleId}>
                                                                {row.moduleName}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-3 font-weight-bold">Projet</label>
                                            <div className="col-md-9">
                                                <Select value={this.state.projectValue} className="w-100" onChange={this.handleProjectSelected}>
                                                    {
                                                        this.state.projects != null && this.state.projects.map((item, index) => (
                                                            <MenuItem value={item.activityId} key={index}>{item.activityName}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }

                                {
                                    this.state.specialTable && <div className="form-group row mb-4">
                                        <label className="col-md-3 font-weight-bold">
                                            Quel défis voudrais-tu soumettre à la SuccessTeam ?
                            <OverlayTrigger trigger="hover" placement="bottom" overlay={
                                                <Tooltip>
                                                    (La table spéciale te donne droit à 20-30 min de Mastermind/Intelligence Collective sur ton projet ou un sujet spécifique où tu souhaites la contribution des Akilis)
                                </Tooltip>
                                            }>
                                                <FontAwesomeIcon icon={faExclamationCircle} className="ml-2" />
                                            </OverlayTrigger>
                                        </label>
                                        <div className="col-md-9">
                                            <textarea onChange={this.handleInput} value={this.state.specialTableDescription} className="form-control" name="specialTableDescription"></textarea>
                                        </div>

                                    </div>
                                }

                                <div className="form-group row mb-4">
                                    <label className="col-md-3 font-weight-bold">
                                        Commentaire
                            <OverlayTrigger trigger="hover" placement="bottom" overlay={
                                            <Tooltip>
                                                (Attentes particulières par rapport à la session? Suggestion d'amélioration?)
                                </Tooltip>
                                        }>
                                            <FontAwesomeIcon icon={faExclamationCircle} className="ml-2" />
                                        </OverlayTrigger>
                                    </label>
                                    <div className="col-md-9">
                                        <textarea onChange={this.handleInput} defaultValue={this.state.comment} className="form-control" name="comment"></textarea>
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                        </CardContent>
                    </Card>
                </div>
            </div>
            
        );
    }
}