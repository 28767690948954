import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

export function CardTestimonial({description, name, image} : {description: string, name: string, image: string}) {
    return (
        <Card className="TestimonialCard my-4 siteStyle shadow">
            <CardContent className="siteStyle px-3">
                <FontAwesomeIcon className="my-3 ml-3" icon={faQuoteLeft} style={{ fontSize: "32px", color: "#fd7419" }} />
                {/*
                <div className="row">
                    <div className="col-md-4 offset-md-8">
                        <img className="img-fluid" src="https://cdn0.iconfinder.com/data/icons/user-collection-4/512/user-512.png" alt="to" />
                    </div>
                </div>
                */}
                <div className="text-center px-3"><div dangerouslySetInnerHTML={{ __html: description }} /></div>
                <div className="d-none d-sm-block" style={{ position: "absolute", bottom: "10%", left: "10%", right: "10%" }}>
                    <div className="container">
                        <div className="row mt-4 mx-1">
                            <div className="col-md-6 text-left pl-0">
                                <div style={{ fontSize: "1.6em" }}>
                                    <strong>{name}</strong>
                                </div>
                            </div>
                            <div className="col-md-6 text-right pr-0">
                                <img
                                    style={{ height: "60px", width: "60px", position: "relative", bottom: "15px" }}
                                    src={image === null ? "https://www.xeus.com/wp-content/uploads/2014/09/One_User_Orange.png" : image}
                                    className="rounded-circle" alt="Entrepreneur"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 mx-1 text-center d-block d-sm-none">
                    <div>
                        <img style={{ height: "50px", width: "50px", position: "relative" }} src={image === null ? "https://www.xeus.com/wp-content/uploads/2014/09/One_User_Orange.png" : image} alt="Entrepreneur" />
                    </div>
                    <div className="mt-2">
                        <strong style={{ fontSize: "1.5em" }}>{name}</strong>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}
