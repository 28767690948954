import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers/rootReducer";
import { ConfigContext } from "./utils/config";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";

const store = createStore(rootReducer);
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
let config: any;

function renderApp() {
    ReactDOM.render(
        <Provider store={store}>
            <ConfigContext.Provider value={config}>
                <BrowserRouter basename={baseUrl!}>
                    <App />
                </BrowserRouter>
            </ConfigContext.Provider>
        </Provider>,
        rootElement
    );
}

async function init() {
    const language = localStorage.getItem("language") || "en"
    await i18next
        .use(initReactI18next)
        .use(HttpApi)
        .init({
            backend: {
                loadPath: "/lang/{{lng}}.json",
            },
            lng: language,
            fallbackLng: "en",

            interpolation: {
                escapeValue: false,
            },
        });
    const r = await fetch("config.json");
    const json = await r.json();
    config = json;
    renderApp();
}

init();

registerServiceWorker();

if ((module as any).hot) {
    (module as any).hot.accept("./App", () => {
        renderApp();
    });
}
