import { IsTokenExpired, IsAdmin, IsSuperAdmin } from '../components/config/IsTokenExpired';
import {Action, SystemState} from "./types";

let isAdmin = IsAdmin();
let isSuperAdmin = IsSuperAdmin();
const iniState: SystemState = {
    auth: !IsTokenExpired(),
    token: localStorage.getItem("token"),
    firstName: localStorage.getItem("firstName"),
    isAdmin: isAdmin,
    isSuperAdmin: isSuperAdmin,
    activePageMember: isAdmin,
    activePageJamSession: !isAdmin,
    activePageMasterData: false,
    participationJamSession: null,
    jamSessionId: null,
    invitatedList: [],
    jamSessionIsReading: false,
    avatar: localStorage.getItem("avatar") === "null" ? null : localStorage.getItem("avatar")
};

const rootReducer = (state = iniState, action: Action) => {
    if (action.type === "DISCONNECT_APP") {
        localStorage.removeItem("token");
        localStorage.removeItem("firstName");
        localStorage.removeItem("avatar");
        return {
            ...state,
            auth: false,
            token: null,
            firstName: ""
        };
    }
    if (action.type === "CONNEXION_APP") {
        localStorage.setItem("token", action.token);
        localStorage.setItem("firstName", action.firstName);
        localStorage.setItem("avatar", action.avatar !== null ? "data:" + action.contentType + ";base64," + action.avatar : '');

        isAdmin = IsAdmin();
        isSuperAdmin = IsSuperAdmin();
        
        return {
            ...state,
            auth: true,
            token: action.token,
            firstName: action.firstName,
            isAdmin: isAdmin,
            isSuperAdmin: isSuperAdmin,
            activePageMember: isAdmin,
            activePageJamSession: !isAdmin,
            avatar: action.avatar !== null ? "data:" + action.contentType+";base64,"+ action.avatar : null
        };
    }
    if (action.type === "PAGE_CHANGED") {
        return {
            ...state,
            activePageMember: action.pageMemberSelected,
            activePageJamSession: action.pageJamSessionSelected,
            activePageMasterData: action.pageMasterDataSelected
        };
    }
    if (action.type === "PARTICIPATION_STATUS") {
        return {
            ...state,
            participationJamSession: action.participationJamSession,
            jamSessionId: action.jamSessionId
        };
    }
    if (action.type === "PARTICIPATION_RESET") {
        return {
            ...state,
            participationJamSession: null,
            jamSessionId: null
        };
    }
    if (action.type === "FILL_INVITATION") {
        return {
            ...state,
            invitatedList: action.invitatedList
        };
    }
    if (action.type === "ADD_INVITATION") {
        var array = state.invitatedList.reverse();
        action.invitationItem.map(item => (
            array.push(item)
        ));

        return {
            ...state,
            invitatedList: array.reverse()
        };
    }
    if (action.type === "TOGGLE_IS_READING") {
        return {
            ...state,
            jamSessionIsReading: action.isReading
        }
    }

    if (action.type === "UPLOAD_AVATAR") {
        localStorage.setItem("avatar", action.avatar);
        return {
            ...state,
            avatar: action.avatar
        }
    }

    return state;
};

export default rootReducer;
