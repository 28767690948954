import React, { ReactNode, useEffect, useState } from "react";
import { Redirect, Route } from "react-router";
import { withRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./components/identification/Login";
import JamSessionsList from "./components/JamSession/JamSessionsList";
import DetailsJamSession from "./components/JamSession/DetailsJamSession";
import ConfirmAccount from "./components/identification/ConfirmAccount";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import TeamsManagement from "./components/masterdata/team/TeamsManagement";
import RolesList from "./components/masterdata/role/RolesList";
import ConfirmJamSession from "./components/JamSession/ConfirmJamSession";
import ModulesList from "./components/masterdata/module/ModulesList";
import RoutesList from "./components/masterdata/route/RoutesList";
import GuestResponse from "./components/JamSession/GuestResponse";
import Signup from "./components/identification/Signup";
import JamSessionParticipation from "./components/JamSession/Redirection/JamSessionParticipation";
import { connect } from "react-redux";
import CreateNewPassword from "./components/members/CreateNewPassword";
import ConfirmAccountMember from "./components/identification/ConfirmAccountMember";
import Projects from "./components/projects/Projects";
import { Home } from "./components/Home";
import { About } from "./components/About";
import { Contact } from "./components/Contact";
import { Sidebar } from "./components/Sidebar";
import ShowProfile from "./components/members/ShowProfile";
import StepsManagement from "./components/projects/StepsManagement";
import EditProject from "./components/projects/EditProject";
import UpdateJamSession from "./components/JamSession/EditJamSession/UpdateJamSession";
import { Dashboard } from "./components/Dashboard";
import { Events } from "./components/Events";
import { Advertisements } from "./components/Advertisements";
import { Parcours } from "./components/Parcours";
import { Shops } from "./components/Shops";
import InscriptionForm from "./components/JamSession/EditJamSession/InscriptionForm";
import { SystemState } from "./reducers/types";
import { composeWrappers } from "./utils/react";
import {
    AuthorizedBackendApiContext,
    UnauthorizedBackendApiContext,
    useAuthorizedBackendApiProvider,
    useUnauthorizedBackendApiProvider,
} from "./api/api";
import { Journeys } from "./components/demos/Journeys";
import { Messages, MessagingContext, useMessagingContextProvider } from "./components/messages/Messages";
import { SignalRContext, useSignalrProvider } from "./api/Signalr";
import { MembersList } from "./components/members/MembersList";
import { Services } from "./components/Services";
import ActivitiesList from "./components/masterdata/activity/ActivitiesList";
import Tenants from "./components/tenant/Tenants";
import { AvatarDownloadContext, makeAvatarDownloader } from "./components/members/AvatarDownloadContext";
import { SnackbarProvider } from "notistack";
import { ViewJamSession2 } from "./components/JamSession/EditJamSession/ViewJamSession";

export function App({
    auth,
    isAdmin,
    isSuperAdmin,
    participationJamSession,
}: {
    auth: boolean;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    participationJamSession: boolean;
}) {
    const signalrProvider = useSignalrProvider();
    const unauthorizedBackendApi = useUnauthorizedBackendApiProvider();
    const authorizedBackendApi = useAuthorizedBackendApiProvider();
    const messagingContextProvider = useMessagingContextProvider(signalrProvider, authorizedBackendApi);

    const Contexts = composeWrappers([
        (p) => <SignalRContext.Provider value={signalrProvider}>{p.children}</SignalRContext.Provider>,
        (p) => (
            <UnauthorizedBackendApiContext.Provider value={unauthorizedBackendApi}>
                {p.children}
            </UnauthorizedBackendApiContext.Provider>
        ),
        (p) =>
            authorizedBackendApi && (
                <AuthorizedBackendApiContext.Provider value={authorizedBackendApi}>
                    {p.children}
                </AuthorizedBackendApiContext.Provider>
            ),
        (p) =>
            messagingContextProvider && (
                <MessagingContext.Provider value={messagingContextProvider}>{p.children}</MessagingContext.Provider>
            ),
        (p) =>
            authorizedBackendApi && (
                <AvatarDownloadContext.Provider value={makeAvatarDownloader()}>
                    {p.children}
                </AvatarDownloadContext.Provider>
            ),
    ]);

    // temporary hack to prevent RenderRoutes from rendering twice after
    // signalr is initialized
    const [wait, setWait] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setWait(false);
        }, 200);
    }, []);

    if (wait) {
        return <></>;
    }

    const routes = (
        <RenderRoutes
            auth={auth}
            isAdmin={isAdmin}
            isSuperAdmin={isSuperAdmin}
            participationJamSession={participationJamSession}
        />
    );
    const body = (
        <Contexts>
            <SnackbarProvider>
                <Layout>{routes}</Layout>
            </SnackbarProvider>
            <ToastContainer />
        </Contexts>
    );

    if (!signalrProvider) {
        return body;
    }

    return <SignalRContext.Provider value={signalrProvider}>{body}</SignalRContext.Provider>;
}

function RenderRoutes({
    auth,
    isAdmin,
    isSuperAdmin,
    participationJamSession,
}: {
    auth: boolean;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    participationJamSession: boolean;
}) {
    function renderBlank(Comp: ReactNode) {
        return <div className="mx-auto">{Comp}</div>;
    }

    function renderAdmin(Comp: ReactNode, flex = false) {
        return (
            <Sidebar
                children={Comp}
                isAuth={auth}
                isAdmin={isAdmin}
                isSuperAdmin={isSuperAdmin}
                flex={flex}
                isFooterShown={false}
            />
        );
    }

    function renderHomeAdmin(Comp: ReactNode) {
        return (
            <Sidebar children={Comp} isAuth={auth} isAdmin={isAdmin} isSuperAdmin={isSuperAdmin} isFooterShown={true} />
        );
    }

    const routes = (
        <>
            <Route
                exact
                path="/"
                render={() =>
                    !auth
                        ? renderHomeAdmin(<Home />)
                        : isAdmin && participationJamSession === null
                        ? renderAdmin(<Dashboard isAuth={auth} isAdmin={isAdmin} />)
                        : renderAdmin(<Projects />)
                }
            />

            <Route path="/home" render={() => (auth ? renderHomeAdmin(<Home />) : <Redirect to="/" />)} />
            <Route path="/about" render={() => renderHomeAdmin(<About />)} />
            <Route path="/contact" render={() => renderHomeAdmin(<Contact />)} />
            <Route path="/services" render={() => renderHomeAdmin(<Services />)} />
            <Route path="/events" render={() => renderHomeAdmin(<Events />)} />
            <Route path="/posts" render={() => renderHomeAdmin(<Advertisements />)} />
            <Route path="/routes" render={() => renderHomeAdmin(<Parcours />)} />
            <Route path="/shops" render={() => renderHomeAdmin(<Shops />)} />
            <Route path="/login" render={() => (!auth ? renderHomeAdmin(<Login />) : <Redirect to="/" />)} />
            <Route path="/signup" render={() => (!auth ? renderHomeAdmin(<Signup />) : <Redirect to="/" />)} />
            <Route
                path="/members"
                render={() => (auth ? renderAdmin(<MembersList isAdmin={isAdmin} />) : <Redirect to="/" />)}
            />
            <Route
                path="/inscriptionGuest/:id"
                render={(props) => renderAdmin(<InscriptionForm {...props} auth={auth} />)}
            />

            <Route
                path="/jamSessions"
                render={() => (auth ? renderAdmin(<JamSessionsList isAdmin={isAdmin} />) : <Redirect to="/" />)}
            />
            <Route
                path="/viewjamsession/:id"
                render={(props) =>
                    auth ? renderAdmin(<ViewJamSession2 sessionId={props.match.params.id} />) : <Redirect to="/login" />
                }
            />
            <Route
                path="/jamsession/:id?"
                render={(props) =>
                    auth ? renderAdmin(<UpdateJamSession {...props} isAdmin={isAdmin} />) : <Redirect to="/" />
                }
            />
            {auth ? (
                <Route path="/detailsJamSession" component={DetailsJamSession} />
            ) : (
                <Route path="/detailsJamSession" render={() => <Redirect to="/" />} />
            )}

            <Route
                path="/dashboard"
                render={() => (auth ? renderAdmin(<Dashboard isAuth={auth} isAdmin={isAdmin} />) : <Redirect to="/" />)}
            />
            <Route path="/projects" render={() => (auth ? renderAdmin(<Projects />) : <Redirect to="/" />)} />
            <Route
                path="/team"
                render={() => (auth ? renderAdmin(<TeamsManagement isAdmin={isAdmin} />) : <Redirect to="/" />)}
            />
            <Route path="/role" render={() => (auth ? renderAdmin(<RolesList />) : <Redirect to="/" />)} />
            <Route path="/module" render={() => (auth ? renderAdmin(<ModulesList />) : <Redirect to="/" />)} />
            <Route path="/route" render={() => (auth ? renderAdmin(<RoutesList />) : <Redirect to="/" />)} />
            <Route path="/activity" render={() => (auth ? renderAdmin(<ActivitiesList />) : <Redirect to="/" />)} />

            <Route path="/design" render={() => (auth ? renderAdmin(<Journeys />) : <Redirect to="/" />)} />

            <Route path="/confirmAccount/:token/:userId/:pwd" component={ConfirmAccount} />
            <Route path="/confirmJamSession" component={ConfirmJamSession} />
            <Route path="/guestResponse/:jamsession/:guest" component={GuestResponse} />
            {!auth && <Route path="/createNewPassword/:token/:user" component={CreateNewPassword} />}
            <Route path="/participation/:jamsession/:member" component={withRouter(JamSessionParticipation)} />
            {!auth && <Route path="/confirmAccountMember/:token/:userId" component={ConfirmAccountMember} />}

            <Route
                path="/project/:projectId?"
                render={(props) => (auth ? renderAdmin(<EditProject {...props} />) : <Redirect to="/" />)}
            />

            <Route
                path="/profile/:userId"
                render={(props) => (auth ? renderAdmin(<ShowProfile {...props} />) : <Redirect to="/" />)}
            />
            <Route path="/step" render={() => (auth ? renderAdmin(<StepsManagement />) : <Redirect to="/" />)} />
            <Route
                path="/messages/:userId?"
                render={(props) =>
                    auth ? renderAdmin(<Messages userId={props.match.params.userId} />, true) : <Redirect to="/" />
                }
            />
            {isSuperAdmin && (
                <Route path="/tenants" render={() => (auth ? renderAdmin(<Tenants />) : <Redirect to="/" />)} />
            )}
        </>
    );
    return routes;
}

const mapStateToProps = (state: SystemState) => {
    return {
        auth: state.auth,
        isAdmin: state.isAdmin,
        isSuperAdmin: state.isSuperAdmin,
        participationJamSession: state.participationJamSession,
    };
};

export default connect(mapStateToProps)(App);
