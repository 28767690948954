import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function useTranslations() {
    const { t } = useTranslation();
    return useMemo(
        () => ({
  example: () => t("example"),
  exampleWithParam: ({name}: {name: string}) => t("exampleWithParam", {name}),
  exampleWithNestedParam: ({name, company: {name: company_name, address: company_address}}: {name: string, company: {name: string, address: string}}) => t("exampleWithNestedParam", {name, company_name, company_address}),
  section: {
    nestedSection: {
      example: () => t("section.nestedSection.example")
    },
    example: () => t("section.example")
  },
  header: {
    register: () => t("header.register"),
    logIn: () => t("header.logIn")
  },
  home:{
    videoBanner:{
      message:()=> t("home.videoBanner.message"),
      button:()=> t("home.videoBanner.button")
    }
  }
}),
        []
    );
}