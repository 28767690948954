import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt, faUser } from '@fortawesome/free-solid-svg-icons';

export default class JamSessionResume extends Component {

    constructor(props) {
        super(props);

        this.state = {
            theme: "",
            jamSessionNumber: "",
            date: new Date(),
            from: new Date(),
            to: new Date(),
            address: "",
            moderatorName: "",
            generalEvaluatorName: "",
            ownerName:""
        }
    }

    getMonth(index) {
        var array = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
        return array[index];
    }

    componentDidMount() {
        fetch(new Request("api/JamSession/GetJamSession?jamSessionId=" + this.props.jamSessionId, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        theme: response.theme,
                        jamSessionNumber: response.jamSessionNumber,
                        date: new Date(response.jamSessionDate),
                        from: new Date("2019/09/09 " + response.jamSessionHourFrom),
                        to: new Date("2019/09/09 " + response.jamSessionHourTo),
                        address: response.address,
                        moderatorName: response.moderator !== null ? response.moderator.fullName : "",
                        generalEvaluatorName: response.generalEvaluator !== null ? response.generalEvaluator.fullName : "",
                        ownerName: response.owner !== null ? response.owner.fullName : ""
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    render() {
        return (
            <Card>
                <CardContent>
                    <div className="form-group row">
                        <Typography variant="h5" component="h2" className="col-md-12">
                            <span className="badge badge-primary mr-2" style={{ fontSize: "45px" }}> </span> Inscription à la
                                Jam-Session - {this.state.jamSessionNumber}
                        </Typography>
                    </div>
                    <div className="form-group row">
                        <Typography className="col-md-12" color="textSecondary">
                            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                            {
                                this.state.date.getDate() + " " + this.getMonth(this.state.date.getMonth()) + " " +
                                this.state.date.getFullYear() + " de " + this.state.from.getHours() + ":" +
                                this.state.from.getMinutes() + " à " + this.state.to.getHours() + ":" + this.state.to.getMinutes()
                            }
                        </Typography>
                    </div>
                    <div className="form-group row">
                        <Typography className="col-md-12" >Thème: {this.state.theme}</Typography>
                    </div>
                    
                    <div className="form-group row">
                        <label className="col-md-12">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" /> {this.state.address}
                        </label>
                    </div>
                    <div className="form-group row">
                        <label className="col-md-12">
                            <FontAwesomeIcon icon={faUser} className="mr-2" /> Modérateur: {this.state.moderatorName}
                        </label>
                    </div>
                    <div className="form-group row">
                        <label className="col-md-12">
                            <FontAwesomeIcon icon={faUser} className="mr-2" /> Evaluateur Général: {this.state.generalEvaluatorName}
                        </label>
                    </div>
                    <div className="form-group row">
                        <label className="col-md-12">
                            <FontAwesomeIcon icon={faUser} className="mr-2" /> Créé par: {this.state.ownerName}
                        </label>
                    </div>
                </CardContent>
            </Card>
        );
    }
}