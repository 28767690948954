import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEye, faStar, faComments } from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { IListedMemberDto } from "../../api/services.generated";
import { useAsyncEffect, useForceUpdate } from "../../utils/react";
import { useSignalr } from "../../api/Signalr";

export function CardMember({ member }: { member: IListedMemberDto}) {
    const [loadImage, setLoadImage] = useState(false);
    const [image, setImage] = useState<string | null>(null);
    const colorsArray = ["yellow", "green", "red"];
    const color = getRandomInt(0, 2);
    const signalr = useSignalr();
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        if (!signalr) {
            return;
        }
        const s = signalr.onOnline((data) => {
            if (data.userId === member.id) {
                member.isOnline = data.online;
                forceUpdate();
            }
        });
        return () => s.unsubscribe();
    }, [signalr]);

    useAsyncEffect(async ({ wrap }) => {
        setLoadImage(true);

        try {
            const response = await wrap(
                fetch(new Request("api/Member/DownloadAvatar?memberId=" + member.id), {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    }),
                })
            );
            const [ok, response2] = [response.ok, await response.json()];
            if (ok) {
                setImage(response2.image);
            } else {
                throw new Error(response2);
            }
        } finally {
            setLoadImage(false);
        }
    }, []);

    return (
        <React.Fragment>
            {!loadImage ? (
                <div className="card mb-4 custom-shadow border-bottom-0 border-right-0 rounded">
                    <img
                        className="card-img-top"
                        src="./img/Blue-bg-mini.jpg"
                        alt="Card cap"
                        style={{ height: "60px" }}
                    />
                    {image === null ? (
                        <img
                            src="./img/logo-successteam.png"
                            className="mt-1 rounded-circle bg-white img-fluid mx-auto"
                            alt="pho"
                            style={{
                                width: "82px",
                                height: "82px",
                                position: "absolute",
                                top: "16px",
                                left: "calc(50% - 35.5px)",
                                border: "1px groove white",
                            }}
                        />
                    ) : (
                        <img
                            src={"data:image/jpeg;base64," + image}
                            className="mt-1 rounded-circle bg-white img-fluid mx-auto"
                            alt="pho"
                            style={{
                                width: "82px",
                                height: "82px",
                                position: "absolute",
                                top: "16px",
                                left: "calc(50% - 35.5px)",
                                border: "1px groove white",
                            }}
                        />
                    )}
                    <FontAwesomeIcon
                        color={colorsArray[color]}
                        icon={faStar}
                        style={{ position: "absolute", top: "82px", right: "20px", width: "22px", height: "22px" }}
                    />
                    <div className="card-body mt-4 h-100">
                        <div className="text-center">
                            <p className="mt-1">
                                <strong>{member.fullName}</strong>
                            </p>
                            {isNotNull(member.teamName) ? (
                                <p className="mt-1">
                                    <span>Equipe {member.teamName}</span>
                                </p>
                            ) : (
                                <p className="mt-1" style={{ opacity: "0" }}>
                                    <span>Autonome</span>
                                </p>
                            )}

                            {isNotNull(member.city) && isNotNull(member.country) ? (
                                <p className="mt-1">
                                    <span>
                                        {member.city}, {member.country}
                                    </span>
                                </p>
                            ) : (
                                <p className="mt-1" style={{ opacity: "0" }}>
                                    <span>Non spécifié</span>
                                </p>
                            )}
                        </div>
                        <div className="row mt-2 align-items-center">
                            <div className="col-md-5">
                                <div className="mx-auto text-center d-flex flex-row">
                                    <Tooltip title="Profil">
                                        <Button
                                            component={Link}
                                            to={"/profile/" + member.id}
                                            className="text-initial bg-white border"
                                            variant="contained"
                                            size="small"
                                            style={{maxWidth: '40px', maxHeight: '30px', minWidth: '40px', minHeight: '30px'}}
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Chat">
                                        <Button
                                            component={Link}
                                            to={"/messages/" + member.id}
                                            className="text-initial bg-white border"
                                            variant="contained"
                                            size="small"
                                            style={{maxWidth: '40px', maxHeight: '30px', minWidth: '40px', minHeight: '30px', marginLeft: '8px'}}
                                        >
                                            <FontAwesomeIcon icon={faComments} />
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="col-md-7 px-0 mt-2">
                                <p className="text-muted text-center" style={{ fontSize: "0.775rem" }}>
                                    En ligne: {member.isOnline ? "NOW" : <span>{member.lastConnection}</span>}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Skeleton />
            )}
        </React.Fragment>
    );
}

function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function isNotNull(word: string | null | undefined) {
    if (word !== null && word !== undefined && word !== "") {
        return true;
    }
    return false;
}
