import React, { Component } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default class JamSessionInvitationMemberRow extends Component {
    static displayName = JamSessionInvitationMemberRow.name;

    constructor(props) {
        super(props);
        this.state = {
            currentCount: 0,
            modal: false,
            image: null,
            loadImage: false
        };

        this.getImage = this.getImage.bind(this);
    }

    componentDidMount() {
        this.getImage();
    }

    getImage() {
        this.setState({ loadImage: true });

        fetch(new Request("api/Member/DownloadAvatar?memberId=" + this.props.id), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        image: response.image,
                        loadImage: false
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                
                this.setState({ loadImage: false });
            });
    }

    render() {
        return (
            <React.Fragment>
                {
                    !this.state.loadImage ?
                        <React.Fragment>
                            {
                                this.state.image === null ?
                                <img src="./img/user.png" className="img-fluid" alt="pho" /> :
                                <img src={"data:image/jpeg;base64," + this.state.image} className="img-fluid" alt="pho" />
                            }
                        </React.Fragment> :
                        <Skeleton />
                }
            </React.Fragment>
        );
    }
}
