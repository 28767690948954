import React, { Component } from 'react';
import { Table as MuiTable } from '@material-ui/core';
import { TableBody as MuiTableBody } from '@material-ui/core';
import { TableCell as MuiTableCell } from '@material-ui/core';
import { TableHead as MuiTableHead } from '@material-ui/core';
import { TableRow as MuiTableRow } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faCalendar } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from "@david.kucsai/react-pdf-table";
import IconImage from '../../../images/logo-successteam.png';

const style = { padding: 5 };
// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        //backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    title: {
        marginTop: 30,
        fontSize: 24,
        textAlign: 'center'
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    // table: { 
    //     display: "table", 
    //     width: "auto", 
    //     borderStyle: "solid", 
    //     borderWidth: 1, 
    //     borderRightWidth: 0, 
    //     borderBottomWidth: 0 
    // }, 
    // tableRow: { 
    //     margin: "auto", 
    //     flexDirection: "row" 
    // }, 
    // tableCol: { 
    //     width: "25%", 
    //     borderStyle: "solid", 
    //     borderWidth: 1, 
    //     borderLeftWidth: 0, 
    //     borderTopWidth: 0 
    // }, 
    // tableCell: { 
    //     margin: "auto", 
    //     marginTop: 5, 
    //     fontSize: 10 
    // }
});

// Create Document Component
const MyDocument = () => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text>Section #1</Text>
            </View>
            <View style={styles.section}>
                <Text>Section #2</Text>
            </View>
        </Page>
    </Document>
);

export default class EditJamSessionAgendaMember extends Component {

    constructor(props) {
        super(props);

        this.state = {
            prevTiming: null,
            prevBeginHour: this.props.beginHour,
            showPDF: false,
            agendasFeed: this.props.agendas ? this.props.agendas.map((el, index, data) => {
                return {
                    "activityName": el.activityName, 
                    "members": this.getMembers(el.members), 
                    "start": this.sumLastDates(data, index), 
                    "timing": el.timing.substr(0, 5)
                } 
            }) : []
        }

        this.printAgenda = this.printAgenda.bind(this);
    }

    getBeginHour(value) {
        const minute = value.substr(3, 2), hour = value.substr(0, 2);
        if (this.state.prevTiming !== null) {
            this.setState({
                prevTiming: (hour * 60) + minute
            });
        }

        return this.state.prevBeginHour.substr(0, 5);
    }

    sumDates(date1, date2) {
        var d1 = date1.split(":");
        var d2 = date2.split(":");
        var base = new Date(0);
        var t1 = new Date(base);
        var t2 = new Date(base);
        t1.setUTCHours(d1[0],d1[1],0);
        t2.setUTCHours(d2[0],d2[1],0);
        var t = new Date(t1.getTime() + t2.getTime() - base.getTime());

        var result = ("0" + t.getUTCHours()).slice(-2) + ":" + ("0" + t.getUTCMinutes()).slice(-2);
        return result;
    }

    sumLastDates(arr, index) {
        var auxDate = this.props.beginHour.substr(0, 5);
        for (var i = 0; i < index; i++) {
            auxDate = this.sumDates(auxDate, arr[i].timing)
        }
        return auxDate;
    }

    getMembers(arr) {
        return arr.map(e => e.fullName).join(', ');
    }

    printAgenda() {
        //alert("Cette fonctionnalité est en cours de réalisation!");
        this.setState({ showPDF: !this.state.showPDF });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        {
                            this.props.canGeneratePDF ? 
                            <Button variant="contained" className="text-initial bg-white mb-3 float-right" size="small" onClick={this.printAgenda}>
                                { 
                                    !this.state.showPDF ?
                                    <React.Fragment>
                                        <FontAwesomeIcon className="mr-1" icon={faPrint}/> Générer un PDF
                                    </React.Fragment> : 
                                    <React.Fragment>
                                        <FontAwesomeIcon className="mr-1" icon={faCalendar}/> Voir l'agenda
                                    </React.Fragment>
                                }
                            </Button> : null
                        }
                    </div>
                </div>
                {
                    !this.state.showPDF ? 
                    <div className="row">
                        <div className="col-12">
                            <MuiTable className="w-100" size="small">
                                <MuiTableHead style={{ background: "#000" }}>
                                    <MuiTableRow>
                                        <MuiTableCell style={{ color: "#fff" }}>Activités</MuiTableCell>
                                        <MuiTableCell style={{ color: "#fff" }}>Nom</MuiTableCell>
                                        <MuiTableCell style={{ color: "#fff" }}>Heure début</MuiTableCell>
                                        <MuiTableCell style={{ color: "#fff" }}>Durée</MuiTableCell>
                                    </MuiTableRow>
                                </MuiTableHead>
                                <MuiTableBody>
                                    {
                                        (this.props.agendas || []).map((row, index, data) => (
                                            <MuiTableRow key={index}>
                                                <MuiTableCell>{row.activityName}</MuiTableCell>
                                                <MuiTableCell>
                                                    {
                                                        row.members.map(member => (
                                                            <Chip className="mr-1" key={member.id} label={member.fullName} color="primary" size="small" />
                                                        ))
                                                    }
                                                </MuiTableCell>
                                                <MuiTableCell>{this.sumLastDates(data, index)}</MuiTableCell>
                                                <MuiTableCell>{row.timing.substr(0,5)}</MuiTableCell>
                                            </MuiTableRow>
                                        ))
                                    }
                                </MuiTableBody>
                            </MuiTable>
                        </div>
                    </div> : 
                    <div className="row">
                        <div className="col-md-12">
                            <PDFViewer style={{ width: "100%", height: "550px" }}>
                                <Document>
                                    <Page size="A4" style={{ margin: 20, paddingRight: 40 }}>
                                        <Image
                                            style={{marginTop: 10, marginHorizontal: "auto", width: 75, height: 75}}
                                            src={IconImage}
                                        />
                                        <Text style={{ marginBottom: 10, marginTop: 20, fontSize: 16 }}>Agenda de la jamSession N° {this.props.jamSessionNumber} du {this.props.jamSessionDate}</Text>
                                        <Text style={{ marginBottom: 10, fontSize: 14 }}>Thème : {this.props.jamSessionTitle}</Text>
                                        
                                        <Table data={this.state.agendasFeed}>
                                            <TableHeader textAlign={"center"} fontSize={12}>
                                                <TableCell style={style}>
                                                    Activités
                                                </TableCell>
                                                <TableCell style={style}>
                                                    Noms 
                                                </TableCell>
                                                <TableCell style={style} weighting={0.3}>
                                                    Début
                                                </TableCell>
                                                <TableCell style={style} weighting={0.3}>
                                                    Durée
                                                </TableCell>
                                            </TableHeader>
                                            <TableBody textAlign={"center"} fontSize={12}>
                                                <DataTableCell style={style} getContent={(r) => r.activityName}/>
                                                <DataTableCell style={style} getContent={(r) => r.members}/>
                                                <DataTableCell style={style} weighting={0.3} getContent={(r) => r.start}/>
                                                <DataTableCell style={style} weighting={0.3} getContent={(r) => r.timing}/>
                                            </TableBody>
                                        </Table>
                                    </Page>
                                </Document>
                            </PDFViewer>
                        </div>
                    </div>
                }
                
            </React.Fragment>
        );
    }
}
