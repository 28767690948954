import React, { Component } from "react";
import { toast } from 'react-toastify';
import { VerifiedInput } from '../../global/VerifiedInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';


export default class EditRole extends Component {

    constructor(props) {
        super(props);

        this.state = {
            jamSessionRoleId: "",
            jamSessionRoleName: "",
            jamSessionRoleSpecialTable: null,
            jamSessionRoleDescription:"",
            loadingData: false
        }

        this.saveJamSessionRole = this.saveJamSessionRole.bind(this);
        this.hanldeJamSessionRoleChange = this.hanldeJamSessionRoleChange.bind(this);
    }

    initFiels() {
        this.setState({
            jamSessionRoleId: "",
            jamSessionRoleName: "",
            jamSessionRoleSpecialTable: null,
            jamSessionRoleDescription: "",
            loadingData: false
        })
    }

    saveJamSessionRole() {
        this.setState({
            loadingData: true
        });

        fetch(new Request("api/JamSessionRole/SetJamSessionRole", {
            method: "POST",
            body: JSON.stringify({
                JamSessionRoleId: this.state.jamSessionRoleId,
                JamSessionRoleName: this.state.jamSessionRoleName,
                JamSessionRoleSpecialTable: this.state.jamSessionRoleSpecialTable,
                JamSessionRoleDescription: this.state.jamSessionRoleDescription
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);

            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        jamSessionRoleName: "",
                        jamSessionRoleId: "",
                        jamSessionRoleSpecialTable: null,
                        jamSessionRoleDescription: "",
                        loadingData:false
                    });

                    toast.success("Mise à jour effectuée !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                    this.props.refresh();
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    loadingData: false
                });
            });
    }

    hanldeJamSessionRoleChange(e) {
        if (e.target.value === " ") {
            this.setState({
                [e.target.name]: null
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    getRole(roleId) {

        fetch(new Request("api/JamSessionRole/GetJamSessionRole?jamSessionRoleId=" + roleId, {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        jamSessionRoleId: response.jamSessionRoleId,
                        jamSessionRoleName: response.jamSessionRoleName,
                        jamSessionRoleSpecialTable: response.jamSessionRoleSpecialTable,
                        jamSessionRoleDescription: response.jamSessionRoleDescription
                    });
                } else {
                    throw new Error(response);
                }

            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="alert alert-secondary">
                    <Button variant="contained" size="small" className="mr-1 text-capitalize btn-success-custom"
                        onClick={this.saveJamSessionRole} disabled={this.state.loadingData}>
                        <FontAwesomeIcon icon={faSave} className="mr-1" size="sm" /> Enregistrer
                    </Button>
                </div>

                <div className="form-group">
                    <VerifiedInput
                        name="jamSessionRoleName" title="Rôle" value={this.state.jamSessionRoleName}
                        checked={false} onChange={this.hanldeJamSessionRoleChange}
                        type="text" errorMessage=""
                    />
                </div>
                <div className="form-group">
                    <VerifiedInput
                        name="jamSessionRoleSpecialTable" title="Type de table"
                        value={this.state.jamSessionRoleSpecialTable === null ? " " : this.state.jamSessionRoleSpecialTable}
                        checked={false} onChange={this.hanldeJamSessionRoleChange}
                        type="select" errorMessage="" options={[
                            { value: " ", label: "Général" },
                            { value: true, label: "Table Spéciale" },
                            { value: false, label: "Table Ordinaire" }
                        ]}
                    />
                </div>
                <div className="form-group">
                    <VerifiedInput name="jamSessionRoleDescription" title="Description" checked={false}
                        value={this.state.jamSessionRoleDescription} onChange={this.hanldeJamSessionRoleChange}
                        type="textarea" errorMessage="" rows={4} />
                </div>
            </React.Fragment>
        );
    }
}