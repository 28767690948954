export function classNames(...classes: Array<[boolean, string] | string>) {
  return classes
    .map(x => {
      if (typeof x === 'string') {
        return x;
      }
      return x[0] ? x[1] : '';
    })
    .join(' ');
}
