import { ApiException } from "./services.generated";

export function getErrorMessage(e: any) {
    if (ApiException.isApiException(e)) {
        if (e.response[0] === '"' && e.response[e.response.length - 1] === '"') {
            return e.response.substring(1, e.response.length - 2);
        } else {
            return e.response;
        }
    }
    return e;
}
