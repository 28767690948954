import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PDFViewer from 'pdf-viewer-reactjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import MuiAlert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import ReactPlayer from 'react-player';

export default class ProjectStep extends Component {
    static displayName = ProjectStep.name;

    constructor(props) {
        super(props);
        this.state = {
            index: props.index,
            steps: props.steps,
            loadingFile: false,
            stepBase64: "",
            searchText: "",
            comment: "",
            nextIndex: null,
            prevIndex: null,
            fileExists: false,
            loadingVideo: true,
            tabSelected: 0,
            videoUrl: null,
            //videoUrl: 'https://vimeo.com/347119375',
            loadStep: true
        };

        this.handleInput = this.handleInput.bind(this);
        this.handleClickSearch = this.handleClickSearch.bind(this);
        this.handleTabSelected = this.handleTabSelected.bind(this);
        this.loadStepFile = this.loadStepFile.bind(this);
        this.getStep = this.getStep.bind(this);
    }

    componentDidMount() {
        this.loadStepFile(this.props.id);
        this.getStep(this.props.id);
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id)
        {
            this.loadStepFile(this.props.id);
            this.getStep(this.props.id);
        }
    } 

    loadStepFile(stepId) {
        this.setState({
            loadingFile: true
        });

        fetch(new Request("api/Step/DownloadStepFile?stepId=" + stepId), {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null])
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        stepBase64: response === null ? "" : response,
                        loadingFile: false,
                        fileExists: response !== null
                    });
                    //this.props.loadSupportsFile(response.supportFiles);
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleClickSearch() {
        alert("This is searching!");
    }

    handleTabSelected(event, value) {
        this.setState({
            tabSelected: value
        });
    }

    getStep(stepId) {
        this.setState({ loadStep: true });
        
        fetch(new Request("api/Step/GetStep?stepId=" + stepId), {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        })
        .then(response => {
            if (response.status !== 204)
                return Promise.all([response.ok, response.json()]);

            return Promise.all([response.ok, null])
        })
        .then(([ok, response]) => {
            if (ok) {
                this.setState({
                    videoUrl: response.videoUrl == null ? "" : response.videoUrl,
                    loadStep: false
                });
            } else {
                throw new Error(response);
            }
        })
        .catch(error => {
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
            this.setState({ loadStep: false });
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-md-8">
                    <Tabs className="mb-0 border-bottom" textColor="primary" variant="scrollable"
                        value={this.state.tabSelected} onChange={this.handleTabSelected} scrollButtons="on"
                        style={{ backgroundColor: "#FFF" }} TabIndicatorProps={{ style: { background: '#fd7419' } }}
                    >
                        <Tab label="Video" />
                        <Tab label="Texte" />
                    </Tabs>
                    <div hidden={this.state.tabSelected !== 0}>
                        {
                            this.state.videoUrl === "" ? 
                            <Card className="border mt-3">
                                <CardContent>
                                    <MuiAlert elevation={6} variant="filled" severity="info">Il n'existe pas de fichier video pour cette étape.</MuiAlert>
                                </CardContent>
                            </Card> : null
                        }
                        {
                            !this.state.loadStep ? 
                            <React.Fragment>
                                {
                                    this.state.videoUrl !== "" && this.state.videoUrl !== null ? 
                                    <React.Fragment>
                                        {
                                            this.state.loadingVideo ? 
                                            <div className="mt-3"><Skeleton /></div> : null
                                        }
                                    </React.Fragment> : null
                                }
                                
                                <ReactPlayer 
                                    url={this.state.videoUrl}
                                    width='100%'
                                    height={this.state.loadingVideo ? '0px' : '80vh'}
                                    style={this.state.loadingVideo ? {} : {backgroundVideo: '#000', border: '1px solid #E5E5E5'}}
                                    className="my-3"
                                    onReady={() => { this.setState({loadingVideo: false}); }}
                                    onError={(err) => { this.setState({loadingVideo: false}); console.log(err); }}
                                />
                            </React.Fragment> : <div className="mt-3"><Skeleton /></div>
                        }
                    </div>
                    <div hidden={this.state.tabSelected !== 1}>
                        {
                            !this.state.loadingFile ?
                                <Card className="border mt-3" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    <CardContent>
                                        {
                                            this.state.fileExists ? 
                                            <PDFViewer document={{ base64: this.state.stepBase64 }} /> 
                                            : <MuiAlert elevation={6} variant="filled" severity="info">Il n'existe pas un fichier principal pour cette étape.</MuiAlert>
                                        }
                                        
                                    </CardContent>
                                </Card> : <div className="mt-3"><Skeleton /></div>
                        }
                    </div>
                
                    <div className="w-100 mt-3">
                        <TextField
                            fullWidth name="comment" value={this.state.comment}
                            onChange={this.handleInput} multiline label="Commentaire" rows="4" variant="filled"
                            placeholder="Rejoignez la conversation..."
                        />
                    </div>
                    <div className="mt-3">
                        <Button className="text-initial btn-warning-custom text-white" variant="contained" size="medium">
                            <FontAwesomeIcon className="mr-1" icon={faPaperPlane} /> Ajoutez un commentaire
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
