import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { EventCard } from './EventCard';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

export function Events() {
    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>Evènements - Success Team</title>
                    <meta name="description" content="Conférences, ateliers, webinaires, networking, découvrez tous les événement entrepreneurials de la success team." />
                </MetaTags>
            </div>
            <nav className="siteStyle top-nav">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to='/dashboard'>Dashboard</Link></li>
                    <li className="breadcrumb-item active">Evènements</li>
                </ol>
            </nav>
            <div className="jumbotron d-flex align-items-center bg-dark min-vh-25 mb-0" style={{ backgroundImage: "url('./img/Orange-Background-Free.png')" }}>
                <div className="container text-center">
                    <h1 className="text-white siteStyle font-weight-bolder custom-title">EVENEMENTS</h1>
                </div>
            </div>

            <div className="bg-white custom-margin">
                <div className="container row mx-auto">
                    <div className="col-md-12">
                        <div className="siteStyle text-center custom-margin-bottom">
                            <h2 className="custom-title2">Quels sont les avantages?</h2>
                            <p className="custom-subtitle mt-3">Pourquoi participer à nos évènements est crucial pour votre développement personnel.</p>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <img src="https://www.lemagdelevenementiel.com/images/guide/produits/2020-01/satori-evenement-soiree-prestige-lieu-privatise-bigmat-103920.jpg" className="rounded img-fluid" alt="event" />
                    </div>
                    <div className="col-md-6">
                        <p className="siteStyle custom-text">
                            L'apprentissage en ligne que nous mettons en place, a pour but de vous faire avancer vers 
                            vos objectifs, à votre rythme et selon vos besoins. Cependant, nous estimons nécessaire de vous 
                            rencontrer, d'échanger, et d'avancer tous ensemble. Nos événements y sont spécialement 
                            destinés. Lors de nos trimestrielles, ce sont tous les membres de la SuccessTeam qui se 
                            réunissent autour d'un buffet. Durant nos JAM sessions, nous nous entraidons les uns les autres. 
                            Enfin vous pouvez également organiser VOTRE propre événement, avec l'aide la SuccessTeam. 
                            Jetez y un coup d'œil !
                        </p>
                    </div>
                </div>
            </div>
            <div className="custom-margin" style={{ backgroundColor: "rgb(243, 245, 249)" }}>
                <div className="container row mx-auto">
                    <div className="col-md-12">
                        <div className="siteStyle text-center custom-margin-bottom">
                            <h2 className="custom-title2">Les derniers événements</h2>
                            <p className="custom-subtitle mt-3">Restez informés de tous les évènements pour ne pas les rater!</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <EventCard
                            id="0"
                            image="./img/logo-successteam.png"
                            title="Assemblée générale de la commaunauté"
                            date="Mardi le 06 octobre 2020"
                            hour="12:30"
                            address="Région de Bruxelles, Centre ville"
                            content="At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                            praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                            excepturi."
                            isOpen={true}
                        />
                        <EventCard
                            id="1"
                            image="https://i.pinimg.com/originals/fc/36/54/fc3654c9ee566238aed667a49dc9c023.jpg"
                            title="Entreprendre et vivre de l'immobilier"
                            date="Samedi le 15 février 2020"
                            hour="09:30"
                            address="Région de Bruxelles, Capitole"
                            content="At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                            praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                            excepturi."
                            isOpen={false}
                        />
                        <EventCard
                            id="2"
                            image="https://bpifrance-creation.fr/sites/default/files/2019-11/graphiques_calculette.jpg"
                            title="Trimestrielle de fin d'année"
                            date="Jeudi le 30 avril 2020"
                            hour="18:00"
                            address="Avenue de la couronne, 127"
                            content="At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                            praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                            excepturi."
                            isOpen={false}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
