import JwtDecode from "jwt-decode";
import { IsAdmin, IsSuperAdmin } from "../components/config/IsTokenExpired";

export function useCurrentUser() {
    // todo: proper login context
    const token = localStorage.getItem("token");
    const firstName = localStorage.getItem("firstName") || "";
    const decoded = JwtDecode(token!) as any;
    return {
        userId: decoded.unique_name,
        firstName,
        isAdmin: IsAdmin(),
        isSuperAdmin: IsSuperAdmin()
    };
}
