import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faLock } from '@fortawesome/free-solid-svg-icons';

export function EventCard({id, image, title, date, content, hour, isOpen, address} : {id: string, image: string, title: string, date: string, content: string, hour: string, isOpen: boolean, address: string}) {
    return (
        <div className="card custom-shadow mt-3" /*style={{ height: "220px" }}*/>
            <div className="card-body siteStyle">
                <div className="row">
                    <div className="col-md-3">
                        <img src={image} className="rounded border mx-auto custom-image my-2" alt="value" />
                    </div>
                    <div className="col-md-6">
                        <div className="custom-subtitle font-weight-bold mt-2">
                            {title}
                        </div>
                        <div className="custom-subtitle font-weight-bold mt-2" style={{ fontSize: "1.05em" }}>
                            {date + " - " + hour + " - " + address}
                        </div>
                        <div className="mt-2" style={{ lineHeight: "20px" }}>
                            {content}
                        </div>
                    </div>
                    <div className="col-md-3 text-center mt-3" style={{ display: "table" }}>
                        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                            {
                                isOpen ? 
                                <Button className="text-initial btn-warning-custom" variant="contained" size="large">
                                    <FontAwesomeIcon className="mr-1" icon={faStar} /> Je participe
                                </Button> : 
                                <Button className="text-initial" variant="contained" size="large" disabled>
                                    <FontAwesomeIcon className="mr-1" icon={faLock} /> Fermé
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
