import React, { useState, useEffect } from 'react'
import { faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useAuthorizedBackendApi } from '../../api/api'
import { BackendService, SetTenantDto } from '../../api/services.generated'
import { toast } from "react-toastify"


 const EditTenant = ({_tenantId,refreshTenants}:{_tenantId:string | null, refreshTenants:()=>void}) => {

    const api: BackendService | null = useAuthorizedBackendApi()

    const [tenantId, setTenantId] = useState<string | null>(_tenantId)
    const [customer, setCustomer] = useState<string>()
    const [host, setHost] = useState<string>()
    const [port, setPort] = useState<number>(0)
    const [userName, setUserName] = useState<string>()
    const [password, setPassword] = useState<string>()
    const [dataBase, setDataBase] = useState<string>()
    const [btnSaveDisabled, setBtnSaveDisabled] = useState(false)

    useEffect(() => {
        if(_tenantId !== null)
            initField(_tenantId)
        else
            clearFields()
    },[_tenantId])

    async function saveTenant(){
        if(!api){
            return;
        }

        setBtnSaveDisabled(true)

        try
        {
            await api.tenant_SetTenant({
                tenantId: tenantId,
                customerName: customer,
                host: host,
                port: port,
                userName: userName,
                password: password,
                dataBase: dataBase
            } as SetTenantDto)

            clearFields()
            refreshTenants()
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        }
        finally{
            setBtnSaveDisabled(false)
        }
        
    }

    function clearFields(){
        setTenantId(null)
        setCustomer("")
        setHost("")
        setPort(0)
        setUserName("")
        setPassword("")
        setDataBase("")
    }

    async function initField(tenantid:string | null){
        if(!api){
            return;
        }

        try
        {
            const response: SetTenantDto | null = await api.tenant_GetTenant(tenantid)
            if(response){
                setTenantId(response.tenantId !== undefined ? response.tenantId : null)
                setCustomer(response.customerName ? response.customerName : "")
                setHost(response.host ? response.host : "")
                setPort(response.port)
                setUserName(response.userName ? response.userName : "")
                setDataBase(response.dataBase ? response.dataBase : "")
                setPassword("")
            }
            
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        }
    }
    
    return (
        <Card>
            <CardContent>
                <div className="alert alert-secondary">
                    <Button size="small" variant="contained" className="bg-success text-white mr-3" onClick={saveTenant} disabled={btnSaveDisabled}>
                        <FontAwesomeIcon icon={faSave} className="mr-2" /> Sauvegarder
                    </Button>
                    <Button size="small" variant="contained" color="secondary" onClick={clearFields}>
                        <FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> Annuler
                    </Button>
                </div>
                <div className="form-group">
                    <TextField label="Client" variant="filled" value={customer} fullWidth
                        onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setCustomer(e.target.value)} />
                </div>
                <div className="form-group">
                    <TextField label="Hôte" variant="filled" value={host} fullWidth
                        onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setHost(e.target.value)} />
                </div>
                <div className="form-group">
                    <TextField label="Port" variant="filled" value={port} fullWidth type="number"
                        onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setPort(parseInt(e.target.value))} />
                </div>
                <div className="form-group">
                    <TextField label="Nom d'utilisateur" variant="filled" value={userName} fullWidth
                        onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setUserName(e.target.value)} />
                </div>
                <div className="form-group">
                    <TextField label="Mot de passe" variant="filled" value={password} fullWidth type="password"
                        onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setPassword(e.target.value)} />
                </div>
                <div className="form-group">
                    <TextField label="Base de données" variant="filled" value={dataBase} fullWidth
                        onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setDataBase(e.target.value)} />
                </div>
            </CardContent>
        </Card>
    )
}

export default EditTenant