import { faSignInAlt, faCheckCircle, faTimesCircle, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useState } from 'react'
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';
import { Link } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert'
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { UnauthorizedBackendApiContext } from '../../api/api';
import { SignUpDto } from '../../api/services.generated';
import { getErrorMessage } from '../../api/utils';
import { useSnackbar  } from 'notistack'


export default function Signup() {

    const context = useContext(UnauthorizedBackendApiContext)
    const { enqueueSnackbar } = useSnackbar()

    const [alertSuccess, setAlertSuccess] = useState(false)
    const [errors, setErrors] = useState<string[]>([])
    const [firstname, setFirstname] = useState<string | null>(null)
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordStrength, setPasswordStrength] = useState("")
    const [progress, setProgress] = useState(0)
    const [color, setColor] = useState("bg-secondary")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [btnSignUpDisabled, setBtnSignUpDisabled] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)

    function isPassword() {
        return password.match(/[a-z]/g)
            && password.match(/[A-Z]/g)
            && password.match(/[0-9]/g)
            && (password.length >= 8)
            && password.match(/[@_#$%^&|*!]/g);
    }

    async function signUp() {
        var _errors = [];

        if (!firstname)
            _errors.push("Entrer votre prénom");
        if (!lastname)
            _errors.push("Entrer votre nom");
        if (!email)
            _errors.push("Entrer votre E-mail");
        else if (!isEmail(email))
            _errors.push("Votre E-mail n'est pas valide");
        if (!password)
            _errors.push("Entrer votre mot de passe");
        else if (!isPassword())
            _errors.push("Votre mot de passe n'est pas valide");
        else if (password !== confirmPassword)
            _errors.push("La confirmation du mot de passe est différente du mot de passe");

        setErrors(_errors)
        setBtnSignUpDisabled(true)

        if (_errors.length > 0) {
            setBtnSignUpDisabled(false)
            return;
        }

        enqueueSnackbar("Compte en cours de création...",{
            variant:"info",
            anchorOrigin:{
                vertical:"top",
                horizontal:"left"
            }
        })

        if(!context){
            return;
        }

        try
        {
            await context.member_Signup({
                password:password,
                confirmPassword:confirmPassword,
                email:email,
                lastName:lastname,
                firstName:firstname
            } as SignUpDto)

            setAlertSuccess(true)
            setOpenSnackbar(false)
        }
        catch(error){
            setBtnSignUpDisabled(false)
            enqueueSnackbar(getErrorMessage(error),{
                variant:"warning",
                anchorOrigin:{
                    vertical:"top",
                    horizontal:"left"
                }
            })
        }
    }



    function isEmail(email:string) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }

    function handleCloseSnackbar(event:object, reason:string) {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false)
    }

    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>Créer un compte - Success Team</title>
                    <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </MetaTags>
            </div>
            <nav className="siteStyle top-nav">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><h1 className="siteTitle">Créer un compte</h1></li>
                </ol>
            </nav>
            <div className="main-content">
                <div className="container">
                    <AppBar color="transparent">
                        <Toolbar>
                            <Typography style={{ flex: 1 }}>
                                <Link to="/">
                                    <img src="./img/logo-successteam.png" alt="logo" width="60" />
                                </Link>
                            </Typography>
                            <Link to="/login">
                                <Button variant="contained" className="bg-white">
                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> S'identifier
                                </Button>
                            </Link>
                        </Toolbar>
                    </AppBar>
                    <div className="row justify-content-md-center">
                        <div className="col-lg-6 col-md-8 col-sm-10 col-12">
                            <div className="login-screen">
                                <div className="login-box">
                                    {
                                        alertSuccess ?
                                            <MuiAlert elevation={6} variant="filled" severity="success">
                                                Un email vous a été envoyé. Consultez votre Inbox ou votre SPAM. <br />
                                                Merci de cliquer sur le bouton pour confirmer votre compte.
                                            </MuiAlert> :
                                            <React.Fragment>
                                                <Link to="/" className="pointer text-center">
                                                    <div className="login-logo">
                                                        <span style={{ color: "#5061a2", fontSize: "1.5rem" }} className="font-weight-bold mx-2">AKILI</span>
                                                        <span style={{ color: "#fd751a", fontSize: "1.5rem" }} className="font-weight-bold">PORTAL</span>
                                                    </div>
                                                </Link>
                                                {
                                                    errors.length > 0 &&
                                                    <MuiAlert elevation={6} variant="filled" severity="warning" className="mb-3">
                                                        <ul>
                                                            {
                                                                errors.map(error => ( <li>{error}</li>))
                                                            }
                                                        </ul>
                                                    </MuiAlert>
                                                }

                                                <div className="row form-group">
                                                    <div className="col-md-6">
                                                        <TextField name="firstname" label="Prénom" value={firstname} variant="filled" className="mb-md-0 mb-3 w-100"
                                                            onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setFirstname(e.target.value)} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <TextField name="lastname" label="Nom" value={lastname} variant="filled" className="w-100"
                                                            onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setLastname(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    
                                                    <div className="col-md-12">
                                                        <TextField name="email" label="Email" value={email} className="w-100" variant="filled" type="email"
                                                            onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setEmail(e.target.value)} />
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group row">
                                                    <div className="col-md-6">
                                                        <TextField name="password" label="Mot de passe" value={password} className="w-100" variant="filled" type="password" inputProps={{ autoComplete: 'off' }}
                                                            onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setPassword(e.target.value)} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div>Le mot de passe est : <span style={{ fontWeight: "bold" }}>{passwordStrength}</span>.</div>
                                                        <LinearProgress 
                                                            variant="determinate" value={progress} className="mt-2" classes={{ bar: color }}
                                                            style={{ backgroundColor : "rgba(182, 188, 226, 0.4)", height: "8px", borderRadius: "2px" }} />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-md-6">
                                                        <TextField name="confirmPassword" label="Confirmer le mot de passe" value={confirmPassword} variant="filled" type="password" className="w-100"
                                                            onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setConfirmPassword(e.target.value)}  />
                                                    </div>
                                                    <div className="col-md-6">
                                                        {
                                                            password !== confirmPassword ? 
                                                            <span>Les mots de passe ne sont pas similaires!</span> : 
                                                            <span>Les mots de passe sont similaires.</span>
                                                        }
                                                    </div>
                                                </div>
                                                
                                                {
                                                    !isPassword() ? 
                                                    <div className="form-group row">
                                                        <div className="col-md-12">
                                                            <div className="card">
                                                                <div className="card-body p-0">
                                                                    <ul className="list-group list-group-flush">
                                                                        <React.Fragment>
                                                                            <li className="list-group-item">
                                                                                {
                                                                                    !password.match(/[a-z]/g) ? <FontAwesomeIcon className="mr-2" color="red" icon={faTimesCircle} size="sm" /> 
                                                                                    : <FontAwesomeIcon className="mr-2" color="green" icon={faCheckCircle} size="sm" />  
                                                                                }
                                                                                Le mot de passe doit contenir une lettre minuscule
                                                                            </li>
                                                                        </React.Fragment>
                                                                        
                                                                        <React.Fragment>
                                                                            <li className="list-group-item">
                                                                                {
                                                                                    !password.match(/[A-Z]/g) ? <FontAwesomeIcon className="mr-2" color="red" icon={faTimesCircle} size="sm" /> 
                                                                                    : <FontAwesomeIcon className="mr-2" color="green" icon={faCheckCircle} size="sm" />  
                                                                                }
                                                                                Le mot de passe doit contenir une lettre majuscule
                                                                            </li>
                                                                        </React.Fragment>
                                                                        
                                                                        <React.Fragment>
                                                                            <li className="list-group-item">
                                                                                {
                                                                                    !password.match(/[0-9]/g) ? <FontAwesomeIcon className="mr-2" color="red" icon={faTimesCircle} size="sm" /> 
                                                                                    : <FontAwesomeIcon className="mr-2" color="green" icon={faCheckCircle} size="sm" />  
                                                                                }
                                                                                Le mot de passe doit contenir un chiffre
                                                                            </li>
                                                                        </React.Fragment>
                                                                        
                                                                        <React.Fragment>
                                                                            <li className="list-group-item">
                                                                                {
                                                                                    !password.match(/[@_#$%^&|*!]/g) ? <FontAwesomeIcon className="mr-2" color="red" icon={faTimesCircle} size="sm" /> 
                                                                                    : <FontAwesomeIcon className="mr-2" color="green" icon={faCheckCircle} size="sm" />  
                                                                                }
                                                                                Le mot de passe doit contenir un caractère spécial autorisé (@_#$%)
                                                                            </li>
                                                                        </React.Fragment>
                                                                        
                                                                        <React.Fragment>
                                                                            <li className="list-group-item">
                                                                                {
                                                                                    password.length < 8 ? <FontAwesomeIcon className="mr-2" color="red" icon={faTimesCircle} size="sm" /> 
                                                                                    : <FontAwesomeIcon className="mr-2" color="green" icon={faCheckCircle} size="sm" />  
                                                                                }
                                                                                Le mot de passe doit contenir au moins 8 caractères
                                                                            </li>
                                                                        </React.Fragment>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                                }

                                                <div className="actions clearfix">
                                                    <Button variant="contained" className="btn-block btn-warning-custom"
                                                        onClick={signUp} disabled={btnSignUpDisabled}>
                                                        <FontAwesomeIcon className="mr-2" icon={faUserPlus} /> Créer mon compte
                                                    </Button>
                                                </div>
                                            </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
