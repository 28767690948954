import { faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useContext, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { UnauthorizedBackendApiContext } from '../../api/api'
import { getErrorMessage } from '../../api/utils'
import { toast } from 'react-toastify'
import { ChangePasswordDto } from '../../api/services.generated'

export default function ChangePassword({closeDialog}:{closeDialog:()=>void}) {

    const context = useContext(UnauthorizedBackendApiContext)
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confimPassword, setConfimPassword] = useState("")
    const [disableSaveChangePassword, setDisableSaveChangePassword] = useState(false)

    async function ChangePassword(){
        setDisableSaveChangePassword(true)

        try
        {
            if(!context){
                return;
            }

            await context.member_ChangePassword({
                password: password,
                newPassword: newPassword,
                confirmPassword: confimPassword
            } as ChangePasswordDto);

            toast.success("Mot de passe modifié !", {
                hideProgressBar: false,
                position: "top-left"
            });
            closeDialog();

        }
        catch(error)
        {
            toast.warn(getErrorMessage(error) + " !", {
                position: "top-left",
                hideProgressBar: false,
            });
            setDisableSaveChangePassword(false)
        }

    }

    return (
        <Dialog open={true} onClose={closeDialog}>
            <DialogTitle className="siteStyle"><span className="h6">Changer votre mot de passe</span></DialogTitle>
            <DialogContent>
                <div className="form-group">
                    <TextField label="Votre mot de passe" value={password} fullWidth type="password"
                    onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setPassword(e.target.value)} />
                </div>
                <div className="form-group">
                    <TextField label="Nouveau mot de passe" value={newPassword} fullWidth type="password"
                    onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setNewPassword(e.target.value)} />
                </div>
                <div className="form-group">
                    <TextField label="Confirmer votre mot de passe" value={confimPassword} fullWidth type="password"
                    onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>setConfimPassword(e.target.value)} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button type="button" className="text-initial" variant="contained" color="primary" onClick={ChangePassword} disabled={disableSaveChangePassword} size="small">
                    <FontAwesomeIcon icon={faSave} className="mr-2" size="sm" /> Sauvegarder
                </Button>
                <Button type="button" className="text-initial bg-white" variant="contained" onClick={closeDialog} size="small">
                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2" size="sm" /> Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
