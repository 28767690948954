import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { ProjectStepDocuments } from "./ProjectStepDocuments";
import { ProjectStepEvaluation } from "./ProjectStepEvaluation";
import ProjectFiles from "./documentation/ProjectFiles";

export class ProjectStepDetails extends React.Component {
    static displayName = ProjectStepDetails.name;

    constructor(props) {
        super(props);
        this.state = {
            currentCount: 0,
            modal: false,
            tabSelected: 0,
            searchText: "",
            id: props.projectId,
            steps: props.steps,
            stepId: props.id,
            stepIndex: props.index,
            nextIndex: props.index + 1,
            prevIndex: props.index - 1,
            supportFiles: [],
            loadSupportFiles: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleTabSelected = this.handleTabSelected.bind(this);
    }

    componentDidMount() {
        if (this.props.stepId !== undefined) {
            this.setState({ loadSupportFiles: true });
            fetch(new Request("api/Step/GetSupportFiles?stepId=" + this.props.stepId), {
                method: "GET",
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                })
            })
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        supportFiles: response === null ? [] : response,
                        loadSupportFiles: false
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
            });
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleTabSelected(event, value) {
        this.setState({
            tabSelected: value
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-md-4">
                    <Tabs className="mb-3 border-bottom" indicatorColor="secondary" textColor="primary"
                          variant="scrollable"
                          value={this.state.tabSelected} onChange={this.handleTabSelected} scrollButtons="on"
                          style={{ backgroundColor: "#FFF" }} TabIndicatorProps={{ style: { background: "#3f51b5" } }}
                    >
                        <Tab label="Général"/>
                        <Tab label="Fichier"/>
                        <Tab label="Evaluation"/>
                    </Tabs>
                    <div hidden={this.state.tabSelected !== 0}>
                        <div className="siteStyle mt-3">
                            <div className="card" style={{ backgroundColor: "#F5F5F5" }}>
                                <div className="card-body">
                                    <p className="font-weight-bold h5">A suivre : </p>
                                    {
                                        this.props.nextIndex !== null ?
                                            <p>{(this.props.nextIndex + 1) + "- " + this.props.steps[this.props.nextIndex].stepName}</p> : null
                                    }
                                    <div className="mt-4">
                                        {
                                            this.props.prevIndex !== null ?
                                                <Button
                                                    className="float-left text-initial bg-white"
                                                    variant="contained" size="small"
                                                    onClick={this.props.gotoPrevIndex}
                                                >
                                                    <FontAwesomeIcon className="mr-1" icon={faChevronLeft} size="sm"/> Précédent
                                                </Button> : null
                                        }
                                        {
                                            this.props.nextIndex !== null ?
                                                <Button
                                                    className="float-right text-initial bg-white"
                                                    variant="contained" size="small"
                                                    onClick={this.props.gotoNextIndex}
                                                >
                                                    Suivant <FontAwesomeIcon className="ml-1" icon={faChevronRight} size="sm"/>
                                                </Button> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ProjectStepDocuments stepId={this.props.id} loadSupportFiles={this.props.loadSupportFiles}
                            supportFiles={this.props.supportFiles}
                            downloadSupportFile={this.props.downloadSupportFile}/>
                    </div>

                    <div hidden={this.state.tabSelected !== 1}>
                        <Card className="mt-4">
                            <CardContent className="pt-3">
                                <ProjectFiles projectId={this.props.projectId} isViewer={this.props.isViewer} />
                            </CardContent>
                        </Card>
                    </div>

                    <div hidden={this.state.tabSelected !== 2}>
                        <ProjectStepEvaluation 
                            stepId={this.props.id} 
                            projectId={this.props.projectId} 
                            isOwner={this.props.isOwner}
                            steps={this.props.steps}
                            projectName={this.props.projectName} 
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
