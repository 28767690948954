
import React, { Component, useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt, faTimesCircle, faUser, faTrash, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import { MemberShareProjectDto, BackendService, ShareProjectDto, PermissionDto, ProjectShareMemberDto, ProjectShareDto } from '../../api/services.generated'
import { toast } from 'react-toastify'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import { useAuthorizedBackendApi } from '../../api/api'

interface MyProps{
    projectName?:string | null,
    projectId?:string | null,
    members: MemberShareProjectDto[],
    //api:BackendService | null,
    ownerName?:string | null,
    refreshData?:boolean,
    closeModal:(refreshData?:boolean)=>void
}

const ShareProject = (props:MyProps)=>{
    const api:BackendService | null = useAuthorizedBackendApi()

    const [sharedTeamOnly, setSharedTeamOnly] = useState(true)
    const [permissionId, setPermissionId] = useState<number>()
    const [permissions, setPermissions] = useState<PermissionDto[]>([])
    const [projectSharingTeam, setProjectSharingTeam] = useState(false)
    const [permissionNameSharingTeam, setPermissionNameSharingTeam] = useState<string | null>()
    const [members, setMembers] = useState<MemberShareProjectDto[]>([])
    const [membersSelected, setMembersSelected] = useState<MemberShareProjectDto[]>()
    const [shareProjectMembers, setShareProjectMembers] = useState<ProjectShareMemberDto[]>()
    const [shareBtnDisabled, setShareBtnDisabled] = useState(false)

    useEffect(() => {
        getPermissions()
        getMembersProjectShare()
    }, [])

    const handlePermissionChanged = (e:React.ChangeEvent<{value:unknown}>) =>{
        setPermissionId(e.target.value as number)
    }

    async function handleRemoveShareProjectTeam(){
        if(!api || !props.projectId){
            return;
        }

        try
        {
            const response = await api.project_RemoveShareProjectTeam(props.projectId);
            if(response){
                toast.success("Equipe supprimée du projet !", {
                    position: "top-left",
                    hideProgressBar: false
                })
                props.closeModal(props.refreshData)
            }
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
    }

    async function handleRemoveProjectShareMember(member:ProjectShareMemberDto){
        if(!api){
            return;
        }

        try
        {
            const response = api.project_ProjectShareRemove(props.projectId, member.memberId)
            if(response){
                var value = props.members.find(f=>f.memberId === member.memberId);
                var _members = members;
    
                if(value !== undefined){
                    _members.push(value);
                }
                    
                var data = shareProjectMembers || [];
                const index = data.findIndex(f=>f.memberId === member.memberId);
                data.splice(index,1);
                setMembers(members)
                setShareProjectMembers(data)
            }
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
    }

    async function handleShareProject(){

        if(!permissionId){
            toast.warn("Sélectionner la permission !", {
                position: "top-left",
                hideProgressBar: false
            })

            return;
        }

        setShareBtnDisabled(true)

        try{
            if(sharedTeamOnly){
                await projectShareTeam()
            }else{
                await projectShareMembers()
            }
    
            toast.success("Projet partagé !", {
                position: "top-left",
                hideProgressBar: false
            })
            props.closeModal(props.refreshData)
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
        finally{
            setShareBtnDisabled(false)
        }
    }

    async function projectShareMembers(): Promise<any>{
        const members = membersSelected ? membersSelected : [];

        try
        {
            let data =  {
                projectId: props.projectId,
                membersShareProject: members.map((value:MemberShareProjectDto)=>{
                    return value.memberId !== null && value.memberId !== undefined ? value.memberId : ""
                }).filter(f=>f !== ""),
                permissionId: permissionId
            } as ShareProjectDto;
        
            if(!api){
                return;
            }

            return await api.project_SetShareProject(data)
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
    }

    async function projectShareTeam(): Promise<any>{
        if(!api){
            return;
        }

        try
        {
            return await api.project_SetShareProjectTeam({
                projectId: props.projectId,
                permissionId: permissionId
            } as ShareProjectDto)
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
    }

    async function getMembersProjectShare(){
        if(!props.projectId || !api){
            return;
        }

        try
        {
            const response:ProjectShareDto | null = await api.project_GetMembersProjectShare(props.projectId)
            if(response){
                var data: MemberShareProjectDto[] = []

                props.members.forEach((value:MemberShareProjectDto)=>{
                    if(!response.members?.some(s=>s.memberId === value.memberId)){
                        data = [...data, value];
                    }
                })

                setMembers(data)
                setProjectSharingTeam(response.shareTeam)
                setPermissionNameSharingTeam(response.permissionNameTeam)
                setShareProjectMembers(response.members ? response.members : [])
            }
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
    }

    async function getPermissions(){
        if(!api){
            return;
        }

        try
        {
            const response:PermissionDto[] | null = await api.member_GetPermission()
            setPermissions(response ? response : [])
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
    }

    return (
        <Dialog open={true} onClose={()=>props.closeModal()} fullWidth={true} maxWidth="sm">
            <DialogTitle>Partager le projet: <strong>{props.projectName}</strong></DialogTitle>
            <DialogContent>
                <div className="form-group row">
                    <label className="col-md-4">Partager avec:</label>
                    <div className="col-md-8">
                        <Radio checked={sharedTeamOnly} onChange={()=>setSharedTeamOnly(true)} name="sharedTeamOnly" value={true} /> L'équipe
                        <Radio checked={!sharedTeamOnly} onChange={()=>setSharedTeamOnly(false)} name="sharedTeamOnly" value={false} /> Les membres
                    </div>
                </div>
                <Divider />
                <div className="form-group">
                    <FormControl className="w-100" variant="filled" size="small">
                        <InputLabel id="permission-select">Permission</InputLabel>
                        <Select labelId="permission-select" value={permissionId} 
                            onChange={handlePermissionChanged}>
                            {
                                permissions.map((item:PermissionDto)=>(
                                    <MenuItem key={item.permissionId} value={item.permissionId}>
                                        {item.permissionName}
                                    </MenuItem>
                                ))
                            } 
                        </Select>
                    </FormControl>
                </div>
                {
                    sharedTeamOnly ? 
                    <div className={projectSharingTeam ? "alert alert-success" : "alert alert-warning"}>
                        {
                            projectSharingTeam ? 
                            <div className="row justify-content-between">
                                <div className="col-10">
                                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-2 fa-lg" />   
                                    Projet partagé avec le rôle <strong>{permissionNameSharingTeam}</strong>
                                </div>
                                <div className="col-2">
                                    <IconButton style={{fontSize:"1em"}} onClick={handleRemoveShareProjectTeam}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </IconButton>
                                </div>
                            </div> : 
                            <React.Fragment>
                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2 fa-lg" />
                                Partager le projet avec les membres de votre équipe. En Sélectionnant une permission
                            </React.Fragment>
                        } 
                    </div> : 
                    <React.Fragment>
                        <div className="form-group">
                            <Autocomplete multiple={true} options={members} 
                            onChange={(e:any, values: MemberShareProjectDto[])=>setMembersSelected(values)}
                                getOptionLabel={(o:MemberShareProjectDto)=>o.fullName !== null && o.fullName !== undefined ? o.fullName : ""}
                                value={membersSelected} renderInput={(params)=>(
                                    <TextField {...params} variant="filled" label="Sélectionner membres" size="small" />
                                )} size="small" />
                        </div>
                        <List dense>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FontAwesomeIcon icon={faUser} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={props.ownerName} />
                                <ListItemSecondaryAction><span className="text-muted">Propriétaire</span></ListItemSecondaryAction>
                            </ListItem>
                            {
                                (shareProjectMembers || []).map((item:ProjectShareMemberDto)=>(
                                    <ListItem key={item.memberId?.toString()}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FontAwesomeIcon icon={faUser} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={props.members.find(m=>m.memberId === item.memberId)?.fullName} />
                                        <ListItemSecondaryAction>
                                            <span className="text-muted mr-3">{item.permissionName}</span>
                                            <FontAwesomeIcon icon={faTrash} 
                                            className="pointer" onClick={()=>handleRemoveProjectShareMember(item)} />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </React.Fragment>
                }
                
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={handleShareProject} size="small" disabled={shareBtnDisabled}>
                    <FontAwesomeIcon icon={faShareAlt} className="mr-2" /> Inviter
                </Button>
                <Button color="secondary" variant="contained" onClick={()=>props.closeModal()} size="small">
                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> Fermer
                </Button>
            </DialogActions>
        </Dialog>

    )
}


interface MyState{
    sharedTeamOnly: boolean,
    projectSharingTeam:boolean,
    permissionNameSharingTeam?:string | null,
    members:MemberShareProjectDto[],
    membersSelected?:MemberShareProjectDto[],
    shareBtnDisabled:boolean,
    permissions:PermissionDto[],
    permissionId?:number,
    shareProjectMembers?:ProjectShareMemberDto[]
}

export default ShareProject

