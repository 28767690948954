import React from 'react';
import { Link } from 'react-router-dom';
import { CardDefault } from './CardDefault';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

export function Services() {
    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>Services pour les entrepreneurs - Success Team</title>
                    <meta name="description" content="Comment developper l'intelligence collective? Découvrez nos outils de l'intelligence collective pour booster votre projet entrepreneurial." />
                </MetaTags>
            </div>
        	<nav className="siteStyle top-nav">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to='/home'>Accueil</Link></li>
                    <li className="breadcrumb-item active">Services</li>
                </ol>
            </nav>
            <div className="jumbotron d-flex align-items-center bg-dark min-vh-25 mb-0" style={{ backgroundImage: "url('./img/Orange-Background-Free.png')" }}>
                <div className="container text-center">
                    <h1 className="text-white siteStyle font-weight-bolder custom-title">NOS SERVICES</h1>
                </div>
            </div>

            <div className="bg-white custom-margin">
                <div className="container row mx-auto">
                    <div className="col-md-12">
                        <div className="siteStyle text-center custom-margin-bottom">
                            <h2 className="custom-title2">Ce que nous faisons</h2>
                            <p className="custom-subtitle mt-3">Découvrez comment nous réunissons ceux qui choisissent de grandir, d'exceller et de réussir.</p>
                        </div>
                    </div>
                    <div className="col-md-3 px-4">
                        <CardDefault
                            link="/routes"
                            title="Parcours"
                            src="./img/home-routes.jpg"
                            description="La Success Team vous propose plusieurs modules d'apprentissage. Vous souhaitez améliorer votre gestion du temps, développer votre marketing, ou suivre un projet..."
                        />
                    </div>
                    <div className="col-md-3 px-4">
                        <CardDefault
                            link="/events"
                            title="Evénements"
                            src="./img/home-events.jpg"
                            description="Inscrivez-vous et venez rencontrer en exclusivité les personnes qui font vibrer la Success Team, participez à une JAM session, ou venez soutenir nos porteurs de projets."
                        />
                    </div>
                    <div className="col-md-3 px-4">
                        <CardDefault
                            link="/posts"
                            title="Annonces"
                            src="./img/home-ads.png"
                            description="Nous rassemblons pour vous les récentes nouvelles de l'entrepreneuriat, des conseils et articles, pour vous aider à devenir l'entrepreneur conscient de demain."
                        />
                    </div>
                    <div className="col-md-3 px-4">
                        <CardDefault
                            link="/shops"
                            title="Boutiques"
                            src="./img/home-e-commerce.jpg"
                            description="Notre boutique en ligne rassemble les créations de nos membres. Nous avons à cœur de les soutenir, mais aussi de vous faire découvrir un travail de qualité."
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}