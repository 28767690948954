import React, { Component, Context } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import { FileDto, BackendService } from '../../../api/services.generated'
import { AuthorizedBackendApiContext } from '../../../api/api'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync,faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import axios, { AxiosResponse } from 'axios'

interface MyProps
{
    jamSessionId: string,
    editFile: boolean
}

interface MyState
{
    files: FileDto[],
    api: BackendService | null,
    loadFiles: boolean
}

export default class JamSessionFiles extends Component<MyProps,MyState> {
    static contextType: Context<BackendService | null> = AuthorizedBackendApiContext

    state:  MyState = {
        files: [],
        api: null,
        loadFiles: true
    }
    
    constructor(props: MyProps){
        super(props);

        this.hanleUploadJamSessionFile = this.hanleUploadJamSessionFile.bind(this);
        this.handleOpenFileDialogBox = this.handleOpenFileDialogBox.bind(this);
    }

    componentDidMount(){
        this.setState({
            api: this.context as BackendService
        },()=>this.getFiles())
    }

    getFiles(){
        this.state.api?.jamSession_GetJamSessionFiles(this.props.jamSessionId).then((response: FileDto[] | null)=>{
            this.setState({
                files:response !== null ? response : []
            })
        })
        .catch(error=>{
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        })
        .finally(()=>{
            this.setState({
                loadFiles:false
            })
        })
    }

    hanleUploadJamSessionFile(e:React.ChangeEvent<HTMLInputElement>){
        var formData = new FormData();
        var file: any = e.target.files !== null ? e.target.files[0] : null;
        
        formData.append("JamSessionId", this.props.jamSessionId);
        formData.append("JamSessionFile", file);

        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
        axios.post("api/JamSession/UploadJamSessionFile", formData)
        .then((response:AxiosResponse<any>)=>{
            this.setState({
                files:[...this.state.files,response.data]
            });
        })
        .catch(error=>{
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        })
    }

    handleOpenFileDialogBox(){
        document.getElementById("jamSessionFile")?.click();
    }

    handleRemoveJamSessionFile(fileName?:string | null){
        this.state.api?.jamSession_RemoveJamSessionFile(fileName,this.props.jamSessionId)
        .then(()=>{
            const index = this.state.files.findIndex(f=>f.fileName === fileName);
            var data =  this.state.files;
            data.splice(index,1);
            this.setState({
                files:data
            })
        })
        .catch(error=>{
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        })
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                <h3 className="siteStyle font-weight-bolder mb-3" style={{fontSize: "1.4em"}}>Fichiers</h3>
                    {
                        this.state.loadFiles ? 
                        <React.Fragment>
                            <FontAwesomeIcon icon={faSync} className="fa-spin fa-3x" /> Chargement...
                        </React.Fragment> :
                        <React.Fragment>
                            {
                                this.props.editFile && 
                                <div className="alert alert-secondary">
                                    <Button variant="contained" size="small" className="text-initial bg-white" onClick={this.handleOpenFileDialogBox}>
                                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Ajouter fichier
                                    </Button>
                                </div>
                            }
                             
                            <List>
                                {
                                    this.state.files.length > 0 ?
                                    this.state.files.map((item:FileDto,index:number)=>(
                                        <ListItem key={index}>
                                            <ListItemText primary={item.fileName} />
                                            {
                                                this.props.editFile && 
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="Delete" onClick={()=>this.handleRemoveJamSessionFile(item.fileName)}>
                                                        <FontAwesomeIcon icon={faTrash} style={{fontSize:"12px"}} />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            }
                                        </ListItem>
                                    )) : <span className="siteStyle">Aucun fichier...</span>
                                }
                            </List>
                            <input type="file" hidden id="jamSessionFile" onChange={this.hanleUploadJamSessionFile} />
                        </React.Fragment>
                    }
                </div>
            </div>
        )
    }
}
