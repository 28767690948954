import React, { useCallback, useEffect, useRef, useState } from "react";
import { LinkedList } from "./LinkedList";
import { useIsMounted } from "./useIsMounted";

export const composeWrappers = (wrappers: React.FunctionComponent[]): React.FunctionComponent => {
    return wrappers.filter(x => x({})).reduceRight(
        (Acc, Current): React.FunctionComponent => {
            return (props) => {
                const x = Acc(props);
                const child = <Current>{props.children}</Current>;
                if (!x) {
                    return child;
                }
                return <Acc>{child}</Acc>;
            };
        }
    );
};

export type AsyncEffectFuncContext = {
    wrap: <T>(p: Promise<T>) => Promise<T>;
};

export type AsyncEffectFunc = (p: AsyncEffectFuncContext) => Promise<void>;

export function useAsyncEffect(
    createGenerator: AsyncEffectFunc,
    deps: React.DependencyList,
    errorCallback: (() => void)|null = null
): { trigger: () => Promise<void> } {
    const [counter, setCounter] = useState(0);
    const pendingPromises = useRef(
        new LinkedList<{
            resolve: () => void;
            reject: (e: Error) => void;
        }>()
    );

    useEffect(() => {
        let isCanceled = false;

        const p = {
            wrap: function <T>(t: Promise<T>) {
                return new Promise<T>((resolve, reject) => {
                    t.then((x) => {
                        if (!isCanceled) {
                            resolve(x);
                        }
                    }).catch((x) => {
                        if (!isCanceled) {
                            reject(x);
                        }
                    });
                });
            },
        };

        createGenerator(p)
            .then(() => {
                // async calls should have been canceled through 'wrap' function
                pendingPromises.current.forEach((x) => {
                    x.resolve();
                });
            })
            .catch((e) => {
                console.error(e);
                if (errorCallback) errorCallback();
                pendingPromises.current.forEach((x) => {
                    x.reject(e);
                });
            });

        return () => {
            isCanceled = true;
        };
    }, [...deps, counter]);

    return {
        trigger: () => {
            setCounter((x) => x + 1);
            return new Promise<void>((resolve, reject) => {
                const r = pendingPromises.current.add({
                    resolve: () => {
                        r.detachSelf();
                        resolve();
                    },
                    reject: () => {
                        r.detachSelf();
                        reject();
                    },
                });
            });
        },
    };
}


export function useAsyncCallback<TParams = void, TReturn = void>( createGenerator: (params: TParams & {
                                               wrap: <T>(p: Promise<T>) => Promise<T>;
                                           }) => Promise<TReturn>,
                                  deps: React.DependencyList) {

    const isMounted = useIsMounted();
    const x = useCallback((pp: TParams) => {
        const p = {
            wrap: function <T>(t: Promise<T>) {
                return new Promise<T>((resolve, reject) => {
                    t.then((x) => {
                        if (isMounted.current) {
                            resolve(x);
                        }
                    }).catch((x) => {
                        if (isMounted.current) {
                            reject(x);
                        }
                    });
                });
            },
        };
        return createGenerator({...pp, ...p});
    }, [deps])
    return x;
}

export function useConsoleLifetime(name?: string) {
    useEffect(() => {
        console.log("mounted " + name);
        return () => console.log("unmounted " + name);
    }, []);
}

export function useForceUpdate() {
    const [counter, setCounter] = useState(0);
    return () => {
        setCounter((x) => x + 1);
    };
}
