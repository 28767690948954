import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { VerifiedInput } from '../../global/VerifiedInput';

export default class EditRoute extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingData: false,
            routeId: "",
            routeName: "",
            modules: [],
            modulesSelected:[]
        };

        this.handleInput = this.handleInput.bind(this);
        this.saveRoute = this.saveRoute.bind(this);
    }

    componentDidMount() {
        this.getModules();
    }

    initFields() {
        this.setState({
            routeId: "",
            routeName: "",
            modulesSelected: [],
            loadingData: false
        })
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    getModules() {
        const request = new Request("api/Module/GetModules", {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        });

        fetch(request)
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        modules: response
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
                this.setState({
                    modules: []
                });
            });
    }

    saveRoute() {
        this.setState({
            loadingData: true
        });

        fetch(new Request("api/Route/SetRoute", {
            method: "POST",
            body: JSON.stringify({
                RouteId: this.state.routeId,
                RouteName: this.state.routeName,
                Modules: this.state.modulesSelected
            }),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.initFields();

                    toast.success("Mise à jour effectuée !", {
                        position: "top-left",
                        hideProgressBar: false
                    });
                    this.props.refresh();
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    loadingData: false
                });
            });
    }

    getRoute(routeId) {
        fetch(new Request("api/Route/GetRoute?routeId=" + routeId, {
            method: "GET",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token")
            })
        }))
            .then(response => {
                if (response.status !== 204)
                    return Promise.all([response.ok, response.json()]);

                return Promise.all([response.ok, null]);
            })
            .then(([ok, response]) => {
                if (ok) {
                    this.setState({
                        routeId: response.routeId,
                        routeName: response.routeName,
                        modulesSelected: response.modules || []
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch(error => {
                toast.warn(error + " !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.setState({
                    modulesSelected: []
                });
            });
    }

    handleCheckbox(module) {
        const index = (this.state.modulesSelected || []).findIndex(s => s.moduleId === module.moduleId);
        if (index >= 0) {
            var array = this.state.modulesSelected;
            array.splice(index, 1);
            this.setState({
                modulesSelected: array
            });
        } else {
            this.setState({
                modulesSelected: [...this.state.modulesSelected, module]
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="alert alert-secondary">
                    <Button variant="contained" size="small" className="mr-1 text-capitalize btn-success-custom" onClick={this.saveRoute} disabled={this.state.loadingData}>
                        <FontAwesomeIcon icon={faSave} className="mr-1" size="sm" /> Enregistrer
                    </Button>
                </div>
                <div className="form-group">
                    <VerifiedInput
                        name="routeName" title="Nom du parcours" value={this.state.routeName}
                        checked={false} onChange={this.handleInput}
                        type="text" errorMessage=""
                    />
                </div>
                <div style={{ maxWidth: '100%' }} className="table-fixing">
                    <Paper>
                        <Table size="small">
                            <TableHead aria-label="sticky table">
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Nom du module</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.modules.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Checkbox
                                                checked={(this.state.modulesSelected || []).some(s => s.moduleId === row.moduleId)}
                                                onChange={() => { this.handleCheckbox(row) }} color="primary" size="small" />
                                        </TableCell>
                                        <TableCell>{row.moduleName}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
            </React.Fragment>
        );
    }
}
