import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { BackendService, ActivityDto } from '../../../api/services.generated'
import { toast } from 'react-toastify'

interface MyState {
    activityId:string | null,
    activityName?: string,
    activityDescription?:string,
    btnSaveDisabled:boolean,
    fieldsDisabled:boolean
}

interface MyProps{
    api: BackendService | null,
    ref:React.RefObject<EditActivity>,
    refreshActivities:()=>any
}

export default class EditActivity extends Component<MyProps,MyState> {

    state: MyState = {
        activityId:null,
        btnSaveDisabled:false,
        fieldsDisabled:false
    }

    constructor(props:MyProps){
        super(props);

        this.handleInput =  this.handleInput.bind(this);
        this.handleSaveActivity =  this.handleSaveActivity.bind(this);
        this.handleClearField =  this.handleClearField.bind(this);
    }

    handleInput(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>){
        if(e.target.name === "activityName"){
            this.setState({
                activityName:e.target.value
            })
        }
        else if(e.target.name === "activityDescription"){
            this.setState({
                activityDescription:e.target.value
            })
        }
    }

    async editActivity(activityId:string){
        if(!this.props.api){
            return;
        }

        this.setState({
            fieldsDisabled:true
        })

        try
        {
            const response:ActivityDto | null = await this.props.api.activity_GetActivity(activityId);
            this.setState({
                activityId:activityId,
                activityName: response && response.activityName ? response.activityName : "",
                activityDescription: response && response.activityDescription ? response.activityDescription : ""
            })
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        }
        finally{
            this.setState({
                fieldsDisabled:false
            })
        }
    }

    async handleSaveActivity(){
        if(this.state.activityName === undefined || (this.state.activityName !== undefined && 
            this.state.activityName.length === 0)){
                toast.warn("Le nom de l'activité ne peut pas être vide !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                return;
            }

        if(!this.props.api){
            return;
        }

        this.setState({
            btnSaveDisabled:true
        })
        
        try
        {
            await this.props.api.activity_SetActivity({
                activityId:this.state.activityId,
                activityName:this.state.activityName,
                activityDescription:this.state.activityDescription} as ActivityDto)

                toast.success("Activité sauvgardé !", {
                    position: "top-left",
                    hideProgressBar: false
                });
                this.handleClearField();
                this.props.refreshActivities();
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        }
        finally{
            this.setState({
                btnSaveDisabled:false
            })
        }
    }

    handleClearField(){
        this.setState({
            activityId:null,
            activityName:"",
            activityDescription:""
        });
    }

    render() {
        return (
            <>
                <div className="alert alert-secondary">
                    <Button variant="contained" className="text-initial bg-success mr-2 text-white" size="small" 
                    disabled={this.state.btnSaveDisabled} onClick={this.handleSaveActivity}>
                        <FontAwesomeIcon icon={faSave}  className="mr-2" /> Sauvegarder
                    </Button>
                    <Button variant="contained" className="text-initial bg-white" size="small" onClick={this.handleClearField}>
                        <FontAwesomeIcon icon={faTimesCircle}  className="mr-2" /> Annuler
                    </Button>
                </div>
                <div className="form-group">
                    <TextField label="Activité" variant="filled" className="w-100" value={this.state.activityName}
                        name="activityName" onChange={this.handleInput} disabled={this.state.fieldsDisabled} />
                </div>
                <div className="form-group">
                    <TextField label="Description" multiline rows="5" variant="filled" className="w-100" disabled={this.state.fieldsDisabled}
                    name="activityDescription" value={this.state.activityDescription} onChange={this.handleInput} />
                </div>
            </>
        )
    }
}