import { faSpinner, faCheckCircle, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UnauthorizedBackendApiContext } from '../../api/api'
import { ConfirmAccountMemberDto } from '../../api/services.generated'
import { getErrorMessage } from '../../api/utils'

export default function ConfirmAccountMember({match}:{match:any}) {
    const context = useContext(UnauthorizedBackendApiContext)
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
        async function init(){
            if(!context){
                return;
            }

            try
            {
                await context.member_ConfirmAccountMember({
                    token: match.params.token,
                    id: match.params.userId
                } as ConfirmAccountMemberDto)
                setLoaded(false)
            }
            catch(error){
                toast.warn(getErrorMessage(error) + " !", {
                    hideProgressBar: false,
                    position: "top-left"
                });
            }
        }
        init()
    }, [])

    return (
        <div className="row justify-content-md-center">
            <div className="col-md-4" style={{ marginTop: "20%" }}>
                {
                    loaded ? <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-8x" /> :
                        <div className="alert alert-success">
                            <FontAwesomeIcon icon={faCheckCircle} className="fa-2x mr-3" />
                            Votre compte a été créé. L'administrateur vous contactera lorsque le compte sera actif.
                            <div className="text-center">
                                <Link to="/">
                                    <Button variant="contained" color="primary" className="my-4">
                                        <FontAwesomeIcon icon={faHome} className="mr-2" /> PAGE D'ACCUEIL
                                    </Button>
                                </Link>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}
