import React, { Component, Context, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faFilter, faPlusCircle, faFolder, faShareAlt, faUsers, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { BackendService, ProjectInfoDto, ProjectLogoInfoDto, RouteInfoDto, MemberShareProjectDto } from '../../api/services.generated'
import { AuthorizedBackendApiContext, useAuthorizedBackendApi } from '../../api/api'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ProjectCard from './ProjectCard'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Chip from '@material-ui/core/Chip'
import { AccordionDetails } from '@material-ui/core'
import { toast } from 'react-toastify';
// @ts-ignore
import { ReactTitle, MetaTags } from 'react-meta-tags';

/*interface IProject {
    project:ProjectInfoDto,
    projectLogo?: ProjectLogoInfoDto
}*/

const Projects=()=>{
    const api:BackendService | null = useAuthorizedBackendApi()

    const [projects, setProjects] = useState<ProjectInfoDto[]>([])
    const [tabValue, setTabValue] = useState(1)
    const [routes, setRoutes] = useState<RouteInfoDto[]>([])
    const [members, setMembers] = useState<MemberShareProjectDto[]>([])
    
    useEffect(()=>{
        getRoutes()
        handleGetProjects()
        getMembers()
    },[])

    async function handleGetProjects() {
        if(!api){
            return;
        }

        try
        {
            const response: ProjectInfoDto[] | null = await api.project_GetProjects()
            if(response){
                setProjects(response ? response : [])
            }else{
                setProjects([])
            }
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            });
        }
    }

    async function getRoutes(){
        if(!api){
            return;
        }
        
        try
        {
            const response:RouteInfoDto[] | null = await api.route_GetRoutes()
            if(response){
                setRoutes(response)
            }
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
    }

    async function getMembers(){
        if(!api){
            return;
        }
        try
        {
            const response:MemberShareProjectDto[] | null = await api.member_GetMemberShareProject()
            setMembers(response ? response : [])
        }
        catch(error){
            toast.warn(error + " !", {
                position: "top-left",
                hideProgressBar: false
            })
        }
    }

    function getProjectsAccessCount(routeId?: string | null){
        const value= (projects || []).filter(f=>f.routeId === routeId && (f.owner || 
            f.shared || f.isPublic)).length;
        return value > 1 ? value+ " projets déverouillés" : value+" projet déverouillé";
    }

    return(
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>Projets - Success Team</title>
                    <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </MetaTags>
            </div>
            <nav className="siteStyle top-nav">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to='/dashboard'>Dashboard</Link></li>
                    <li className="breadcrumb-item active"><h1 className="siteTitle">Projets</h1></li>
                </ol>
            </nav>
            <div className="card border-0 shadow-none">
                <div className="card-body">
                    <div className="alert alert-secondary">
                        <Link className="pointer" to="/project">
                            <Button variant="contained" className="text-initial mr-2" size="small" color="primary">
                                <FontAwesomeIcon className="mr-1" icon={faPlusCircle} size="sm" /> Nouveau
                            </Button>
                        </Link>
                        <Button variant="contained" className="text-initial btn-info-custom" onClick={handleGetProjects} size="small">
                            <FontAwesomeIcon className="mr-1" icon={faSync} size="sm" /> Rafraîchir
                        </Button>
                        <div className="float-right">
                            <Button variant="contained" className="text-initial bg-white" size="small">
                                <FontAwesomeIcon className="mr-1" icon={faFilter} size="sm" /> Filtre
                            </Button>
                        </div>
                    </div>

                    <Tabs indicatorColor="primary" textColor="primary" className="mb-3"
                            value={tabValue} onChange={(e:React.ChangeEvent<{}>,value:number)=>setTabValue(value)}>
                        <Tab label="Mes projets" icon={<FontAwesomeIcon icon={faFolder} />} />
                        <Tab label="Tous les projets" icon={<FontAwesomeIcon icon={faShareAlt} />} />
                        <Tab label="Partagés avec moi" icon={<FontAwesomeIcon icon={faUsers} />} />
                    </Tabs>
                    <div role="tabpanel" hidden={tabValue !== 0}>
                        <div className="row">
                            {
                                projects.filter(p => p.owner).map((item:ProjectInfoDto) => (
                                    <ProjectCard projectId={item.projectId} elevatorPitch={item.elevatorPitch}
                                        key={item.projectId === null ? undefined : item.projectId}
                                        routeName={item.routeName} granted={true} projectName={item.projectName} 
                                        fullName={item.createdBy} createdDate={item.createdDate} canShared={true}
                                        logoId={item.projectLogoId} firstName={item.createdByFirstName}
                                        createdById={item.createdById} members={members} />
                                ))
                            }
                        </div>
                    </div>
                    <div role="tabpanel" hidden={tabValue !== 1}>
                        {
                            routes.map((route:RouteInfoDto)=>(

                            projects.some(p=>p.routeId === route.routeId) &&
                            <Accordion key={route.routeId === null ? undefined : route.routeId}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <p style={{flexBasis:"50%",flexShrink:0}} className="mb-0">
                                        <FontAwesomeIcon icon={faFolderOpen} className="mr-1" />{route.routeName}</p>
                                    <Chip color="primary" label={getProjectsAccessCount(route.routeId)} size="small" />
                                </AccordionSummary>
                                <AccordionDetails>
                                <div className="row w-100">
                                    {
                                        projects.filter(f=>f.routeId === route.routeId).map((item:ProjectInfoDto) => (
                                            <ProjectCard projectId={item.projectId} key={item.projectId === null ? undefined : item.projectId} 
                                                firstName={item.createdByFirstName} createdById={item.createdById} routeName={item.routeName}
                                                granted={item.owner || item.shared || item.isPublic} canShared={item.owner} members={members}
                                                fullName={item.createdBy} createdDate={item.createdDate} elevatorPitch={item.elevatorPitch}
                                                logoId={item.projectLogoId} projectName={item.projectName} />
                                        ))
                                    }
                                </div>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }

                    </div>
                    <div role="tabpanel" hidden={tabValue !== 2}>
                        <div className="row">
                            {
                                projects.filter(p => p.shared).map((item:ProjectInfoDto) => (
                                    <ProjectCard projectId={item.projectId} routeName={item.routeName}
                                        key={item.projectId === null ? undefined : item.projectId} canShared={false}
                                        granted={true} projectName={item.projectName} fullName={item.createdBy} 
                                        createdDate={item.createdDate} elevatorPitch={item.elevatorPitch} 
                                        firstName={item.createdByFirstName} createdById={item.createdById} members={[]}
                                        logoId={item.projectLogoId}  /> 
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Projects