import React, { Component } from 'react';
import RegisterJamSession from './Register/RegisterJamSession';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export default class ConfirmJamSession extends Component {

    constructor(props) {
        super(props);
        this.state = {
            params: new URLSearchParams(this.props.location.search)
        }
    }

    render() {
        const notRegister = false;
        return (
            <div className="container">
                <Paper className="p-5">
                    {
                        this.state.params.get("participation") === "1" ?
                            <React.Fragment>
                                <Typography variant="h5" component="h3">
                                    Enregistrement Jam Session
                                </Typography>
                                <RegisterJamSession isRegistered={notRegister}
                                    jamSessionId={this.state.params.get("jamSession")}
                                    memberId={this.state.params.get("member")} />
                            </React.Fragment> :
                            <div className="alert alert-info">
                                <strong>
                                    Nous expérons que vous allez assister à
                                    la prochaine séance. Mais vous avez la possibilité de choisir d'assister
                                    1 jour avant la date de la séance.
                                </strong>
                            </div>

                    }
                    
                </Paper>
            </div>
        );
    }
}
